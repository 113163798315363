import { FetchUtils } from "../../utils/fetch.utils";
import { Subscription } from "../models/subscription";
import { SubscriptionFormData } from "../models/subscriptionFormData";
import { PaginatedSubscription } from "../models/adminSubscription";
import { Filter } from "../models/filter";
import appConfig from "../../utils/appConfig";
import { FilteredAdminSubscription } from "../selectors/subscription.selector";

const apiBaseUrl = appConfig.apiBaseUrl;
const subscriptionApi = 'subscription';

const getSubscriptions = (): Promise<Subscription[]> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/GetMySubscriptions`;
  let response = FetchUtils.GET<Subscription[]>(URL);
  return response;
};

const getSubscriptionFormData = (): Promise<SubscriptionFormData> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/GetSubscriptionFormData`;
  let response = FetchUtils.GET<SubscriptionFormData>(URL)
  return response;
};


const addSubscription = (subscription: Subscription): Promise<Subscription> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/AddSubscription`;
  let response = FetchUtils.POST<Subscription>(URL, subscription);
  return response;
}

const saveSubscription = (subscription: Subscription): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/UpdateSubscription`;
  let response = FetchUtils.PUT<boolean>(URL, subscription);
  return response;
}

const updateSubscriptionActive = (subscriptionId: number, isActive: boolean): Promise<Date> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/UpdateSubscriptionActive?subscriptionId=${subscriptionId}&isActive=${isActive}`;
  let response = FetchUtils.PUT<Date>(URL);
  return response;
}

const deleteSubscription = (subscriptionId: number): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/DeleteSubscription?subscriptionId=${subscriptionId}`;
  let response = FetchUtils.DELETE<boolean>(URL);
  return response;
}

const getSubscriptionById = (subscriptionId: number): Promise<Subscription> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/GetSubscriptionBySubscriptionId?subscriptionId=${subscriptionId}`;
  let response = FetchUtils.GET<Subscription>(URL);
  return response;
}

const getAdminSubscriptions = (pageSize: number, currentPage: number, filters?: Filter<FilteredAdminSubscription>[], categoryId?: number, sortField?: string[], sortDirection?: string): Promise<PaginatedSubscription> => {
  const URL = `${apiBaseUrl}/${subscriptionApi}/GetAllSubscriptions?pageSize=${pageSize}&currentPage=${currentPage}${categoryId ? `&categoryId=${categoryId}` : ''}&sortField=${sortField?.join(',')}&desc=${sortDirection === "desc"}`;
  let response = FetchUtils.POST<PaginatedSubscription>(URL, filters);
  return response;
}

export const subscriptionService = {
  getSubscriptions,
  addSubscription,
  getSubscriptionFormData,
  getSubscriptionById,
  saveSubscription,
  updateSubscriptionActive,
  deleteSubscription,
  getAdminSubscriptions
};
