import React, { useEffect, useState } from "react";
import Check from "@mui/icons-material/Check";
import { useHistory,useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reportActions } from "../../redux/actions/report.actions";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import SubscriptionForm from "./SubscriptionForm";
import RecurrenceForm from "./RecurrenceForm";
import SubscribersForm from "./SubscribersForm";
import { SubscriptionExtension } from "../../redux/models/subscriptionExtension";
import {
  CreateDefaultSubscriptionFormValidation,
  CreateEditSubscriptionFormValidation,
} from "../../redux/models/subscriptionFormValidation";
import { CreateDefaultSubscription } from "../../redux/models/subscription";
import DeleteSubscriptionDialog from "../DeleteSubscriptionDialog/DeleteSubscriptionDialog";
import {
  reportFilters as selectReportFilters,
  reportSlicers as selectReportSlicers,
  selectSelectedReport,
} from "../../redux/selectors/report.selector";
import {
  selectFormData,
  selectSelectedSubscription,
  selectDefaultTimezone,
  selectSaved,
  selectFieldsValid,
  selectParameters,
  selectValidateFacilities,
  selectFormLoading,
  FilteredSubscription,
} from "../../redux/selectors/subscription.selector";
import "./SubscriptionWizard.scss";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import { Button, CircularProgress, Dialog } from "@mui/material";
import CommonStepper from "../Common/CommonStepper";
import NewDeleteDialog from "../Common/NewDeleteDialog";



export interface SubscriptionWizardProps {
    action: "add" | "edit";
    admin: boolean;
    open:boolean;
    setOpen: (open: boolean) => void;
    reportId?:number;
    subscription?:FilteredSubscription;
    filter?: string;

}



function SubscriptionWizard({
    action, admin,open,setOpen,reportId,subscription,filter 
}: Readonly<SubscriptionWizardProps>) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isStyleOpen, setIsStyleOpen]=useState<boolean>(open);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = admin ? ["General", "Recurrence", "Subscribers"] : ["General", "Recurrence"];
  const selectedReport = useSelector(selectSelectedReport);
  const formData = useSelector(selectFormData);
  const selectedSubscription = useSelector(selectSelectedSubscription);
  const isUserAdmin = useSelector(isUserAdministrator);
  const defaultTimezone = useSelector(selectDefaultTimezone);
  const saved = useSelector(selectSaved);
  const fieldsValid = useSelector(selectFieldsValid);
  const parameters = useSelector(selectParameters);
  const isFormLoading = useSelector(selectFormLoading);
  const validateFacilities = useSelector(selectValidateFacilities);
  const reportFilters = useSelector(selectReportFilters);
  const reportSlicers = useSelector(selectReportSlicers);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  
  // const { id, sid, filter } = useParams<{
  //   id: string;
  //   sid: string;
  //   filter: string;
  // }>();

  useEffect(() => {
    if (!isUserAdmin && admin) {
      // go to regular user page if the user is not an admin, intentionally refresh page
      window.location.replace(
        `${history.location.pathname.replace("admin", "reports")}`
      );
    } else {
      if (selectedReport?.reportId === 0&&reportId) {
        dispatch(reportActions.getReportById(reportId));
      }
      dispatch(subscriptionActions.getSubscriptionFormData());
      if (subscription?.subscriptionId) {
        dispatch(subscriptionActions.getSubscriptionById(subscription?.subscriptionId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action === "add") {
      if (
        selectedReport?.reportId !== 0 &&
        formData?.timezones?.length > 0 &&
        defaultTimezone
      ) {
        dispatch(
          subscriptionActions.setSubscription(
            CreateDefaultSubscription(
              parameters,
              JSON.stringify(reportFilters),
              JSON.stringify(reportSlicers),
              defaultTimezone,
              admin
            )
          )
        );
        dispatch(
          subscriptionActions.validateSubscription(
            CreateDefaultSubscriptionFormValidation(),
            false
          )
        );
      }
    } else {
      dispatch(
        subscriptionActions.validateSubscription(
          CreateEditSubscriptionFormValidation(),
          false
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedReport, defaultTimezone]);

  useEffect(() => {
    if (filter) {
      dispatch(subscriptionActions.seSubscriptiontFilters(parameters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscription]);

  useEffect(() => {
    if(saved){
      setOpen(false);
    }
  }, [saved]);

  useEffect(() => {
    // As soon as the form data loads, check if there is only 1 file extension coming back
    // If so, and if the user is adding a new subscription,
    // set the file type by default
    if (formData?.fileExtensions) {
      if (action === "add")
        if (formData.fileExtensions.length >0) {
          let extensions = [
            {
              subscriptionExtensionId: 0,
              subscriptionId: 0,
              exportFileExtensionId:
                formData.fileExtensions[0].exportFileExtensionId,
            } as SubscriptionExtension,
          ];
          dispatch(subscriptionActions.setSubscriptionExtensions(extensions));
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  
  const handleClose = () => {
    setIsStyleOpen(false);
    setTimeout(()=>{
      setOpen(false);
    },200)
  };

  function isFormValid(subscription: any) {
    if (subscription.subscriptionExtensions.length === 0||(subscription.jobSchedule?.jobScheduleFreqTypeId ===
        formData.jobScheduleFreqType?.filter(
          (t) => t.description === "Weekly"
        )[0]?.jobScheduleFreqTypeId &&
      subscription.jobSchedule?.jobScheduleDaysOfWeek?.length === 0)||(parseInt(subscription.jobSchedule.runTime.split(":")[0]) === 0 ||
      parseInt(subscription.jobSchedule.runTime.split(":")[0]) > 12)||((admin &&
        validateFacilities &&
        selectedSubscription.subscriptionTenantGroup?.subscriptionTenants
          ?.length) ??
      0)) {
      return false;
    }
    return true;
  }

  function validateForm() {
    if (admin) {
      dispatch(
        subscriptionActions.validateAdminSubscription(fieldsValid, true)
      );
    } else {
      dispatch(subscriptionActions.validateSubscription(fieldsValid, true));
    }

    let subscription = selectedSubscription;
    subscription.reportId = reportId;

    if (subscription.jobSchedule.jobScheduleFreqTypeId === 0) {
      subscription.jobSchedule.jobScheduleFreqTypeId =
        formData.jobScheduleFreqType[0]?.jobScheduleFreqTypeId;

      if (subscription.jobSchedule.jobScheduleDaysOfWeek?.length === 0) {
        subscription.jobSchedule.jobScheduleDaysOfWeek =
          formData.daysOfWeek.map((d) => {
            return {
              jobScheduleDayOfWeekId: 0,
              jobScheduleId: selectedSubscription.jobScheduleId,
              dayOfWeekId: d.dayOfWeekId,
            };
          });
      }
    }

    return subscription;
  }

  function handleSaveBtnClick() {
    let subscription = validateForm();

    if (isFormValid(subscription)) {
      if (action === "add") {
        dispatch(subscriptionActions.addSubscription(subscription));
      } else {
        subscription.jobSchedule.jobScheduleDaysOfWeek.forEach((d) => {
          d.dayOfWeek = undefined;
          d.jobScheduleId = subscription.jobScheduleId;
          d.jobScheduleDayOfWeekId = 0;
        });
        dispatch(subscriptionActions.saveSubscription(subscription));
      }
    }
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    let subscription = validateForm();
    if (isFormValid(subscription)) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function openDeleteDialog() {
    setDeleteDialogOpen(true);
  }
  const handleDelete = () => {
    dispatch(subscriptionActions.deleteSubscription(selectedSubscription.subscriptionId, history));
    setOpen(false);
  };

  return (
    <div id="add-report-container">
     {deleteDialogOpen && (
        // <DeleteSubscriptionDialog
        //   open={open}
        //   setOpen={setDeleteDialogOpen}
        //   subscriptionId={selectedSubscription.subscriptionId}
        // />
        <NewDeleteDialog open={open} handleClose={handleClose} actionName={"Unsubscribe"} setOpen={setDeleteDialogOpen} handleDelete={handleDelete} heading={`Are you sure you want to proceed with disabling this subscription?`} />
      )}
      <Dialog open={open} onClose={handleClose} className={isStyleOpen?"dialog-right in":"dialog-right"}>
        <div className="close" onClick={handleClose} onKeyDown={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g id="ic">
                <path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
              </svg>
            </div>
        <div className="dialog-title">Scheduling {selectedReport?.name}
        {action === "edit" && (
        <svg className="del-subscription" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={openDeleteDialog}>
          <path d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        )}
        </div>
        <div className="dialog-content" data-testid="dialog-content">
        
        <CommonStepper steps={steps} activeStep={activeStep}/>
          <div className="form-container">
            {activeStep === 0 && (
              <SubscriptionForm
                action={action}
                admin={admin}
                disabled={isFormLoading}
                reportTypeId={selectedReport?.reportTypeId}
              />
            )}
            {activeStep === 1 && (
              <div className="form-row">
                <RecurrenceForm formData={formData}/>
              </div>
            )}
            {activeStep === 2 && (
              <div className="form-row">
                <SubscribersForm action={action} admin={admin} />
              </div>
            )}
          </div>
                        
                </div>
                <div className="dialog-footer">
                {activeStep > 0 && (
                <Button onClick={handleBack} disabled={isFormLoading} className="btn-cancel">
                  Back
                </Button>
              )}
               {activeStep ===0 && (
                <Button onClick={handleClose} className="btn-cancel medium-text" disabled={isFormLoading}>Cancel</Button>
              )}
                    
                    {activeStep < steps.length - 1 &&
                (isFormLoading ? (
                  <CircularProgress />
                ) : (
                  <Button onClick={handleNext} data-testid="save" className="btn-save">
                    Next
                  </Button>
                ))}
              {activeStep === steps.length - 1 && (
                <Button onClick={handleSaveBtnClick} disabled={isFormLoading} data-testid="save" className="btn-save">
                  {saved ? (
                    <div>
                      <Check />
                      Saved
                    </div>
                  ) : <>Save</>
                  }
                </Button>
              )}
                </div>
         
            
        
      </Dialog>
    </div>
  );
  
}

export default SubscriptionWizard;


