import { styled, SvgIcon, TextField } from "@mui/material";

export const guidRequired = (value: any) =>{
    if(value &&
    value.length > 0 &&
    /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi.test(
      value
    )){
        return undefined;
    }
    else if(!value || value.length === 0){
        return "is required";
    }
    else{
        return "must be a valid GUID";
    }
}
export const DropdonwArrowIcon = (props:any) => (
    <SvgIcon {...props} viewBox="0 0 18 18" fill="none" width="18" height="18">
      <path d="M14.25 6.75L9 12L3.75 6.75" fill="none" stroke="#637583" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </SvgIcon>
   );

export const SearchTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#959494",
      fontSize: "1em",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#959494",
      },
      "&:hover fieldset": {
        borderColor: "#959494",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#959494",
      },
      "& .MuiIconButton-root": {
        padding: "0px",
      },
    },
  });

    
