import { FormControl, InputAdornment, IconButton, styled, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { filterActions } from "../../redux/actions/filters.actions";
import { selectUserSearchKeyword } from "../../redux/selectors/user.selector";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const SearchTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "#959494",
      fontSize: "1em",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#959494",
      },
      "&:hover fieldset": {
        borderColor: "#959494",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#959494",
      },
      "& .MuiIconButton-root": {
        padding: "0px",
      },
    },
  });
function SearchComponent(){

  const dispatch = useDispatch();
  const searchKeyword = useSelector(selectUserSearchKeyword);
  const handleSearchText=(event:any)=>{
    dispatch(filterActions.setSearchKeyword(event.target.value))
  }
    return (
        <div className="search-bar">
              <FormControl sx={{ m: 1 }}>
                <SearchTextField
                  id="search-bar"
                  data-testid="search-bar"
                  value={searchKeyword}
                  color="primary"
                  size="small"
                  onChange={(event:any) =>
                   handleSearchText(event)
                  }
                  InputLabelProps={{ shrink: false }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            dispatch(filterActions.setSearchKeyword(""))
                          }
                          edge="end"
                          id="searchfield-test"
                          data-testid="searchfield-test"
                        >
                          {searchKeyword?.length > 0 ? (
                            <ClearIcon data-testid="clear-test"/>
                          ) : (
                            <SearchIcon data-testid="search-test" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  label={searchKeyword?.length === 0 ? "Search.." : ""}
                />
              </FormControl>
            </div>
    )
}

export const ShareTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#959494",
    fontSize: "1em",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});

export default SearchComponent;