import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Select,{SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import {
  selectNewWidget,
  selectDashboardsCount,
  selectExistingDashboards,
} from "../../redux/selectors/dashboard.selector";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./PinToDashboardDialog.scss";
import { Widget } from "../../redux/models/widget";
import { Dashboard } from "../../redux/models/dashboard";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    width: 380,
    padding: "6px 5px 5px 12px",
    color: "#00334e",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

export interface PinToDashboardDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  addingReport: boolean;
}

function PinToDashboardDialog({
  open,
  setOpen,
  addingReport,
}: PinToDashboardDialogProps) {
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [selectedDashboardId, setSelectedDashboardId] = useState<number | null>(
    null
  );
  const [newDashboardName, setNewDashboardName] = useState<string>("");
  const existingDashboards = useSelector(selectExistingDashboards);
  const newWidget = useSelector(selectNewWidget);
  const dashboardsCount = useSelector(selectDashboardsCount);
  const [isStyleOpen, setStyleOpen]=useState<boolean>(open);

  useEffect(() => {
    // Get Dashboard list
    if (open) {
      dispatch(dashboardActions.getUserDashboards(null));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (open) {
      if (existingDashboards?.length > 0) {
        var firstValidDash = existingDashboards.find(
          (ed) =>
            (addingReport && ed.dontAllowAddReport === false) ||
            (!addingReport && ed.dontAllowAddReportVisual === false)
        );
        if (firstValidDash) {
          setSelectedOption(1);
          setSelectedDashboardId(firstValidDash.dashboardId);
          return;
        }
      }

      setSelectedDashboardId(null);
      setSelectedOption(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, existingDashboards]);

  useEffect(()=>{
    setTimeout(()=>{
      setStyleOpen(open);
    },500)
  },[open])

  const handleClose = () => {
    setStyleOpen(false)
    setTimeout(()=>{
      setOpen(false);
    },200)
  };

  const handleOptionChange = (event: any, value: string) => {
    setSelectedOption(+value);
  };

  const handleSubmit = () => {
    if(newWidget.reportVisual)
      newWidget.reportVisual.reportId=newWidget.reportId;
    dispatch(
      dashboardActions.addWidget({
        ...newWidget,
        dashboardId: selectedOption === 1 ? selectedDashboardId : 0,
        dashboard:
          selectedOption === 1
            ? existingDashboards.find(
                (ed) => ed.dashboardId === selectedDashboardId!
              )
            : ({ name: newDashboardName } as Dashboard),
      } as Widget)
    );
    setOpen(false);
  };


  const handleDashboardChange = (event: SelectChangeEvent<number | string>) => {
    var ed = existingDashboards.find(
      (d) => d.dashboardId === +event.target.value
    );
    if (addingReport && ed?.dontAllowAddReport) return;
    if (!addingReport && ed?.dontAllowAddReportVisual) return;
    setSelectedDashboardId(+event.target.value);
  };

  const handleDashboardNameChange = (event: any) => {
    setNewDashboardName(event.target.value);
  };

  return (
    <div id="pin-dashboard-container">
      <Dialog open={open} onClose={handleClose} className={isStyleOpen?"dialog-right in":"dialog-right"}>
        <div className="close" onClick={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g id="ic">
                <path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
              </svg>
            </div>
        <div className="dialog-title">Pin To Dashboard</div>
        <div className="dialog-content">
          {
            <ToggleButtonGroup
              color="primary"
              value={selectedOption}
              exclusive
              onChange={handleOptionChange}
              size="large"
              aria-label="Platform"
            >
              <ToggleButton value="1" className={selectedOption===1?'selected':''} disabled={dashboardsCount === 0}>Existing dashboard</ToggleButton>
              <ToggleButton value="2" className={selectedOption===2?'selected':''} >New dashboard</ToggleButton>
            </ToggleButtonGroup>
          }
          {selectedOption === 1 && (
            <div className="existing-container">
              <div className="select-lable">Select Dashboard</div>
              <Select
                id="existing-dashboard-select"
                className="select-xlarge"
                value={selectedDashboardId ?? ""}
                onChange={handleDashboardChange}
                input={<Input />}
                IconComponent={KeyboardArrowDownIcon}
                style={{ position: "relative" }}
              >
                {existingDashboards.map((d, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={d.dashboardId}
                      className={
                        "pd-menuitem medium-text blue-text " +
                        ((addingReport && d.dontAllowAddReport) ||
                        (!addingReport && d.dontAllowAddReportVisual)
                          ? "pd-disabled"
                          : "")
                      }
                    >
                      <div className="pd-menuitem-inner">
                        {((addingReport && d.dontAllowAddReport) ||
                          (!addingReport && d.dontAllowAddReportVisual)) && (
                          <div className="pd-popover">
                            <div>{d.dontAllowAddMessage}</div>
                          </div>
                        )}
                        <div className="db-name">{d.name}</div>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
          )}
          {selectedOption === 2 && (
            <div className="new-container">
             <div className="select-lable">
                Dashboard Name
                <span className="warning small-text">*</span>
              </div>
              <input
                value={newDashboardName}
                placeholder="Enter name"
                onChange={handleDashboardNameChange}
                maxLength={50}
              />
              <div
                className={
                  50 - newDashboardName.length === 0 ? "small-error" : "small"
                }
              >
                Limit: 50 characters ({50 - newDashboardName.length} characters
                remaining)
              </div>
            </div>
          )}
        </div>
        <div className="dialog-footer">
          <Button onClick={handleClose} className="btn-cancel medium-text">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className="btn-save medium-text"
            disabled={
              (selectedOption === 1 && selectedDashboardId == null) ||
              (selectedOption === 2 && newDashboardName.length === 0)
            }
          >
            Save
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default PinToDashboardDialog;
