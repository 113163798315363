import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMsal, useAccount } from "@azure/msal-react";
import { AuthClaim } from "../../redux/models/authClaim";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { SideNavBarIcons } from "../SideNavBar/SideNavItem/SideNavBarIcons";
import { Box } from "@mui/material";

export interface UserCardProps {
  isCollapsed: boolean;
}

function UserCard({ isCollapsed }: UserCardProps) {
  let history = useHistory();

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [userFullName, setUserFullName] = useState<string>("");

  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  let userClaims: AuthClaim = account?.idTokenClaims as AuthClaim;

  let dropdownRef = useRef<HTMLDivElement>(null);

  const onClickEvent = (event: MouseEvent) => {
    if (
      event.target instanceof Element &&
      dropdownRef &&
      !dropdownRef.current?.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", onClickEvent);
    setUserFullName(userClaims?.name);
    return function cleanup() {
      window.removeEventListener("click", onClickEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dropdown = (
    <div className="dropdown">
      <div
        onClick={() => {
          history.push("/logout");
        }}
      >
        {!isCollapsed && <div>Sign out</div>}
        <SideNavBarIcons iconName={"logout"} />
      </div>
    </div>
  );


  return (
    <div id="user-card-container" className="no-select">
      {isDropdownOpen && dropdown}
      <div ref={dropdownRef} className="top-nav-item">
        <div
          className="top-nav-item-inner"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span className="user-logo-name">{userFullName ? userFullName.split(" ")?.map(name => name[0]): "N/A"}</span>
          {!isCollapsed && <Box paddingLeft="24px">
            <Box display="flex" justifyContent="space-between" width="195px">
            <div className="user-name">{userFullName}</div>
          <div className="button">
          <MoreHorizOutlinedIcon
            style={{ display: isDropdownOpen ? "none" : "" }}
          />
          <MoreVertOutlinedIcon style={{ display: isDropdownOpen ? "" : "none" }} />
          </div>
            </Box>
            </Box>}
        </div>
      </div>
    </div>
  );
}

export default UserCard;
