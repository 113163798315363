import { AppState } from "../../redux/reducers";
import { SubscriptionEmailDownload } from "../models/subscriptionEmailDownload";


const getSubscriptionEmailDownload = (s: SubscriptionEmailDownload | null, downloadLoading: Record<string, boolean>) => {
    if (s) {
        var sed: SubscriptionEmailDownload | undefined = {
            ...s,
            completeDateUTC: s?.completeDate && new Date(`${s.completeDate}Z`),
            reportStreams: s.reportStreams.map(rs => {
                return {
                    ...rs,
                    startDateUTC: rs.startDate && new Date(`${rs.startDate}Z`),
                    completeDateUTC: rs.completeDate && new Date(`${rs.completeDate}Z`),
                    downloadLoading: downloadLoading[rs.userReportHistoryExportDetailsId.toString()],
                }
            })
        }
        return sed;
    }
}


export const selectSubscriptionEmailDownload = (state: AppState) => { return getSubscriptionEmailDownload(state.downloadReducer.subscriptionEmailDownload, state.downloadReducer.downloadLoading) };

//     return state.downloadReducer.subscriptionEmailDownload.map(report => {
//     return {
//         ...report,
//         nameLowered: report.name && report.name.toLowerCase(),
//         lastRunDateUTC: report.lastRunDate && report.lastRunDate && new Date(`${report.lastRunDate}Z`),
//         nextRunDateUTC: report.nextRunDate && report.nextRunDate && new Date(`${report.nextRunDate}Z`)
//     }
// }); }
export const selectDownloadStarted = (state: AppState) => { return state.downloadReducer.downloadStarted };
export const selectReportByEmailLoading = (state: AppState) => { return state.downloadReducer.loading };