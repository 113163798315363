import { StringLiteral } from "typescript";
import appConfig from "../../utils/appConfig";
import { FetchUtils } from "../../utils/fetch.utils";
import { UserWithDetails as UserWithDetails } from "../models/userWithDetails";
import { BlobStorageStats } from "../models/blobStorageStats";
import { PowerBIReport } from "../models/powerBIReport";
import { PowerBIWorkspace } from "../models/powerBIWorkspace";
import { Report } from "../models/report";
import { ReportCategory } from "../models/reportCategory";
import { SecurityRole } from "../models/securityRole";
import { Setting } from "../models/setting";
import { User } from "../models/user";
import { UpdateUserReportAccessModel } from "../models/updateUserReportAccessModel";

const apiBaseUrl = appConfig.apiBaseUrl;
const adminApi = 'admin';


const setSettings = (settings: Setting[]): Promise<void> => {
  const URL = `${apiBaseUrl}/${adminApi}/UpdateApplicationSettings`;
  let response = FetchUtils.POST<void>(URL, settings);
  return response;
};

const addReport = (reports: Report[]): Promise<Report[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/AddReports`;
  let response = FetchUtils.POST<Report[]>(URL, reports);
  return response;
};

const deleteReport = (reportIds: number[]): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${adminApi}/DeleteReport`;
  let response = FetchUtils.DELETE<boolean>(URL, reportIds);
  return response;
};

const saveReport = (report: Report): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${adminApi}/UpdateReport`;
  let response = FetchUtils.PUT<boolean>(URL, report);
  return response;
};

const getStorageStats = (): Promise<BlobStorageStats> => {
  const URL = `${apiBaseUrl}/${adminApi}/GetStorageStats`;
  let response = FetchUtils.GET<BlobStorageStats>(URL);
  return response;
}

const addReportCategory = (category: ReportCategory): Promise<ReportCategory> => {
  const URL = `${apiBaseUrl}/${adminApi}/AddReportCategory`;
  let response = FetchUtils.POST<ReportCategory>(URL, category);
  return response;
};

const deleteUserReportHistory = (userReportHistoryId: number): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${adminApi}/DeleteUserReportHistory?userReportHistoryId=${userReportHistoryId}`;
  let response = FetchUtils.DELETE<boolean>(URL, userReportHistoryId);
  return response;
};

const updateReportCategory = (category: ReportCategory): Promise<ReportCategory> => {
  const URL = `${apiBaseUrl}/${adminApi}/UpdateReportCategory`;
  let response = FetchUtils.PUT<ReportCategory>(URL, category);
  return response;
};

const deleteReportCategory = (categoryId: number, nextCategoryId: number | null): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${adminApi}/DeleteReportCategory?categoryId=${categoryId}${nextCategoryId !== null ? `&nextCategoryId=${nextCategoryId}` : ''}`;
  let response = FetchUtils.DELETE<boolean>(URL);
  return response;
};

const getPowerBIWorkspaces = (): Promise<PowerBIWorkspace[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/GetWorkspaces`;
  let response = FetchUtils.GET<PowerBIWorkspace[]>(URL);
  return response;
};

const getPowerBIReports = (workspaceId: string): Promise<PowerBIReport[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/GetReportsFromWorkspaceId?workspaceId=${workspaceId}`;
  let response = FetchUtils.GET<PowerBIReport[]>(URL);
  return response;
};

const getUsers = (): Promise<UserWithDetails[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/GetUsers`;
  let response = FetchUtils.GET<UserWithDetails[]>(URL);
  return response;
}

const updateUserActive = (userId: number, isActive: boolean): Promise<UserWithDetails> => {
  const URL = `${apiBaseUrl}/${adminApi}/UpdateUserActive?userId=${userId}&IsActive=${isActive}`;
  let response = FetchUtils.PUT<UserWithDetails>(URL);
  return response;
}

const getSecurityRoles = (): Promise<SecurityRole[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/GetSecurityRoles`;
  let response = FetchUtils.GET<SecurityRole[]>(URL);
  return response;
}

const updateUserSecurityRoles = (userIds: number[], roleIds: number[]): Promise<UserWithDetails[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/UpdateUserSecurityRoles`;
  let response = FetchUtils.PUT<UserWithDetails[]>(URL, {
    userIds,
    roleIds
  });
  return response;
}

const updateUserReportAccess = (data: UpdateUserReportAccessModel): Promise<UserWithDetails[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/UpdateUserReportAccess`;
  let response = FetchUtils.PUT<UserWithDetails[]>(URL, data);
  return response;
}

const updateUserDefaultDashboard = (userIds: number[], reportId: number): Promise<UserWithDetails[]> => {
  const URL = `${apiBaseUrl}/${adminApi}/UpdateUserDefaultDashboard`;
  let response = FetchUtils.PUT<UserWithDetails[]>(URL, {
    userIds,
    reportId
  });
  return response;
}

export const adminService = {
  setSettings,
  addReport,
  deleteReport,
  saveReport,
  getStorageStats,
  addReportCategory,
  updateReportCategory,
  deleteReportCategory,
  getPowerBIWorkspaces,
  getPowerBIReports,
  getUsers,
  updateUserActive,
  getSecurityRoles,
  updateUserSecurityRoles,
  updateUserReportAccess,
  updateUserDefaultDashboard,
  deleteUserReportHistory
};
