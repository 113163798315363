import { DownloadActions } from "../actions/download.actions"
import { NetworkError, ServerError } from "../models/serverError"
import { SubscriptionEmailDownload } from "../models/subscriptionEmailDownload"

type DownloadState = {
    loading: boolean;
    error?: ServerError | NetworkError;
    blob?: Blob;
    subscriptionEmailDownload: SubscriptionEmailDownload | null,
    downloadLoading: Record<string, boolean>;
    downloadStarted: boolean;
}
const initialState: DownloadState = {
    loading: false,
    error: undefined,
    blob: undefined,
    subscriptionEmailDownload: null,
    downloadLoading: {},
    downloadStarted: false
}
const DownloadReducer = (state: DownloadState = initialState, action: DownloadActions): DownloadState => {
    switch (action.type) {
        // DOWNLOAD FILE
        case 'DOWNLOAD_FILE_REQUEST':
            return {
                ...state,
                downloadLoading: { ...state.downloadLoading, [action.userReportHistoryExportDetailsId.toString()]: true },
                downloadStarted: true,
                error: undefined
            }
        case 'DOWNLOAD_FILE_SUCCESS':
            return {
                ...state,
                blob: action.blob,
                downloadLoading: { ...state.downloadLoading, [action.userReportHistoryExportDetailsId.toString()]: false },
                downloadStarted: state.downloadStarted || false,
                error: undefined
            }
        case 'DOWNLOAD_FILE_FAILURE':
            return {
                ...state,
                downloadLoading: { ...state.downloadLoading, [action.userReportHistoryExportDetailsId.toString()]: false },
                downloadStarted: state.downloadStarted || false,
                error: action.error
            }

        case 'GET_REPORTS_BY_EMAIL_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case 'GET_REPORTS_BY_EMAIL_SUCCESS':
            return {
                ...state,
                loading: false,
                subscriptionEmailDownload: action.payload ?? null
            }
        case 'GET_REPORTS_BY_EMAIL_FAILURE':
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}
export default DownloadReducer;