import { SubscriptionActions } from "../actions/subscription.actions";
import { Subscription, CreateDefaultSubscription } from "../models/subscription";
import { SubscriptionFormData,CreateDefaultSubscriptionFormData } from "../models/subscriptionFormData";
import { JobScheduleDaysOfWeek } from "../models/jobScheduleDaysOfWeek";
import { JobScheduleFreqType,CreateDefaultJobScheduleFreqType } from "../models/jobScheduleFreqType";
import { ReportCategory } from "../models/reportCategory";
import { Timezone } from "../models/timezone";
import { findWindows } from 'windows-iana';
import { PaginatedSubscription } from "../models/adminSubscription";
import { SubscriptionFormValidation, CreateDefaultSubscriptionFormValidation } from "../models/subscriptionFormValidation";
import { Filter, CreateSubscriptionFilters, CreateAdminSubscriptionFilters } from "../models/filter";
import { NetworkError, ServerError } from "../models/serverError";
import { FilteredAdminSubscription, FilteredSubscription } from "../selectors/subscription.selector";


type SubscriptionState = {
  subscriptions: Subscription[];
  subscription: Subscription;
  subscriptionFormData: SubscriptionFormData;
  getSubscriptionLoading: Record<string, boolean>;
  sortField: string;
  sortDirection: "asc" | "desc";
  adminSortField: string[];
  adminSortDirection: "asc" | "desc";
  error?: ServerError | NetworkError;
  loading: boolean;
  loadingSubcriptionForm: boolean;
  saved: boolean;
  fieldsValidation: SubscriptionFormValidation;
  selectedFreqType: JobScheduleFreqType;
  selectedDaysOfWeek: JobScheduleDaysOfWeek[];
  subscriptionsLoading: boolean;
  subscriptionSelectedCategory?: ReportCategory;
  parameters: string;
  defaultTimezone?: Timezone;
  adminSubscriptions?: PaginatedSubscription;
  adminSelectedCategory?: ReportCategory;
  selectedReportId?: number;
  pageCount: number;
  currentPage: number;
  subscriptionSearchKeyword: string;
  filterPaneOpen: boolean;
  pageFilters: Filter<FilteredSubscription>[];
  adminFilterPaneOpen: boolean;
  adminPageFilters: Filter<FilteredAdminSubscription>[];
  validateFacilities: boolean;
};
const initialState: SubscriptionState = {
  subscriptions: [],
  subscription: CreateDefaultSubscription(""),
  subscriptionFormData: CreateDefaultSubscriptionFormData(),
  getSubscriptionLoading: {},
  sortField: "nameLowered",
  sortDirection: "asc",
  adminSortField: ["emailLowered", "nameLowered"],
  adminSortDirection: "asc",
  error: undefined,
  saved: false,
  loading: false,
  loadingSubcriptionForm: false,
  fieldsValidation: CreateDefaultSubscriptionFormValidation(),
  selectedFreqType: CreateDefaultJobScheduleFreqType(),
  selectedDaysOfWeek: [],
  subscriptionsLoading: false,
  parameters: '',
  defaultTimezone: undefined,
  adminSubscriptions: undefined,
  subscriptionSelectedCategory: undefined,
  adminSelectedCategory: undefined,
  pageCount: 10,
  currentPage: 1,
  subscriptionSearchKeyword: "",
  filterPaneOpen: false,
  pageFilters: CreateSubscriptionFilters(),
  adminFilterPaneOpen: false,
  adminPageFilters: CreateAdminSubscriptionFilters(),
  validateFacilities: false
};
const SubscriptionReducer = (
  state: SubscriptionState = initialState,
  action: SubscriptionActions
): SubscriptionState => {

  switch (action.type) {
    // GET SUBSCRIPTIONS
    case 'GET_SUBSCRIPTIONS_REQUEST':
      return {
        ...state,
        loading: true,
        error: undefined,
        subscriptionsLoading: true,
        saved: false
      }
    case 'GET_SUBSCRIPTIONS_SUCCESS':
      return {
        ...state,
        subscriptions: action.payload ?? state.subscriptions,
        loading: false,
        error: undefined,
        subscriptionsLoading: false,
      }
    case 'GET_SUBSCRIPTIONS_FAILURE':
      return {
        ...state,
        subscriptions: [],
        loading: false,
        error: action.error,
        subscriptionsLoading: false
      }

    // SORT SUBSCRIPTIONS
    case 'SORT_SUBSCRIPTIONS':
      const { sortField, sortDirection } = state;
      return {
        ...state,
        sortField: action.field,
        sortDirection: sortField === action.field ? (sortDirection === "asc" ? "desc" : "asc") : "asc"
      }

    // SEARCH SUBSCRIPTIONS
    case 'SEARCH_SUBSCRIPTIONS':
      return {
        ...state,
        subscriptionSearchKeyword: action.search,
      }

    // SET PARAMETERS
    case 'SET_PARAMETERS':
      return {
        ...state,
        parameters: action.parameters,
        subscription: {
          ...state.subscription,
          parameters: action.parameters
        }
      }
    // GET SUBSCRIPTION
    case 'GET_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        loading: true,
        error: undefined
      }
    case 'GET_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        subscription: action.payload ?? state.subscription,
        loading: false,
        error: undefined,
      }
    case 'GET_SUBSCRIPTION_FAILURE':
      return {
        ...state,
        subscription: state.subscription,
        loading: false,
        error: action.error
      }

    // GET FREQUENCY TYPES
    case "GET_SUBSCRIPTION_FORM_REQUEST":
      return {
        ...state,
        loadingSubcriptionForm: true,
        error: undefined,
      };
    case "GET_SUBSCRIPTION_FORM_SUCCESS":
      return {
        ...state,
        subscriptionFormData: action.payload ?? state.subscriptionFormData,
        defaultTimezone: action.payload?.timezones?.filter(tz => tz.name === findWindows(Intl.DateTimeFormat().resolvedOptions().timeZone)[0])[0],
        selectedFreqType: action.payload?.jobScheduleFreqType?.[0] ?? state.selectedFreqType,
        selectedDaysOfWeek: action.payload?.daysOfWeek.map((d: any) => {
          return {
            jobScheduleDayOfWeekId: 0,
            jobScheduleId: 0,
            dayOfWeekId: d.dayOfWeekId,
          }
        }) ?? [],
        loadingSubcriptionForm: false,
        error: undefined,
      };
    case "GET_SUBSCRIPTION_FORM_FAILURE":
      return {
        ...state,
        subscriptionFormData: CreateDefaultSubscriptionFormData(),
        loadingSubcriptionForm: false,
        error: action.error,
      };

    // UPDATE SUBSCRIPTION ACTIVE
    case "UPDATE_SUBSCRIPTION_ACTIVE_REQUEST":
      return {
        ...state,
        getSubscriptionLoading: { ...state.getSubscriptionLoading, [action.subscriptionId.toString()]: true }
      };
    case "UPDATE_SUBSCRIPTION_ACTIVE_SUCCESS":
      return {
        ...state,
        subscriptions: [...state.subscriptions.map(s => s.subscriptionId === action.subscriptionId ? {
          ...s, active: action.isActive, nextRunDate: action.payload
        } as Subscription : s)],
        getSubscriptionLoading: { ...state.getSubscriptionLoading, [action.subscriptionId.toString()]: false }
      };
    case "UPDATE_SUBSCRIPTION_ACTIVE_FAILURE":
      return {
        ...state,
        getSubscriptionLoading: { ...state.getSubscriptionLoading, [action.subscriptionId.toString()]: false }
      };
    // SET CATEGORY
    case 'SET_SUBSCRIPTION_CATEGORY':
      return {
        ...state,
        subscriptionSelectedCategory: action.category
      }

    // SET ADMIN CATEGORY
    case 'SET_ADMIN_CATEGORY':
      return {
        ...state,
        adminSelectedCategory: action.category
      }

    // SET SUBSCRIPTION
    case "SET_SUBSCRIPTION":
      return {
        ...state,
        subscription: action.subscription,
        saved: false,
      };

    // VALIDATE SUBSCRIPTION
    case "VALIDATE_SUBSCRIPTION":
      return {
        ...state,
        fieldsValidation: action.isSubmit ? { ...state.fieldsValidation, fileType: state.subscription.subscriptionExtensions.length > 0, showFileTypeError: state.subscription.subscriptionExtensions.length === 0, days: state.subscription.jobSchedule.jobScheduleFreqTypeId !== state.subscriptionFormData?.jobScheduleFreqType?.filter(t => t.description === "Weekly")[0]?.jobScheduleFreqTypeId || state.subscription.jobSchedule.jobScheduleDaysOfWeek?.length > 0, showDaysError: state.subscription.jobSchedule.jobScheduleFreqTypeId === state.subscriptionFormData?.jobScheduleFreqType?.filter(t => t.description === "Weekly")[0]?.jobScheduleFreqTypeId && state.subscription.jobSchedule.jobScheduleDaysOfWeek?.length === 0 } : action.fieldsValidation
      };

    // VALIDATE ADMIN SUBSCRIPTION
    case "VALIDATE_ADMIN_SUBSCRIPTION":
      return {
        ...state,
        fieldsValidation: action.isSubmit ? { ...state.fieldsValidation, fileType: state.subscription.subscriptionExtensions.length > 0, showFileTypeError: state.subscription.subscriptionExtensions.length === 0, days: state.subscription.jobSchedule.jobScheduleFreqTypeId !== state.subscriptionFormData?.jobScheduleFreqType?.filter(t => t.description === "Weekly")[0]?.jobScheduleFreqTypeId || state.subscription.jobSchedule.jobScheduleDaysOfWeek?.length > 0, showDaysError: state.subscription.jobSchedule.jobScheduleFreqTypeId === state.subscriptionFormData?.jobScheduleFreqType?.filter(t => t.description === "Weekly")[0]?.jobScheduleFreqTypeId && state.subscription.jobSchedule.jobScheduleDaysOfWeek?.length === 0, facilities: state.validateFacilities && ((state.subscription.subscriptionTenantGroup?.subscriptionTenants?.length ?? 0) === 0) ? false : true, showFacilitiesError: state.validateFacilities && (state.subscription.subscriptionTenantGroup?.subscriptionTenants?.length ?? 0) === 0 ? true : false } : action.fieldsValidation
      };

    // Add SUBSCRIPTION
    case "ADD_SUBSCRIPTION_REQUEST":
      return {
        ...state,
        loading: true,
        error: undefined,
        saved: false,
      };
    case "ADD_SUBSCRIPTION_SUCCESS":
      return {
        ...state,
        loading: false,
        subscriptions: action.newSubscription ? [...state.subscriptions, action.newSubscription] : [...state.subscriptions],
        error: undefined,
        saved: true
      };
    case "ADD_SUBSCRIPTION_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.error,
        saved: false,
      };

    //Update Subscription
    case 'SAVE_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        loading: true,
        error: undefined,
        saved: false
      }
    case 'SAVE_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        saved: true,
        loading: false,
        error: undefined
      }
    case 'SAVE_SUBSCRIPTION_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
        saved: false
      }

    //Delete Subscription
    case 'DELETE_SUBSCRIPTION_REQUEST':
      return {
        ...state,
        loading: true,
        error: undefined
      }
    case 'DELETE_SUBSCRIPTION_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        subscriptions: state.subscriptions.filter(s => s.subscriptionId !== state.subscription.subscriptionId),
      }
    case 'DELETE_SUBSCRIPTION_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.error,
      }

    // GET ADMIN SUBSCRIPTIONS
    case 'GET_ADMIN_SUBSCRIPTIONS_REQUEST':
      return {
        ...state,
        loading: true,
        error: undefined,
        subscriptionsLoading: true
      }
    case 'GET_ADMIN_SUBSCRIPTIONS_SUCCESS':
      return {
        ...state,
        adminSubscriptions: action.payload,
        loading: false,
        error: undefined,
        subscriptionsLoading: false,
        currentPage: action.payload?.pagination.currentPage ?? 1
      }
    case 'GET_ADMIN_SUBSCRIPTIONS_FAILURE':
      return {
        ...state,
        adminSubscriptions: undefined,
        loading: false,
        error: action.error,
        subscriptionsLoading: false
      }

    // SORT ADMIN SUBSCRIPTIONS
    case 'SORT_ADMIN_SUBSCRIPTIONS':
      const { adminSortField, adminSortDirection } = state;
      return {
        ...state,
        adminSortField: action.field,
        adminSortDirection: adminSortField.length === 1 && adminSortField[0] === action.field[0] ? (adminSortDirection === "asc" ? "desc" : "asc") : "asc"
      }
    // SET REPORT ID
    case "SET_REPORT_ID":
      return {
        ...state,
        selectedReportId: action.reportId,
      }

    // SET ROW COUNT
    case "SET_ROW_COUNT":
      return {
        ...state,
        pageCount: action.count
      };

    // SET CURRENT PAGE
    case "SET_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.page,
      };

    // SET SUBTITLE
    case "SET_SUBTITLE":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, name: action.subtitle },
      };

    // SET NOTES
    case "SET_NOTES":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, notes: action.notes } },
      };

    // SET EXTENSIONS
    case "SET_EXTENSIONS":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, subscriptionExtensions: action.extensions },
        fieldsValidation: { ...state.fieldsValidation, fileType: action.extensions.length > 0, showFileTypeError: action.extensions.length === 0 }
      };

    // SET JOB SCHEDULE
    case "SET_JOB_SCHEDULE":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: action.jobSchedule },
        fieldsValidation: { ...state.fieldsValidation, fileType: true, showFileTypeError: false, days: true, showDaysError: false, runTime: true, showRunTimeError: false }
      };

    // SET RUN TIME
    case "SET_RUN_TIME":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, runTime: action.runTime } },
        fieldsValidation: { ...state.fieldsValidation, runTime: parseInt(action.runTime.split(":")[0]) > 0 && parseInt(action.runTime.split(":")[0]) <= 12, showRunTimeError: parseInt(action.runTime.split(":")[0]) === 0 || parseInt(action.runTime.split(":")[0]) > 12 }
      };

    // SET TIMEZONE
    case "SET_TIMEZONE":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, timezoneId: action.timezoneId } }
      };

    // SET DAYS
    case "SET_DAYS":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, jobScheduleDaysOfWeek: action.days } },
        subscriptionFormData: { ...state.subscriptionFormData, excludeWeekends: action.excludeWeekends },
        fieldsValidation: { ...state.fieldsValidation, days: action.valid, showDaysError: !action.valid }
      };

    // SET RECURRENCE FACTOR
    case "SET_RECURRENCE_FACTOR":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, jobScheduleRecurrenceFactor: action.recurrenceFactor } }
      };

    // SET MONTHLY RUN DAY
    case "SET_MONTHLY_RUN_DAY":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, jobScheduleMonthlyRunDay: action.monthlyRunDayId } }
      };

    // SET MONTHLY DAY OF WEEK
    case "SET_MONTHLY_DAY_OF_WEEK":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, jobScheduleMonthlyDayOfWeekId: action.dayOfWeekId } }
      };

    // SET MONTHLY FIRST/LAST DAY
    case "SET_MONTHLY_FIRST_LAST_DAY":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, jobSchedule: { ...state.subscription.jobSchedule, jobScheduleMonthlyFirstOrLastDayId: action.dayId } }
      };

    // SET SUBSCRIPTION FILTERS
    case "SET_SUBSCRIPTION_FILTERS":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, parameters: action.filters }
      };

    // SET FILTER PANE OPEN
    case 'SET_SUBSCRIPTION_FILTER_PANE_OPEN':
      return {
        ...state,
        filterPaneOpen: action.open
      }

    // SET FILTERS
    case 'SET_SUBSCRIPTION_PAGE_FILTERS':
      return {
        ...state,
        pageFilters: [...action.filters]
      }

    // APPLY FILTERS
    case 'APPLY_SUBSCRIPTION_FILTERS':
      return {
        ...state,
        pageFilters: [...[], ...action.pageFilters.map((f) => { return { ...f, applied: f.values.length > 0 && (f.values[0] || f.values[1]) ? true : false } })]
      }

    case 'CLEAR_SUBSCRIPTION_ALL_FILTERS':
      return {
        ...state,
        pageFilters: [...CreateSubscriptionFilters()]
      }

    case 'CLEAR_SUBSCRIPTION_FILTER':
      return {
        ...state,
        pageFilters: [...[], ...state.pageFilters.map((f) => { return { ...f, applied: action.filterName === f.name ? false : f.applied, values: action.filterName === f.name ? [] : f.values, rawValues: action.filterName === f.name ? undefined : f.rawValues } })]
      }

    // SET FILTER PANE OPEN
    case 'SET_ADMIN_FILTER_PANE_OPEN':
      return {
        ...state,
        adminFilterPaneOpen: action.open
      }

    // SET FILTERS
    case 'SET_ADMIN_FILTERS':
      return {
        ...state,
        adminPageFilters: [...action.filters]
      }

    // APPLY FILTERS
    case 'APPLY_ADMIN_FILTERS':
      return {
        ...state,
        adminPageFilters: [...[], ...action.filters.map((f) => { return { ...f, applied: f.values.length > 0 && (f.values[0] || f.values[1]) ? true : false } })],
      }

    case 'CLEAR_ADMIN_ALL_FILTERS':
      return {
        ...state,
        adminPageFilters: [...CreateAdminSubscriptionFilters()],
      }

    case 'CLEAR_ADMIN_FILTER':
      return {
        ...state,
        adminPageFilters: [...[], ...state.adminPageFilters.map((f) => { return { ...f, applied: action.filterName === f.name ? false : f.applied, values: action.filterName === f.name ? [] : f.values, rawValues: action.filterName === f.name ? undefined : f.rawValues } })],
      }

    // SET FACILITIES
    case "SET_FACILITIES":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, subscriptionTenantGroup: action.tenantGroup },
        fieldsValidation: { ...state.fieldsValidation, facilities: action.valid, showFacilitiesError: !action.valid }
      };

    // SET TO EMAIL
    case "SET_TO_EMAIL":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, subscriptionEmail: { ...state.subscription.subscriptionEmail!, to: action.value } }
      };

    // SET CC EMAIL
    case "SET_CC_EMAIL":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, subscriptionEmail: { ...state.subscription.subscriptionEmail!, cc: action.value } }
      };

    // SET BCC EMAIL
    case "SET_BCC_EMAIL":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, subscriptionEmail: { ...state.subscription.subscriptionEmail!, bcc: action.value } }
      };

    // SET FOR ALL USERS
    case "SET_FOR_ALL_USERS":
      return {
        ...state,
        saved: false,
        subscription: { ...state.subscription, isForAllUsers: action.value },
        validateFacilities: action.validateFacilities
      };

    // CLEAR ALL
    case "CLEAR-ALL":
      return initialState;
    default:
      return state;
  }
};
export default SubscriptionReducer;
