import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { DateUtils } from '../../utils/date.utils';
import './DateRangeFilter.scss';
import { Grid, InputAdornment } from '@mui/material';

export interface DateRangeFilterProps {
  index: number;
  displayName: string;
  setFilter: (
    index: number,
    values: any[],
    newValue: DateRange<Dayjs>
  ) => void;
  values: DateRange<Dayjs>;
}
const today = dayjs();
const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  
  {
    label: 'Today',
    getValue: () => {
      return [today.startOf('day'), today];
    },
  },
  {
    label: 'This Week',
    getValue: () => {
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Next Month',
    getValue: () => {
      const startOfNextMonth = today.endOf('month').add(1, 'day');
      return [startOfNextMonth, startOfNextMonth.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];
function DateRangeFilter({
  index,
  displayName,
  setFilter,
  values,
}: DateRangeFilterProps) {
  function setValues(newValue: DateRange<Dayjs>) {
    console.log(newValue)
    let val = [];
    newValue[0]
      ? val.push(new Date(newValue[0].toString()).toLocaleDateString())
      : val.push(null);
    newValue[1]
      ? val.push(
          DateUtils.addDays(new Date(newValue[1].toString()), 1).toLocaleDateString()
        )
      : val.push(null);

    setFilter(index, val, newValue);
  }

  return (
    <Grid container spacing={0} className="filter-wrapper">
          {/* <Grid item md={2.5}></Grid> */}
          <Grid item md={4} sm={4} xs={12} className="label-container">
            <label>{displayName}</label>
          </Grid>
          <Grid item md={8} sm={8} xs={12} className="field-control">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker slots={{ field: SingleInputDateRangeField }} slotProps={{
              shortcuts: {
                items: shortcutsItems,
              },
              actionBar: { actions: [] },
              textField: {
                InputProps:{
                  startAdornment: (
                    <InputAdornment position="start" className='icon-cal' >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M5.33333 4.66667V2M10.6667 4.66667V2M4.66667 7.33333H11.3333M3.33333 14H12.6667C13.0203 14 13.3594 13.8595 13.6095 13.6095C13.8595 13.3594 14 13.0203 14 12.6667V4.66667C14 4.31304 13.8595 3.97391 13.6095 3.72386C13.3594 3.47381 13.0203 3.33333 12.6667 3.33333H3.33333C2.97971 3.33333 2.64057 3.47381 2.39052 3.72386C2.14048 3.97391 2 4.31304 2 4.66667V12.6667C2 13.0203 2.14048 13.3594 2.39052 13.6095C2.64057 13.8595 2.97971 14 3.33333 14Z" stroke="#758496" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </InputAdornment>
                  )
                }
              },
            }}
            calendars={2}
            onChange={setValues}
            value={values}
            />
            </LocalizationProvider>
           </Grid>
        </Grid>
  );
}
export default DateRangeFilter;
