export interface ReportType {
    reportTypeId: ReportTypeEnum;
    name: string;
    label: string;
    description: string;
    active: boolean;
    createdDate?: Date;
    modifiedDate?: Date;
}

export enum ReportTypeEnum {
    PowerBI_Report = 1,
    PowerBI_Paginated = 2
}