import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadActions } from "../../redux/actions/download.actions";
import { useParams } from "react-router-dom";
import { ReportStream } from "../../redux/models/reportStream";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import "./Download.scss";
import {
  selectDownloadStarted,
  selectReportByEmailLoading,
  selectSubscriptionEmailDownload,
} from "../../redux/selectors/download.selector";
import { DateUtils } from "../../utils/date.utils";
import { CircularProgress, Divider, Tooltip } from "@mui/material";

function Download() {
  const dispatch = useDispatch();
  const subscriptionEmailDownload = useSelector(
    selectSubscriptionEmailDownload
  );
  const downloadStarted = useSelector(selectDownloadStarted);
  const reportLoading = useSelector(selectReportByEmailLoading);

  const { id } = useParams<{ id: string }>();

  // const testStreams: ReportStream[] = [];
  // testStreams.push({
  //   reportName: "sdafdasj",
  //   extension: "pdf",
  //   userReportHistoryExportDetailsId: 1052,
  // } as ReportStream);

  useEffect(() => {
    dispatch(downloadActions.getReportsFromEmail(parseInt(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !downloadStarted &&
      subscriptionEmailDownload?.reportStreams &&
      subscriptionEmailDownload.reportStreams.length === 1
    ) {
      // console.log("downloading");
      handleDownloadFile(subscriptionEmailDownload.reportStreams[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionEmailDownload?.reportStreams?.length]);

  const handleDownloadFile = (reportStream: ReportStream) => {
    if (reportStream.errorMessage) return;
    dispatch(
      downloadActions.downloadFile(
        reportStream.userReportHistoryExportDetailsId
      )
    );
  };

  return (
    <div id="download-container">
      <div className="page-title large-text">Download Files</div>
      <div className="page-subtitle medium-text">
        <span>Subscription:</span>
        <span style={{ marginLeft: 25 }}>
          {subscriptionEmailDownload?.subscriptionName ?? ""}
        </span>
      </div>
      <div className="page-subtitle medium-text">
        <span>Completed On:</span>
        <span style={{ marginLeft: 16 }}>
          {DateUtils.formatDateTime(subscriptionEmailDownload?.completeDateUTC)}
        </span>
      </div>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
      <TableContainer style={{ width: "100%" }}>
        <Table stickyHeader size="small" aria-label="Download Files">
          <TableHead>
            <TableRow className="table-header">
              <TableCell width={50} align="center">
                Download
              </TableCell>
              <TableCell width={200} align="center">
                Time Started
              </TableCell>
              <TableCell width={200} align="center">
                Time Completed
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionEmailDownload?.reportStreams?.map((data, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    align="center"
                    className={"rs-cell" + (data.errorMessage ? " failed" : "")}
                  >
                    <div
                      className={
                        "download-container" +
                        (data.errorMessage ? " failed" : "")
                      }
                    >
                      {data.downloadLoading ? (
                        <CircularProgress size={15} />
                      ) : (
                        <React.Fragment>
                          {data.errorMessage && (
                            <Tooltip
                              key="0"
                              title={data.errorMessage ?? ""}
                              className="failed-tooltip"
                            >
                              <ReportGmailerrorredOutlinedIcon color="error" />
                            </Tooltip>
                          )}
                          <img
                            key="1"
                            src={`/${data.extension.toLowerCase()}.png`}
                            alt={`${data.extension.toUpperCase()}`}
                            onClick={() => handleDownloadFile(data)}
                            className={data.errorMessage ? " failed" : ""}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={"rs-cell" + (data.errorMessage ? " failed" : "")}
                  >
                    {DateUtils.formatDateTime(data.startDateUTC)}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={"rs-cell" + (data.errorMessage ? " failed" : "")}
                  >
                    {DateUtils.formatDateTime(data.completeDateUTC)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {reportLoading && (
        <div style={{ margin: "auto" }}>
          <CircularProgress size={40} />
        </div>
      )}
      {subscriptionEmailDownload?.reportStreams?.length === 1 && (
        <div id="download-auto-prompt">
          Your download will start in a few seconds. If your download does not
          start, click on the file above.
        </div>
      )}
      {/* <div className="files-container">
        {testStreams?.map((data, index) => {
          return (
            <div key={index} onClick={() => downloadFile(data)}>
              <img
                src={`/${data.extension.toLowerCase()}.png`}
                alt={`/${data.extension.toLowerCase()}`}
              />
              <div>{data.reportName}</div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
}

export default Download;
