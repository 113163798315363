import EditIcon from "@mui/icons-material/EditOutlined";
import { useDispatch } from "react-redux";
import { Checkbox, CircularProgress } from "@mui/material";
import Switch from "react-switch";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { FilteredUser } from "../../redux/selectors/user.selector";
import { userActions } from "../../redux/actions/user.actions";
import { DateUtils } from "../../utils/date.utils";

interface UserManagementComponentProps {
  user: FilteredUser;
  openRoleDialog: (users: FilteredUser[]) => void;
  openDashboardDialog: (users: FilteredUser[]) => void;
  openReportAccessDialog: (users: FilteredUser[]) => void;
  openDisableDialog: (user: FilteredUser, checked: boolean) => void;
}

function UserManagementComponent({
  user,
  openRoleDialog,
  openDashboardDialog,
  openReportAccessDialog,
  openDisableDialog,
}: UserManagementComponentProps) {
  const dispatch = useDispatch();

  return (
    <TableRow>
      <TableCell align="center">
        <Checkbox
          checked={user.checked}
          onChange={(e) =>
            dispatch(userActions.checkUser(user.userId, e.target.checked))
          }
          size="small"
        />
      </TableCell>
      <TableCell align="left">
        <div style={{ width: 300, maxWidth: 350, wordBreak: "break-word" }}>
          {user.emailAddress}
        </div>
      </TableCell>
      <TableCell align="center">
        <div>{user.ampUserId ?? "-"}</div>
      </TableCell>
      <TableCell align="center">
        <div>{DateUtils.formatDateTime(user.lastSignInDateTime)}</div>
      </TableCell>
      <TableCell align="center">{user.b2CObjectId ?? "-"}</TableCell>
      <TableCell align="right">
        <div className="edit-action-container">
          <div>{user.defaultDashboardName ?? "-"}</div>
          <button onClick={() => openDashboardDialog([user])}>
            <EditIcon />
          </button>
        </div>
      </TableCell>
      <TableCell align="right">
        <div className="edit-action-container">
          <div>{`${user.reports?.length ?? 0} reports`}</div>
          <button onClick={() => openReportAccessDialog([user])}>
            <EditIcon />
          </button>
        </div>
      </TableCell>
      <TableCell align="right">
        <div className="edit-action-container">
          <div>{user.role}</div>
          <button onClick={() => openRoleDialog([user])}>
            <EditIcon />
          </button>
        </div>
      </TableCell>
      <TableCell align="center">
        <div className="actions">
          <div>
            <Switch
              onChange={(checked) => openDisableDialog(user, checked)}
              checked={user.active}
              className="toggle-switch"
              onColor="#5588a3"
              offColor="#a3afb6"
              width={32}
              height={16}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </div>
          <div className="active-container">
            {user.isLoading ? (
              <div>
                <CircularProgress className="progress" size={20} />
              </div>
            ) : user.active ? (
              <div>Active</div>
            ) : (
              <div>Disabled</div>
            )}
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}
export default UserManagementComponent;
