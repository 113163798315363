import { Subscription } from "../models/subscription";
import { AppState } from "../../redux/reducers";
import {memoize} from "proxy-memoize";
import _orderBy from "lodash/orderBy";
import { compliesWithFilter } from "../models/filter";


export interface FilteredSubscription extends Subscription {
    name: string;
    nameLowered?: string;
    emailLowered?: string;
    nextRunDate?: Date;
    nextRunDateUTC?: Date;
    lastRunDate?: Date;
    lastRunDateUTC?: Date;
    isLoading?: boolean;
    isAdmin?: boolean;
    isForAllUsers?: boolean;
}

export interface FilteredAdminSubscription extends Subscription {
    name: string;
    nameLowered?: string;
    emailLowered?: string;
    nextRunDate?: Date;
    nextRunDateUTC?: Date;
    lastRunDate?: Date;
    lastRunDateUTC?: Date;
    isLoading?: boolean;
    isAdmin?: boolean;
    isForAllUsers?: boolean;
    user: string;
    title: string;
    status: "All" | "Active" | "Disabled"
}

const getAllSubscriptions = memoize((subscriptions: Subscription[]): FilteredSubscription[] => {
    // (subscriptions: Subscription[]): FilteredSubscription[] => {
    return subscriptions.map(s => {
        return {
            ...s,
            name: `${s.report?.name && s.report?.name}${s.name.length ? ` - ${s.name}` : ''}`,
            nameLowered: `${s.report?.name && s.report?.name.toLowerCase()}${s.name.length ? ` - ${s.name.toLowerCase()}` : ''}`,
            lastRunDateUTC: s.lastRunDate && new Date(`${s.lastRunDate}Z`),
            nextRunDateUTC: s.nextRunDate && new Date(`${s.nextRunDate}Z`),
        }
    })
}
    , {
        size: 50
    });

export const isSubscriptionsLoading = (state: AppState) => state.subscriptionReducer.subscriptionsLoading;

export const selectFilteredSubscriptions = //memoize((state: AppState): FilteredSubscription[] => {
    (state: AppState): FilteredSubscription[] => {
        const { subscriptions, getSubscriptionLoading, subscriptionSelectedCategory, sortField, sortDirection, selectedReportId, subscriptionSearchKeyword, pageFilters } = state.subscriptionReducer;
        const filtered = getAllSubscriptions(subscriptions).filter(s => !s.isHidden &&
            (!subscriptionSelectedCategory || !subscriptionSelectedCategory.categoryId || s.report?.categoryId === subscriptionSelectedCategory.categoryId) &&
            (!selectedReportId || s.report?.reportId === selectedReportId) &&
            (!subscriptionSearchKeyword || subscriptionSearchKeyword.length === 0 || s.nameLowered?.includes(subscriptionSearchKeyword.toLowerCase())) &&
            compliesWithFilter(s, pageFilters));

        const filteredLoading = filtered.map(f => {
            return {
                ...f,
                isLoading: getSubscriptionLoading[f.subscriptionId.toString()]
            }
        })
        return _orderBy(filteredLoading, [sortField], [sortDirection]);
    }

//         size: 50
// });

export const selectSubscriptionSelectedCategory = (state: AppState) => state.subscriptionReducer.subscriptionSelectedCategory;
export const selectAdminSubscriptionSelectedCategory = (state: AppState) => state.subscriptionReducer.adminSelectedCategory;

const getAdminSubscriptions = memoize((subscriptions: Subscription[]): FilteredAdminSubscription[] => {
    return subscriptions?.map(us => {
        return {
            ...us,
            emailLowered: us.ownedByUser?.emailAddress && us.ownedByUser?.emailAddress.toLowerCase(),
            nameLowered: us.report?.name && us.report?.name.toLowerCase(),
            lastRunDateUTC: us.lastRunDate && us.lastRunDate && new Date(`${us.lastRunDate}Z`),
            nextRunDateUTC: us.nextRunDate && us.nextRunDate && new Date(`${us.nextRunDate}Z`),
            user: us.ownedByUser?.emailAddress ?? "",
            title: us.report?.name ?? "",
            status: us.active ? "Active" : "Disabled"
        }
    });
});

export const selectAdminSortedSubscriptions = memoize((state: AppState) => {
    const { adminSubscriptions } = state.subscriptionReducer;
    return _orderBy(getAdminSubscriptions(adminSubscriptions ? adminSubscriptions.subscriptions : []), ["active"], ["desc"]);
}, {
    size: 50
});

export const selectAdminSubscriptionsPagination = memoize((state: AppState) => {
    const { adminSubscriptions } = state.subscriptionReducer;
    return adminSubscriptions?.pagination;
}, {
    size: 50
});

export const selectAdminSubscriptionsPageCount = (state: AppState) => state.subscriptionReducer.pageCount;
export const selectAdminSubscriptionsCurrentPage = (state: AppState) => state.subscriptionReducer.currentPage;

export const selectSubscriptionSearchKeyword = (state: AppState) => state.subscriptionReducer.subscriptionSearchKeyword;

export const selectSortField = (state: AppState) => state.subscriptionReducer.sortField;
export const selectSortDirection = (state: AppState) => state.subscriptionReducer.sortDirection;

export const selectAdminSortField = (state: AppState) => state.subscriptionReducer.adminSortField;
export const selectAdminSortDirection = (state: AppState) => state.subscriptionReducer.adminSortDirection;

export const selectFormData = memoize((state: AppState) => {
    const { subscriptionFormData } = state.subscriptionReducer;
    return {
        ...subscriptionFormData,
        portfolios: _orderBy(subscriptionFormData.portfolios, ["name"], "asc").map(p => {
            return {
                ...p, regions: _orderBy(p.regions, ["name"], "asc").map(r => {
                    return {
                        ...r, facilities: _orderBy(r.facilities, ["name"], "asc")
                    }
                })
            }
        }),
    }
});
export const selectSelectedSubscription = (state: AppState) => state.subscriptionReducer.subscription;
export const selectDefaultTimezone = (state: AppState) => state.subscriptionReducer.defaultTimezone;
export const selectSaved = (state: AppState) => state.subscriptionReducer.saved;

export const selectFieldsValid = (state: AppState) => state.subscriptionReducer.fieldsValidation;

export const selectParameters = (state: AppState) => state.subscriptionReducer.parameters;

export const selectSelectedFrequencyType = (state: AppState) => state.subscriptionReducer.selectedFreqType;

export const selectFilterPanOpen = (state: AppState) => state.subscriptionReducer.filterPaneOpen;
export const selectPageFilters = (state: AppState) => state.subscriptionReducer.pageFilters;
export const selectAppliedFiltersCount = (state: AppState) => { return state.subscriptionReducer.pageFilters.filter((f) => f.applied === true).length };

export const selectAdminFilterPanOpen = (state: AppState) => state.subscriptionReducer.adminFilterPaneOpen;
export const selectAdminPageFilters = (state: AppState) => state.subscriptionReducer.adminPageFilters;
export const selectAppliedAdminPageFilters = (state: AppState) => { return state.subscriptionReducer.adminPageFilters.filter((f) => f.applied === true) };
export const selectAppliedAdminPageFiltersCount = (state: AppState) => { return state.subscriptionReducer.adminPageFilters.filter((f) => f.applied === true).length };

export const selectLoading = (state: AppState) => state.subscriptionReducer.loading;
export const selectFormLoading = (state: AppState) => state.subscriptionReducer.loading || state.subscriptionReducer.loadingSubcriptionForm || state.reportReducer.loading

export const selectValidateFacilities = (state: AppState) => state.subscriptionReducer.validateFacilities;
