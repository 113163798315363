import { JobSchedule } from "./jobSchedule";
import { SubscriptionExtension } from "./subscriptionExtension";
import { Report } from "./report";
import { CreateDefaultJobSchedule } from "./jobSchedule";
import { Timezone } from "./timezone";
import { SubscriptionEmail, CreateDefaultSubscriptionEmail } from "./subscriptionEmail";
import { User } from "./user";
import { SubscriptionTenantGroup } from "./subscriptionTenantGroup";

export interface Subscription {
  subscriptionId: number;
  reportId?: number;
  report?: Report;
  jobScheduleId?: number;
  jobSchedule: JobSchedule;
  subscriptionExtensions: SubscriptionExtension[];
  name: string;
  description?: string;
  active: boolean;
  isHidden: boolean;
  status: string;
  lastUserReportHistoryId?: number;
  parameters: string;
  filters?: string;
  slicers?: string;
  subscriptionTenantGroup?: SubscriptionTenantGroup;
  subscriptionEmail?: SubscriptionEmail;
  ownedByUser?: User
  nextRunDate?: Date;
  lastRunDate?: Date;
  isAdmin?: boolean;
  isForAllUsers?: boolean;
}

export function CreateDefaultSubscription(parameters: string, filters?: string, slicers?: string, defaultTimezone?: Timezone, isAdmin?: boolean): Subscription {
  let ret: Subscription = {
    subscriptionId: 0,
    jobScheduleId: 0,
    name: '',
    active: true,
    isHidden: false,
    jobSchedule: CreateDefaultJobSchedule(defaultTimezone),
    subscriptionExtensions: [],
    status: '',
    parameters: parameters,
    filters: filters,
    slicers: slicers,
    subscriptionEmail: isAdmin ? CreateDefaultSubscriptionEmail() : undefined,
    isAdmin: isAdmin,
    isForAllUsers: false
  }
  return ret;
}
