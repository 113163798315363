export interface JobScheduleFreqType {
  jobScheduleFreqTypeId: number;
  description: string;
  active: boolean;
}

export function CreateDefaultJobScheduleFreqType(): JobScheduleFreqType {
  let ret: JobScheduleFreqType = {
    jobScheduleFreqTypeId: 0,
    description: '',
    active: false
  }
  return ret;
}