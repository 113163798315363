import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
// import AzureAD from "react-aad-msal";
// import { signInAuthProvider } from "./azure/authProvider";
import store from "./redux/store/store";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./azure/authConfig";
import { BrowserRouter } from "react-router-dom";

// ReactDOM.render(
//   <React.StrictMode>
//     <AzureAD provider={signInAuthProvider} forceLogin={true}>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </AzureAD>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);


const rootElement = document.getElementById("root");

function renderToApp() {
  if (rootElement) {
    ReactDOM.render(<React.StrictMode>
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </MsalProvider>
      </Provider>
    </React.StrictMode>, rootElement);
  }
}

renderToApp();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { renderToApp };
