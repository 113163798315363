import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed, service } from "powerbi-client";
import useMyAuth from "../../hooks/useMyAuth";
import { useDispatch, useSelector } from "react-redux";
import { reportActions } from "../../redux/actions/report.actions";
import { AppState } from "../../redux/reducers";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useParams } from "react-router-dom";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import appConfig from "../../utils/appConfig";
import { UserReportHistoryExportDetailsStatuses } from "../../redux/models/userReportHistoryExportDetails";
import { SignalRTargets } from "../../redux/models/signalRTargets";
import { PowerBIUtils } from "../../utils/powerBi.utils";
import PinToDashboardDialog from "../PinToDashboardDialog/PinToDashboardDialog";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import "./ViewReport.scss";
import { ReportTypeEnum } from "../../redux/models/reportType";
import { CircularProgress } from "@mui/material";
import { notificationActions } from "../../redux/actions/notification.actions";
import { INotificationType } from "../../redux/models/notificationType";
import { name as platformName } from "platform";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export interface ViewReportPageProps {
  reportId?: string;
  selectedReport?: any,
}

const ViewReportPage = forwardRef(({
  reportId,
  selectedReport,
}: ViewReportPageProps, ref) => {
  const { token } = useMyAuth();
  const dispatch = useDispatch();
  const [updateParams, setUpdateParams] = useState(false);
  const [dashboardOptionsOpen, setDashboardOptionsOpen] = useState(false);
  const [reportLoaded, setReportLoaded] = useState<boolean>(false);
  const { id, sid } = useParams<{
    id: string;
    sid: string;
  }>();

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const connectionRef = React.useRef(connection);

  const [report, setReport] = useState<Report>();
  const reportRef = React.useRef(report);

  const reportReducer = useSelector((state: AppState) => state.reportReducer);
  const { embedToken, embedUrl, slicerStates, isCurrentlyExporting } =
    reportReducer;
  const localReport = reportReducer.selectedReport;
  const reportReducerRef = useRef(reportReducer);
  reportReducerRef.current = reportReducer;
  const [error, setError] = useState<string>();
  const [reportById, setReportById] = useState<string>(id);
  const errorRef = useRef(error);
  const handle = useFullScreenHandle();
  const [isReady,setIsReady]=useState<boolean>(false);
  function _setError(e: string) {
    errorRef.current = e;
    setError(e);
  }

  const cleanupConnection = () => {
    if (connectionRef.current) {
      connectionRef.current.stop();
    }
  };
  // Called when mounted
  useEffect(() => {
    dispatch(reportActions.setSelectedReport(selectedReport));
    if (sid) {
      setUpdateParams(true);
    }
    if (reportId) {
      setReportById(reportId);
    }

    // cleanup
    return () => {
      cleanupConnection();
      dispatch(reportActions.setIsCurrentlyExporting(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _setConnection = (data: HubConnection | null) => {
    connectionRef.current = data;
    setConnection(data);
  };

  const _setReport = (report: Report) => {
      reportRef.current = report;
      setReport(report);
  };

  const SignalRCallback_embedTokenAndURL = (
    incomingUniqueEmbedId: string,
    embedToken: string,
    embedUrl: string
  ) => {
    if (incomingUniqueEmbedId === reportReducerRef.current.uniqueEmbedId) {
      console.log('unique updated');
      setTimeout(() => {
        setIsReady(true);
      }, 500);
      dispatch(reportActions.updateReport(embedToken, embedUrl));
    }
  };

  const SignalRCallback_status = (
    incomingUserReportHistoryId: number,
    incomingUserReportHistoryStatus: string,
    incomingUserReportHistoryExportDetailsId: number,
    incomingUserReportHistoryExportDetailsStatus: string,
    progressComplete: number
  ) => {
    console.log("RECEIVED STATUS AND PROGRESS");
    console.log(`incomingUserReportHistoryId: ${incomingUserReportHistoryId}`);
    console.log(
      `incomingUserReportHistoryStatus: ${incomingUserReportHistoryStatus}`
    );
    console.log(
      `incomingUserReportHistoryExportDetailsId: ${incomingUserReportHistoryExportDetailsId}`
    );
    console.log(
      `incomingUserReportHistoryExportDetailsStatus: ${incomingUserReportHistoryExportDetailsStatus}`
    );
    console.log(`Progress: ${progressComplete}`);
    console.log(
      `Current ID: ${reportReducerRef.current.userReportHistoryExportDetailsId}`
    );
    if (
      incomingUserReportHistoryExportDetailsId ===
      reportReducerRef.current.userReportHistoryExportDetailsId
    ) {
      dispatch(
        reportActions.updateReport(
          undefined,
          undefined,
          incomingUserReportHistoryExportDetailsStatus,
          progressComplete
        )
      );
      if (
        incomingUserReportHistoryExportDetailsStatus ===
        UserReportHistoryExportDetailsStatuses.SUCCEEDED
      ) {
        dispatch(
          reportActions.getExportedFile(
            reportReducerRef.current.userReportHistoryExportDetailsId,
            `${localReport?.name}.pdf`
          )
        );
      }
    }
  };

  const SignalRCallback_error = (
    incomingUniqueEmbedId: string,
    incomingUserReportHistoryId: number,
    incomingUserReportHistoryExportDetailsId: number,
    errorMessage: string
  ) => {
    console.log("RECEIVED ERROR");
    console.log(`Incoming ID: ${incomingUniqueEmbedId}`);
    console.log(`Current ID: ${reportReducerRef.current.uniqueEmbedId}`);
    console.log(`incomingUserReportHistoryId: ${incomingUserReportHistoryId}`);
    console.log(
      `incomingUserReportHistoryExportDetailsId: ${incomingUserReportHistoryExportDetailsId}`
    );
    console.log(`errorMessage: ${errorMessage}`);
    if (
      incomingUniqueEmbedId.length > 0 &&
      incomingUniqueEmbedId === reportReducerRef.current.uniqueEmbedId
    ) {
      _setError(errorMessage);
    }
  };

  // Called when user authenticated
  useEffect(() => {
    if (token) {
      const hubConnection = new HubConnectionBuilder()
        .withUrl(`${appConfig.apiBaseUrl}/reportHub`, {
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .build();

      hubConnection.on(
        SignalRTargets.reportUpdate_embedTokenAndURL,
        SignalRCallback_embedTokenAndURL
      );
      hubConnection.on(
        SignalRTargets.reportUpdate_status,
        SignalRCallback_status
      );
      hubConnection.on(
        SignalRTargets.reportUpdate_error,
        SignalRCallback_error
      );

      _setConnection(hubConnection);

      hubConnection
        .start()
        .then(() => {
          console.log("SignalR Connected");
          if (!reportReducer.selectedReport) {
            dispatch(reportActions.getReportById(parseInt(reportById)));
          }
          dispatch(reportActions.runReportByReportId(parseInt(reportById)));
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return ()=>{
      cleanupConnection();
      dispatch(reportActions.setIsCurrentlyExporting(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    // Set event handler for the instance
    reportRef?.current?.on('dataSelected', async (event: any) => {
      console.log('data selected', event?.detail?.dataPoints);
    });
    return ()=>{
      cleanupConnection();
      dispatch(reportActions.setIsCurrentlyExporting(false));
    }
  }, []);

  useEffect(() => {
    if (isCurrentlyExporting) {
      dispatch(
        notificationActions.setNotification(
          INotificationType.Loading,
          "Hang on! Your file is exporting. Please stay on this page.",
          true
        )
      );
    }
    return ()=>{
      cleanupConnection();
      dispatch(reportActions.setIsCurrentlyExporting(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentlyExporting]);

  function reportLoadedHandler(event: any) {
    console.log("Report Loaded");
    reportActions.setReportLoading(true);
    setReportLoaded(true);
  }

  function reportRenderedHandler(event: any) {
    // await getPageVisuals();
  }

  // Map of event handlers to be applied to the embedding report
  const eventHandlersMap = new Map([
    ["loaded", reportLoadedHandler],
    ["rendered", reportRenderedHandler],
    ["dataSelected",(event)=>{
      console.log(event);
    }],
    ["filtersApplied",(event)=>{
      console.log("filtersApplied",event);
    }],
    ["buttonClicked",(event)=>{
      console.log('buttonClicked');
    }],
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
         console.log(event)
        }
      },
    ],
    [
      "visualClicked",
      (e: any) => {
        console.log('visualClicked',e)
       
      },
    ],
    [
      "selectionChanged",
      (e: any) => {
       console.log('selectionChanged',e)
      },
    ],
    [
      "commandTriggered",
      async function (event: any) {
        if (event.detail.command === "pinvisual") {
          let filters = await PowerBIUtils.getFilters(reportRef.current!);
          let slicerStates = await PowerBIUtils.getSlicerStates(
            reportRef.current!
          );
          dispatch(
            dashboardActions.setSelectedWidget(
              parseInt(reportById),
              event.detail.page.name,
              event.detail.visual.name,
              slicerStates,
              filters
            )
          );
          setDashboardOptionsOpen(true);
        }
      },
    ],
  ]);

  useImperativeHandle(ref, () => ({
    async handleSubscribeBtnClick() {
      let filters = await PowerBIUtils.getFilters(report!);
      let slicerStates = await PowerBIUtils.getSlicerStates(report!);
      dispatch(reportActions.saveSlicerState(slicerStates));
      dispatch(reportActions.saveReportFilters(filters));
      dispatch(
        subscriptionActions.setParameters(
          PowerBIUtils.parseFiltersToStrings(filters)
        )
      );
    },
    async callExportReport(extention: string) {
      let filters = await PowerBIUtils.getFilters(report!);
      let filterUrl = PowerBIUtils.parseFiltersToStrings(filters);
      let url = `${embedUrl}&filter=${filterUrl}`;

      if (localReport) {
        dispatch(
          reportActions.exportReport(localReport.reportId, url, [extention])
        );
      }
    },
    async handlePinReportBtnClick() {
      let filters = await PowerBIUtils.getFilters(reportRef.current!);
      let slicerStates = await PowerBIUtils.getSlicerStates(reportRef.current!);
      dispatch(
        dashboardActions.setSelectedWidget(
          parseInt(reportById),
          undefined,
          undefined,
          slicerStates,
          filters
        )
      );
    },
    async callPrintReport() {
      const sleep = (ms: number) =>
        new Promise((resolve) => setTimeout(resolve, ms));
  
      if (platformName === "Safari") {
        console.log("printing from safari");
        await sleep(400);
        window.print();
      } else {
        console.log(`printing from other`);
        console.log(reportRef.current);
        await reportRef.current?.print();
      }
    },
    async refreshReport() {
      try {
        await report?.refresh();
        dispatch(
          notificationActions.setNotification(
            INotificationType.Success,
            "Your report has refreshed.",
            false
          )
        );
      } catch (e: any) {
        dispatch(
          notificationActions.setNotification(
            INotificationType.Warning,
            "Please wait 15 seconds before refreshing again.",
            false
          )
        );
      }
    },
   async handleFullScreen(){
     await handle.enter();
    },
    async handleSubscribeUsersBtnClick() {
      let filters = await PowerBIUtils.getFilters(report!);
      let slicerStates = await PowerBIUtils.getSlicerStates(report!);
      dispatch(reportActions.saveSlicerState(slicerStates));
      dispatch(reportActions.saveReportFilters(filters));
      dispatch(
        subscriptionActions.setParameters(
          PowerBIUtils.parseFiltersToStrings(filters)
        )
      );
    },
    isReportLoaded() {
      return reportLoaded;
    }
  }));

  const reportSettings: models.ISettings = {
    bars: {
      actionBar: {
        visible: false,
      },
    },
    layoutType: models.LayoutType.Custom,
    customLayout: {
      displayOption: models.DisplayOption.FitToWidth,
    },
    panes: {
      filters: {
        visible: false,
      },
      pageNavigation: {
        visible: false,
      },
    },
    extensions: {
      commands: [
        {
          name: "pinvisual",
          title: "Pin visual to dashboard",
          icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAK5QTFRFAAAAADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOADNOXyFVVQAAADp0Uk5TAL//gNffYPOzDHR7JD9To5/H5wT797vrKFwgLGg0cKvD44dM28uPVINYQJPPOFC3mxAc7wivf9Mwp3+jFQMAAAGDSURBVHic7dZpW4JAEABgGEAsLS2IzCPTsuyyQ+34/3+sZfUDe8Www+NjPTvfZnb2BZZrPW9X4cNvEewHGPr6iGzBhmEo+L9gZBiKrcBmfjN1Awf5wGF10GvlE9tK+SgvH1t4LDpsaleqnbBay47bns2pUIm1Z42PRFpIlqUEbksYs30Bz+oGs0J2XgdYfCmadYC9QnZRB1jM+mRwIAlkcKiAIxrYVcBLGhgp4JgGAlwJeQwTKjgV8ox6VwCuhTygg2LeJoI9ZT4RbGjAAQWUH8McHFLAGw3YoYAAM6Vi+v0jwVup4tPuCsCdVJlTwHvNL4R9z+DBjnsE7e6GVy32Dk98YvqsDCw2e+KXatwrnzR50w6+L/noHK2tQj5hbe5IZrzDTzBcP0Wt0maFl/prEGKdN2blfSu+yh/ljSB/U83B3vRPFIj0kL0Aca3gV5XPSYgApwALNIhpZgdFPV08RgDfZT1sWSJ8iNtRE1gtdg6aj2M504EOdKADHehABzrwL4M/HLcP0CaaXbcAAAAASUVORK5CYII=",
          extend: {
            visualContextMenu: {
              title: "custom context menu",
            },
            visualOptionsMenu: {
              title: "Pin visual to dashboard",
            },
          },
        },
      ],
    },
  };

  const paginatedSettings: any = {
    commands: {
      parameterPanel: {
        enabled:true
      },
    },
  };

  const reportConfig: models.IReportEmbedConfiguration = {
    type: "report",
    embedUrl: embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken: embedToken,
    settings: reportSettings,
    slicers: slicerStates,
  };

  const paginatedConfig: models.IEmbedConfigurationBase = {
    type: "report",
    embedUrl: embedUrl,
    tokenType: models.TokenType.Embed,
    accessToken: embedToken,
    settings: paginatedSettings,
  };

  const showEmbed =
    localReport?.reportTypeId === ReportTypeEnum.PowerBI_Report
      ? true
      : !!embedToken;

  return (
     <div id="report-page-wrapper">
      <FullScreen handle={handle} >
      <div id="report-page-main">
        <PinToDashboardDialog
          open={dashboardOptionsOpen}
          setOpen={setDashboardOptionsOpen}
          addingReport={false}
        />
        {updateParams && (
          <div className="edit-mode">Editing Subscription Filters</div>
        )}
       
         <div className="report-container">
          <div
            className="report-wrapper"
          >
            
            {(errorRef?.current?.length ?? 0) > 0 && (
              <div id="error-container">
                <CancelOutlinedIcon />
                <span>{errorRef.current}</span>
              </div>
            )}
            {showEmbed&&isReady ? (
              <PowerBIEmbed
                eventHandlers={eventHandlersMap}
                embedConfig={
                  localReport?.reportTypeId === ReportTypeEnum.PowerBI_Report
                    ? reportConfig
                    : paginatedConfig
                }
                cssClassName="report-style2"
                getEmbeddedComponent={(embedObject: Embed) => {
                  _setReport(embedObject as Report);
                  if (
                    localReport?.reportTypeId ===
                    ReportTypeEnum.PowerBI_Paginated
                  ) {
                    setReportLoaded(true);
                  }
                }}
              />
            ) : (errorRef?.current?.length ?? 0) <= 0 &&
              <div id="progress-container">
                <CircularProgress size={40} />
              </div>
            }
          </div>
          
        </div>
      </div>
      </FullScreen>
    </div> 
  );
})
export default ViewReportPage;
