import { ExportFileExtension } from './exportFileExtension';
import { UserReportHistory } from './userReportHistory';

export interface UserReportHistoryExportDetails {
    userReportHistoryExportDetailsId: number;
    userReportHistoryId: number;
    userReportHistory?: UserReportHistory;
    exportFileExtensionId: number;
    exportFileExtension?: ExportFileExtension;
    storageURL: string;
    percentComplete?: number;
    exportStatus: string;
    startDate?: Date;
    completeDate?: Date;
    createdDate?: Date;
    modifiedDate?: Date;
    errorMessage: string;
}

export enum UserReportHistoryExportDetailsStatuses {
    QUEUED = "QUEUED",
    RUNNING = "RUNNING",
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED"
}