import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import { Subscription } from "../../redux/models/subscription";
import { DateUtils } from "../../utils/date.utils";
import { CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import { reportActions } from "../../redux/actions/report.actions";
import { FilteredSubscription } from "../../redux/selectors/subscription.selector";
import SubscriptionWizard from "../SubscriptionWizard/SubscriptionWizard";

export interface SubscriptionComponentProps {
  subscription: FilteredSubscription;
  indexItem:number
}

function SubscriptionComponent({ subscription,indexItem }: SubscriptionComponentProps) {
  const dispatch = useDispatch();
  const isUserAdmin = useSelector(isUserAdministrator);
  const ref = useRef<any>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  const [subscribeUsersDialogOpen,setSubscribeUsersDialogOpen]=useState<boolean>(false);
  const [subscribeDialogOpen,setSubscribeDialogOpen]=useState<boolean>(false);


  const handleSubscribe = () => {
        if(subscription.report){
          dispatch(reportActions.setSelectedReport(subscription.report));
          setSubscribeDialogOpen(true);
          ref.current?.handleSubscribeBtnClick();
        }	
      }
      const handleSubscribeUsers = () => {
        if(subscription.report){
          dispatch(reportActions.setSelectedReport(subscription.report));
          setSubscribeUsersDialogOpen(true);
          ref.current?.handleSubscribeUsersBtnClick();
        }	
      }

  function setSelectedSubscription() {
    dispatch(subscriptionActions.setSubscription(subscription));
  }

  function handleActiveToggleChanged(checked: boolean) {
    console.log(
      `Changing ${subscription.name} from ${subscription.active} to ${checked}`
    );
    if (!subscription.isLoading) {
      dispatch(
        subscriptionActions.updateSubscriptionActive(
          subscription.subscriptionId,
          checked
        )
      );
    }
  }

  function renderEditIcon(subscription: Subscription): JSX.Element {
    if (subscription.isAdmin) {
      if (isUserAdmin) {
        return (
          <div
            onKeyDown={() => setSubscribeUsersDialogOpen(true)}
            onClick={() => setSubscribeUsersDialogOpen(true)}
            title="Edit"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2.5 21.5003L8.04927 19.366C8.40421 19.2295 8.58168 19.1612 8.74772 19.0721C8.8952 18.9929 9.0358 18.9015 9.16804 18.7989C9.31692 18.6834 9.45137 18.5489 9.72028 18.28L21 7.0003C22.1046 5.89574 22.1046 4.10487 21 3.0003C19.8955 1.89573 18.1046 1.89573 17 3.0003L5.72028 14.28C5.45138 14.5489 5.31692 14.6834 5.20139 14.8323C5.09877 14.9645 5.0074 15.1051 4.92823 15.2526C4.83911 15.4186 4.77085 15.5961 4.63433 15.951L2.5 21.5003ZM2.5 21.5003L4.55812 16.1493C4.7054 15.7663 4.77903 15.5749 4.90534 15.4872C5.01572 15.4105 5.1523 15.3816 5.2843 15.4068C5.43533 15.4356 5.58038 15.5807 5.87048 15.8708L8.12957 18.1299C8.41967 18.4199 8.56472 18.565 8.59356 18.716C8.61877 18.848 8.58979 18.9846 8.51314 19.095C8.42545 19.2213 8.23399 19.2949 7.85107 19.4422L2.5 21.5003Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          </div>
        );
      } else {
        return <svg xmlns="http://www.w3.org/2000/svg" className="disabled" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M2.5 21.5003L8.04927 19.366C8.40421 19.2295 8.58168 19.1612 8.74772 19.0721C8.8952 18.9929 9.0358 18.9015 9.16804 18.7989C9.31692 18.6834 9.45137 18.5489 9.72028 18.28L21 7.0003C22.1046 5.89574 22.1046 4.10487 21 3.0003C19.8955 1.89573 18.1046 1.89573 17 3.0003L5.72028 14.28C5.45138 14.5489 5.31692 14.6834 5.20139 14.8323C5.09877 14.9645 5.0074 15.1051 4.92823 15.2526C4.83911 15.4186 4.77085 15.5961 4.63433 15.951L2.5 21.5003ZM2.5 21.5003L4.55812 16.1493C4.7054 15.7663 4.77903 15.5749 4.90534 15.4872C5.01572 15.4105 5.1523 15.3816 5.2843 15.4068C5.43533 15.4356 5.58038 15.5807 5.87048 15.8708L8.12957 18.1299C8.41967 18.4199 8.56472 18.565 8.59356 18.716C8.61877 18.848 8.58979 18.9846 8.51314 19.095C8.42545 19.2213 8.23399 19.2949 7.85107 19.4422L2.5 21.5003Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>;
      }
    } else {
      return (
        <div
            onKeyDown={() => setSubscribeDialogOpen(true)}
          onClick={() => setSubscribeDialogOpen(true)}
          title="Edit"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M2.5 21.5003L8.04927 19.366C8.40421 19.2295 8.58168 19.1612 8.74772 19.0721C8.8952 18.9929 9.0358 18.9015 9.16804 18.7989C9.31692 18.6834 9.45137 18.5489 9.72028 18.28L21 7.0003C22.1046 5.89574 22.1046 4.10487 21 3.0003C19.8955 1.89573 18.1046 1.89573 17 3.0003L5.72028 14.28C5.45138 14.5489 5.31692 14.6834 5.20139 14.8323C5.09877 14.9645 5.0074 15.1051 4.92823 15.2526C4.83911 15.4186 4.77085 15.5961 4.63433 15.951L2.5 21.5003ZM2.5 21.5003L4.55812 16.1493C4.7054 15.7663 4.77903 15.5749 4.90534 15.4872C5.01572 15.4105 5.1523 15.3816 5.2843 15.4068C5.43533 15.4356 5.58038 15.5807 5.87048 15.8708L8.12957 18.1299C8.41967 18.4199 8.56472 18.565 8.59356 18.716C8.61877 18.848 8.58979 18.9846 8.51314 19.095C8.42545 19.2213 8.23399 19.2949 7.85107 19.4422L2.5 21.5003Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      {subscribeDialogOpen && (
            <SubscriptionWizard action="edit" admin={false} open={subscribeDialogOpen}
            setOpen={setSubscribeDialogOpen} reportId={subscription.report?.reportId} subscription={subscription}/>	
          )}
          {subscribeUsersDialogOpen && (
            <SubscriptionWizard action="edit" admin={isUserAdmin?isUserAdmin:false} open={subscribeUsersDialogOpen}
            setOpen={setSubscribeUsersDialogOpen} reportId={subscription.report?.reportId} subscription={subscription}/>	
          )}
              <TableRow className={subscription?.active ? "subscription-table" : "subscription-table inactive"}>
                <TableCell style={{borderLeft:isOpen?'3px solid #AF1E37':'none'}}
                >
                   <IconButton
                    aria-label="expand row"
                    size="small"
                    className="show"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {isOpen ? 
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z" stroke="#165CE8" strokeWidth="1.5"/>
                    <path d="M16 13.5L12.3 10.725C12.1222 10.5917 11.8778 10.5917 11.7 10.725L8 13.5" stroke="#165CE8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#758496" strokeWidth="1.5"/>
                    <path d="M8 10.5L11.7 13.275C11.8778 13.4083 12.1222 13.4083 12.3 13.275L16 10.5" stroke="#758496" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg> }
                  </IconButton>
                </TableCell>
                <TableCell className="tblrow-title">
                  <span>{subscription.name}</span>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "10%", minWidth: 165 }}
                >
                  {DateUtils.formatDateTime(subscription.lastRunDateUTC)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "7%", minWidth: 100 }}
                >
                  <Link to={`/reports/storage/${subscription.subscriptionId}`}>
                    <span className="no-select">View</span>
                  </Link>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "10%", minWidth: 165 }}
                >
                  {DateUtils.formatDateTime(subscription.nextRunDateUTC)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    position: "sticky",
                    right: 0,
                    borderBottomRightRadius: isOpen ? 0 : 10,
                    background:'#FFF'
                  }}
                >
                  <div className="action-items">
                    <Link
                      to={"/reports/" + subscription.report?.reportId}
                      title="Run"
                      onClick={() => {
                        dispatch(
                          reportActions.saveSlicerState(
                            subscription.slicers
                              ? JSON.parse(subscription.slicers)
                              : undefined
                          )
                        );
                        dispatch(
                          reportActions.saveReportFilters(
                            subscription.filters
                              ? JSON.parse(subscription.filters)
                              : undefined
                          )
                        );
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9.5 8.96533C9.5 8.48805 9.5 8.24941 9.59974 8.11618C9.68666 8.00007 9.81971 7.92744 9.96438 7.9171C10.1304 7.90525 10.3311 8.03429 10.7326 8.29239L15.4532 11.3271C15.8016 11.551 15.9758 11.663 16.0359 11.8054C16.0885 11.9298 16.0885 12.0702 16.0359 12.1946C15.9758 12.337 15.8016 12.449 15.4532 12.6729L10.7326 15.7076C10.3311 15.9657 10.1304 16.0948 9.96438 16.0829C9.81971 16.0726 9.68666 15.9999 9.59974 15.8838C9.5 15.7506 9.5 15.512 9.5 15.0347V8.96533Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </Link>
                    
                    {renderEditIcon(subscription)}
                    <div className="toggle-switch">
                      <Switch
                        onChange={handleActiveToggleChanged}
                        checked={subscription.active}
                        onColor="#5AC09F"
                        offColor="#D6DADF"
                        width={32}
                        height={16}
                        checkedIcon={false}
                        uncheckedIcon={false}
                      />
                    </div>
                    <div className="active-container">
                      {subscription.isLoading ? (
                        <CircularProgress className="progress" size={20} />
                      ) : subscription.active ? (
                        <div>On</div>
                      ) : (
                        <div>Off</div>
                      )}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
          <TableRow>
                  <TableCell className={isOpen?"collapsible-block in":"collapsible-block"} colSpan={6}>
          <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            
          >
            <Grid container spacing={1}>
              <Grid item xs={12} xl={8} lg={8} md={4} sm={8}>
                <label>Report Filters: </label>
                {subscription?.parameters
                      .split(" And ")
                      .map((p, i) => (
                        <Typography key={p}>
                          {p.includes(".") ? p.split(".")[1] : p.split("/")[1]}
                        </Typography>
                      ))}
              </Grid>
              <Grid item xs={12} xl={4} lg={4} md={8} sm={4}>
                <label>File types: </label>
                <Typography>
                {subscription.subscriptionExtensions
                  .map((s) => s.exportFileExtension?.fileExtension)
                  .join(", ")}
              </Typography>
              </Grid>
            </Grid>
            <div>
                <label className="title">Notes: </label>
                {subscription.jobSchedule.notes ?? "N/A"}
              </div>
            {/* <div className="medium-text">
              <span className="bold-text">Filters: </span>
              <span>
              {subscription.parameters &&
                    subscription.parameters
                      .split(" And ")
                      .map((p, i) => (
                        <div key={i}>
                          {p.includes(".") ? p.split(".")[1] : p.split("/")[1]}
                        </div>
                      ))}
              </span>
            </div>
            <div className="subscription-component-params medium-text">
              <div className="filters-container">
                <span className="bold-text">Filters: </span>
                <span>
                  {subscription.parameters &&
                    subscription.parameters
                      .split(" And ")
                      .map((p, i) => (
                        <div key={i}>
                          {p.includes(".") ? p.split(".")[1] : p.split("/")[1]}
                        </div>
                      ))}
                </span>
              </div>
              <div>
                <span className="bold-text">Notes: </span>
                {subscription.jobSchedule.notes ?? "N/A"}
              </div>
            </div> */}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SubscriptionComponent;
