import { selectSelectedSubscription } from "../../redux/selectors/subscription.selector";
import EditIcon from "@mui/icons-material/EditOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { PowerBIUtils } from "../../utils/powerBi.utils";

export interface ReportFiltersProps {
  action: string;
  admin: boolean;
  disabled: boolean;
}

function ReportFilters({ action, admin, disabled }: Readonly<ReportFiltersProps>) {
  const selectedSubscription = useSelector(selectSelectedSubscription);

  return (
    <div className="report-filters">
      <div className="select-lable"> Report Filters 
          {action === "edit" &&
            (!disabled ? (
              <Link
                to={`/reports/${selectedSubscription.report?.reportId}/${
                  selectedSubscription.subscriptionId
                }/${admin ? 1 : 0}`}
                title="Edit Filters"
              >
                <EditIcon />
              </Link>
            ) : (
              <EditIcon style={{ color: "grey" }} />
            ))}
        </div>
      {selectedSubscription?.parameters?.split(" and ").map((p, index) => {
          if(p){
            let f = PowerBIUtils.getReadableText(p);
          return (
            <div className="item small-text" key={f}>
              {f}
            </div>
          );
          }
        })}
    </div>
  );
}

export default ReportFilters;
