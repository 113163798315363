import React from "react";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DesktopDateRangePicker from "@mui/lab/DesktopDateRangePicker";
import { DateRange } from "@mui/lab/DateRangePicker";
import { DateUtils } from "../../utils/date.utils";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Dayjs } from "dayjs";

export interface DateRangeFilterProps {
  index: number;
  displayName: string;
  setFilter: (
    index: number,
    values: any[],
    newValue: DateRange<Dayjs>
  ) => void;
  values: DateRange<Dayjs>;
}

function DateRangeFilter({
  index,
  displayName,
  setFilter,
  values,
}: DateRangeFilterProps) {
  function setValues(newValue: DateRange<Dayjs>) {
    let val = [];
    newValue[0]
      ? val.push(new Date(newValue[0].toString()).toLocaleDateString())
      : val.push(null);
    newValue[1]
      ? val.push(
          DateUtils.addDays(new Date(newValue[1].toString()), 1).toLocaleDateString()
        )
      : val.push(null);

    setFilter(index, val, newValue);
  }

  console.log(displayName);
  console.log(values);

  return (
    <div className="filter-wrapper small-text">
      {displayName}:
      <div className="date-filter-container">
        <DateRangeIcon />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDateRangePicker
            startText="From"
            endText="To"
            value={values}
            onChange={setValues}
            toolbarTitle="Select date range"
            allowSameDateSelection={true}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <input
                  key="1"
                  ref={startProps.inputRef}
                  {...startProps.inputProps}
                  placeholder="From"
                />
                -
                <input
                  key="2"
                  ref={endProps.inputRef}
                  {...endProps.inputProps}
                  placeholder="To"
                />
              </React.Fragment>
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
export default DateRangeFilter;
