import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";
import { reportActions } from "../../redux/actions/report.actions";
import { useEffect } from "react";
import { selectDeleted } from "../../redux/selectors/report.selector";
import './DeleteDialog.scss';
import NewDeleteDialog from "../Common/NewDeleteDialog";

interface DeleteReportDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleMenuClose: () => void;
  isAdmin: boolean;

}

function DeleteReportDialog({ open, setOpen,handleMenuClose,isAdmin }: DeleteReportDialogProps) {
  const dispatch = useDispatch();

  const { reportReducer } = useSelector(
    (state: AppState) => state
  );
  const deleted = useSelector(selectDeleted);
  const localReport = reportReducer.selectedReport;

  useEffect(() => {
    if (deleted) {
      dispatch(reportActions.setDeleted(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if(localReport?.reportId){
      let reportIds: number[] = [];
      reportIds.push(localReport?.reportId);
      dispatch(reportActions.deleteReport(reportIds));
      setOpen(false);
      handleMenuClose();
    }
    
   
  };

  if (isAdmin) {
    return (
      <div id="delete-report-dialog">
      <NewDeleteDialog open={open} handleClose={handleClose} heading={`Delete ${localReport?.name} Dashboard ?`} setOpen={setOpen} handleDelete={handleDelete} warningMsg={`You are about to delete "${localReport?.name}" from the Report Catalog, which
              will also result in the disabling of any user-created recurring
              subscriptions for this report.`} />

      </div>
    );
  } else {
    return <div></div>;
  }
}

export default DeleteReportDialog;
