import { AppState } from "../../redux/reducers";
import _orderBy from "lodash/orderBy";
import {memoize} from "proxy-memoize";


export const selectAllSettings = (state: AppState) => state.adminReducer.settings;

export const selectTimeOutActive = (state: AppState) => state.adminReducer.settings.find(s => s.name === "SessionTimeout")?.active;
export const selectTimeOutValue = (state: AppState) => +(state.adminReducer.settings.find(s => s.name === "SessionTimeout")?.value ?? 60);
export const selectTimeOutWarning = (state: AppState) => +(state.adminReducer.settings.find(s => s.name === "SessionWarning")?.value ?? 10);
export const selectReportAsWidgetSettingValue = (state: AppState) => (state.adminReducer.settings.find(s => s.name === "AllowReportAddAsWidget")?.value?.toString() ?? "")?.toUpperCase() === "TRUE";
export const selectInteractiveAsWidgetSettingValue = (state: AppState) => (state.adminReducer.settings.find(s => s.name === "AllowInteractiveAddAsWidget")?.value?.toString() ?? "")?.toUpperCase() === "TRUE";

export const selectTimeoutSettings = (state: AppState) => state.adminReducer.settings.find(s => s.name === "SessionTimeout");
export const selectWarningSettings = (state: AppState) => state.adminReducer.settings.find(s => s.name === "SessionWarning");
export const selectFilePurgeSettings = (state: AppState) => state.adminReducer.settings.find(s => s.name === "FileStoragePurgeAfter");
export const selectReportAsWidgetSetting = (state: AppState) => state.adminReducer.settings.find(s => s.name === "AllowReportAddAsWidget");
export const selectInteractiveAsWidgetSetting = (state: AppState) => state.adminReducer.settings.find(s => s.name === "AllowInteractiveAddAsWidget");

export const selectSaved = (state: AppState) => state.adminReducer.saved;

export const selectStorageStats = (state: AppState) => state.adminReducer.blobStorageStats;

export const selectpowerBIReports = memoize((state: AppState) => {
    const { powerBIReports } = state.adminReducer;

    return _orderBy(powerBIReports, ["name"], ["asc"]);
});

export const selectpowerBIWorkspaces = memoize((state: AppState) => {
    const { powerBIWorkspaces } = state.adminReducer;

    return _orderBy(powerBIWorkspaces, ["name"], ["asc"]);
});

export const selectloadingPowerBIReports = (state: AppState) => state.adminReducer.loadingPowerBIReports;
export const selectloadingPowerBIWorkspaces = (state: AppState) => state.adminReducer.loadingPowerBIWorkspaces;
export const selectSecurityRoles = (state: AppState) => state.adminReducer.securityRoles;