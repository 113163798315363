import { Dispatch } from "react";
import { IBaseAction } from "./base.action";
import { Dashboard } from "../models/dashboard";
import { Widget } from "../models/widget";
import { dashboardService } from "../services/dashboard.service";
import { ShareDashboard } from "../models/shareDashboard";
import { DashboardUser } from "../models/dashboardUser";
import { models } from "powerbi-client";
import { NotificationActions } from "./notification.actions";
import { INotificationType } from "../models/notificationType";

// interface IGetWidgetsAction extends IBaseAction {
//     readonly type: 'GET_WIDGETS_REQUEST' | 'GET_WIDGETS_SUCCESS' | 'GET_WIDGETS_FAILURE';
//     payload?: WidgetTemplate[];
// }

interface ISetWidgetsAction extends IBaseAction {
    readonly type: 'SET_WIDGETS'
    widgets: Widget[] | undefined
}

interface IUpdateWidgetEmbedAction {
    readonly type: 'UPDATE_WIDGET_EMBED'
    uniqueEmbedId: string;
    embedUrl: string;
    embedToken: string;
}

interface IUpdateWidgetErrorAction {
    readonly type: 'UPDATE_WIDGET_ERROR'
    uniqueEmbedId: string;
    errorMessage: string
}

interface ISetWidgetFullscreen {
    readonly type: 'SET_WIDGET_FULLSCREEN'
    widgetId: number;
    isFullscreen: boolean;
}

// interface IRunReportAction extends IBaseAction {
//     readonly type: 'DASHBOARD_RUN_REPORT_REQUEST' | 'DASHBOARD_RUN_REPORT_SUCCESS' | 'DASHBOARD_RUN_REPORT_FAILURE';
//     userReportHistoryId?: number;
//     reportId: number;
// }

interface IGetUserDashboards extends IBaseAction {
    readonly type: 'DASHBOARD_GET_USER_DASHBOARDS_REQUEST' | 'DASHBOARD_GET_USER_DASHBOARDS_SUCCESS' | 'DASHBOARD_GET_USER_DASHBOARDS_FAILURE';
    response?: Dashboard[];
    selectDashboardId: number | null;
}

interface ICloneDashboard extends IBaseAction {
    readonly type: 'DASHBOARD_CLONE_REQUEST' | 'DASHBOARD_CLONE_SUCCESS' | 'DASHBOARD_CLONE_FAILURE';
    dashboardId: number;
    response?: Dashboard;
}

interface IAddWidget extends IBaseAction {
    readonly type: 'DASHBOARD_ADD_WIDGET_REQUEST' | 'DASHBOARD_ADD_WIDGET_SUCCESS' | 'DASHBOARD_ADD_WIDGET_FAILURE';
    response?: Widget;
}

interface IUpdateWidget extends IBaseAction {
    readonly type: 'DASHBOARD_UPDATE_WIDGET_REQUEST' | 'DASHBOARD_UPDATE_WIDGET_SUCCESS' | 'DASHBOARD_UPDATE_WIDGET_FAILURE';
    widget: Widget;
    response?: Widget;
}

interface IUpdateDashboard extends IBaseAction {
    readonly type: 'DASHBOARD_UPDATE_DASHBOARD_REQUEST' | 'DASHBOARD_UPDATE_DASHBOARD_SUCCESS' | 'DASHBOARD_UPDATE_DASHBOARD_FAILURE';
    dashboard: Dashboard;
    response?: Dashboard;
}

interface IDeleteDashboard extends IBaseAction {
    readonly type: 'DASHBOARD_DELETE_DASHBOARD_REQUEST' | 'DASHBOARD_DELETE_DASHBOARD_SUCCESS' | 'DASHBOARD_DELETE_DASHBOARD_FAILURE';
    dashboardId?: number;
    nextDefaultDashboardId: number | null;
}

interface IDeleteWidget extends IBaseAction {
    readonly type: 'DASHBOARD_DELETE_WIDGET_REQUEST' | 'DASHBOARD_DELETE_WIDGET_SUCCESS' | 'DASHBOARD_DELETE_WIDGET_FAILURE';
    dashboardId?: number;
    widgetId?: number;
}

interface ISetCurrentDashboard {
    readonly type: 'DASHBOARD_SET_CURRENT_DASHBOARD';
    dashboardId?: number;
}

interface IEmbedWidgetsAction extends IBaseAction {
    readonly type: 'DASHBOARD_EMBED_WIDGETS_REQUEST' | 'DASHBOARD_EMBED_WIDGETS_SUCCESS' | 'DASHBOARD_EMBED_WIDGETS_FAILURE';
    dashboardId: number;
    response?: Widget[];
}

interface IResizeWidget extends IBaseAction {
    readonly type: 'DASHBOARD_RESIZE_WIDGET_REQUEST' | 'DASHBOARD_RESIZE_WIDGET_SUCCESS' | 'DASHBOARD_RESIZE_WIDGET_FAILURE';
    dashboardId?: number;
    newWidgets?: Widget[];
    response?: Widget;
}

// interface ISetPinToDashboardAction extends IBaseAction {
//     readonly type: 'SET_PIN_TO_DASHBOARD';
//     dashboardId: number;
// }

interface ISetSelectedWidgetAction extends IBaseAction {
    readonly type: 'SET_NEW_WIDGET';
    reportId: number;
    pageName?: string;
    visualName?: string;
    slicers?: models.ISlicer[];
    filters?: models.IFilter[];
}

interface IShareDashboard extends IBaseAction {
    readonly type: 'DASHBOARD_SHARE_REQUEST' | 'DASHBOARD_SHARE_SUCCESS' | 'DASHBOARD_SHARE_FAILURE';
    data?: ShareDashboard;
    response?: DashboardUser;
}

interface ISetDashboardUserToRemove {
    readonly type: 'DASHBOARD_SET_REMOVE_USER'
    dashboardUserId: number;
}

interface IRemoveDashboardUser extends IBaseAction {
    readonly type: 'DASHBOARD_REMOVE_USER_REQUEST' | 'DASHBOARD_REMOVE_USER_SUCCESS' | 'DASHBOARD_REMOVE_USER_FAILURE';
    dashboardId: number;
    dashboardUserId: number;
    nextDefaultDashboardId: number | null;
    response?: boolean;
}

interface IClearAllAction {
    readonly type: 'DASHBOARD-CLEAR-ALL'
}

export type DashboardActions =
    // | IGetWidgetsAction | IRunReportAction
    | IUpdateWidgetEmbedAction | IUpdateWidgetErrorAction
    | IEmbedWidgetsAction
    | IGetUserDashboards | ICloneDashboard | IAddWidget | IUpdateWidget | IUpdateDashboard | IDeleteDashboard | IDeleteWidget
    | ISetCurrentDashboard | IClearAllAction | ISetSelectedWidgetAction | ISetWidgetsAction
    | IResizeWidget | ISetWidgetFullscreen | IShareDashboard | IRemoveDashboardUser | ISetDashboardUserToRemove


// const getWidgets = () => {
//     return function (dispatch: Dispatch<IGetWidgetsAction>) {
//         dispatch({ type: 'GET_WIDGETS_REQUEST' });
//         dashboardService.getWidgets().then((response) => {
//             return dispatch({ type: 'GET_WIDGETS_SUCCESS', payload: response })
//         }, (error) => {
//             return dispatch({ type: 'GET_WIDGETS_FAILURE', error })
//         })
//     }
// }

const setWidgets = (widgets: Widget[] | undefined) => {
    return function (dispatch: Dispatch<ISetWidgetsAction>) {
        return dispatch({ type: 'SET_WIDGETS', widgets });
    }
}

// const runReportByReportId = (reportId: number) => {
//     return function (dispatch: Dispatch<IRunReportAction>) {
//         dispatch({ type: 'DASHBOARD_RUN_REPORT_REQUEST', reportId });
//         reportService.runReportByReportId(reportId).then((response) => {
//             return dispatch({ type: 'DASHBOARD_RUN_REPORT_SUCCESS', userReportHistoryId: response, reportId })
//         }, (error) => {
//             return dispatch({ type: 'DASHBOARD_RUN_REPORT_FAILURE', reportId, error })
//         })
//     }
// }

const updateWidgetEmbed = (uniqueEmbedId: string, embedUrl: string, embedToken: string) => {
    return function (dispatch: Dispatch<IUpdateWidgetEmbedAction>) {
        dispatch({ type: 'UPDATE_WIDGET_EMBED', uniqueEmbedId, embedUrl, embedToken });
    }
}

const updateWidgetError = (uniqueEmbedId: string, error: string) => {
    return function (dispatch: Dispatch<IUpdateWidgetErrorAction>) {
        dispatch({ type: 'UPDATE_WIDGET_ERROR', uniqueEmbedId, errorMessage: error });
    }
}

const setWidgetFullscreen = (widgetId: number, isFullscreen: boolean) => {
    return function (dispatch: Dispatch<ISetWidgetFullscreen>) {
        dispatch({ type: 'SET_WIDGET_FULLSCREEN', widgetId, isFullscreen });
    }
}

const getUserDashboards = (selectDashboardId: number | null) => {
    return function (dispatch: Dispatch<IGetUserDashboards>) {
        dispatch({ type: 'DASHBOARD_GET_USER_DASHBOARDS_REQUEST', selectDashboardId });
        dashboardService.getUserDashboards(selectDashboardId).then((response) => {
            if (selectDashboardId) {
                if (!response.find(d => d.dashboardId === selectDashboardId)) {
                    return dispatch({ type: 'DASHBOARD_GET_USER_DASHBOARDS_SUCCESS', response, selectDashboardId: null })
                }
            }
            return dispatch({ type: 'DASHBOARD_GET_USER_DASHBOARDS_SUCCESS', response, selectDashboardId })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_GET_USER_DASHBOARDS_FAILURE', error, selectDashboardId })
        })
    }
}

const cloneDashboard = (dashboardId: number, newDashboardName: string) => {
    return function (dispatch: Dispatch<ICloneDashboard>) {
        dispatch({ type: 'DASHBOARD_CLONE_REQUEST', dashboardId });
        dashboardService.cloneDashboard(dashboardId, newDashboardName).then((response) => {
            return dispatch({ type: 'DASHBOARD_CLONE_SUCCESS', dashboardId, response })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_CLONE_FAILURE', dashboardId, error })
        })
    }
}

const addWidget = (widget?: Widget) => {
    if (!widget)
        return;
    return function (dispatch: Dispatch<IAddWidget>) {
        dispatch({ type: 'DASHBOARD_ADD_WIDGET_REQUEST' });
        dashboardService.addWidget(widget).then((response) => {
            //setExistingDashboard();
            return dispatch({ type: 'DASHBOARD_ADD_WIDGET_SUCCESS', response })
        }, (error) => {
            //setExistingDashboard();
            return dispatch({ type: 'DASHBOARD_ADD_WIDGET_FAILURE', error })
        })
    }
}

const updateWidget2 = (widget: Widget) => {
    return function (dispatch: Dispatch<IUpdateWidget>) {
        dispatch({ type: 'DASHBOARD_UPDATE_WIDGET_REQUEST', widget });
        dashboardService.updateWidget(widget).then((response) => {
            return dispatch({ type: 'DASHBOARD_UPDATE_WIDGET_SUCCESS', widget, response })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_UPDATE_WIDGET_FAILURE', widget, error })
        })
    }
}

const updateDashboard = (dashboard: Dashboard) => {
    return function (dispatch: Dispatch<IUpdateDashboard>) {
        dispatch({ type: 'DASHBOARD_UPDATE_DASHBOARD_REQUEST', dashboard });
        dashboardService.updateDashboard(dashboard).then((response) => {
            return dispatch({ type: 'DASHBOARD_UPDATE_DASHBOARD_SUCCESS', dashboard, response })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_UPDATE_DASHBOARD_FAILURE', dashboard, error })
        })
    }
}

const deleteDashboard = (dashboardId: number, nextDefaultDashboardId: number | null,isDefault:boolean|undefined) => {
    return function (dispatch: Dispatch<IDeleteDashboard| NotificationActions>) {
        nextDefaultDashboardId=isDefault?nextDefaultDashboardId:0;
        dispatch({ type: 'DASHBOARD_DELETE_DASHBOARD_REQUEST', dashboardId, nextDefaultDashboardId });
        dashboardService.deleteDashboard(dashboardId, nextDefaultDashboardId).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Info, message: isDefault&&nextDefaultDashboardId?`Default Dashboard has been updated.`:'Your Dashboard has been deleted Successfully.', stayVisible: false })
            return dispatch({ type: 'DASHBOARD_DELETE_DASHBOARD_SUCCESS', dashboardId, nextDefaultDashboardId })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_DELETE_DASHBOARD_FAILURE', dashboardId, nextDefaultDashboardId, error })
        })
    }
}

const deleteWidget = (dashboardId: number, widgetId: number) => {
    return function (dispatch: Dispatch<IDeleteWidget>) {
        dispatch({ type: 'DASHBOARD_DELETE_WIDGET_REQUEST', dashboardId, widgetId });
        dashboardService.deleteWidget(dashboardId, widgetId).then((response) => {
            return dispatch({ type: 'DASHBOARD_DELETE_WIDGET_SUCCESS', dashboardId, widgetId })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_DELETE_WIDGET_FAILURE', dashboardId, error })
        })
    }
}

const setCurrentDashboard = (dashboardId?: number) => {
    return function (dispatch: Dispatch<ISetCurrentDashboard>) {
        dispatch({ type: 'DASHBOARD_SET_CURRENT_DASHBOARD', dashboardId });
    }
}

// const setPinToDashboard = (dashboardId: number) => {
//     return function (dispatch: Dispatch<ISetPinToDashboardAction>) {
//         dispatch({ type: 'SET_PIN_TO_DASHBOARD', dashboardId });
//     }
// }

const setSelectedWidget = (reportId: number, pageName: string | undefined, visualName: string | undefined, slicers: models.ISlicer[] | undefined, filters: models.IFilter[] | undefined) => {
    return function (dispatch: Dispatch<ISetSelectedWidgetAction>) {
        dispatch({ type: 'SET_NEW_WIDGET', reportId, pageName, visualName, slicers, filters });
    }
}

const embedWidgets = (dashboardId: number) => {
    return function (dispatch: Dispatch<IEmbedWidgetsAction>) {
        dispatch({ type: 'DASHBOARD_EMBED_WIDGETS_REQUEST', dashboardId });
        dashboardService.embedWidgets(dashboardId).then((response) => {
            return dispatch({ type: 'DASHBOARD_EMBED_WIDGETS_SUCCESS', dashboardId, response })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_EMBED_WIDGETS_FAILURE', dashboardId, error })
        })
    }
}

const resizeWidgets = (dashboard: Dashboard, newWidgets?: Widget[]) => {
    return function (dispatch: Dispatch<IResizeWidget>) {
        dispatch({ type: 'DASHBOARD_RESIZE_WIDGET_REQUEST', dashboardId: dashboard.dashboardId });
        dashboardService.updateDashboard({ ...dashboard, widgets: newWidgets }).then((response) => {
            return dispatch({ type: 'DASHBOARD_RESIZE_WIDGET_SUCCESS', newWidgets })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_RESIZE_WIDGET_FAILURE', dashboardId: dashboard.dashboardId, error })
        })
    }
}

const shareDashboard = (data: ShareDashboard) => {
    return function (dispatch: Dispatch<IShareDashboard>) {
        dispatch({ type: 'DASHBOARD_SHARE_REQUEST', data });
        dashboardService.shareDashboard(data).then((response) => {
            return dispatch({ type: 'DASHBOARD_SHARE_SUCCESS', data, response, successMessage: `Successfully shared with ${data.emailAddress}` })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_SHARE_FAILURE', data, error })
        })
    }
}

const removeDashboardUser = (dashboardId: number, dashboardUserId: number, nextDefaultDashboardId: number | null) => {
    return function (dispatch: Dispatch<IRemoveDashboardUser>) {
        dispatch({ type: 'DASHBOARD_REMOVE_USER_REQUEST', dashboardId, dashboardUserId, nextDefaultDashboardId });
        dashboardService.removeDashboardUser(dashboardId, dashboardUserId, nextDefaultDashboardId).then((response) => {
            return dispatch({ type: 'DASHBOARD_REMOVE_USER_SUCCESS', dashboardId, dashboardUserId, response, nextDefaultDashboardId })
        }, (error) => {
            return dispatch({ type: 'DASHBOARD_REMOVE_USER_FAILURE', dashboardId, dashboardUserId, error, nextDefaultDashboardId })
        })
    }
}

const setDashboardUserToRemove = (dashboardUserId: number) => {
    return function (dispatch: Dispatch<ISetDashboardUserToRemove>) {
        dispatch({ type: 'DASHBOARD_SET_REMOVE_USER', dashboardUserId });
    }
}

const clearAll = () => {
    return function (dispatch: Dispatch<IClearAllAction>) {
        return dispatch({ type: 'DASHBOARD-CLEAR-ALL' })
    }
}

export const dashboardActions = {
    setWidgets,
    updateWidgetEmbed,
    updateWidgetError,
    setWidgetFullscreen,
    getUserDashboards,
    cloneDashboard,
    addWidget,
    updateWidget2,
    updateDashboard,
    deleteDashboard,
    deleteWidget,
    setCurrentDashboard,
    embedWidgets,
    resizeWidgets,
    // setPinToDashboard,
    setSelectedWidget,
    shareDashboard,
    setDashboardUserToRemove,
    removeDashboardUser,
    clearAll
}