import { Box } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import SideNavSubItem, { ISideNavSubItem } from "../SideNavSubItem";
import '../../../styles/Variables-Functions.scss';
import { TooltipStyle } from "../../Common/TooltipStyle";
import { SideNavBarIcons } from "./SideNavBarIcons";

interface ISideNavItem extends ISideNavSubItem {
  icon: string,
  subItems?: any[],
  chevrun?: JSX.Element,
  isCollapsed: boolean,
  setArrowUp: (is: boolean) => void,
  setIsAdministrationUp: (is: boolean) => void,
  setPageName: (p: string) => void,
}

const getActiveNav = (url: string, subItems?: any[])=>{
  if (
    window.location.pathname === "/" ||
    window.location.pathname === "/home"
  ) {
    return url === "/";
  } else if (url.includes("/reports/catalog")) {
    return url === `${window.location.pathname}${window.location.search}`;
  } else if (url.includes("/favorites")) {
    return url === `${window.location.pathname}${window.location.search}`;
  } else if (url === "/mydashboard") {
    return url === window.location.pathname;
  } else if (url === "/support") {
    return url === window.location.pathname;
  }
  else {
    return subItems?.some(u => u.url === window.location.pathname);
  }
}

const SideNavItem = ({
  label,
  url,
  icon,
  adminOnly,
  subItems,
  chevrun,
  isAdmin,
  isCollapsed,
  arrowUp,
  isAdministrationUp,
  setIsCollapsed,
  setArrowUp,
  setIsAdministrationUp,
  setPageName
}: ISideNavItem
) => {
  const history = useHistory();
  let isActive = getActiveNav(url,subItems);
 
  if (adminOnly && !isAdmin) {
    return null;
  }
  return (
    <React.Fragment key={label}>
      <Box
        key="sidenav1"
        data-testid={"sidenav1"}
        className={
          "nav-item no-select" +
          (isActive ? " active" : "") +
          (isCollapsed ? " collapsed" : "")
        }

        onClick={() => {
          if (url === '') {
            return;
          }
          setPageName(label);
          if (url === '/configurations') {
            setArrowUp(!arrowUp);
            if (isCollapsed) {
              setIsCollapsed(false);
            }
            return;
          }
          if (url.includes('/admin')) {
            setIsAdministrationUp(!isAdministrationUp);
            if (isCollapsed) {
              setIsCollapsed(false);
            }
            return;
          }
          if (url === '/alerts') {
            alert('We are working on this feature. Coming Soon.');
            return;
          }
          setIsCollapsed(true);
          history.push(url);
        }}
      >

        {isCollapsed ? <TooltipStyle title={label} arrow placement="right" >
          <div><SideNavBarIcons iconName={icon} /></div>
        </TooltipStyle> : <SideNavBarIcons iconName={icon} />}
        {subItems?.length == 0 ? 
        <span key={"title" + label} data-testid="menutitle" className={isCollapsed ? "nav-collapsed" : "nav-collapse"}>{label}</span> :
        <div key={"title" + label} data-testid="menutitle" className={isCollapsed ? "nav-collapsed" : "nav-collapse"}>
          <Box className="nav-title-container">
            <span>{label}</span> <span className="nav-chevrun">{!isCollapsed && chevrun}</span>
          </Box>
        </div>
        }

      </Box>
      <div
        key="sidenav2"
        className={
          "nav-sub-item-container" + (isCollapsed ? " collapsed" : "")
        }
      >
        {subItems?.map((si) => {
          return <SideNavSubItem
            key={si.label}
            label={si.label}
            url={si.url}
            adminOnly={si.adminOnly}
            navItemName={si.navItemName}
            isAdmin={isAdmin}
            arrowUp={arrowUp}
            isAdministrationUp={isAdministrationUp}
            setIsCollapsed={setIsCollapsed}
          />
        })}
      </div>
    </React.Fragment>
  );
};

export default SideNavItem;