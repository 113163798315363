import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import {
  selectSelectedDashboard,
  selectDashboardUserIdToRemove,
} from "../../redux/selectors/dashboard.selector";
import NewDeleteDialog from "../Common/NewDeleteDialog";

export interface RemoveUserDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function RemoveUserDialog({ open, setOpen }: RemoveUserDialogProps) {
  const dispatch = useDispatch();

  const selectedDashboard = useSelector(selectSelectedDashboard);
  const dashboardUserIdToRemove = useSelector(selectDashboardUserIdToRemove);
  const userEmail = selectedDashboard?.dashboardUsers?.map(u=>u.dashboardUserId===dashboardUserIdToRemove?u.user.emailAddress:'')

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    dispatch(
      dashboardActions.removeDashboardUser(
        selectedDashboard.dashboardId ?? 0,
        dashboardUserIdToRemove,
        null
      )
    );
    setOpen(false);
  };

  return (
    <div id="delete-report-dialog">
      <NewDeleteDialog open={open} handleClose={handleClose} heading={`Delete ${userEmail } User?`} setOpen={setOpen} handleDelete={handleRemove} warningMsg={"Are you sure you want to proceed with removing this user?"} />
    </div>
  );
}

export default RemoveUserDialog;
