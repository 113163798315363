import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedSubscription,
  selectFieldsValid,
} from "../../redux/selectors/subscription.selector";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { JobScheduleDaysOfWeek } from "../../redux/models/jobScheduleDaysOfWeek";
import { Grid } from "@mui/material";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    width: 50,
    padding: "6px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

const recurrenceFactors = [
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
];

export interface WeeklyRecurrenceProps {
  formData:any;
}

function WeeklyRecurrence({formData}:Readonly<WeeklyRecurrenceProps>) {
  const dispatch = useDispatch();

  const selectedSubscription = useSelector(selectSelectedSubscription);
  const fieldsValid = useSelector(selectFieldsValid);

  function handleRecurrenceFactorChange(event: any) {
    dispatch(
      subscriptionActions.setSubscriptionRecurrenceFactor(event.target.value)
    );
  }

  function handleDayClick(id: number, day: string) {
    let days = selectedSubscription.jobSchedule.jobScheduleDaysOfWeek;
    if (days?.some((d) => d.dayOfWeekId === id)) {
      days = days.filter((d) => d.dayOfWeekId !== id);
    } else {
      let d = { dayOfWeekId: id } as JobScheduleDaysOfWeek;
      days.push(d);
    }

    const valid =
      selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
        formData.jobScheduleFreqType.filter(
          (t:any) => t.description === "Weekly"
        )[0].jobScheduleFreqTypeId && days?.length > 0;

    dispatch(subscriptionActions.setSubscriptionDays(days, false, valid));
  }

  return (
    <div className="weekly-recurrence">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} md={3} lg={3} className="select-lable" data-testid="timeLable">
          Recur Every
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Select
          className="select"
          value={selectedSubscription.jobSchedule.jobScheduleRecurrenceFactor}
          onChange={handleRecurrenceFactorChange}
          input={<Input />}
          IconComponent={KeyboardArrowDownIcon}
          data-testid="test-recurevery"
        >
          {recurrenceFactors.map((val) => (
            <MenuItem
              key={val.value}
              value={val.value}
              className="medium-text blue-text"
            >
              {val.value}
            </MenuItem>
          ))}
        </Select>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2} className="select-lable"> Week </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} md={3} lg={3} className="select-lable">
          Select Days
        </Grid>
        <Grid item xs={12} sm={9} md={9} lg={9} className="recurrence-row mb-15">
          {formData.daysOfWeek.map((d:any) => {
            return (
              <div
                key={d.dayOfWeekId}
                className={
                  "day" +
                  (selectedSubscription.jobSchedule.jobScheduleDaysOfWeek?.some(
                    (jd) => jd.dayOfWeekId === d.dayOfWeekId
                  )
                    ? " chosen"
                    : "")
                }
                data-testid={`day-${d.dayOfWeekId}`}
                onClick={() => handleDayClick(d.dayOfWeekId, d.shortName)}
              >
                {d.shortName}
              </div>
            );
          })}
          {fieldsValid.showDaysError && (
            <span className="small-error">Run days required</span>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default WeeklyRecurrence;
