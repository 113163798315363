import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { DateRange } from "@mui/lab/DateRangePicker";
import { Filter } from "../../redux/models/filter";
import "./Filters.scss";
import Grid from "@mui/material/Grid";
import DateRangeFilter from "./DateRangeFilter";
import TextFilter from "./TextFilter";
import SelectFilter from "./SelectFilter";
import { Dayjs } from "dayjs";

export interface FiltersProps {
  isOpen: boolean;
  filters: Filter<any>[];
  appliedFiltersCount: number;
  setIsOpen: (isOpen: boolean) => void;
  setFilters: (filters: Filter<any>[]) => void;
  applyFilters: (filters: Filter<any>[]) => void;
  clearAllFilters: () => void;
  clearFilter: (filterName: string) => void;
}

function Filters({
  isOpen,
  setIsOpen,
  filters,
  setFilters,
  applyFilters,
  clearAllFilters,
  clearFilter,
  appliedFiltersCount,
}: FiltersProps) {
  function setDateFilter(
    index: number,
    values: any[],
    newValue: DateRange<Dayjs>
  ) {
    filters[index].values = values;
    filters[index].rawValues = newValue;
    setFilters(filters);
  }

  function setTextFilter(index: number, values: any[]) {
    filters[index].values = values;
    setFilters(filters);
  }

  function applyNewFilters() {
    applyFilters(filters);
  }

  return (
    <div id="filters-container">
      <div className="filters-header">
        <TuneIcon />
        Filters
        {isOpen && (
          <span onClick={() => setIsOpen(false)}>
            <ExpandLessIcon />
          </span>
        )}
        {!isOpen && (
          <span onClick={() => setIsOpen(true)}>
            <ExpandMoreIcon />
          </span>
        )}
      </div>
      {isOpen && (
        <div className="filters-body">
          <div className="filters">
            <Grid container spacing={0} display="flex" gap={2}>
              {filters?.map((filter, i) => {
                if (filter.filterType === "date") {
                  return (
                    <Grid item minWidth={220} key={i}>
                      <DateRangeFilter
                        key={i}
                        index={i}
                        displayName={filter.displayName}
                        setFilter={setDateFilter}
                        values={filter.rawValues ?? [null, null]}
                      />
                    </Grid>
                  );
                } else if (filter.filterType === "text") {
                  return (
                    <Grid item key={i} minWidth={220}>
                      <TextFilter
                        key={i}
                        index={i}
                        displayName={filter.displayName}
                        setFilter={setTextFilter}
                        values={filter.values}
                      />
                    </Grid>
                  );
                } else if (filter.filterType === "select") {
                  return (
                    <Grid item key={i} minWidth={220}>
                      <SelectFilter
                        key={i}
                        index={i}
                        displayName={filter.displayName}
                        setFilter={setTextFilter}
                        values={filter.values}
                        options={filter.options ?? []}
                      />
                    </Grid>
                  );
                } else return null;
              })}
               <Grid item minWidth={220} sx={{marginTop: {md:"1rem", lg:"1rem", xl:"1rem"}}} marginLeft="auto">
               <div className="filter-btn small-text">
            <button onClick={applyNewFilters}>Apply Filters</button>
          </div>
               </Grid>
            </Grid>
          </div>
         
        </div>
      )}
      {appliedFiltersCount > 0 && (
        <div className="filters-footer">
          <div className="applied-filters">
            {filters?.map((filter, i) => {
              if (filter.applied)
                return (
                  <div className="filter small-text" key={i}>
                    {filter.displayName}{" "}
                    <span
                      onClick={() => {
                        clearFilter(filter.name?.toString());
                      }}
                    >
                      X
                    </span>
                  </div>
                );
              return null;
            })}
          </div>
          <div
            className="clear-filters small-text"
            onClick={() => clearAllFilters()}
          >
            Clear All Filters
          </div>
        </div>
      )}
    </div>
  );
}
export default Filters;
