import { Divider, IconButton,Menu } from "@mui/material"
import { ReportActionIcons } from "./ReportActionIcons"
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReportTypeEnum } from "../../../redux/models/reportType";
import { reportActions } from "../../../redux/actions/report.actions";
import {
    selectInteractiveAsWidgetSettingValue,
    selectReportAsWidgetSettingValue,
} from "../../../redux/selectors/admin.selector";
import PinToDashboardDialog from "../../PinToDashboardDialog/PinToDashboardDialog";
import ExportDialog from "../../ExportDialog/ExportDialog";
import DeleteReportDialog from "../../DeleteReportDialog/DeleteReportDialog";
import "./ReportActions.scss"
import { AppState } from "../../../redux/reducers";
import AddEditReportDialog from "../AddReport/AddReport";


export interface ReportActionsProps {
   
    exportReport: (extension: string) => void;
    printReport: () => void;
    refreshReport: () => void;
    reportType?: ReportTypeEnum;
    selectedReport?: any;
    setUnfavoriteDialogOpen?: (open: boolean) => void;
    isAdmin: boolean;
    handleSubscribe: () => void;
    handleSubscribeUsers: () => void;
    handleFullScreen: () => void;
    isReportLoaded: () => boolean;
}

export const ReportActions = ({
    exportReport,
    printReport,
    refreshReport,
    reportType,
    selectedReport,
    setUnfavoriteDialogOpen,
    isAdmin,
    handleSubscribe,
    handleSubscribeUsers,
    handleFullScreen,
    isReportLoaded,
}: ReportActionsProps) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [isExportOpen, setExportOpen] = useState<boolean>(false);
    const [isThreedotActive, setThreedotActive] = useState<boolean>(false);
    const [isPinActive, setPinActive] = useState<boolean>(false);
    const [isPinToDashboardOpen, setPinToDashboardeOpen] =
        useState<boolean>(false);
    const loadingReport = useSelector((state: AppState) => state.reportReducer.loadingReport);
    const [addReportDialogOpen,setAddReportDialog]=useState<boolean>(false);

    const reportAddAsWidgetSettingValue = useSelector(
        selectReportAsWidgetSettingValue
    );
    const interactiveAddAsWidgetSettingValue = useSelector(
        selectInteractiveAsWidgetSettingValue
    );
    const dispatch = useDispatch();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(()=>{
        if(!open){
            setThreedotActive(false);
        }
        if(!isPinToDashboardOpen){
            setPinActive(false);
        }
    },[open, isPinToDashboardOpen])

    useEffect(()=>{
        const disabled = loadingReport;
        console.log(" disabled ", disabled)
    },[loadingReport])

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isAllowedToPin = () => {
        if (reportType === ReportTypeEnum.PowerBI_Paginated)
            return reportAddAsWidgetSettingValue;
        else if (reportType === ReportTypeEnum.PowerBI_Report)
            return interactiveAddAsWidgetSettingValue;
        return false;
    };

    function updateFavorite() {
        if(selectedReport?.reportId){
                dispatch(reportActions.setFavoriteReport(selectedReport));
                handleClose();
        }
    }

    const handleEdit = ()=>{
        if(selectedReport?.reportId){
            setAddReportDialog(true);
            handleClose();
            dispatch(reportActions.setSelectedReport(selectedReport));
        }
      }
      const handleDelete = ()=>{
        if(selectedReport?.reportId){
          dispatch(reportActions.setSelectedReport(selectedReport));
          setDeleteDialogOpen(true);
        }
      }

    const handleAction = (event: any) => {
        setAnchorEl(event?.currentTarget);
        setThreedotActive(true);
    }
    const handlePintoDashboard = (event: any) => {
        setPinToDashboardeOpen(true);
        setPinActive(true);
    }
  
    const disabled = loadingReport;
    

    return <div
    className="dashboard-nav-right">
        {deleteDialogOpen && (
            <DeleteReportDialog
                open={deleteDialogOpen}
                handleMenuClose={handleClose}
                isAdmin={isAdmin}
                setOpen={setDeleteDialogOpen}
            />
        )}
        {addReportDialogOpen&&(
            <AddEditReportDialog
            open={true}
            setOpen={setAddReportDialog}
            action='edit'
            type="dashboard"
            data-testid="addeditelement"
            selectedCategoryId={selectedReport?.categoryId}
          />
        )}
        <PinToDashboardDialog
            open={isPinToDashboardOpen}
            setOpen={setPinToDashboardeOpen}
            addingReport={true}
        />
        {isExportOpen && (
            <ExportDialog
                open={isExportOpen}
                setOpen={setExportOpen}
                exportReport={exportReport}
                reportTypeId={selectedReport?.reportTypeId}
            />
        )}
        <IconButton 
        id="report-actions-button"
        data-testid="rpt-actions-button"
        onClick={handlePintoDashboard}
            sx={{
                "&:hover": {
                    bgcolor: "transparent"
                },
            }}
            disabled={disabled || !isAllowedToPin()}
            disableRipple
            className={isPinActive ? "dasboard-threedot-icon": ""}
        >
            <ReportActionIcons iconName="pin" />
        </IconButton>
        <IconButton className="no-bradius" data-testid="full-screen" onClick={handleFullScreen}
            sx={{
                "&:hover": {
                    bgcolor: "transparent"
                },
               
            }}
            disableRipple
            disabled={disabled}
        >
            <ReportActionIcons iconName="fullScreen" />
        </IconButton>
        <IconButton onClick={handleAction} data-testid="menu-options" id="menu-options"
            sx={{
                "&:hover": {
                    bgcolor: "transparent"
                },
            }}
            disableRipple
            disabled={disabled}
            className={isThreedotActive ? "three-dots dasboard-threedot-icon": "three-dots"}
        >
            <ReportActionIcons iconName="threedot" />
        </IconButton>
        <Menu
            id="rpt-actions-menu"
            data-testid="rpt-actions-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                "aria-labelledby": "rpt-actions-button",
            }}
            sx={{fontSize:"0.8rem"}}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
           
        >
            {selectedReport?.reportTypeId==1&&<MenuItem className="menu-item" 
                onClick={() => {
                    handleClose();
                    if(selectedReport?.reportId){
                        setExportOpen(true);
                    }
                }}
            >
                <ReportActionIcons iconName="export" />
                <ListItemText className="item-name">Export</ListItemText>
            </MenuItem>
            }
            {selectedReport?.reportTypeId==1&&<><MenuItem className="menu-item"
                onClick={() => {
                    handleClose();
                    if(selectedReport?.reportId&&printReport!=undefined){
                        printReport();
                    }
                }}
            >
                <ReportActionIcons iconName="print" />
                <ListItemText className="item-name">Print Report</ListItemText>
            </MenuItem>
            <Divider sx={{ mx: 2 }} /></>
        }
            <MenuItem className="menu-item"
                onClick={()=>{handleClose();handleSubscribe()}}
            >
                <ReportActionIcons iconName="subscribe" />
                <ListItemText className="item-name">Subscribe</ListItemText>
            </MenuItem>
            {isAdmin && (
                <MenuItem className="menu-item"
                    onClick={()=>{handleClose();handleSubscribeUsers()}}
                >
                    <ReportActionIcons iconName="subscribeUser" />
                    <ListItemText className="item-name">Create Subscriptions for Users</ListItemText>
                </MenuItem>
            )}
            <Divider sx={{ mx: 2 }} />
            {selectedReport?.reportTypeId==1&&<MenuItem className={selectedReport?.isFavorite ? "menu-item favorite-icon": "menu-item"}
                onClick={updateFavorite}
            >
                {(
                    <ReportActionIcons iconName="favorite" />
                )}
                <ListItemText className="item-name">
                    {selectedReport?.isFavorite ? "Favorite" : "Make Favorite"}
                </ListItemText>
            </MenuItem>
            }
            <MenuItem className="menu-item"
                onClick={() => {
                    handleClose();
                    if(selectedReport?.reportId&&refreshReport!=undefined){
                        refreshReport();
                    }
                }}
            >
                <ReportActionIcons iconName="refresh" />
                <ListItemText className="item-name">Refresh</ListItemText>
            </MenuItem>
          
            <Divider sx={{ mx: 2 }} />
            
            {selectedReport?.reportTypeId==1&&isAdmin && <MenuItem className="menu-item" data-testid="edit-report" id="edit-report"
                onClick={handleEdit}
            >
                <ReportActionIcons iconName="edit" />
                <ListItemText className="item-name">Edit</ListItemText>
            </MenuItem>}
            {selectedReport?.reportTypeId==1&&isAdmin && <MenuItem
                onClick={handleDelete}
                className="menu-item deleteMenuItem"
                data-testid="deleteMenuItem"
            >
                <ReportActionIcons iconName="delete" />
                <ListItemText className="item-name">Delete</ListItemText>
            </MenuItem>}
        </Menu>
    </div>
}