export interface ContactForm {
    senderEmailAddress: string;
    senderName: string;
    replyToEmailAddress: string;
    topic?: ContactFormTopicEnum;
    selectedReportId?: number;
    message: string;
}

export enum ContactFormTopicEnum {
    Portal = 1,
    Report = 2,
    Subscriptions = 3,
    FileStorage = 4,
    Dashboard = 5,
    Other = 6
}