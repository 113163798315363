import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import { selectTimeOutWarning } from "../../redux/selectors/admin.selector";
import { memo, useCallback, useEffect, useState } from "react";

export interface TimeoutWarningDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function TimeoutWarningDialog({ open, setOpen }: TimeoutWarningDialogProps) {
  let history = useHistory();
  const warningValue = useSelector(selectTimeOutWarning);
  const [seconds, setSeconds] = useState<number>(warningValue * 60);
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    history.push("/logout");
    setOpen(false);
  };

  const countdown = useCallback(() => {
    setSeconds((s) => s - 1);
  }, []);

  const secondsToTime = (secs: number): string => {
    //let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let formattedMinutes = minutes.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    let formattedSeconds = seconds.toLocaleString("en-US", {
      minimumIntegerDigits: 2,
    });
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  /**
   * Get current time
   */
  useEffect(() => {
    if (timer === null) {
      setTimer(setInterval(countdown, 1000));
    }

    // Cleanup
    return () => {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Clear the timer when 0
   */
  useEffect(() => {
    if (timer && seconds === 0) {
      clearInterval(timer);
      setTimer(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <div className="timeout-warning-dialog">
      <Dialog open={open} onClose={handleClose}>
        <div className="dialog-title warning">Warning</div>
        <div className="dialog-content">
          <p>
            {"Your session is about to expire. You will be logged out in "}
            <span style={{ fontWeight: "bold" }}>{secondsToTime(seconds)}</span>
          </p>
        </div>
        <div className="dialog-footer">
          <hr className="warning" />
          <Button onClick={handleLogout} className="cancel medium-text">
            Logout
          </Button>
          <Button onClick={handleClose} className="action medium-text">
            Continue Session
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default memo(TimeoutWarningDialog);
