import React from "react";

export interface TextFilterProps {
  index: number;
  displayName: string;
  setFilter: (index: number, values: any[]) => void;
  values: string[];
}

function TextFilter({
  index,
  displayName,
  setFilter,
  values,
}: TextFilterProps) {
  function setValues(newValue: string) {
    let val = [newValue];
    setFilter(index, val);
  }

  return (
    <div className="filter-wrapper small-text">
      {displayName}: <span className="small">(50 char. limit)</span>
      <div className="text-filter-container">
        <input
          value={values[0] ?? ""}
          placeholder=""
          onChange={(e) => setValues(e.target.value)}
          maxLength={50}
        />
      </div>
    </div>
  );
}
export default TextFilter;
