import React, { useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./redux/reducers";
import { userActions } from "./redux/actions/user.actions";
import { adminActions } from "./redux/actions/admin.actions";
import Notification from "./components/Notification/Notification";
import {
  selectTimeOutActive,
  selectTimeOutValue,
  selectTimeOutWarning,
} from "./redux/selectors/admin.selector";
import {
  isDashboardLoading,
} from "./redux/selectors/dashboard.selector";
import TimeoutWarningDialog from "./components/TimeoutWarning/TimeoutWarningDialog";
import Loading from "./components/Loading/Loading";
import EnvironmentIndicator from "./components/EnvironmentIndicator/EnvironmentIndicator";
import "./App.scss";
import { INotificationType } from "./redux/models/notificationType";
import { Box, Typography } from "@mui/material";
import { LicenseInfo } from '@mui/x-license-pro';
import { MainRoutes } from "./routes/MainRoutes";
import SideNavBar from "./components/SideNavBar";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import appConfig from "./utils/appConfig";

LicenseInfo.setLicenseKey(appConfig.dataRangePicker);

function App() {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [pageName, setPageName] = useState("Home");
  let isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const notificationReducer = useSelector(
    (state: AppState) => state.notificationReducer
  );
  const user = useSelector((state: AppState) => state.userReducer.user);
  const loadingUser = useSelector(
    (state: AppState) => state.userReducer.loadingUser
  );

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  const history = useHistory();
  let match = useRouteMatch("/");
  let match2 = useRouteMatch([
    "/history",
    "/reports",
    "/mydashboard",
    "/reports/:id",
    "/admin",
    "/report",
    "/report/:id",
    "/reports/catalog",
    "/reports/subscription/:id",
    "/reports/subscriptions",
    "/support",
    "/alerts",
    "/favorites",
  ]);

  let logoutTimeout = useRef<NodeJS.Timeout>();
  let logoutWarning = useRef<NodeJS.Timeout>();

  const dispatch = useDispatch();

  const [sessionWarningOpen, _setSessionWarningOpen] = React.useState(false);
  const sessionWarningOpenRef = useRef(sessionWarningOpen);

  const timeoutActive = useSelector(selectTimeOutActive);
  const timeoutValue = useSelector(selectTimeOutValue);
  const warningValue = useSelector(selectTimeOutWarning);
  const isDashLoading = useSelector(isDashboardLoading);

  const setSessionWarningOpen = (isOpen: boolean) => {
    sessionWarningOpenRef.current = isOpen;
    _setSessionWarningOpen(isOpen);
  };

  useEffect(() => {
    if (timeoutActive) {
      logoutTimeout.current = setTimeout(logout, timeoutValue * 60000);
      logoutWarning.current = setTimeout(
        warning,
        (timeoutValue - warningValue) * 60000
      );
      for (let i in events) {
        window.addEventListener(events[i], resetTimeout);
      }
    } else {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeoutActive]);

  let showNav = false;
  if (
    inProgress === "none" &&
    isAuthenticated &&
    !loadingUser &&
    user?.isValid === true
  ) {
    showNav = match?.isExact || match2 !== null;
  }

  useEffect(() => {
    if (notificationReducer.notificationType === INotificationType.Forbidden) {
      history.push("/forbidden");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationReducer.notificationType, user]);

  function resetTimeout() {
    if (!sessionWarningOpenRef.current) {
      if (logoutTimeout.current) {
        clearTimeout(logoutTimeout.current);
      }
      if (logoutWarning.current) {
        clearTimeout(logoutWarning.current);
      }
      logoutTimeout.current = setTimeout(logout, timeoutValue * 60000);
      logoutWarning.current = setTimeout(
        warning,
        (timeoutValue - warningValue) * 60000
      );
    }
  }

  function logout() {
    history.push("/logout?timeout=true");
    setSessionWarningOpen(false);
  }

  function warning() {
    setSessionWarningOpen(true);
  }

  useEffect(() => {
    if (inProgress === "none") {
      if (isAuthenticated) {
        dispatch(userActions.getOrCreateUser());
      } else {
        dispatch(adminActions.clearAll());
      }
    }
  }, [inProgress, isAuthenticated, dispatch]);

  useEffect(() => {
    if (user?.isValid && user.isActive) {
      dispatch(adminActions.getSettings());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const pageLoaded = inProgress !== "none" ? null : isAuthenticated && user?.isValid;

  return (
    <div data-testid="app-container">
      {sessionWarningOpen && (
        <TimeoutWarningDialog open={true} setOpen={setSessionWarningOpen} />
      )}
      <Notification />
      {pageLoaded && <Box sx={{
        display: {sm: "none",md: "none", lg: "none", xl: "none"},
        paddingBottom: {xs: "50px", sm: "64px"},
        }}
        >
        <AppBar position="fixed" sx={{backgroundColor: "#FFFFFF"}} >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ color: "#142330" }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="black" component="div" sx={{ flexGrow: 1 }}>
            {pageName}
          </Typography>
          </Toolbar>
        </AppBar>
      </Box>}
     
      <Box display={"flex"} data-testid="app-sidebar"
      >
        {showNav && <SideNavBar 
          isCollapsed= {isCollapsed}
          setIsCollapsed= {setIsCollapsed}
          setPageName={setPageName}
        />}
       
        <Box
          flexGrow={1}
          position="fixed"
          height="100%"
          overflow="inherit"
          sx={{
            backgroundColor: "#F1F4F7", 
            width: "100%"
          }}
            onClick={()=> !isCollapsed && setIsCollapsed(!isCollapsed)}
          >
            <Box
            className="main-box"
            sx={{
            paddingLeft: pageLoaded ? {xs: 0,sm: "70px", md: "70px", lg: "70px", xl: "70px"}: 0,
            height: "100%",
          }}
            >
          <MainRoutes
            inProgress={inProgress}
            isAuthenticated={isAuthenticated}
            user={user}
          />
          </Box>
        </Box>
        {isAuthenticated && (loadingUser || isDashLoading) && <Loading />}
        {process.env.REACT_APP_STAGE !== "prod" && <EnvironmentIndicator />}
      </Box>
    </div>
  );
}

export default App;
