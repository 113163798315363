import { DateRange } from "@mui/lab/DateRangePicker";
import { FilteredFileStorage } from "../selectors/history.selector";
import { FilteredReport } from "../selectors/report.selector";
import { FilteredAdminSubscription, FilteredSubscription } from "../selectors/subscription.selector";
import { FilteredUser } from "../selectors/user.selector";
import { Dayjs } from "dayjs";



export interface Filter<T> {
  name: keyof T;
  displayName: string;
  values: any[];
  filterType: "date" | "text" | "select";
  rawValues?: DateRange<Dayjs>;
  applied: boolean;
  options?: any[];
}


export const compliesWithFilter = <T>(object: T, filters: Filter<T>[]): boolean => {
  for (var f of filters) {
    if (!f.applied) {
      continue;
    }
    let reportValue = object[f.name] as any;

    if (!reportValue || f.values[0] === null) {
      return false;
    }

    if (f.filterType === "date") {
      if (reportValue < new Date(f.values[0]) || reportValue > new Date(f.values[1])) {
        return false;
      }

    } else if (f.filterType === "text") {
      reportValue = (reportValue?.toString() ?? "").toLowerCase();
      if (!reportValue?.includes(f.values[0].toLowerCase())) {
        return false;
      }
    } else if (f.filterType === "select") {
      if (f.values[0] === "All")
        continue;
      if (reportValue !== f.values[0]) {
        return false;
      }
    }
  }

  return true;
}

export const validateReportHistory=(reportHistory:any,selectedUserReportHistoryCategory:any)=>{
  if(!selectedUserReportHistoryCategory?.categoryId || reportHistory.userReport?.report.categoryId === selectedUserReportHistoryCategory.categoryId || reportHistory.subscription?.report?.categoryId === selectedUserReportHistoryCategory.categoryId) return true;
  return false;
}


export function CreateReportFilters() {
  let ret: Filter<FilteredReport>[] = [{
    name: "lastRunDateUTC",
    displayName: "Last Report",
    values: [null, null],
    filterType: "date",
    rawValues: [null, null],
    applied: false
  }, {
    name: "nextRunDateUTC",
    displayName: "Next Report",
    values: [null, null],
    filterType: "date",
    rawValues: [null, null],
    applied: false
  },]

  return ret;
}

export function CreateSubscriptionFilters() {
  let ret: Filter<FilteredSubscription>[] = [{
    name: "lastRunDateUTC",
    displayName: "Last Report",
    values: [null, null],
    filterType: "date",
    rawValues: [null, null],
    applied: false
  }, {
    name: "nextRunDateUTC",
    displayName: "Next Report",
    values: [null, null],
    filterType: "date",
    rawValues: [null, null],
    applied: false
  },]

  return ret;
}


export function CreateFileStorageFilters() {
  let ret: Filter<FilteredFileStorage>[] = [{
    name: "startDateUTC",
    displayName: "Run Date",
    values: [null, null],
    filterType: "date",
    rawValues: [null, null],
    applied: false,
  }]

  return ret;
}

export function CreateAdminSubscriptionFilters() {
  let ret: Filter<FilteredAdminSubscription>[] = [{
    name: "lastRunDate",
    displayName: "Last Report",
    values: [null, null],
    filterType: "date",
    rawValues: [null, null],
    applied: false
  }, {
    name: "nextRunDate",
    displayName: "Next Report",
    values: [null, null],
    filterType: "date",
    rawValues: [null, null],
    applied: false
  }, {
    name: "user",
    displayName: "User",
    values: [null],
    filterType: "text",
    applied: false
  }, {
    name: "title",
    displayName: "Report Title",
    values: [null],
    filterType: "text",
    applied: false
  }, {
    name: "status",
    displayName: "Status",
    values: [],
    filterType: "select",
    options: ["All", "Active", "Disabled"],
    applied: false
  }]

  return ret;
}

export function CreateUserFilters(): Filter<FilteredUser>[] {
  let ret: Filter<FilteredUser>[] = [{
    name: "emailAddress",
    displayName: "Email",
    values: [null],
    filterType: "text",
    applied: false
  }, {
    name: "role",
    displayName: "Role",
    values: [],
    filterType: "select",
    options: ["All", "Admin", "User"],
    applied: false
  },
  {
    name: "ampUserId",
    displayName: "AMPUserId",
    values: [null],
    filterType: "text",
    applied: false
  },
  {
    name: 'b2CObjectId',
    displayName: "B2CObjectId",
    values: [null],
    filterType: "text",
    applied: false
  },
  ]

  return ret;
}