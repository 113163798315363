import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import {Tooltip } from "@mui/material";
import { DropdonwArrowIcon } from "../Common/Utils";



export default function DashboardDropdown() {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector(
    (state: AppState) => state.dashboardReducer
  );
  const { dashboards, selectedDashboard } = dashboardReducer;

  const handleDropdownSelection = (event: any) => {
    console.log(`Handle dropdown selection: ${event.target.value}`);
    if (event.target.value === "-1") {
      console.log("Go to manage screen");
    } else {
      dispatch(dashboardActions.setCurrentDashboard(event.target.value));
    }
  };

  if (selectedDashboard?.dashboardId !== undefined) {
    return (
      <div>
        <Box>
          <FormControl sx={{ minWidth: 160 }} className="dashboard-select">
            <Select
              labelId="dashboard-select-label"
              id="dashboard-select"
              value={selectedDashboard.dashboardId}
              onChange={handleDropdownSelection}
              renderValue={() => selectedDashboard.name}
              IconComponent={DropdonwArrowIcon}
            >
              {dashboards.map((d, i) => {
                return (
                  <MenuItem
                    key={`${d.dashboardId}menuitem`}
                    value={d.dashboardId}
                    className="myMenuItem"
                  >
                    <span className="menu-title">{d.name}</span>
                    {d.isDefault && (
                      <Tooltip title="Default dashboard" placement="right">
                        <span className="default-dshbd-icon">Default</span>
                      </Tooltip>
                    )
                    }
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </div>
    );
  }
  return <div>No dashboards found!</div>;
}
