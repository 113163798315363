import React from "react"

const navBarIcons: any = {
    "threedot": <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
    <g id="DotsHorizontalOutline">
    <path id="Vector" d="M11.0007 17.4166V17.4074M11.0007 10.9999V10.9908M11.0007 4.58325V4.57409M11.0007 16.4999C11.2438 16.4999 11.4769 16.5965 11.6488 16.7684C11.8207 16.9403 11.9173 17.1735 11.9173 17.4166C11.9173 17.6597 11.8207 17.8929 11.6488 18.0648C11.4769 18.2367 11.2438 18.3333 11.0007 18.3333C10.7575 18.3333 10.5244 18.2367 10.3525 18.0648C10.1806 17.8929 10.084 17.6597 10.084 17.4166C10.084 17.1735 10.1806 16.9403 10.3525 16.7684C10.5244 16.5965 10.7575 16.4999 11.0007 16.4999ZM11.0007 10.0833C11.2438 10.0833 11.4769 10.1798 11.6488 10.3517C11.8207 10.5236 11.9173 10.7568 11.9173 10.9999C11.9173 11.243 11.8207 11.4762 11.6488 11.6481C11.4769 11.82 11.2438 11.9166 11.0007 11.9166C10.7575 11.9166 10.5244 11.82 10.3525 11.6481C10.1806 11.4762 10.084 11.243 10.084 10.9999C10.084 10.7568 10.1806 10.5236 10.3525 10.3517C10.5244 10.1798 10.7575 10.0833 11.0007 10.0833ZM11.0007 3.66659C11.2438 3.66659 11.4769 3.76316 11.6488 3.93507C11.8207 4.10698 11.9173 4.34014 11.9173 4.58325C11.9173 4.82637 11.8207 5.05953 11.6488 5.23143C11.4769 5.40334 11.2438 5.49992 11.0007 5.49992C10.7575 5.49992 10.5244 5.40334 10.3525 5.23143C10.1806 5.05953 10.084 4.82637 10.084 4.58325C10.084 4.34014 10.1806 4.10698 10.3525 3.93507C10.5244 3.76316 10.7575 3.66659 11.0007 3.66659Z" stroke="#758496" strokeWidth="2.36962" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>,
    "fullScreen": <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g id="uil:expand-alt">
    <path id="Vector" d="M8.15866 10.6584L3.33366 15.4917V14.1667C3.33366 13.9457 3.24586 13.7338 3.08958 13.5775C2.9333 13.4212 2.72134 13.3334 2.50033 13.3334C2.27931 13.3334 2.06735 13.4212 1.91107 13.5775C1.75479 13.7338 1.66699 13.9457 1.66699 14.1667V17.5001C1.66831 17.609 1.69096 17.7166 1.73366 17.8167C1.81822 18.0204 1.98003 18.1822 2.18366 18.2667C2.28384 18.3094 2.39143 18.3321 2.50033 18.3334H5.83366C6.05467 18.3334 6.26663 18.2456 6.42291 18.0893C6.57919 17.9331 6.66699 17.7211 6.66699 17.5001C6.66699 17.2791 6.57919 17.0671 6.42291 16.9108C6.26663 16.7545 6.05467 16.6667 5.83366 16.6667H4.50866L9.34199 11.8417C9.49891 11.6848 9.58707 11.472 9.58707 11.2501C9.58707 11.0282 9.49891 10.8153 9.34199 10.6584C9.18507 10.5015 8.97224 10.4133 8.75033 10.4133C8.52841 10.4133 8.31558 10.5015 8.15866 10.6584ZM18.267 2.18341C18.1824 1.97979 18.0206 1.81798 17.817 1.73341C17.7168 1.69071 17.6092 1.66806 17.5003 1.66675H14.167C13.946 1.66675 13.734 1.75455 13.5777 1.91083C13.4215 2.06711 13.3337 2.27907 13.3337 2.50008C13.3337 2.7211 13.4215 2.93306 13.5777 3.08934C13.734 3.24562 13.946 3.33341 14.167 3.33341H15.492L10.6587 8.15841C10.5806 8.23588 10.5186 8.32805 10.4762 8.4296C10.4339 8.53115 10.4122 8.64007 10.4122 8.75008C10.4122 8.86009 10.4339 8.96901 10.4762 9.07056C10.5186 9.17211 10.5806 9.26428 10.6587 9.34175C10.7361 9.41986 10.8283 9.48185 10.9298 9.52416C11.0314 9.56646 11.1403 9.58825 11.2503 9.58825C11.3603 9.58825 11.4693 9.56646 11.5708 9.52416C11.6724 9.48185 11.7645 9.41986 11.842 9.34175L16.667 4.50841V5.83341C16.667 6.05443 16.7548 6.26639 16.9111 6.42267C17.0673 6.57895 17.2793 6.66675 17.5003 6.66675C17.7213 6.66675 17.9333 6.57895 18.0896 6.42267C18.2459 6.26639 18.3337 6.05443 18.3337 5.83341V2.50008C18.3323 2.39118 18.3097 2.2836 18.267 2.18341Z" fill="#758496"/>
    </g>
    </svg>,
    "pin": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="pin">
    <path id="Vector" d="M2 14.0001L5.33333 10.6667" stroke="#758496" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M8.83892 12.5809C6.34336 12.0143 3.98554 9.65653 3.41894 7.161C3.32924 6.76593 3.2844 6.56844 3.41432 6.24798C3.54425 5.92753 3.70297 5.82837 4.02039 5.63006C4.73795 5.18177 5.51494 5.03925 6.32131 5.11057C7.45278 5.21065 8.01852 5.26069 8.30072 5.11365C8.58298 4.96661 8.77472 4.62278 9.15832 3.93513L9.64418 3.06403C9.96425 2.49019 10.1243 2.20327 10.5008 2.06801C10.8773 1.93275 11.1038 2.01466 11.5569 2.17847C12.6165 2.56157 13.4383 3.38337 13.8215 4.44299C13.9853 4.89611 14.0672 5.12267 13.9319 5.49913C13.7967 5.8756 13.5097 6.03564 12.9359 6.35572L12.0447 6.8528C11.3583 7.2356 11.0152 7.42707 10.8682 7.712C10.7212 7.997 10.7746 8.5504 10.8813 9.65727C10.9599 10.4712 10.8245 11.2533 10.3703 11.9798C10.1717 12.2972 10.0725 12.4559 9.75212 12.5857C9.43172 12.7155 9.23412 12.6707 8.83892 12.5809Z" stroke="#758496" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>,
    "export": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="file-export 1">
    <path id="Vector" d="M12.3334 10.6667C12.6705 10.9944 14.0001 11.8666 14.0001 12.3334M14.0001 12.3334C14.0001 12.8002 12.6705 13.6724 12.3334 14.0001M14.0001 12.3334H8.66675" stroke="#758496" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_2" d="M7.33333 14.6666H7.15153C4.97738 14.6666 3.89031 14.6666 3.13538 14.1347C2.91908 13.9823 2.72705 13.8016 2.56513 13.598C2 12.8875 2 11.8643 2 9.81812V8.12112C2 6.14568 2 5.15796 2.31263 4.36909C2.81521 3.10087 3.87809 2.10051 5.22557 1.62749C6.06375 1.33325 7.1132 1.33325 9.21213 1.33325C10.4115 1.33325 11.0112 1.33325 11.4901 1.50139C12.2601 1.77169 12.8675 2.34332 13.1547 3.06801C13.3333 3.5188 13.3333 4.08321 13.3333 5.21204V8.66658" stroke="#758496" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path id="Vector_3" d="M2 7.99992C2 6.77265 2.99492 5.7777 4.22222 5.7777C4.66608 5.7777 5.18936 5.85547 5.62091 5.73984C6.00435 5.63709 6.30384 5.3376 6.40659 4.95416C6.52222 4.52261 6.44445 3.99933 6.44445 3.55547C6.44445 2.32817 7.4394 1.33325 8.66667 1.33325" stroke="#758496" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>clip
    </svg>,
    "print": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="printer" clipPath="url(#clip0_2919_73264)">
    <path id="Icon" d="M11.9999 4.66659V3.46659C11.9999 2.71985 11.9999 2.34648 11.8546 2.06126C11.7268 1.81038 11.5228 1.60641 11.2719 1.47858C10.9867 1.33325 10.6133 1.33325 9.86659 1.33325H6.13325C5.38652 1.33325 5.01315 1.33325 4.72793 1.47858C4.47705 1.60641 4.27307 1.81038 4.14524 2.06126C3.99992 2.34648 3.99992 2.71985 3.99992 3.46659V4.66659M3.99992 11.9999C3.37994 11.9999 3.06995 11.9999 2.81561 11.9318C2.12543 11.7468 1.58633 11.2077 1.4014 10.5176C1.33325 10.2632 1.33325 9.95323 1.33325 9.33325V7.86659C1.33325 6.74648 1.33325 6.18643 1.55124 5.7586C1.74299 5.38228 2.04895 5.07632 2.42527 4.88457C2.85309 4.66659 3.41315 4.66659 4.53325 4.66659H11.4666C12.5867 4.66659 13.1467 4.66659 13.5746 4.88457C13.9509 5.07632 14.2569 5.38228 14.4486 5.7586C14.6666 6.18643 14.6666 6.74648 14.6666 7.86659V9.33325C14.6666 9.95323 14.6666 10.2632 14.5984 10.5176C14.4135 11.2077 13.8744 11.7468 13.1842 11.9318C12.9299 11.9999 12.6199 11.9999 11.9999 11.9999M9.99992 6.99992H11.9999M6.13325 14.6666H9.86659C10.6133 14.6666 10.9867 14.6666 11.2719 14.5213C11.5228 14.3934 11.7268 14.1895 11.8546 13.9386C11.9999 13.6534 11.9999 13.28 11.9999 12.5333V11.4666C11.9999 10.7198 11.9999 10.3465 11.8546 10.0613C11.7268 9.81038 11.5228 9.60641 11.2719 9.47858C10.9867 9.33325 10.6133 9.33325 9.86659 9.33325H6.13325C5.38652 9.33325 5.01315 9.33325 4.72793 9.47858C4.47705 9.60641 4.27307 9.81038 4.14524 10.0613C3.99992 10.3465 3.99992 10.7198 3.99992 11.4666V12.5333C3.99992 13.28 3.99992 13.6534 4.14524 13.9386C4.27307 14.1895 4.47705 14.3934 4.72793 14.5213C5.01315 14.6666 5.38652 14.6666 6.13325 14.6666Z" stroke="#758496" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_2919_73264">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
    </svg>,
    "favorite": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="star-01">
    <path id="Icon" d="M7.52169 2.30217C7.67534 1.99088 7.75217 1.83523 7.85647 1.7855C7.94722 1.74224 8.05264 1.74224 8.14339 1.7855C8.24769 1.83523 8.32452 1.99088 8.47817 2.30217L9.93596 5.25548C9.98132 5.34738 10.004 5.39333 10.0372 5.42901C10.0665 5.4606 10.1017 5.48619 10.1408 5.50437C10.185 5.52491 10.2357 5.53232 10.3371 5.54714L13.5979 6.02376C13.9413 6.07395 14.113 6.09905 14.1924 6.18292C14.2616 6.25589 14.2941 6.35616 14.2809 6.45581C14.2658 6.57035 14.1415 6.69141 13.8929 6.93355L11.5342 9.23091C11.4607 9.30252 11.4239 9.33833 11.4002 9.38093C11.3792 9.41866 11.3657 9.4601 11.3605 9.50296C11.3547 9.55137 11.3633 9.60196 11.3807 9.70312L11.9372 12.948C11.9959 13.2903 12.0253 13.4614 11.9701 13.563C11.9221 13.6513 11.8368 13.7133 11.738 13.7316C11.6243 13.7527 11.4707 13.6719 11.1633 13.5102L8.24817 11.9772C8.15734 11.9294 8.11193 11.9055 8.06408 11.8961C8.02172 11.8878 7.97814 11.8878 7.93578 11.8961C7.88793 11.9055 7.84252 11.9294 7.75169 11.9772L4.83653 13.5102C4.5292 13.6719 4.37553 13.7527 4.2619 13.7316C4.16304 13.7133 4.07773 13.6513 4.02974 13.563C3.97459 13.4614 4.00394 13.2903 4.06264 12.948L4.61918 9.70312C4.63653 9.60196 4.6452 9.55137 4.63933 9.50296C4.63413 9.4601 4.62066 9.41866 4.59966 9.38093C4.57593 9.33833 4.53917 9.30252 4.46564 9.2309L2.10696 6.93355C1.85836 6.69141 1.73406 6.57035 1.71894 6.45581C1.70578 6.35616 1.73829 6.25589 1.80742 6.18292C1.88688 6.09905 2.05857 6.07395 2.40195 6.02376L5.66279 5.54714C5.7642 5.53232 5.81491 5.52491 5.85906 5.50437C5.89816 5.48619 5.93336 5.4606 5.96271 5.42901C5.99586 5.39333 6.01854 5.34738 6.0639 5.25548L7.52169 2.30217Z" stroke="#758496" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>,
    "edit": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="pencil-01" clipPath="url(#clip0_2919_73257)">
    <path id="Icon" d="M1.66675 14.3334L5.36626 12.9105C5.60289 12.8195 5.7212 12.774 5.83189 12.7146C5.93022 12.6618 6.02395 12.6009 6.11211 12.5324C6.21136 12.4554 6.301 12.3658 6.48027 12.1865L14.0001 4.66671C14.7365 3.93033 14.7365 2.73642 14.0001 2.00004C13.2637 1.26366 12.0698 1.26366 11.3334 2.00004L3.8136 9.51985C3.63433 9.69912 3.5447 9.78876 3.46768 9.88801C3.39926 9.97617 3.33835 10.0699 3.28557 10.1682C3.22615 10.2789 3.18065 10.3972 3.08964 10.6339L1.66675 14.3334ZM1.66675 14.3334L3.03883 10.766C3.13701 10.5107 3.1861 10.3831 3.27031 10.3246C3.34389 10.2735 3.43495 10.2542 3.52295 10.271C3.62364 10.2902 3.72034 10.3869 3.91374 10.5803L5.4198 12.0864C5.6132 12.2798 5.7099 12.3765 5.72912 12.4772C5.74593 12.5652 5.72661 12.6562 5.67551 12.7298C5.61705 12.814 5.48941 12.8631 5.23413 12.9613L1.66675 14.3334Z" stroke="#758496" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_2919_73257">
    <rect width="16" height="16" fill="white"/>
    </clipPath>
    </defs>
    </svg>,
    "subscribe": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <g id="ic">
    <path id="Vector" d="M12.6667 7.83333H3.33333M12.6667 7.83333C13.0203 7.83333 13.3594 7.97381 13.6095 8.22386C13.8595 8.47391 14 8.81304 14 9.16667V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V9.16667C2 8.81304 2.14048 8.47391 2.39052 8.22386C2.64057 7.97381 2.97971 7.83333 3.33333 7.83333M12.6667 7.83333V6.5C12.6667 6.14638 12.5262 5.80724 12.2761 5.55719C12.0261 5.30714 11.687 5.16667 11.3333 5.16667M3.33333 7.83333V6.5C3.33333 6.14638 3.47381 5.80724 3.72386 5.55719C3.97391 5.30714 4.31304 5.16667 4.66667 5.16667M11.3333 5.16667V3.83333C11.3333 3.47971 11.1929 3.14057 10.9428 2.89052C10.6928 2.64048 10.3536 2.5 10 2.5H6C5.64638 2.5 5.30724 2.64048 5.05719 2.89052C4.80714 3.14057 4.66667 3.47971 4.66667 3.83333V5.16667M11.3333 5.16667H4.66667" stroke="#758496" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>,
    "subscribeUser": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="users-01">
    <path id="Icon" d="M14.6666 14V12.6667C14.6666 11.4241 13.8167 10.38 12.6666 10.084M10.3333 2.19384C11.3105 2.58943 11.9999 3.54754 11.9999 4.66667C11.9999 5.78579 11.3105 6.7439 10.3333 7.13949M11.3333 14C11.3333 12.7575 11.3333 12.1362 11.1303 11.6462C10.8596 10.9928 10.3405 10.4736 9.68707 10.203C9.19702 10 8.57576 10 7.33325 10H5.33325C4.09074 10 3.46949 10 2.97943 10.203C2.32602 10.4736 1.80689 10.9928 1.53624 11.6462C1.33325 12.1362 1.33325 12.7575 1.33325 14M8.99992 4.66667C8.99992 6.13943 7.80601 7.33333 6.33325 7.33333C4.86049 7.33333 3.66659 6.13943 3.66659 4.66667C3.66659 3.19391 4.86049 2 6.33325 2C7.80601 2 8.99992 3.19391 8.99992 4.66667Z" stroke="#758496" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>,
    "delete": <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="trash-01">
    <path id="Icon" d="M10.6667 4.00004V3.46671C10.6667 2.71997 10.6667 2.3466 10.5213 2.06139C10.3935 1.8105 10.1895 1.60653 9.93865 1.4787C9.65344 1.33337 9.28007 1.33337 8.53333 1.33337H7.46667C6.71993 1.33337 6.34656 1.33337 6.06135 1.4787C5.81046 1.60653 5.60649 1.8105 5.47866 2.06139C5.33333 2.3466 5.33333 2.71997 5.33333 3.46671V4.00004M6.66667 7.66671V11M9.33333 7.66671V11M2 4.00004H14M12.6667 4.00004V11.4667C12.6667 12.5868 12.6667 13.1469 12.4487 13.5747C12.2569 13.951 11.951 14.257 11.5746 14.4487C11.1468 14.6667 10.5868 14.6667 9.46667 14.6667H6.53333C5.41323 14.6667 4.85318 14.6667 4.42535 14.4487C4.04903 14.257 3.74307 13.951 3.55132 13.5747C3.33333 13.1469 3.33333 12.5868 3.33333 11.4667V4.00004" stroke="#E2494F" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>,
    "refresh":<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g id="refresh-ccw-01">
    <path id="Icon" d="M14.6667 9.33333C14.6667 9.33333 13.3301 11.1545 12.2442 12.2412C11.1583 13.3278 9.65768 14 8.00008 14C4.68637 14 2.00008 11.3137 2.00008 8C2.00008 4.68629 4.68637 2 8.00008 2C10.7355 2 13.0433 3.83048 13.7656 6.33333M14.6667 9.33333V13.3333M14.6667 9.33333H10.6667" stroke="#758496" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    </svg>,
    "dasbhoardFullScreen":<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 23 22" fill="none">
    <path d="M20.7349 3.23613C21.0034 2.96765 21.0034 2.53234 20.7349 2.26386C20.4664 1.99538 20.0311 1.99538 19.7626 2.26386L20.7349 3.23613ZM12.8876 9.13886C12.6192 9.40738 12.6192 9.84261 12.8876 10.1111C13.1561 10.3796 13.5914 10.3796 13.8599 10.1111L12.8876 9.13886ZM19.7626 2.26386L12.8876 9.13886L13.8599 10.1111L20.7349 3.23613L19.7626 2.26386Z" fill="#68788B"/>
    <path d="M11.1099 12.8611C11.3784 12.5926 11.3784 12.1574 11.1099 11.8889C10.8414 11.6204 10.4062 11.6204 10.1376 11.8889L11.1099 12.8611ZM3.26264 18.7639C2.99416 19.0324 2.99416 19.4676 3.26264 19.7361C3.53112 20.0046 3.96643 20.0046 4.23491 19.7361L3.26264 18.7639ZM10.1376 11.8889L3.26264 18.7639L4.23491 19.7361L11.1099 12.8611L10.1376 11.8889Z" fill="#68788B"/>
    <path d="M17.0405 9.83801C16.2665 9.84901 13.703 10.3815 13.1595 9.83801C12.6161 9.29452 13.1485 6.73107 13.1595 5.95703" stroke="#68788B" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.838 16.043C10.849 15.2689 11.3815 12.7055 10.838 12.162C10.2945 11.6185 7.73107 12.151 6.95703 12.162" stroke="#68788B" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}
interface IReportActionIcons{
    iconName: string;
}

export const ReportActionIcons = ({iconName}: IReportActionIcons) => {
    const icon = navBarIcons[iconName] ? navBarIcons[iconName]: navBarIcons["Census"];
    return <React.Fragment>{icon}</React.Fragment>
}