import { Dispatch } from "react"
import { ContactForm } from "../models/contactForm"
import { contactService } from "../services/contact.service"
import { IBaseAction } from "./base.action"

// DEFINE THE ACTION INTERFACES

interface IGetContactFormAction extends IBaseAction {
    readonly type: 'CONTACT_GET_CONTACT_FORM_REQUEST' | 'CONTACT_GET_CONTACT_FORM_SUCCESS' | 'CONTACT_GET_CONTACT_FORM_FAILURE';
    response?: ContactForm
}

interface ISubmitContactFormAction extends IBaseAction {
    readonly type: 'CONTACT_SUBMIT_CONTACT_FORM_REQUEST' | 'CONTACT_SUBMIT_CONTACT_FORM_SUCCESS' | 'CONTACT_SUBMIT_CONTACT_FORM_FAILURE';
    response?: boolean
}


// EXPORT THE ACTION INTERFACES FOR REDUCER CONSUMPTION

export type ContactActions = IGetContactFormAction | ISubmitContactFormAction



// DEFINE THE ACTIONS

const getContactForm = () => {
    return function (dispatch: Dispatch<IGetContactFormAction>) {
        dispatch({ type: 'CONTACT_GET_CONTACT_FORM_REQUEST' });
        contactService.getContactForm().then((response) => {
            return dispatch({ type: 'CONTACT_GET_CONTACT_FORM_SUCCESS', response: response })
        }, (error) => {
            return dispatch({ type: 'CONTACT_GET_CONTACT_FORM_FAILURE', error })
        })
    }
}

const submitContactForm = (form: ContactForm, callback: () => void) => {
    return function (dispatch: Dispatch<ISubmitContactFormAction>) {
        dispatch({ type: 'CONTACT_SUBMIT_CONTACT_FORM_REQUEST' });
        contactService.submitContactForm(form).then((response) => {
            callback();
            return dispatch({ type: 'CONTACT_SUBMIT_CONTACT_FORM_SUCCESS', response: response })
        }, (error) => {
            return dispatch({ type: 'CONTACT_SUBMIT_CONTACT_FORM_FAILURE', error })
        })
    }
}



// EXPORT THE ACTIONS FOR COMPONENT CONSUMPTION

export const contactActions = {
    getContactForm,
    submitContactForm
}