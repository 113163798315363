import { useEffect, useState } from "react";
import { useLocation,useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Check from "@mui/icons-material/Check";
import { styled } from "@mui/material/styles";
import Select,{SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { reportActions } from "../../redux/actions/report.actions";
import {
  selectFormReportTypes,
  selectFormReportCategories,
  selectSelectedReport,
  selectSaved,
} from "../../redux/selectors/report.selector";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import { Form, Field } from "react-final-form";
import "./ReportForm.scss";
import Grid from "@mui/material/Grid";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import ReportCategoryForm from "./ReportCategoryForm";
import { ReportCategory } from "../../redux/models/reportCategory";
import { adminActions } from "../../redux/actions/admin.actions";
import {
  selectloadingPowerBIReports,
  selectloadingPowerBIWorkspaces,
  selectpowerBIReports,
  selectpowerBIWorkspaces,
} from "../../redux/selectors/admin.selector";
import { FormApi } from "final-form";
import { Report } from "../../redux/models/report";
import { GenericSelectItem, getPowerBIReports, getPowerBiWorkSpaces, getReportTypeCategories, getTypes, renderSelectedCateogry, selectCategoryRequired } from "./ReportFormUtils";
import { guidRequired } from "../Common/Utils";



interface InputProps {
  input: any;
  label: string;
  placeholder: string;
  disabled: boolean;
  meta: any;
}

interface SelectProps {
  input: any;
  label: string;
  children: GenericSelectItem[];
  disabled: boolean;
  control: any;
  meta: any;
}

interface ReportFormProps {
  action: string;
}

const ReportForm = ({ action }: ReportFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] =
    useState<boolean>(false);
  const [categoryToEdit, setCatagoryToEdit] = useState<
    ReportCategory | undefined
  >();
  const [localWorkspaceId, setLocalWorkspaceId] = useState<string | undefined>(
    undefined
  );
  const [localReportId, setLocalReportId] = useState<string | undefined>(
    undefined
  );
  const isAdmin = useSelector(isUserAdministrator);
  const [selectedReport,setSelectedReport] = useState<Report | null>(useSelector(selectSelectedReport));
  const reportTypes = useSelector(selectFormReportTypes);
  const reportCategories = useSelector(selectFormReportCategories);
  const saved = useSelector(selectSaved);
  const _powerBIReports = useSelector(selectpowerBIReports);
  const _powerBIWorkspaces = useSelector(selectpowerBIWorkspaces);
  const loadingPowerBIReports = useSelector(selectloadingPowerBIReports);
  const loadingPowerBIWorkspaces = useSelector(selectloadingPowerBIWorkspaces);
  let type = new URLSearchParams(location.search).get("type");
  let selectedCategoryId = new URLSearchParams(location.search).get("category");
  const formTitle= type&&type=='dashboard'?'Add Dashboard': "Add Report";
  const [isSaved,setIsSaved]=useState(false);
  

  useEffect(() => {
    dispatch(reportActions.getReportCategories());
    dispatch(reportActions.getReportTypes());
    dispatch(adminActions.getPowerBIWorkspaces());
    if (action === "add") {
      const reportTypeId=type&&type=='dashboard'?1:0
      let defaultReport = {
        reportId:0,
        name: "",
        description: "",
        longRunningReport: true,
        embedURL: "",
        active: true,
        reportTypeId: reportTypeId==0&&type&&type=='report'?2:reportTypeId,
        categoryId: selectedCategoryId&&selectedCategoryId.length?parseInt(selectedCategoryId):0,
        defaultReport: false,
        powerBIReportId: "",
        powerBIWorkspaceId: ""
    }
    setSelectedReport(defaultReport);
    console.log(selectedCategoryId);
    } else if(id){
      dispatch(reportActions.getReportById(parseInt(id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action === "add" && saved) {
      dispatch(reportActions.setSaved(false));
      if(type&&type.toLowerCase()=='dashboard'){
        if(isSaved)
        history.goBack()
    }
    else{
      history.push('/');
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  useEffect(() => {
    if (
      action === "edit" &&
      _powerBIWorkspaces.length > 0 &&
      selectedReport?.powerBIWorkspaceId
    ) {
      dispatch(
        adminActions.getPowerBIReports(selectedReport?.powerBIWorkspaceId)
      );
    }
  }, [_powerBIWorkspaces]);

  const getCategories = (
    form: FormApi<any, Partial<any>>
  ): GenericSelectItem[] => {
    return categories.map((c) => {
      return {
        ...c,
        form: form,
      };
    });
  };

  

   


  const categories: GenericSelectItem[] =getReportTypeCategories(reportCategories,setCatagoryToEdit,setIsCategoryDialogOpen);


  const powerBIWorkspaces: GenericSelectItem[] =getPowerBiWorkSpaces(_powerBIWorkspaces);
  
  const powerBIReports: GenericSelectItem[] = getPowerBIReports(_powerBIReports);
  
  const types:GenericSelectItem[]=getTypes(reportTypes);
  

  const ReportTypeInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: action === "add" ? "rgba(85, 136, 163, 0.2)" : "#e8e8e8",
      borderBottom:
        action === "add" ? "2px solid #5588a3" : "2px solid #959494",
      fontSize: "1em",
      padding: "10px 16px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        border: "2px solid #5588a3",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e8e8e8",
      },
    },
  }));

  const CategoryInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "rgba(85, 136, 163, 0.2)",
      borderBottom: "2px solid #5588a3",
      fontSize: "1em",
      padding: "10px 16px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        border: "2px solid #5588a3",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e8e8e8",
      },
    },
  }));

  const required = (value: any) =>
    value && value.length > 0 ? undefined : "is required";
  const selectRequired = (value: any) =>
    value && value > 0 ? undefined : "is required";
 

  const onChange = () => {
    dispatch(reportActions.setSaved(false));
  };

  const getMenuItem = (val: GenericSelectItem) => {
    return (
      <MenuItem
        key={val.value}
        value={val.value}
        className="medium-text blue-text"
      >
        <div className="rf-menu-item-inner">
          <div>{val.name}</div>
          {val.icon && (
            <Tooltip title={val.iconTooltip ?? ""} placement="right">
              {val.icon}
            </Tooltip>
          )}
          {val.subtitle && <span className="subtitle">{val.subtitle}</span>}
        </div>
      </MenuItem>
    );
  };

  const renderReportTypeSelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select className="select" value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (!disabled) {
            input.onChange(e);
            onChange();
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (reportTypes.length === 0 || selected === 0 ||selected === "") {
            return <em>Choose...</em>;
          }
          let r = reportTypes.find((rt) => rt.reportTypeId === selected)!;
          return r.name;
        }}
      >
        {children.map((val) =>{
            if(type&&type=='dashboard'){
            if(val.name.toLowerCase()=='interactive'){
              return getMenuItem(val)
            }
          }
          else{
           return getMenuItem(val)
          }
        } )}
      </Select>
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
    </div>
  );

  const renderCategorySelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (e.target.value === -1) {
            console.log("adding");
            setCatagoryToEdit(undefined);
            setIsCategoryDialogOpen(true);
          } else if (!disabled) {
            input.onChange(e);
            onChange();
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => renderSelectedCateogry(selected,reportCategories)}
      >
        {children.map((val) => {
           if(selectedCategoryId&&selectedCategoryId!=null){
            if(val.value==selectedCategoryId){
              return getMenuItem(val)
            }
          }
          else{
           return getMenuItem(val)
          }
          })}
      </Select>
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
    </div>
  );

  const renderWorkspaceSelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (!disabled) {
            input.onChange(e);
            onChange();
            let w = powerBIWorkspaces.find(
              (pw) => pw.value === e.target.value
            )!;
            dispatch(adminActions.getPowerBIReports(w.value.toString()));
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (loadingPowerBIWorkspaces) {
            return "Loading...";
          }
          if (
            powerBIWorkspaces.length === 0 ||
            selected === 0 ||
            selected === ""
          ) {
            return <em>Choose...</em>;
          }
          let w = powerBIWorkspaces.find((pw) => pw.value === selected);
          if (w) return w.name;
          else return "Error: Not found";
        }}
      >
        {children.map((val) => getMenuItem(val))}
      </Select>
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
      {!error && localWorkspaceId && !loadingPowerBIWorkspaces && (
        <span className="info small-text">{localWorkspaceId}</span>
      )}
    </div>
  );

  const renderReportSelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (!disabled) {
            input.onChange(e);
            onChange();
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (loadingPowerBIWorkspaces || loadingPowerBIReports) {
            return "Loading...";
          }
          if (
            powerBIReports.length === 0 ||
            selected === 0 ||
            selected === ""
          ) {
            return <em>Choose...</em>;
          }

          let w = powerBIReports.find((pw) => pw.value === selected);
          if (w) return w.name;
          else return <em>Error: Not found in workspace</em>;
        }}
      >
        {children.map((val) => getMenuItem(val))}
      </Select>
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
      {!error &&
        localReportId &&
        !loadingPowerBIWorkspaces &&
        !loadingPowerBIReports && (
          <span className="info small-text">{localReportId}</span>
        )}
      {localWorkspaceId &&
        powerBIReports.length === 0 &&
        !loadingPowerBIReports &&
        !loadingPowerBIWorkspaces && (
          <span className="error small-text">
            There are no reports in the chosen Workspace
          </span>
        )}
    </div>
  );

  const renderTextField = ({
    input,
    label,
    placeholder,
    disabled,
    meta: { touched, error },
  }: InputProps) => (
    <div>
      <input
        label={label}
        placeholder={placeholder}
        {...input}
        disabled={disabled}
      />
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
    </div>
  );

  const renderTextAreaField = ({
    input,
    label,
    placeholder,
    disabled,
    meta: { touched, error },
  }: InputProps) => {
    return (
      <div>
        <textarea placeholder={placeholder} {...input} disabled={disabled} />
        {touched && error && (
          <span className="error small-text" style={{ display: "contents" }}>
            {label} {error}
          </span>
        )}
      </div>
    );
  };

  const formDebug = (state: any, fieldStates: any) => {
    var w_id = state.values?.selectedReport?.powerBIWorkspaceId;
    if (w_id) setLocalWorkspaceId(w_id);
    var r_id = state.values?.selectedReport?.powerBIReportId;
    if (r_id) setLocalReportId(r_id);
  };

  if (isAdmin) {
    return (
      <div id="report-form-page">
        <ReportCategoryForm
          open={isCategoryDialogOpen}
          handleClose={() => setIsCategoryDialogOpen(false)}
          categoryToEdit={categoryToEdit}
        />
        <Form
          initialValues={{
            selectedReport: selectedReport,
          }}
          mutators={{
            resetCategory: (args, state, utils) => {
              utils.changeValue(state, "selectedReport.categoryId", () => 0);
            },
          }}
          debug={formDebug}
          onSubmit={(values: any) => {
            if (action === "add") {
              dispatch(reportActions.addReport([values.selectedReport]));
            } else {
              dispatch(reportActions.saveReport(values.selectedReport));
            }
            setIsSaved(true);
          }}
          render={({ handleSubmit, form, submitting }) => (
            <form id="report-form" onSubmit={handleSubmit} onChange={onChange}>
              <div id="report-form-main">
                <div className="title large-text">
                  {action === "add" ?formTitle : "Edit Report"}
                </div>
                <div className="form-container">
                  <Grid container spacing={2} columns={16}>
                    <Grid item md={8} sm={16}>
                      <div className="label-cell">Report Title *</div>
                      <div className="control-cell">
                        <Field
                          name="selectedReport.name"
                          component={renderTextField}
                          label="Report Title"
                          placeholder="Enter title"
                          validate={required}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} sm={8}>
                      <span className="label-cell">Report Type *</span>
                      <div className="control-cell">
                        <Field
                          name="selectedReport.reportTypeId"
                          component={renderReportTypeSelectField}
                          label="Report Type"
                          children={types}
                          disabled={
                            action !== "add" ||
                            (action === "add" && reportTypes.length === 0)
                          }
                          control={<ReportTypeInput />}
                          validate={selectRequired}
                        />
                      </div>
                    </Grid>
                    <Grid item md={4} sm={8}>
                      <div className="label-cell">Report Category *</div>
                      <div className="control-cell">
                        <div className="select-div">
                          <Field
                            name="selectedReport.categoryId"
                            component={renderCategorySelectField}
                            label="Report Category"
                            children={getCategories(form)}
                            disabled={categories.length === 1}
                            control={<CategoryInput />}
                            validate={(value)=>selectCategoryRequired(value,reportCategories)}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={8} sm={16}>
                      <div className="label-cell">Workspace *</div>
                      <div className="control-cell">
                        <Field
                          name="selectedReport.powerBIWorkspaceId"
                          component={renderWorkspaceSelectField}
                          label="Workspace"
                          children={powerBIWorkspaces}
                          disabled={
                            action === "edit" ||
                            loadingPowerBIWorkspaces ||
                            powerBIWorkspaces.length === 0
                          }
                          control={<ReportTypeInput />}
                          validate={guidRequired}
                        />
                      </div>
                    </Grid>
                    <Grid item md={8} sm={16}>
                      <div className="label-cell">Report *</div>
                      <div className="control-cell">
                        <Field
                          name="selectedReport.powerBIReportId"
                          component={renderReportSelectField}
                          label="Report"
                          children={powerBIReports}
                          disabled={
                            action === "edit" ||
                            loadingPowerBIReports ||
                            powerBIReports.length === 0
                          }
                          control={<ReportTypeInput />}
                          validate={guidRequired}
                        />
                        {(loadingPowerBIReports ||
                          loadingPowerBIWorkspaces) && (
                          <div className="loading">
                            <CircularProgress size={15} />
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item md={8} sm={16}>
                      <div className="label-cell">Report Info </div>
                      <div className="control-cell">
                        <Field
                          name="selectedReport.description"
                          component={renderTextAreaField}
                          label="Report Info"
                          placeholder="Enter info about this report"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="btn-row">
                  <button
                    type="submit"
                    className="save-button"
                    disabled={submitting}
                  >
                    {saved ? (
                      <div>
                        <Check />
                        Saved
                      </div>
                    ) : (
                      <div>Save</div>
                    )}
                  </button>

                  {/* <Link to={"/reports/catalog"} className="link">
                  Cancel
                </Link> */}
                  <Button
                    onClick={() => history.goBack()}
                    className="cancel-button"
                  >
                    {saved ? "Close" : "Cancel"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default ReportForm;
