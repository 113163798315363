import { ExportFileExtension } from "./exportFileExtension";
import { DayOfWeek } from "./dayOfWeek";
import { JobScheduleFreqType } from "./jobScheduleFreqType";
import { JobScheduleMonthlyDayOfWeek } from "./jobScheduleMonthlyDayOfWeek";
import { JobScheduleMonthlyFirstOrLastDay } from "./jobScheduleMonthlyFirstOrLastDay";
import { Timezone } from "./timezone";
import { Portfolio } from "./portfolio";

export interface SubscriptionFormData {
  fileExtensions: ExportFileExtension[];
  daysOfWeek: DayOfWeek[];
  jobScheduleFreqType: JobScheduleFreqType[];
  jobScheduleMonthlyDayOfWeek: JobScheduleMonthlyDayOfWeek[];
  jobScheduleMonthlyFirstOrLastDay: JobScheduleMonthlyFirstOrLastDay[];
  timezones: Timezone[];
  excludeWeekends: boolean;
  portfolios?: Portfolio[];
}


export function CreateDefaultSubscriptionFormData(): SubscriptionFormData {
  let ret: SubscriptionFormData = {
    fileExtensions: [],
    daysOfWeek: [],
    jobScheduleFreqType: [],
    jobScheduleMonthlyDayOfWeek: [],
    jobScheduleMonthlyFirstOrLastDay: [],
    timezones: [],
    excludeWeekends: false,
  }
  return ret;
}