import React, { useRef, useState, useEffect } from "react";
import GridLayout, { Layout } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import WidgetVisualComponent from "./WidgetVisualComponent";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import useMyAuth from "../../hooks/useMyAuth";
import appConfig from "../../utils/appConfig";
import { SignalRTargets } from "../../redux/models/signalRTargets";
import DashboardDropdown from "./DashboardDropdown";
import DashboardActions from "./DashboardActions";
import WidgetReportComponent from "./WidgetReportComponent";
import {
  selectDashboardLayout,
  selectSelectedDashboardWidgets,
} from "../../redux/selectors/dashboard.selector";
import { Widget } from "../../redux/models/widget";
import { Checkbox } from "@mui/material";
import { DashboardPermissionValues } from "../../redux/models/dashboardPermission";
import { TooltipStyle } from "../Common/TooltipStyle";

let globalSelectedDashboardId = 0;

export default function DashboardContent() {
  const dispatch = useDispatch();
  const [connection, _setConnection] = useState<null | HubConnection>(null);
  const connectionRef = React.useRef(connection);

  const { token } = useMyAuth();

  const dashboardReducer = useSelector(
    (state: AppState) => state.dashboardReducer
  );
  const { selectedDashboard } = dashboardReducer;
  const localWidgets = useSelector(selectSelectedDashboardWidgets);
  const dashboardLayout = useSelector(selectDashboardLayout);

  const containerRef = useRef<HTMLDivElement>(null);
  const [containerSize, setContainerSize] = useState({
    width: 0,
    height: 0,
  });

  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [newDashboardName, setNewDashboardName] = useState(
    selectedDashboard.name ?? ""
  );

  const setConnection = (data: HubConnection | null) => {
    connectionRef.current = data;
    _setConnection(data);
  };

  useEffect(() => {
    return () => {
      globalSelectedDashboardId = 0;
      dispatch(dashboardActions.clearAll());
      if (connectionRef.current) {
        connectionRef.current.stop();
      }
    };
  }, []);

  const canRename = selectedDashboard.dashboardPermissions?.some(
    (dp) => dp.permission === DashboardPermissionValues.DASHBOARDRENAME
  );

  const SignalRCallback_embedTokenAndURL = (
    uniqueEmbedId: string,
    embedToken: string,
    embedUrl: string
  ) => {
    dispatch(
      dashboardActions.updateWidgetEmbed(uniqueEmbedId, embedUrl, embedToken)
    );
  };

  const SignalRCallback_error = (
    incomingUniqueEmbedId: string,
    incomingUserReportHistoryId: number,
    incomingUserReportHistoryExportDetailsId: number,
    errorMessage: string
  ) => {
    console.log("RECEIVED ERROR");
    console.log(`Incoming ID: ${incomingUniqueEmbedId}`);
    console.log(`incomingUserReportHistoryId: ${incomingUserReportHistoryId}`);
    console.log(
      `incomingUserReportHistoryExportDetailsId: ${incomingUserReportHistoryExportDetailsId}`
    );
    console.log(`errorMessage: ${errorMessage}`);
    dispatch(
      dashboardActions.updateWidgetError(incomingUniqueEmbedId, errorMessage)
    );
  };

  useEffect(() => {
    if (token && !connectionRef.current?.connectionId) {
      const hubConnection = new HubConnectionBuilder()
        .withUrl(`${appConfig.apiBaseUrl}/reportHub`, {
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect()
        .build();

      hubConnection.on(
        SignalRTargets.reportUpdate_embedTokenAndURL,
        SignalRCallback_embedTokenAndURL
      );
      hubConnection.on(
        SignalRTargets.reportUpdate_error,
        SignalRCallback_error
      );

      setConnection(hubConnection);

      hubConnection
        .start()
        .then(() => {
          dispatch(
            dashboardActions.embedWidgets(selectedDashboard.dashboardId!)
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (connectionRef.current?.connectionId) {
      dispatch(dashboardActions.embedWidgets(selectedDashboard.dashboardId!));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboard.dashboardId]);

  const openFullScreen = (id: number) => {
    dispatch(dashboardActions.setWidgetFullscreen(id, true));
    setFullScreenOpen(true);
  };

  const closeFullScreen = (id: number) => {
    dispatch(dashboardActions.setWidgetFullscreen(id, false));
    setFullScreenOpen(false);
  };

  useEffect(() => {
    function handleResize() {
      setContainerSize({
        width: (containerRef?.current?.offsetWidth) ?? 1000,
        height: (containerRef?.current?.offsetHeight) ?? 0,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function layoutChangeCallback(layout: Layout[]) {
    if (globalSelectedDashboardId === selectedDashboard.dashboardId) {
      let newWidgets = localWidgets;
      newWidgets?.forEach((w: Widget, i: number) => {
        const newWidget = layout[i];
        if (newWidget) {
          w.width = newWidget.w;
          w.height = newWidget.h;
          w.x = newWidget.x;
          w.y = newWidget.y;
          w.widgetLayout = w.widgetLayout
            ? {
                ...w.widgetLayout,
                i: i.toString(),
                w: newWidget.w,
                h: newWidget.h,
              }
            : undefined;
        }
      });
      console.log("resizing widgets");
      if (newWidgets?.find((w) => (w?.width ?? 0) < 4 || (w?.height ?? 0) < 4))
        return;
      dispatch(dashboardActions.resizeWidgets(selectedDashboard, newWidgets));
    } else {
      console.log("setting globalSelectedDashboardId");
      globalSelectedDashboardId = selectedDashboard.dashboardId ?? 0;
    }
  }

  const setDefaulDashboard = () => {
    // Prevent the selection change event to occur
    // event.stopPropagation();
   
    if(!selectedDashboard.isDefault){
      const dashboardToDefault = selectedDashboard;
      dispatch(
        dashboardActions.updateDashboard({
          ...dashboardToDefault,
          isDefault: true,
        })
      );
      selectedDashboard.isDefault=true;
    }
  };

  useEffect(() => {
    setNewDashboardName(selectedDashboard.name ?? "")
  },[selectedDashboard?.name]);

  const renameDashboard=()=>{
    if(selectedDashboard.name!==newDashboardName&&newDashboardName.length&&canRename){
      handleSubmit();
    }
  }

  const handleSubmit = () => {
    dispatch(
      dashboardActions.updateDashboard({
        ...selectedDashboard,
        name: newDashboardName,
      })
    );
  };
  return (
    <div id="dashboard-content" ref={containerRef}>
      {!fullScreenOpen && (
        <div className="dashboard-header">
          <div className="dropdown">
            <DashboardDropdown />
          </div>
          <div className={newDashboardName.length>30?"inpt-edit fullWidth":"inpt-edit"}>
          <TooltipStyle title={"Click to edit"} arrow placement="bottom" >
              <input type={"text"} value={newDashboardName} disabled={!canRename} className="inpt-dsbd-title" onBlur={renameDashboard} onChange={(e) => setNewDashboardName(e.target.value)}
                maxLength={50}/>
            </TooltipStyle>
          </div>
          <div className="mark-default">
            <Checkbox sx={{color: '#EAECEE', borderRadius:'4px'}} checked={selectedDashboard.isDefault} onClick={setDefaulDashboard}/>
            <span className="default-label">Mark as Default</span>
          </div>
          <div className="action-items">
          <DashboardActions />
         </div>
        </div>
      )}
      <div className="dashboard-grid">
        {dashboardLayout && localWidgets!.length > 0 && (
          <GridLayout
            className={
              fullScreenOpen
                ? "react-grid-layout-fullscreen"
                : "react-grid-layout"
            }
            layout={dashboardLayout}
            cols={12}
            rowHeight={50}
            width={containerSize.width}
            containerPadding={[10, 10]}
            margin={[10, 10]}
            transformScale={1}
            resizeHandles={["se"]}
            onLayoutChange={layoutChangeCallback}
          >
            {localWidgets!.map((data, index) => {
              return (
                <div key={data.widgetLayout?.i} data-grid={data}>
                  {data.reportVisual ? (
                    <WidgetVisualComponent
                      widget={data}
                      fullScreenOpen={fullScreenOpen}
                      openFullScreen={openFullScreen}
                      closeFullScreen={closeFullScreen}
                    />
                  ) : (
                    <WidgetReportComponent
                      widget={data}
                      fullScreenOpen={fullScreenOpen}
                      openFullScreen={openFullScreen}
                      closeFullScreen={closeFullScreen}
                    />
                  )}
                </div>
              );
            })}
          </GridLayout>
        )}
        {(!dashboardLayout || localWidgets!.length === 0) && (
          <div className="no-widgets">No widgets!</div>
        )}
      </div>
    </div>
  );
}
