export const DateUtils = {
    formatDateTime,
    addDays
}

function formatDateTime(dateTimeUTC?: Date): string {
    return dateTimeUTC?.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' }) ?? "-"
}

function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

// function getBrowserLocales(options = {}) {
//     const defaultOptions = {
//         languageCodeOnly: false,
//     };
//     const opt = {
//         ...defaultOptions,
//         ...options,
//     };
//     const browserLocales =
//         navigator.languages === undefined
//             ? [navigator.language]
//             : navigator.languages;
//     if (!browserLocales) {
//         return undefined;
//     }
//     return browserLocales.map(locale => {
//         const trimmedLocale = locale.trim();
//         return opt.languageCodeOnly
//             ? trimmedLocale.split(/-|_/)[0]
//             : trimmedLocale;
//     });
// }