import appConfig from "../../utils/appConfig";
import {
  FetchUtils,
} from "../../utils/fetch.utils";
import { Setting } from "../models/setting";
import { User } from "../models/user";

const apiBaseUrl = appConfig.apiBaseUrl;
const userApi = 'user';


const getOrCreateUser = (): Promise<User> => {
  const URL = `${apiBaseUrl}/${userApi}/GetOrCreateUser`;
  let response = FetchUtils.POST<User>(URL);
  return response;
};

const userSignOut = (): Promise<any> => {
  const URL = `${apiBaseUrl}/${userApi}/UserSignOut`;
  let response = FetchUtils.POST(URL);
  return response;
};

const getSettings = (): Promise<Setting[]> => {
  const URL = `${apiBaseUrl}/${userApi}/GetSettings`;
  let response = FetchUtils.GET<Setting[]>(URL);
  return response;
};

export const userService = {
  getOrCreateUser,
  userSignOut,
  getSettings
};
