import { useEffect } from "react";
import Switch from "react-switch";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { Form, Field } from "react-final-form";
import Check from "@mui/icons-material/Check";
import {
  selectInteractiveAsWidgetSetting,
  selectInteractiveAsWidgetSettingValue,
  selectReportAsWidgetSetting,
  selectReportAsWidgetSettingValue,
  selectSaved,
} from "../../redux/selectors/admin.selector";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import { useDispatch, useSelector } from "react-redux";
import { adminActions } from "../../redux/actions/admin.actions";
import {
  selectTimeoutSettings,
  selectWarningSettings,
  selectFilePurgeSettings,
  selectStorageStats,
} from "../../redux/selectors/admin.selector";
import LinearProgress from "@mui/material/LinearProgress";
import "./SettingsForm.scss";
import Grid from "@mui/material/Grid";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      borderColor: "#5588a3",
    },
  },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: 250,
}));

interface InputProps {
  input: any;
}

interface SelectProps {
  input: any;
  label: string;
  children: any[];
  meta: any;
  disabled: boolean;
}

const SettingsForm = (props: any) => {
  const dispatch = useDispatch();

  const isAdmin = useSelector(isUserAdministrator);
  const saved = useSelector(selectSaved);

  const timeoutSettings = useSelector(selectTimeoutSettings);
  const warningSettings = useSelector(selectWarningSettings);
  const purgeSettings = useSelector(selectFilePurgeSettings);
  const storageStats = useSelector(selectStorageStats);
  const reportAsWidgetSettings = useSelector(selectReportAsWidgetSetting);
  const reportAsWidgetValue = useSelector(selectReportAsWidgetSettingValue);
  const interactiveAsWidgetSettings = useSelector(
    selectInteractiveAsWidgetSetting
  );
  const interactiveAsWidgetValue = useSelector(
    selectInteractiveAsWidgetSettingValue
  );

  const renderCheckbox = ({ input }: InputProps) => (
    <Switch
      onChange={(e) => {
        input.onChange(e);
        onChange();
      }}
      checked={input.value ? true : false}
      onColor="#5588a3"
      offColor="#959494"
    />
  );

  const renderSelectField = ({ input, children, disabled }: SelectProps) => (
    <div>
      <Select
        id="timeout-value-select"
        value={input.value}
        onChange={(e) => {
          input.onChange(e);
          onChange();
        }}
        input={<Input />}
        disabled={disabled}
      >
        {children.map((val) => (
          <MenuItem
            key={val.value}
            value={val.value}
            className="medium-text blue-text"
            disabled={val.disabled}
          >
            {val.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  useEffect(() => {
    dispatch(adminActions.getSettings());
    dispatch(adminActions.getBlobStorageStats());
  }, [dispatch]);

  const timeoutValues = [
    { value: 15, name: "15 minutes" },
    { value: 30, name: "30 minutes" },
    { value: 60, name: "60 minutes" },
    { value: 120, name: "120 minutes" },
  ];

  const warningValues = [
    { value: 2, name: "2 minutes" },
    { value: 5, name: "5 minutes" },
    { value: 10, name: "10 minutes" },
    { value: 15, name: "15 minutes" },
  ];

  const purgeValues = [
    { value: 0, name: "Not Set" },
    { value: 30, name: "30 days" },
    { value: 60, name: "60 days" },
    { value: 90, name: "90 days" },
    { value: 120, name: "120 days" },
  ];

  function percentage(partialValue?: number, totalValue?: number) {
    if (partialValue && totalValue) return (100 * partialValue) / totalValue;
    else return 0;
  }

  function availableSpace(partialValue?: number, totalValue?: number) {
    if (partialValue && totalValue)
      return Math.trunc(100 - percentage(partialValue, totalValue));
    else return 0;
  }

  function usedSpace(partialValue?: number, totalValue?: number) {
    if (partialValue && totalValue)
      return Math.trunc(100 - availableSpace(partialValue, totalValue));
    else return 0;
  }

  const onChange = () => {
    dispatch(adminActions.setSaved(false));
  };

  if (isAdmin) {
    return (
      <Form
        initialValues={{
          timeoutActive: timeoutSettings?.active,
          timeoutValue: timeoutSettings?.value,
          timeoutWarning: warningSettings?.value,
          purgeValue: purgeSettings?.value,
          reportAsWidgetValue: reportAsWidgetValue,
          interactiveAsWidgetValue: interactiveAsWidgetValue,
        }}
        onSubmit={(values: any) => {
          if (
            timeoutSettings &&
            warningSettings &&
            purgeSettings &&
            reportAsWidgetSettings &&
            interactiveAsWidgetSettings
          )
            dispatch(
              adminActions.saveSettings([
                {
                  ...timeoutSettings,
                  active: values.timeoutActive,
                  value: values.timeoutValue,
                },
                { ...warningSettings, value: values.timeoutWarning },
                { ...purgeSettings, value: values.purgeValue },
                {
                  ...reportAsWidgetSettings,
                  value: values.reportAsWidgetValue,
                },
                {
                  ...interactiveAsWidgetSettings,
                  value: values.interactiveAsWidgetValue,
                },
              ])
            );
        }}
      >
        {({ handleSubmit, submitting, values }) => (
          <form id="settings-form" onSubmit={handleSubmit}>
            <div className="title large-text">Administrative Settings</div>
            <div className="sub-title">Automatic Session Timeout</div>
            <div className="form-container">
              <Grid container spacing={2} columns={16}>
                <Grid item md={4} sm={16}>
                  <div className="form-cell">
                    <div className="form-cell">Timeout</div>
                    <Field
                      name="timeoutActive"
                      component={renderCheckbox}
                      label="Timeout"
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={16}>
                  <div className="form-cell">
                    <div
                      className={
                        values["timeoutActive"]
                          ? "form-cell"
                          : "form-cell-disabled"
                      }
                    >
                      Set timeout
                    </div>
                    <Field
                      name="timeoutValue"
                      component={renderSelectField}
                      label="Timeout Value"
                      children={timeoutValues.map((v) => {
                        return {
                          ...v,
                          disabled:
                            values["timeoutWarning"] >= v.value ? true : false,
                        };
                      })}
                      disabled={!values["timeoutActive"]}
                    />
                  </div>
                </Grid>
                <Grid item md={6} sm={16}>
                  <div className="form-cell">
                    <div
                      className={
                        values["timeoutActive"]
                          ? "form-cell"
                          : "form-cell-disabled"
                      }
                    >
                      Timeout warning
                    </div>
                    <Field
                      name="timeoutWarning"
                      component={renderSelectField}
                      label="Timeout Warning"
                      children={warningValues.map((v) => {
                        return {
                          ...v,
                          disabled:
                            values["timeoutValue"] <= v.value ? true : false,
                        };
                      })}
                      disabled={!values["timeoutActive"]}
                    />
                  </div>
                </Grid>
                <Grid item md={8} sm={16}>
                  <div className="form-cell">
                    <div className="form-cell">
                      Allow Adding Reports as Widgets
                    </div>
                    <Field
                      name="reportAsWidgetValue"
                      component={renderCheckbox}
                      label="Report As Widget"
                    />
                  </div>
                </Grid>
                <Grid item md={8} sm={16}>
                  <div className="form-cell">
                    <div className="form-cell">
                      Allow Adding Interactive Dashboards as Widgets
                    </div>
                    <Field
                      name="interactiveAsWidgetValue"
                      component={renderCheckbox}
                      label="Interactive As Widget"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* <div className="sub-title">File Storage Management</div>
            <div className="form-container">
              <Grid container spacing={2} columns={16}>
                <Grid item md={6} sm={16}>
                  <div className="form-cell">
                    <div className="form-cell">Purge files after</div>
                    <div className="form-cell">
                      <Field
                        name="purgeValue"
                        component={renderSelectField}
                        label="Purge Value"
                        children={purgeValues}
                        disabled={false}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item md={10} sm={16} className="storage-wrapper">
                  <div className="form-cell progress-bar-container">
                    <div className="form-cell">Storage</div>
                    <div className="progress-bar small-text">
                      <div className="progress-bar-text">
                        <div className="left">
                          {`${storageStats?.totalUsageInGb.toFixed(
                            2
                          )} GB / ${storageStats?.totalCapacityInGb.toFixed(
                            0
                          )} GB`}
                        </div>
                        <div
                          className={`right${
                            usedSpace(
                              storageStats?.totalUsageInGb,
                              storageStats?.totalCapacityInGb
                            ) >= 90
                              ? " error"
                              : usedSpace(
                                  storageStats?.totalUsageInGb,
                                  storageStats?.totalCapacityInGb
                                ) >= 75
                              ? " warning"
                              : ""
                          }`}
                        >{`${availableSpace(
                          storageStats?.totalUsageInGb,
                          storageStats?.totalCapacityInGb
                        )}% available`}</div>
                      </div>
                      <BorderLinearProgress
                        variant="determinate"
                        value={usedSpace(
                          storageStats?.totalUsageInGb,
                          storageStats?.totalCapacityInGb
                        )}
                        {...props}
                        color={"inherit"}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div> */}
            <div className="btn-row">
              <button
                type="submit"
                disabled={
                  submitting ||
                  (props.timeoutActive && props.timeoutValue === undefined)
                }
              >
                {saved ? (
                  <div>
                    <Check />
                    Saved
                  </div>
                ) : (
                  <div>Save</div>
                )}
              </button>
            </div>
          </form>
        )}
      </Form>
    );
  } else {
    return <div></div>;
  }
};

export default SettingsForm;
