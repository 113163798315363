import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { selectAdminSubscriptionsPagination } from "../../redux/selectors/subscription.selector";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import { Filter } from "../../redux/models/filter";
import { ReportCategory } from "../../redux/models/reportCategory";

export interface PaginationProps {
  pageCount: number;
  currentPage: number;
  appliedFilters: Filter<any>[];
  selectedCategory?: ReportCategory;
  sortField: string[];
  sortDirection: string;
  refreshSubscriptions: (
    pageSize: number,
    currentPage: number,
    filters?: Filter<any>[],
    categoryId?: number,
    sortField?: string[],
    sortDirection?: string
  ) => void;
}

function Pagination({
  pageCount,
  currentPage,
  appliedFilters,
  selectedCategory,
  sortField,
  sortDirection,
  refreshSubscriptions,
}: PaginationProps) {
  const dispatch = useDispatch();

  const pagination = useSelector(selectAdminSubscriptionsPagination);

  const handlePageChange = (value: string) => {
    if (!value) {
      dispatch(subscriptionActions.setAdminCurrentPage(0));
    } else {
      if (/^[1-9]\d*$/.test(value)) {
        if (pagination && parseInt(value) <= pagination.totalPages) {
          dispatch(subscriptionActions.setAdminCurrentPage(parseInt(value)));
          refreshSubscriptions(
            pageCount,
            parseInt(value),
            appliedFilters,
            selectedCategory?.categoryId,
            sortField,
            sortDirection
          );
        }
      }
    }
  };

  const handlePrevClick = () => {
    dispatch(subscriptionActions.setAdminCurrentPage(currentPage - 1));
    refreshSubscriptions(
      pageCount,
      currentPage - 1,
      appliedFilters,
      selectedCategory?.categoryId,
      sortField,
      sortDirection
    );
  };

  const handleNextClick = () => {
    dispatch(subscriptionActions.setAdminCurrentPage(currentPage + 1));
    refreshSubscriptions(
      pageCount,
      currentPage + 1,
      appliedFilters,
      selectedCategory?.categoryId,
      sortField,
      sortDirection
    );
  };

  return (
    <div className="pagination-wrapper">
      <div
        className={currentPage === 1 ? "btn disabled" : "btn"}
        onClick={() => handlePrevClick()}
      >
        <KeyboardArrowLeftIcon />
      </div>
      <div className="txtbox">
        <input
          value={currentPage > 0 ? currentPage : ""}
          onChange={(event) => {
            handlePageChange(event.target.value);
          }}
        />
      </div>
      <div className="total-pages">
        of <span>{pagination?.totalPages}</span>
      </div>
      <div
        className={
          currentPage === pagination?.totalPages ? "btn disabled" : "btn"
        }
        onClick={() => handleNextClick()}
      >
        <KeyboardArrowRightIcon />
      </div>
    </div>
  );
}

export default Pagination;
