import React, { useEffect, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useDispatch, useSelector } from "react-redux";
import { reportActions } from "../../redux/actions/report.actions";
import { ReportCategory } from "../../redux/models/reportCategory";
import { selectAllReportCategories } from "../../redux/selectors/report.selector";
import "./ReportTypeDropdown.scss";

export interface ReportTypeDropdownProps {
  visibleCategories?: ReportCategory[];
  selectedCategory?: ReportCategory;
  setSelectedCategory: (category: ReportCategory) => void;
}

function ReportTypeDropdown({
  visibleCategories,
  selectedCategory,
  setSelectedCategory,
}: ReportTypeDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  let profileDropdownref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  let c = useSelector(selectAllReportCategories);
  let categories = visibleCategories ?? c;

  useEffect(() => {
    dispatch(reportActions.getReportCategories());
    window.addEventListener("click", onClickEvent);

    return function cleanup() {
      window.removeEventListener("click", onClickEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEvent = (event: MouseEvent) => {
    if (
      event.target instanceof Element &&
      profileDropdownref &&
      !profileDropdownref.current?.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (
      categories.length > 0 &&
      categories.find((c) => c.categoryId === selectedCategory?.categoryId) ===
        undefined
    )
      setSelectedCategory(categories[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  return (
    <div
      id="type-inner-container"
      ref={profileDropdownref}
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
    >
      {selectedCategory && (
        <div id="chosen-report-type">{selectedCategory?.name}</div>
      )}

      <ArrowDropDownIcon style={{ display: isDropdownOpen ? "none" : "" }} />
      <ArrowDropUpIcon style={{ display: isDropdownOpen ? "" : "none" }} />
      {isDropdownOpen && (
        <div id="report-dropdown">
          {categories.map((cat, i) => {
            return [
              <div
                key={i * 2}
                onClick={() => {
                  setSelectedCategory(cat);
                }}
              >
                {cat.name}
              </div>,
              i !== categories.length - 1 && <div key={i * 2 + 1} />,
            ];
          })}
        </div>
      )}
    </div>
  );
}
export default ReportTypeDropdown;
