import { ReportCategory } from "./reportCategory";
import { ReportType, ReportTypeEnum } from "./reportType";

export interface Report {
    reportId: number;
    name: string;
    description: string;
    longRunningReport: boolean;
    embedURL: string;
    active: boolean;
    reportTypeId: ReportTypeEnum;
    reportType?: ReportType;
    categoryId: number;
    category?: ReportCategory;
    defaultReport: boolean;
    createdDate?: Date;
    modifiedDate?: Date;
    powerBIReportId: string;
    powerBIWorkspaceId: string;
    lastRunDate?: Date;
    lastRunDateUTC?: Date;
    nextRunDate?: Date;
    nextRunDateUTC?: Date;
    userSubscribed?: boolean;
    isFavorite?: boolean;
    isLoading?: boolean;
    uniqueEmbedId?: string;
    iconUrl?: string;
}


export function CreateDefaultReport(): Report {
    let ret: Report = {
        reportId: 0,
        name: "",
        description: "",
        longRunningReport: true,
        embedURL: "",
        active: true,
        reportTypeId: 0,
        categoryId: 0,
        defaultReport: false,
        powerBIReportId: "",
        powerBIWorkspaceId: ""
    }
    return ret;
}