import {memoize} from "proxy-memoize";
import { AppState } from "../../redux/reducers";
import { compliesWithFilter, Filter } from "../models/filter";
import _orderBy from "lodash/orderBy";
import { UserWithDetails } from "../models/userWithDetails";


export interface FilteredUser extends UserWithDetails {
    role: "Admin" | "User",
    isLoading: boolean,
    checked: boolean
}

const _getMemoizedUsers = memoize((state: AppState): FilteredUser[] => {
    const { users, getUserLoading, getUserChecked } = state.userReducer;

    let us: FilteredUser[] = users.map(u => {
        return {
            ...u,
            role: u.roles.some(r => r.name === "Admin") ? "Admin" : "User",// TODO should be a list in future
            isLoading: getUserLoading[u.userId.toString()] ?? false,
            checked: getUserChecked[u.userId.toString()] ?? false,
            lastSignInDateTime: u.lastSignInDateTime && new Date(`${u.lastSignInDateTime}Z`),
            lastSignOutDateTime: u.lastSignOutDateTime && new Date(`${u.lastSignOutDateTime}Z`),
        }
    })
    return us;
})

const getMemoizedFilteredUsers = memoize((state: AppState): FilteredUser[] => {
    const { filters, sortField, sortDirection, searchKeyword } = state.filterReducer

    let us = _getMemoizedUsers(state);
    us = us.filter(u => compliesWithFilter(u, filters as Filter<FilteredUser>[]));
    if (searchKeyword !== "") {
        us = us.filter(u => u.emailAddress.toLowerCase().includes(searchKeyword.toLowerCase()) || (u.ampUserId?.toString() ?? "").includes(searchKeyword) || u.b2CObjectId.toString().includes(searchKeyword));
    }
    if (sortField as keyof FilteredUser) {
        if (sortField !== "emailAddress") {
            return _orderBy(us, [sortField as keyof FilteredUser, "emailAddress"], [sortDirection, "asc"]);
        }
        us = _orderBy(us, [sortField as keyof FilteredUser], [sortDirection]);
    }

    return us;
})

const getNumUsersChecked = memoize((state: AppState): number => {
    let us = _getMemoizedUsers(state);
    return us.filter(u => u.checked).length;
})

const getAllUsersChecked = memoize((state: AppState): boolean | undefined => {
    let us = _getMemoizedUsers(state);
    // if (us.length === 0)
    //     return false
    return us.every(u => u.checked);
})

export const isUserValid = (state: AppState) => state.userReducer.user?.isValid;
export const isUserAdministrator = (state: AppState) => state.userReducer.user?.isAdmin;
export const selectLoadingUsers = (state: AppState) => state.userReducer.loadingUsers;
export const selectUserGenericLoading = (state: AppState) => state.userReducer.loading;

export const selectFilteredUsers = (state: AppState) => getMemoizedFilteredUsers(state);
export const selectUserSortField = (state: AppState) => state.filterReducer.sortField;
export const selectUserSortDirection = (state: AppState) => state.filterReducer.sortDirection;
export const selectUserSearchKeyword = (state: AppState) => state.filterReducer.searchKeyword;
export const selectAreAllUsersChecked = (state: AppState) => getAllUsersChecked(state);
export const selectNumUsersChecked = (state: AppState) => getNumUsersChecked(state);