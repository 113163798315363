import { UserReportHistoryExportDetails } from './userReportHistoryExportDetails';
import { Subscription } from './subscription';
import { UserReport } from './userReport';

export interface UserReportHistory {
    userReportHistoryId: number;
    userReportId?: number;
    userReport?: UserReport;
    embedURL: string;
    storageURL: string;
    parameterHistoryId?: number;
    startDate: Date;
    completeDate: Date;
    runDate: Date;
    status: string
    emailAccessId: number,
    createdDate?: Date;
    modifiedDate?: Date;
    subscriptionId?: number;
    subscription?: Subscription;
    userReportHistoryExportDetails: UserReportHistoryExportDetails[];
    name?: string;
    nameLowered?: string;
    startDateUTC?: Date;
    isPartial?: boolean;
}

export enum UserReportHistoryStatuses {
    NEW = "NEW",
    QUEUED = "QUEUED",
    RUNNING = "RUNNING",
    COMPLETE = "COMPLETE",
    EMBED_SUCCESS = "EMBED_SUCCESS",
    ERROR = "ERROR"
}