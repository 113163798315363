import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

interface DeleteDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDelete: () => void;
  warningMsg: string;
}

function DeleteDialog({ open, setOpen, warningMsg, handleDelete }: DeleteDialogProps) {

  const handleClose = () => {
    setOpen(false);
  };

    return (
      <div id="delete-report-dialog">
        <Dialog open={open} onClose={handleClose}>
          <div className="dialog-title warning">Warning</div>
          <div className="dialog-content">
            <p>{warningMsg}</p>
            <p>Are you sure you want to proceed?</p>
          </div>
          <div className="dialog-footer">
            <hr className="warning" />
            <Button onClick={handleClose} className="cancel medium-text">
              Cancel
            </Button>
            <Button onClick={handleDelete} className="action medium-text">
              Delete
            </Button>
          </div>
        </Dialog>
      </div>
    );
  } 

export default DeleteDialog;
