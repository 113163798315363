import CloseIcon from "@mui/icons-material/Close";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Embed, service, Report } from "powerbi-client";
import { Widget } from "../../redux/models/widget";
import WidgetActions from "./WidgetActions";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";

export interface WidgetProps {
  widget: Widget;
  fullScreenOpen: boolean;
  openFullScreen: (id: number) => void;
  closeFullScreen: (id: number) => void;
}

function WidgetReportComponent({
  widget,
  fullScreenOpen,
  openFullScreen,
  closeFullScreen,
}: WidgetProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerStyle, setContainerStyle] = useState<
    React.CSSProperties | undefined
  >(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [report, setReport] = useState<Report>();

  const embedSettings: models.ISettings = {
    bars: {
      actionBar: {
        visible: false,
      },
    },
    // layoutType: models.LayoutType.Custom,
    // customLayout: {
    //   displayOption: models.DisplayOption.FitToWidth,
    // },
    panes: {
      filters: {
        visible: false,
      },
      pageNavigation: {
        visible: false,
      },
    },
  };

  var reportSlicers = widget.slicers ? JSON.parse(widget.slicers) : undefined;

  const reportConfig: models.IReportEmbedConfiguration = {
    type: "report",
    embedUrl: widget.widgetLayout!.embedUrl,
    id: widget.report!.powerBIReportId,
    tokenType: models.TokenType.Embed,
    accessToken: widget.widgetLayout!.embedToken,
    settings: embedSettings,
    slicers: reportSlicers,
  };

  const eventHandlersMap = new Map([
    [
      "loaded",
      function () {
        console.log("Report has loaded");
        console.log(loaded);
        setLoaded(true);
      },
    ],
    [
      "rendered",
      function () {
        console.log("Report has rendered");
      },
    ],
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  const updateFullscreenPositionAndSize = () => {
    if (fullScreenOpen) {
      if (containerRef.current) {
        let cx = containerRef.current.getBoundingClientRect().x;
        let cy = containerRef.current.getBoundingClientRect().y;

        setContainerStyle({
          position: "absolute",
          top: -window.scrollY + -cy + 10,
          left: -cx + 10,
          width: "calc(100vw - 20px)",
          height: "calc(100vh - 20px)",
        });
      }
    }
  };

  const escFunction = (e: any)=>{
    if (e.key === "Escape") {
      closeFullScreen(widget.widgetId!);
    }
  }

  useEffect(()=>{
    document.addEventListener("keyup", escFunction, false);
    return ()=>{
      document.addEventListener("keyup", escFunction, false);
    }
  },[])

  useEffect(() => {
    if (report) {
      var filters: models.IFilter[] = widget.filters
        ? JSON.parse(widget.filters)
        : undefined;
      if (filters) {
        report.setFilters(filters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report]);

  useEffect(() => {
    if (widget.widgetLayout?.isFullScreen) {
      console.log(`Widget ${widget.widgetId} is fullscreen`);
      updateFullscreenPositionAndSize();
      let parentReactGridItem = containerRef.current?.parentElement;
      if (parentReactGridItem) {
        parentReactGridItem.style.zIndex = "2";
      }
    } else {
      setContainerStyle(undefined);
      let parentReactGridItem = containerRef.current?.parentElement;
      if (parentReactGridItem) {
        parentReactGridItem.style.zIndex = "unset";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.widgetLayout?.isFullScreen]);

  const showEmbed =
    // widget?.report?.reportTypeId === ReportTypeEnum.PowerBI_Report
    //   ? true
    //   :
    !!widget?.widgetLayout?.embedToken;

  console.log(`report error: ${widget.widgetLayout?.errorMessage}`);

  if (widget.widgetLayout?.visible) {
    return (
      <div
        className="widget-container"
        ref={containerRef}
        style={containerStyle}
      >
        <div className="widget-ellipsis large-text">
          {fullScreenOpen ? (
            <span onClick={() => closeFullScreen(widget.widgetId!)} onTouchEnd={() => closeFullScreen(widget.widgetId!)}>
              <CloseIcon/>
            </span>
          ) : (
            <WidgetActions widgetId={widget.widgetId ?? 0} openFullScreen={openFullScreen}/>
          )}
        </div>
        <div className="widget-inner-container">
          <div className="widget-body">
            {(widget.widgetLayout?.errorMessage?.length ?? 0) > 0 &&
              <div id="error-container">
                {/* <div> */}
                <CancelOutlinedIcon />
                {/* </div> */}
                <span>{widget.widgetLayout?.errorMessage}</span>
              </div>}
              {showEmbed ?<PowerBIEmbed
                eventHandlers={eventHandlersMap}
                embedConfig={reportConfig}
                cssClassName="report-style"
                getEmbeddedComponent={(embedObject: Embed) => {
                  console.log(
                    `Embedded object of type "${embedObject.embedtype}" received`
                  );
                  setReport(embedObject as Report);
                }}
              />
           : (
              <div id="progress-container">
                <CircularProgress size={40} />
              </div>
            )}
          </div>
          {/* <div className="widget-footer">
            {!fullScreenOpen &&
              (loaded ? (
                <div
                  onClick={() => openFullScreen(widget.widgetId!)}
                  onTouchEnd={() => openFullScreen(widget.widgetId!)}
                >
                  <LaunchOutlinedIcon />
                </div>
              ) : (
                <div className="inactive">
                  <LaunchOutlinedIcon />
                </div>
              ))}
          </div> */}
        </div>
      </div>
    );
  } else {
    return null;
  }
}
export default WidgetReportComponent;
