import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { userActions } from "../../redux/actions/user.actions";
import {
  FilteredUser,
  selectUserGenericLoading,
} from "../../redux/selectors/user.selector";
import CircularProgress from "@mui/material/CircularProgress";

export interface DisableUserDialogProps {
  userToDisable: FilteredUser;
  open: boolean;
  setOpen: (open: boolean) => void;
}

function DisableUserDialog({
  userToDisable,
  open,
  setOpen,
}: DisableUserDialogProps) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectUserGenericLoading);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (!isLoading) {
      dispatch(
        userActions.updateUserActive(userToDisable.userId, false, handleClose)
      );
    }
  };

  return (
    <div id="delete-report-dialog">
      <Dialog open={open} onClose={handleClose}>
        <div className="dialog-title warning">Please Confirm</div>
        <div className="dialog-content">
          <p>
            Are you sure you want to disable this user? The user will no longer
            be able to sign in and all of their subscriptions will be disabled.
          </p>
        </div>
        <div className="dialog-footer">
          <hr className="warning" />
          <Button onClick={handleClose} className="cancel medium-text">
            Cancel
          </Button>
          {isLoading ? (
            <div
              style={{
                display: "inline-flex",
                justifyContent: "center",
                width: "120px",
                height: "37px",
              }}
            >
              <CircularProgress size={20} />
            </div>
          ) : (
            <Button onClick={handleDelete} className="action medium-text">
              Disable User
            </Button>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default DisableUserDialog;
