import { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedSubscription,
} from "../../redux/selectors/subscription.selector";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import { JobScheduleDaysOfWeek } from "../../redux/models/jobScheduleDaysOfWeek";
import { Grid } from "@mui/material";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    width: 60,
    padding: "6px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

const InputLarge = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    width: 130,
    padding: "6px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

function days() {
  let days = [];
  for (let i = 1; i < 32; i++) {
    days.push(
      <MenuItem key={i} value={i} className="medium-text blue-text">
        {i}
      </MenuItem>
    );
  }

  return days;
}
export interface MonthlyRecurrenceProps {
  formData:any;
}
function MonthlyRecurrence({formData}:Readonly<MonthlyRecurrenceProps>) {
  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(1);

  const selectedSubscription = useSelector(selectSelectedSubscription);

  useEffect(() => {
    if (selectedSubscription.jobSchedule.jobScheduleMonthlyRunDay) {
      setSelectedOption(1);
    } else if (selectedSubscription.jobSchedule.jobScheduleMonthlyDayOfWeekId) {
      setSelectedOption(2);
    } else if (
      selectedSubscription.jobSchedule.jobScheduleMonthlyFirstOrLastDayId
    ) {
      setSelectedOption(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReccurenceChange = (val: number) => {
    setSelectedOption(val);

    const jobSchedule = selectedSubscription.jobSchedule;
    jobSchedule.jobScheduleMonthlyRunDay = 0;

    if (val === 1) {
      jobSchedule.jobScheduleMonthlyRunDay = 1;
      jobSchedule.jobScheduleMonthlyDayOfWeekId = undefined;
      jobSchedule.jobScheduleMonthlyFirstOrLastDayId = undefined;
    }
    if (val === 2) {
      jobSchedule.jobScheduleMonthlyRunDay = undefined;
      jobSchedule.jobScheduleMonthlyFirstOrLastDayId = undefined;
      jobSchedule.jobScheduleMonthlyDayOfWeekId =
        formData.jobScheduleMonthlyDayOfWeek[0].jobScheduleMonthlyDayOfWeekId;
    } else if (val === 3) {
      jobSchedule.jobScheduleMonthlyRunDay = undefined;
      jobSchedule.jobScheduleMonthlyDayOfWeekId = undefined;
      jobSchedule.jobScheduleMonthlyFirstOrLastDayId =
        formData.jobScheduleMonthlyFirstOrLastDay[0].jobScheduleMonthlyFirstOrLastDayId;
      jobSchedule.jobScheduleDaysOfWeek = [
        {
          dayOfWeekId: formData.daysOfWeek[0].dayOfWeekId,
        } as JobScheduleDaysOfWeek,
      ];
    }

    dispatch(subscriptionActions.setSubscriptionJobSchedule(jobSchedule));
  };

  function handleDayChange(event: any) {
    dispatch(
      subscriptionActions.setSubscriptionMonthlyRunDay(event.target.value)
    );
  }

  function handleMonthlyDayOfWeekChange(event: any) {
    dispatch(
      subscriptionActions.setSubscriptionMonthlyDayOfWeek(event.target.value)
    );
  }

  function handleMonthlyFirstOrLastDayChange(event: any) {
    dispatch(
      subscriptionActions.setSubscriptionMonthlyFirstOrLastDay(
        event.target.value
      )
    );
  }

  function handleMonthlyRunDayChange(event: any) {
    dispatch(
      subscriptionActions.setSubscriptionDays(
        [{ dayOfWeekId: event.target.value } as JobScheduleDaysOfWeek],
        false,
        true
      )
    );
  }

  return (
    <div className="monthly-recurrence" style={{ paddingTop: 0 }} data-testid="timeLable">
      <RadioGroup
        name="radio-buttons-group"
        onChange={(event) =>
          handleReccurenceChange(parseInt(event.target.value))
        }
        value={selectedOption}
        className="radio-buttons-group"
        data-testid="test-recuron"
      >
        <FormControlLabel
          key={1}
          value={1}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
                "&.Mui-checked": {
                  color: "#5588a3",
                },
              }}
            />
          }
          label={
            <Grid container spacing={1}>
              <Grid item xs={3.5} sm={3.2} md={3.6} lg={3.6} className="select-lable">
                Recur on
              </Grid>
              <Grid item xs={5} sm={3} md={3} lg={3}>
              <Select
                className="select"
                value={
                  selectedSubscription.jobSchedule.jobScheduleMonthlyRunDay &&
                  selectedSubscription.jobSchedule.jobScheduleMonthlyRunDay > 0
                    ? selectedSubscription.jobSchedule.jobScheduleMonthlyRunDay
                    : 1
                }
                onChange={handleDayChange}
                data-testid="test-daychange"
                input={<Input />}
                IconComponent={KeyboardArrowDownIcon}
                disabled={selectedOption !== 1}
              >
                {days()}
              </Select>
              </Grid>
              <Grid item xs={3} sm={2} md={2} lg={2} className="select-lable"> day </Grid>
            </Grid>
          }
        />
         <FormControlLabel
          key={2}
          value={2}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
                "&.Mui-checked": {
                  color: "#5588a3",
                },
              }}
            />
          }
          label={
            <Grid container spacing={1}>
              <Grid item xs={3.5} sm={3.2} md={3.6} lg={3.6} className="select-lable">
                Recur only
              </Grid>
              <Grid item xs={5} sm={4} md={4} lg={5}>
                <Select
                  className="select-large"
                  value={
                    selectedSubscription.jobSchedule
                      .jobScheduleMonthlyDayOfWeekId &&
                    selectedSubscription.jobSchedule
                      .jobScheduleMonthlyDayOfWeekId > 0
                      ? selectedSubscription.jobSchedule
                          .jobScheduleMonthlyDayOfWeekId
                      : formData.jobScheduleMonthlyDayOfWeek[0]
                          .jobScheduleMonthlyDayOfWeekId
                  }
                  onChange={handleMonthlyDayOfWeekChange}
                  data-testid="test-monthlydayofweek"
                  input={<InputLarge />}
                  IconComponent={KeyboardArrowDownIcon}
                  disabled={selectedOption !== 2}
                >
                  {formData.jobScheduleMonthlyDayOfWeek.map((o:any) => {
                    return (
                      <MenuItem
                        key={o.jobScheduleMonthlyDayOfWeekId}
                        value={o.jobScheduleMonthlyDayOfWeekId}
                        className="medium-text blue-text"
                      >
                        {o.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          }
        />

<FormControlLabel
          key={3}
          value={3}
          control={
            <Radio
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
                "&.Mui-checked": {
                  color: "#5588a3",
                },
              }}
            />
          }
          label={
            <Grid container spacing={1}>
              <Grid item xs={3.5} sm={3.2} md={3.6} lg={3.6} className="select-lable">
                Recur only
              </Grid>
              <Grid item xs={4} sm={3} md={3} lg={3}>
              <Select
                className="select-small"
                value={
                  selectedSubscription.jobSchedule
                    .jobScheduleMonthlyFirstOrLastDayId &&
                  selectedSubscription.jobSchedule
                    .jobScheduleMonthlyFirstOrLastDayId > 0
                    ? selectedSubscription.jobSchedule
                        .jobScheduleMonthlyFirstOrLastDayId
                    : formData.jobScheduleMonthlyFirstOrLastDay[0]
                        .jobScheduleMonthlyFirstOrLastDayId
                }
                onChange={handleMonthlyFirstOrLastDayChange}
                data-testid="test-monthlyfirstorlast"
                input={<Input />}
                IconComponent={KeyboardArrowDownIcon}
                disabled={selectedOption !== 3}
              >
                {formData.jobScheduleMonthlyFirstOrLastDay.map((o:any) => {
                  return (
                    <MenuItem
                      key={o.jobScheduleMonthlyFirstOrLastDayId}
                      value={o.jobScheduleMonthlyFirstOrLastDayId}
                      className="medium-text blue-text"
                    >
                      {o.description}
                    </MenuItem>
                  );
                })}
              </Select>
              </Grid>
              <Grid item xs={4.5} sm={4} md={4} lg={4}>
              
              <Select
                className="select-medium"
                value={
                  selectedSubscription.jobSchedule.jobScheduleDaysOfWeek?.[0]
                    ?.dayOfWeekId ?? formData.daysOfWeek[0].dayOfWeekId
                }
                onChange={handleMonthlyRunDayChange}
                data-testid="test-monthlyrunday"
                input={<InputLarge />}
                IconComponent={KeyboardArrowDownIcon}
                disabled={selectedOption !== 3}
              >
                {formData.daysOfWeek.map((d:any) => {
                  return (
                    <MenuItem
                      key={d.dayOfWeekId}
                      value={d.dayOfWeekId}
                      className="medium-text blue-text"
                    >
                      {d.name}
                    </MenuItem>
                  );
                })}
              </Select>
              </Grid>
            </Grid>
          }
        />
    
          
      </RadioGroup>
    </div>
  );
}

export default MonthlyRecurrence;
