import { FilterActions, FilteredList as FilterList, FilteredObject, FilteredObjectKeys } from "../actions/filters.actions"
import { Filter } from "../models/filter"


type FiltersState = {
    filters: FilterList
    sortField?: FilteredObjectKeys
    sortDirection: "asc" | "desc"
    searchKeyword: string
}
const initialState: FiltersState = {
    filters: [],
    sortField: "emailAddress",
    sortDirection: "asc",
    searchKeyword: ""
}

const FiltersReducer = (state: FiltersState = initialState, action: FilterActions): FiltersState => {
    switch (action.type) {
        case 'FILTERS_SET_FILTERS':
            return {
                ...state,
                filters: [...action.filters]
            }

        case 'FILTERS_SET_FILTER':
            return {
                ...state,
                filters: [...state.filters.map(f => { return f.name === action.filter.name ? { ...action.filter } : f })]
            }

        case 'FILTERS_SET_SORT_FIELD':
            return {
                ...state,
                sortField: action.sortField,
                sortDirection: state.sortField === action.sortField ? (state.sortDirection === "asc" ? "desc" : "asc") : "asc"
            }

        case 'FILTERS_SET_SEARCH_KEYWORD':
            return {
                ...state,
                searchKeyword: action.searchKeyword
            }

        case 'FILTERS_APPLY_FILTERS':
            return {
                ...state,
                filters: [...state.filters.map(f => {
                    return f.values && f.values.length > 0 && f.values[0]
                        ? { ...f, applied: true }
                        : f;
                })]
            }

        case 'FILTERS_CLEAR_FILTER':
            return {
                ...state,
                filters: [...state.filters.map(f => {
                    return f.name === action.filterName
                        ? { ...f, applied: false, values: [], rawValues: undefined }
                        : f;
                })]
            }

        default:
            return state;
    }
}
export default FiltersReducer;