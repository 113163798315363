import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  UserReportHistory,
  UserReportHistoryStatuses,
} from "../../redux/models/userReportHistory";
import { reportActions } from "../../redux/actions/report.actions";
import { AppState } from "../../redux/reducers";
import { CircularProgress } from "@mui/material";
import { DateUtils } from "../../utils/date.utils";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import DeleteDialog from "../Common/DeleteDialog";
import ReportShareDialog from "../Common/ReportShareDialog";
import { ShareReportUser } from "../../redux/models/shareReport";
import { UserReportHistoryExportShare } from "../../redux/models/UserReportHistoryExportShare";


export interface FileStorageComponentProps {
  reportHistory: UserReportHistory;
  isAdmin?:boolean
  isReportShare?:boolean
}

function FileStorageComponent({ reportHistory,isAdmin,isReportShare }: FileStorageComponentProps) {
  const dispatch = useDispatch();
  const reportReducer = useSelector((state: AppState) => state.reportReducer);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { getExportLoading } = reportReducer;

  const getExportFile = function (
    id: number,
    fileName: string,
    fileExtension: string
  ) {
    dispatch(reportActions.getExportedFile(id, `${fileName}.${fileExtension}`));
  };

  function getProgress() {
    let percentComplete = 0;
    reportHistory.userReportHistoryExportDetails.forEach((x) => {
      if (x.percentComplete) {
        percentComplete += x.percentComplete;
      }
    });

    return Math.round(
      percentComplete / reportHistory.userReportHistoryExportDetails.length
    );
  }

  const openDeleteDialog = ()=>{
    setDeleteDialogOpen(true);
  }
  const openShareDialog = ()=>{
    setShareDialogOpen(true);
  }
  const handleDelete = ()=>{
    dispatch(reportActions.DeleteUserReportHistory(reportHistory.userReportHistoryId));
    setDeleteDialogOpen(false);
  }
  const handleReportShare = (userObj?:UserReportHistoryExportShare,selectedEmail?:string)=>{
    const req:ShareReportUser={
      userReportHistoryExportDetailsId:reportHistory.userReportHistoryExportDetails[0].userReportHistoryExportDetailsId,
      emailAddress:[selectedEmail],
      urlToken:userObj?.urlToken,
      userReportHistoryExportShareId:userObj?.userReportHistoryExportShareId
    }
    if(userObj)
    dispatch(reportActions.EmailReportShare(req));
    setShareDialogOpen(false);
  }


  return (
    <React.Fragment>
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          warningMsg={`You are about to delete "${reportHistory?.name}" from the File Storage, which
          will also result in the disabling of any user-created recurring
          subscriptions for this report.`}
          handleDelete={handleDelete}
          data-testid="deletebox"
        />
      )}
      {shareDialogOpen && (
        <ReportShareDialog
          open={shareDialogOpen}
          setOpen={setShareDialogOpen}
          warningMsg="Report Share"
          handleReportShare={handleReportShare}
          data-testid="sharebox"
          reportHistory={reportHistory}
        />
      )}
      <TableRow>
        <TableCell align="left" className="tblrow-title">
          <span className="row-span">{reportHistory.name}</span>
        </TableCell>
        <TableCell align="center">
          <span>{DateUtils.formatDateTime(reportHistory.startDateUTC)}</span>
        </TableCell>
        <TableCell align="left">
          {reportHistory.userReportHistoryExportDetails?.map((r, i) => {
            if (
              getExportLoading[r.userReportHistoryExportDetailsId.toString()]
            ) {
              return <CircularProgress key={i} className="loading" size={20} />;
            } else {
              return (
                <button
                  className="export-btn"
                  data-testid="export-btn"
                  key={i}
                  onClick={() =>
                    getExportFile(
                      r.userReportHistoryExportDetailsId,
                      reportHistory.name ?? "",
                      r.storageURL?.split(".")?.pop()?.toLowerCase() ?? ""
                    )
                  }
                  disabled={r.storageURL === undefined || r.storageURL === null}
                >
                  {r.exportFileExtension?.fileExtension.toUpperCase()==='PDF'?<svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24" fill="none">
                      <path d="M17.8535 5.1465L12.8535 0.146484C12.7598 0.0527344 12.6328 0 12.5 0H1.99997C0.896953 0 0 0.896953 0 2.00002V22C0 23.103 0.896953 24 2.00002 24H16C17.103 24 18 23.103 18 22V5.49998C18 5.36719 17.9473 5.24025 17.8535 5.1465ZM13 1.70705L16.293 5.00002H14C13.4487 5.00002 13 4.55128 13 4.00003V1.70705ZM17 22C17 22.5512 16.5513 23 16 23H2.00002C1.44877 23 1.00003 22.5512 1.00003 22V2.00002C1.00003 1.44877 1.44877 1.00003 2.00002 1.00003H12V4.00003C12 5.10305 12.897 6 14 6H17V22Z" fill="#165CE8"/>
                      <path d="M11.5493 14.6782C11.0864 14.314 10.6465 13.9395 10.3535 13.6465C9.97266 13.2657 9.63328 12.8965 9.33839 12.545C9.79837 11.1236 10 10.3907 10 10C10 8.34036 9.40041 8 8.50003 8C7.81594 8 7.00003 8.35545 7.00003 10.0479C7.00003 10.794 7.40873 11.6997 8.21878 12.7525C8.02055 13.3574 7.78762 14.0552 7.52592 14.8418C7.39992 15.2193 7.26323 15.5689 7.11867 15.8921C7.00337 15.9431 6.88925 15.9966 6.77639 16.0528C6.37894 16.2515 6.0015 16.4302 5.65139 16.5962C4.05469 17.3521 3 17.8521 3 18.8394C3 19.5562 3.77883 20 4.5 20C5.42967 20 6.83348 18.7583 7.85888 16.6665C8.92331 16.2466 10.2466 15.9355 11.291 15.7407C12.1279 16.3842 13.0522 17 13.5 17C14.7397 17 15 16.2832 15 15.6821C15 14.5 13.6494 14.5 13 14.5C12.7983 14.5 12.2573 14.5596 11.5493 14.6782ZM4.5 19C4.21434 19 4.02098 18.8653 3.99998 18.8394C3.99998 18.4849 5.05711 17.9839 6.07959 17.4995C6.14452 17.4688 6.21047 17.438 6.27736 17.4063C5.52638 18.4952 4.78369 19 4.5 19ZM8.00002 10.0479C8.00002 9.00003 8.32523 9.00003 8.50003 9.00003C8.85356 9.00003 9.00005 9.00003 9.00005 10C9.00005 10.211 8.85942 10.7383 8.60208 11.5616C8.20945 10.9571 8.00002 10.439 8.00002 10.0479ZM8.38331 15.4219C8.41478 15.3344 8.44506 15.2465 8.47416 15.1582C8.65969 14.6016 8.8267 14.1016 8.97563 13.6514C9.18314 13.8799 9.40678 14.1138 9.64655 14.3535C9.7403 14.4473 9.9727 14.6582 10.2823 14.9224C9.666 15.0567 9.01027 15.2232 8.38331 15.4219ZM14 15.6822C14 15.9068 14 16 13.5361 16.003C13.3999 15.9737 13.085 15.7881 12.6963 15.5235C12.8374 15.5079 12.9414 15.5 13 15.5C13.7388 15.5 13.9483 15.5723 14 15.6822Z" fill="#165CE8"/>
                    </svg>:
                    <svg width="22" height="28" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M63.6349 18.38L46.3318 0.400347C46.2099 0.27377 46.0637 0.173078 45.902 0.10429C45.7403 0.0355022 45.5664 3.1873e-05 45.3907 0L19.6016 0C16.7155 0 14.3674 2.34716 14.3674 5.2321V23.5102H3.91605C1.75672 23.5102 0 25.2664 0 27.4252V47.0239C0 49.1825 1.75672 50.9387 3.91605 50.9387H14.3674V62.6954C14.3674 65.5753 16.7155 67.9183 19.6016 67.9183H58.7658C61.6519 67.9183 64 65.5766 64 62.6983V19.2857C64 18.948 63.8691 18.6234 63.6349 18.38ZM45.7143 3.52605L59.9187 18.2857H45.7143V3.52605ZM3.91605 48.3265C3.19707 48.3265 2.61233 47.7422 2.61233 47.0239V27.4252C2.61233 26.7069 3.1972 26.1224 3.91605 26.1224H39.1859C39.9048 26.1224 40.4898 26.7069 40.4898 27.4252V47.0239C40.4898 47.7422 39.9048 48.3265 39.1859 48.3265H3.91605ZM61.3877 62.6983C61.3877 64.1362 60.2114 65.3061 58.7656 65.3061H19.6016C18.1558 65.3061 16.9796 64.135 16.9796 62.6955V50.9387H39.1858C41.3453 50.9387 43.102 49.1825 43.102 47.0239V27.4252C43.102 25.2664 41.3453 23.5102 39.1858 23.5102H16.9796V5.2321C16.9796 3.78737 18.1558 2.6122 19.6016 2.6122H43.102V19.5918C43.102 20.3131 43.6869 20.8979 44.4081 20.8979H61.3877V62.6983Z" fill="#165CE8"></path>
                    <path d="M35.7992 38.0473C35.5008 37.565 35.0874 37.185 34.5595 36.9068C34.0314 36.6289 33.2165 36.3599 32.1144 36.0996C31.0123 35.8393 30.3185 35.5893 30.0328 35.3496C29.8081 35.1608 29.6959 34.9334 29.6959 34.6684C29.6959 34.3775 29.8157 34.1452 30.0558 33.9718C30.4283 33.7015 30.9436 33.5663 31.6016 33.5663C32.2392 33.5663 32.7177 33.6927 33.0366 33.945C33.3554 34.1975 33.5632 34.6122 33.6602 35.1888L35.9254 35.0892C35.8898 34.0586 35.5163 33.2346 34.8045 32.6175C34.0925 32 33.0328 31.6914 31.6248 31.6914C30.7624 31.6914 30.0264 31.8213 29.4168 32.0817C28.8071 32.3419 28.3402 32.7207 28.0162 33.218C27.6923 33.7156 27.5301 34.2502 27.5301 34.8216C27.5301 35.7091 27.8745 36.4617 28.5635 37.0792C29.0533 37.5178 29.9052 37.8878 31.1196 38.1887C32.0633 38.4235 32.6681 38.5868 32.9331 38.6785C33.3211 38.8163 33.5927 38.9783 33.7482 39.1644C33.9039 39.3509 33.9816 39.5764 33.9816 39.842C33.9816 40.2551 33.7967 40.6162 33.4269 40.9247C33.0569 41.2333 32.5073 41.3877 31.7776 41.3877C31.0889 41.3877 30.5417 41.2143 30.1362 40.8671C29.7306 40.5204 29.4616 39.977 29.3287 39.2371L27.1245 39.4513C27.2724 40.7065 27.7266 41.662 28.4869 42.3176C29.2471 42.9729 30.3363 43.3008 31.7548 43.3008C32.7291 43.3008 33.5432 43.1643 34.1963 42.8913C34.8493 42.6187 35.3541 42.2014 35.7113 41.64C36.0685 41.079 36.2472 40.4769 36.2472 39.8339C36.2469 39.1249 36.0977 38.5293 35.7992 38.0473ZM20.451 41.2116V31.9745H18.1854V43.1019H26.0837V41.2116H20.451ZM13.1269 37.3315L16.6168 31.8827H13.9919L11.7879 35.4873L9.53767 31.8827H6.88992L10.3641 37.2473L6.53003 43.1019H9.24676L11.7418 39.2217L14.2289 43.1019H16.9611L13.1269 37.3315Z" fill="#165CE8"></path>
                    </svg>
                    
                    }
                </button>
              );
            }
          })}
        </TableCell>
        <TableCell align="center">
          <div className="status">
            {reportHistory.status === UserReportHistoryStatuses.COMPLETE && (
              <div className="status success">
                <span className="status-name">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <circle cx="6" cy="6" r="6" fill="#33933D"/>
                  </svg>
                  Completed
                </span>
                
              </div>
            )}
            {(reportHistory.status === UserReportHistoryStatuses.RUNNING ||
              reportHistory.status === UserReportHistoryStatuses.QUEUED) && (
              <div className={reportHistory.status === UserReportHistoryStatuses.RUNNING?"status in-progress":"status queue"}>
                <span className="status-name">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <circle cx="6" cy="6" r="6"/>
                  </svg>
                  {reportHistory.status === UserReportHistoryStatuses.RUNNING?"In Progress":"In Queue"}
                </span>
                {reportHistory.userReportHistoryExportDetails.length ===
                        1 && (
                  <span className="status-message">{`${getProgress()}%`} Progress</span>
                      )}
                {reportHistory.userReportHistoryExportDetails.length >
                        1 &&
                        reportHistory.userReportHistoryExportDetails.map(
                          (x, i) => {
                            if (x.exportStatus.toUpperCase() === "FAILED")
                              return (
                                <span className="status-message" key={x.userReportHistoryExportDetailsId}>{x.errorMessage}</span>
                                
                              );
                            else
                              return (
                                <span className="status-message" key={x.userReportHistoryExportDetailsId}>{x.percentComplete}%{x.exportFileExtension?.fileExtension}</span>
                              );
                          }
                        )}
              </div>
            )}
            {reportHistory.status === UserReportHistoryStatuses.ERROR && (
              <div className="status error-block">
                <span className="status-name">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                    <circle cx="6" cy="6" r="6" fill="#33933D"/>
                  </svg>
                  Error
                </span>
                <span className="status-message">Unable to Compile</span>
              </div>
            )}
             
          </div>
        </TableCell>
        <TableCell  align="center" style={{ position: "sticky", right: 0, background:'#FFF' }}>
        {(isAdmin && reportHistory.status === "COMPLETE"&&!isReportShare) && <span onClick={openDeleteDialog} className="delete-action" data-testid="delete-dialog">
             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M19 7L18.133 19.142C18.0971 19.6466 17.8713 20.1188 17.5011 20.4636C17.1309 20.8083 16.6439 21 16.138 21H7.862C7.35614 21 6.86907 20.8083 6.49889 20.4636C6.1287 20.1188 5.90292 19.6466 5.867 19.142L5 7M10 11V17M14 11V17M15 7V4C15 3.73478 14.8946 3.48043 14.7071 3.29289C14.5196 3.10536 14.2652 3 14 3H10C9.73478 3 9.48043 3.10536 9.29289 3.29289C9.10536 3.48043 9 3.73478 9 4V7M4 7H20" stroke="#FF9B9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              </span>}
              {(isAdmin && reportHistory.status === "COMPLETE"&&!isReportShare) && <span onClick={openShareDialog} className="share-action" data-testid="share-dialog">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 10 35 35" fill="none" width={28}>
              <path d="M24.684 27.3421C24.886 26.9381 25 26.4821 25 26.0001C25 25.5181 24.886 25.0621 24.684 24.6581M24.684 27.3421C24.3817 27.9465 23.8842 28.4311 23.2721 28.7175C22.6599 29.0038 21.9691 29.0751 21.3114 28.9198C20.6537 28.7645 20.0677 28.3917 19.6484 27.8618C19.229 27.3319 19.0009 26.6759 19.0009 26.0001C19.0009 25.3243 19.229 24.6684 19.6484 24.1384C20.0677 23.6085 20.6537 23.2357 21.3114 23.0804C21.9691 22.9251 22.6599 22.9964 23.2721 23.2828C23.8842 23.5691 24.3817 24.0537 24.684 24.6581M24.684 27.3421L31.316 30.6581M24.684 24.6581L31.316 21.3421M31.316 30.6581C30.96 31.37 30.9015 32.194 31.1531 32.9491C31.4048 33.7041 31.9461 34.3282 32.658 34.6841C33.3698 35.04 34.1939 35.0986 34.9489 34.8469C35.7039 34.5953 36.328 34.054 36.684 33.3421C37.0399 32.6303 37.0984 31.8062 36.8468 31.0512C36.5951 30.2962 36.0538 29.672 35.342 29.3161C34.9895 29.1399 34.6058 29.0348 34.2127 29.0069C33.8196 28.9789 33.4249 29.0287 33.051 29.1533C32.296 29.405 31.6719 29.9463 31.316 30.6581ZM31.316 21.3421C31.4922 21.6945 31.7361 22.0088 32.0338 22.2669C32.3315 22.525 32.6771 22.722 33.0509 22.8466C33.4247 22.9711 33.8194 23.0209 34.2124 22.9929C34.6054 22.9649 34.9891 22.8598 35.3415 22.6836C35.6939 22.5074 36.0081 22.2635 36.2662 21.9658C36.5244 21.6681 36.7213 21.3225 36.8459 20.9487C36.9705 20.5749 37.0202 20.1802 36.9922 19.7872C36.9643 19.3942 36.8592 19.0105 36.683 18.6581C36.327 17.9464 35.703 17.4052 34.948 17.1537C34.1931 16.9021 33.3692 16.9607 32.6575 17.3166C31.9457 17.6725 31.4046 18.2966 31.153 19.0515C30.9014 19.8065 30.96 20.6304 31.316 21.3421Z" stroke="#8EA0B6" strokeWidth={2} strokeLinecap={"round"} strokeLinejoin={"round"}></path>
              </svg>
              </span>}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default FileStorageComponent;
