import { Dispatch } from "react"
import { INotificationType } from "../models/notificationType";

export interface ISetNotificationAction {
    readonly type: 'SET_NOTIFICATION';
    notificationType: INotificationType;
    message: string;
    stayVisible: boolean;
}
// export interface IHideNotificationAction {
//     readonly type: 'HIDE_NOTIFICATION';
// }
export type NotificationActions =
    | ISetNotificationAction
// | IHideNotificationAction


const setNotification = (notificationType: INotificationType, message: string, stayVisible: boolean) => {
    return function (dispatch: Dispatch<ISetNotificationAction>) {
        return dispatch({ type: 'SET_NOTIFICATION', notificationType, message, stayVisible });
    }
}

// const hideNotification = () => {
//     return function (dispatch: Dispatch<IHideNotificationAction>) {
//         return dispatch({ type: 'HIDE_NOTIFICATION' });
//     }
// }

export const notificationActions = {
    setNotification,
    //hideNotification
}