import { AppState } from "../../redux/reducers";
import { INotificationType } from "../models/notificationType";


const getNotificationColor = (notificationType?: INotificationType, type?:string): string => {
    if (notificationType === INotificationType.Error) {
        return type=='border'?"#CF324D":"#FAE6E8"; //red
    }
    else if (
        notificationType === INotificationType.Unauthorized ||
        notificationType === INotificationType.Warning
    ) {
        return type=='border'?"#E8AB2D":"#FFF9ED"; // orange/yellow
    }
    else if
        (notificationType === INotificationType.Info ||
        notificationType === INotificationType.Loading)
        return "#1A2F42"; // light blue
    else
        return type=='border'?"#149E58":"#F2FFF2"; // green
}


export const selectNotificationMessage = (state: AppState) => state.notificationReducer.message;
export const selectNotificationType = (state: AppState) => state.notificationReducer.notificationType;
export const selectNotificationColor = (state: AppState) => getNotificationColor(state.notificationReducer.notificationType,'background');
export const selectNotificationBorderColor = (state: AppState) => getNotificationColor(state.notificationReducer.notificationType,'border');
export const selectNotificationStayVisible = (state: AppState) => state.notificationReducer.stayVisible;
export const selectNotificationIsVisible = (state: AppState) => state.notificationReducer.visibilityRefresh;