import appConfig from "../../utils/appConfig";
import { FetchUtils } from "../../utils/fetch.utils";
import { Dashboard } from "../models/dashboard";
import { Widget } from "../models/widget";
import { ShareDashboard } from "../models/shareDashboard";
import { DashboardUser } from "../models/dashboardUser";

const apiBaseUrl = appConfig.apiBaseUrl;
const dashboardApi = 'dashboard';


const getUserDashboards = (dashboardId: number | null): Promise<Dashboard[]> => {
  let optionalParam = dashboardId !== null ? `?checkDashboardId=${dashboardId}` : '';
  const URL = `${apiBaseUrl}/${dashboardApi}/GetUserDashboards${optionalParam}`;
  let response = FetchUtils.GET<Dashboard[]>(URL);
  return response;
}

const cloneDashboard = (dashboardId: number, newDashboardName: string): Promise<Dashboard> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/CloneDashboard?dashboardId=${dashboardId}&newDashboardName=${newDashboardName}`;
  let response = FetchUtils.GET<Dashboard>(URL);
  return response;
}

const addWidget = (widget: Widget): Promise<Widget> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/AddWidget`;
  let response = FetchUtils.POST<Widget>(URL, widget);
  return response;
}

const updateWidget = (widget: Widget): Promise<Widget> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/UpdateWidget`;
  let response = FetchUtils.PUT<Widget>(URL, widget);
  return response;
}

const updateDashboard = (dashboard: Dashboard): Promise<Dashboard> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/UpdateDashboard`;
  let response = FetchUtils.PUT<Dashboard>(URL, dashboard);
  return response;
}

const deleteDashboard = (dashboardId: number, nextDefaultDashboardId: number | null): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/DeleteDashboard?dashboardId=${dashboardId}${nextDefaultDashboardId ? `&nextDefaultDashboardId=${nextDefaultDashboardId}` : ''}`;
  let response = FetchUtils.DELETE<boolean>(URL);
  return response;
}

const deleteWidget = (dashboardId: number, widgetId: number): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/DeleteWidget?dashboardId=${dashboardId}&widgetId=${widgetId}`;
  let response = FetchUtils.DELETE<boolean>(URL);
  return response;
}

const embedWidgets = (dashboardId: number): Promise<Widget[]> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/EmbedWidgets?dashboardId=${dashboardId}`;
  let response = FetchUtils.GET<Widget[]>(URL);
  return response;
}

const shareDashboard = (data: ShareDashboard): Promise<DashboardUser> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/ShareDashboard`;
  let response = FetchUtils.POST<DashboardUser>(URL, data);
  return response;
}

const removeDashboardUser = (dashboardId: number, dashboardUserId: number, nextDefaultDashboardId: number | null): Promise<boolean> => {
  const URL = `${apiBaseUrl}/${dashboardApi}/RemoveDashboardUser?dashboardId=${dashboardId}&dashboardUserIdToRemove=${dashboardUserId}${nextDefaultDashboardId ? `&nextDefaultDashboardId=${nextDefaultDashboardId}` : ''}`;
  let response = FetchUtils.DELETE<boolean>(URL);
  return response;
}

export const dashboardService = {
  getUserDashboards,
  cloneDashboard,
  addWidget,
  updateWidget,
  updateDashboard,
  deleteDashboard,
  deleteWidget,
  embedWidgets,
  shareDashboard,
  removeDashboardUser
};
