import { Report, models } from "powerbi-client";

export const PowerBIUtils = {
    getFilters,
    getSlicerStates,
    // setSlicerStates,
    parseFiltersToStrings,
    getReadableText
}


async function getFilters(report: Report): Promise<(models.IBasicFilter | models.IAdvancedFilter | models.IRelativeTimeFilter)[]> {
    var filters = [];
    var page = await report.getActivePage();
    var visuals = await page.getVisuals();
    var slicers = visuals.filter((v) => v.type === "slicer");
    for (const slicer of slicers) {
        var slicerState = await slicer.getSlicerState();
        if (slicerState.filters.length > 0) {
            filters.push(slicerState.filters[0]);
        }
    }
    await page
        .getFilters()
        .then((pagefilters) => {
            for (const filter of pagefilters) {
                filters.push(filter);
            }
        })
        .catch((err) => { });

    return filters;
}

async function getSlicerStates(report: Report): Promise<models.ISlicer[]> {
    var slicerStates: models.ISlicer[] = [];
    var page = await report.getActivePage();
    var visuals = await page.getVisuals();
    var slicers = visuals.filter((v) => v.type === "slicer");
    for (const visual of slicers) {
        var slicerState = await visual.getSlicerState();
        let islicer: models.ISlicer = {
            selector: { visualName: visual.name } as models.SlicerSelector,
            state: slicerState
        }
        slicerStates.push(islicer);
    }
    return slicerStates;
}

// async function setSlicerStates(report: Report, states: Record<string, models.ISlicerState>): Promise<boolean> {
//     try {
//         var page = await report.getActivePage();
//         var visuals = await page.getVisuals();
//         var slicerVisuals = visuals.filter((v) => v.type === "slicer");
//         for (const visual of slicerVisuals) {
//             var slicerState = states[visual.name];
//             await visual.setSlicerState(slicerState);
//         }
//         return true;
//     }
//     catch (error) {
//         return false;
//     }
// }

function injectSpecialCharacters(value: string): string {
    const specialChars = ' `!@#$%^&*()_+-=[]{};\':"\\|,.<>/?~]';
    var newValue = value;
    if(value)
    specialChars.split('').forEach(specialChar => {
        let i = value.indexOf(specialChar);
        if (i !== -1) {
            var hex = specialChar.codePointAt(0)!.toString(16);
            var result = "0000".substring(0, 4 - hex.length) + hex;
            newValue = newValue.replaceAll(specialChar, `_x${result}_`)
        }
    });

    return newValue;
}

function getReadableText(value: string): string {
    let s = value.split("/")[1].split('_x');
    let ret: string = '';
    if (s.length > 1) {
        for (var i = 0; i < s.length; i++) {
            let ind = s[i].indexOf('_')
            if (ind !== -1) {
                let u = s[i].substring(0, ind);
                let uu = String.fromCharCode(parseInt(u, 16))
                ret += (uu + s[i].substring(ind + 1, s[i].length))
            }
            else {
                ret += s[i]
            }
        }
    }
    else {
        ret = value;;
    }
    ret = ret.replaceAll(' eq ', " = ")
    ret = ret.replaceAll(' ne ', " ≠ ")
    ret = ret.replaceAll(' lt ', " < ")
    ret = ret.replaceAll(' gt ', " > ")
    ret = ret.replaceAll(' le ', " ≤ ")
    ret = ret.replaceAll(' ge ', " ≥ ")
    return ret;
}

function parseFiltersToStrings(filters: any[]): string {
    let filter = "";

    filters.forEach((f, i) => {
        const tableName = injectSpecialCharacters(f.target.table);
        const columnName = injectSpecialCharacters(f.target.column);

        if (f.filterType === 1) {
            if (f.values?.length > 0) {
                const val = f.values
                    .map((v: any) => {
                        return `'${v}'`;
                    })
                    .join(",");

                const and =
                    filters.length > 1 && i < filters.length - 1 ? " and " : "";
                filter = `${filter}${tableName}/${columnName} ${f.operator.toLowerCase()} (${val})${and}`;
            }
        } else if (f.filterType === 0) {
            f.conditions.forEach((c: any, j: number) => {
                let operator = undefined;

                switch (c.operator) {
                    case "Is":
                        operator = "eq";
                        break;
                    case "IsNot":
                        operator = "ne";
                        break;
                    case "LessThan":
                        operator = "lt";
                        break;
                    case "GreaterThan":
                        operator = "gt";
                        break;
                    case "LessThanOrEqual":
                        operator = "le";
                        break;
                    case "GreaterThanOrEqual":
                        operator = "ge";
                        break;
                }

                const and =
                    filters.length > 1 && i < filters.length - 1 ? " and " : "";
                const logicalOperator =
                    f.conditions.length > 1 &&
                        j < f.conditions.length - 1 &&
                        [
                            "Is",
                            "IsNot",
                            "LessThan",
                            "GreaterThan",
                            "LessThanOrEqual",
                            "GreaterThanOrEqual",
                        ].includes(f.conditions[j + 1].operator)
                        ? ` ${f.logicalOperator} `
                        : and;

                if (operator) {
                    filter = `${filter}${tableName}/${columnName} ${operator.toLowerCase()} '${c.value}'${logicalOperator.toLowerCase()}`;
                }
            });
        }
    });

    if (filter.endsWith(' and ')) {
        let ai = filter.lastIndexOf(' and ');
        filter = filter.substring(0, ai);
    }
    return filter;
}
