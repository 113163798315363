import { Dispatch } from "react"
import { UserWithDetails } from "../models/userWithDetails";
import { User } from "../models/user"
import { adminService } from "../services/admin.service";
import { userService } from "../services/user.service"
import { IBaseAction } from "./base.action"
import { UpdateUserReportAccessActionEnum } from "../models/updateUserReportAccessModel";

interface IGetCreateUserAction extends IBaseAction {
  readonly type: 'GET_CREATE_USER_REQUEST' | 'GET_CREATE_USER_SUCCESS' | 'GET_CREATE_USER_FAILURE';
  payload?: User;
}

interface IGetUsersAction extends IBaseAction {
  readonly type: 'GET_USERS_REQUEST' | 'GET_USERS_SUCCESS' | 'GET_USERS_FAILURE';
  users?: UserWithDetails[];
}

interface IUpdateUserActiveAction extends IBaseAction {
  readonly type: 'UPDATE_USER_ACTIVE_REQUEST' | 'UPDATE_USER_ACTIVE_SUCCESS' | 'UPDATE_USER_ACTIVE_FAILURE';
  userId: number
  isActive: boolean
}

interface IUpdateUserSecurityRolesAction extends IBaseAction {
  readonly type: 'USER_UPDATE_USER_SECURITY_ROLES_REQUEST' | 'USER_UPDATE_USER_SECURITY_ROLES_SUCCESS' | 'USER_UPDATE_USER_SECURITY_ROLES_FAILURE';
  users?: UserWithDetails[]
}

interface IUpdateUserReportAccessAction extends IBaseAction {
  readonly type: 'USER_UPDATE_USER_REPORT_ACCESS_REQUEST' | 'USER_UPDATE_USER_REPORT_ACCESS_SUCCESS' | 'USER_UPDATE_USER_REPORT_ACCESS_FAILURE';
  users?: UserWithDetails[]
}

interface IUpdateUserDefaultDashboardAction extends IBaseAction {
  readonly type: 'USER_UPDATE_USER_DEFAULT_DASHBOARD_REQUEST' | 'USER_UPDATE_USER_DEFAULT_DASHBOARD_SUCCESS' | 'USER_UPDATE_USER_DEFAULT_DASHBOARD_FAILURE';
  users?: UserWithDetails[]
}

interface IUserSignoutAction extends IBaseAction {
  readonly type: 'USER_SIGN_OUT_REQUEST' | 'USER_SIGN_OUT_SUCCESS' | 'USER_SIGN_OUT_FAILURE';
}

interface ICheckUserAction {
  readonly type: 'USER_CHECK_USER'
  userId: number;
  isChecked: boolean;
}

interface ICheckAllUsersAction {
  readonly type: 'USER_CHECK_ALL_USERS'
  isChecked: boolean;
}

interface IClearAllAction {
  readonly type: 'USERS_CLEAR_ALL'
}

export type UserActions =
  | IGetCreateUserAction | IGetUsersAction | IUpdateUserActiveAction | IUpdateUserSecurityRolesAction | IUpdateUserReportAccessAction | IUpdateUserDefaultDashboardAction
  | IUserSignoutAction | ICheckUserAction | ICheckAllUsersAction
  | IClearAllAction


const getOrCreateUser = () => {
  return function (dispatch: Dispatch<IGetCreateUserAction>) {
    dispatch({ type: 'GET_CREATE_USER_REQUEST' });
    userService.getOrCreateUser().then((response) => {
      return dispatch({ type: 'GET_CREATE_USER_SUCCESS', payload: response })
    }, (error) => {
      return dispatch({ type: 'GET_CREATE_USER_FAILURE', error })
    })
  }
}

const getUsers = () => {
  return function (dispatch: Dispatch<IGetUsersAction>) {
    dispatch({ type: 'GET_USERS_REQUEST' });
    adminService.getUsers().then((response) => {
      return dispatch({ type: 'GET_USERS_SUCCESS', users: response })
    }, (error) => {
      return dispatch({ type: 'GET_USERS_FAILURE', error })
    })
  }
}

const updateUserActive = (userId: number, isActive: boolean, callback?: () => void) => {
  return function (dispatch: Dispatch<IUpdateUserActiveAction>) {
    dispatch({ type: 'UPDATE_USER_ACTIVE_REQUEST', userId, isActive });
    adminService.updateUserActive(userId, isActive).then((response) => {
      if (callback)
        callback();
      return dispatch({ type: 'UPDATE_USER_ACTIVE_SUCCESS', userId, isActive })
    }, (error) => {
      return dispatch({ type: 'UPDATE_USER_ACTIVE_FAILURE', userId, isActive, error })
    })
  }
}

const updateUserSecurityRoles = (userIds: number[], roleIds: number[], callback: () => void) => {
  return function (dispatch: Dispatch<IUpdateUserSecurityRolesAction>) {
    dispatch({ type: 'USER_UPDATE_USER_SECURITY_ROLES_REQUEST' });
    adminService.updateUserSecurityRoles(userIds, roleIds).then((response) => {
      callback()
      return dispatch({ type: 'USER_UPDATE_USER_SECURITY_ROLES_SUCCESS', users: response })
    }, (error) => {
      return dispatch({ type: 'USER_UPDATE_USER_SECURITY_ROLES_FAILURE', error })
    })
  }
}

const updateUserReportAccess = (action: UpdateUserReportAccessActionEnum, userIds: number[], reportIds: number[], callback: () => void) => {
  return function (dispatch: Dispatch<IUpdateUserReportAccessAction>) {
    dispatch({ type: 'USER_UPDATE_USER_REPORT_ACCESS_REQUEST' });
    adminService.updateUserReportAccess({ action, userIds, reportIds }).then((response) => {
      callback()
      return dispatch({ type: 'USER_UPDATE_USER_REPORT_ACCESS_SUCCESS', users: response })
    }, (error) => {
      return dispatch({ type: 'USER_UPDATE_USER_REPORT_ACCESS_FAILURE', error })
    })
  }
}

const updateUserDefaultDashboard = (userIds: number[], reportId: number, callback: () => void) => {
  return function (dispatch: Dispatch<IUpdateUserDefaultDashboardAction>) {
    dispatch({ type: 'USER_UPDATE_USER_DEFAULT_DASHBOARD_REQUEST' });
    adminService.updateUserDefaultDashboard(userIds, reportId).then((response) => {
      callback()
      return dispatch({ type: 'USER_UPDATE_USER_DEFAULT_DASHBOARD_SUCCESS', users: response })
    }, (error) => {
      return dispatch({ type: 'USER_UPDATE_USER_DEFAULT_DASHBOARD_FAILURE', error })
    })
  }
}

const userSignOut = (logoutMsal: () => void) => {
  return function (dispatch: Dispatch<IUserSignoutAction>) {
    dispatch({ type: 'USER_SIGN_OUT_REQUEST' });
    userService.userSignOut().then((response) => {
      logoutMsal();
      return dispatch({ type: 'USER_SIGN_OUT_SUCCESS' })
    }, (error) => {
      logoutMsal();
      return dispatch({ type: 'USER_SIGN_OUT_FAILURE', error })
    })
  }
}

const checkUser = (userId: number, isChecked: boolean) => {
  return function (dispatch: Dispatch<ICheckUserAction>) {
    return dispatch({ type: 'USER_CHECK_USER', userId, isChecked })
  }
}

const checkAllUsers = (isChecked: boolean) => {
  return function (dispatch: Dispatch<ICheckAllUsersAction>) {
    return dispatch({ type: 'USER_CHECK_ALL_USERS', isChecked })
  }
}

export const userActions = {
  getOrCreateUser,
  getUsers,
  updateUserActive,
  updateUserSecurityRoles,
  updateUserReportAccess,
  updateUserDefaultDashboard,
  userSignOut,
  checkUser,
  checkAllUsers
}