import { useEffect, useRef, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { reportActions } from "../../redux/actions/report.actions";
import { ReportCategory } from "../../redux/models/reportCategory";
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { DropdonwArrowIcon } from "../Common/Utils";
import { selectAllReportCategories } from "../../redux/selectors/report.selector";

export interface ReportTypeDropdownProps {
  visibleCategories?: ReportCategory[];
  selectedCategory?: ReportCategory;
  setSelectedCategory: (category: ReportCategory) => void;
}

function ReportTypeDropdown({
  visibleCategories,
  selectedCategory,
  setSelectedCategory,
}: ReportTypeDropdownProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  let profileDropdownref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();
  let c = useSelector(selectAllReportCategories);
  let categories = visibleCategories ?? c;
  

  useEffect(() => {
    dispatch(reportActions.getReportCategories());
    window.addEventListener("click", onClickEvent);
    console.log(isDropdownOpen);
    console.log(selectedCategory?.name);

    return function cleanup() {
      window.removeEventListener("click", onClickEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickEvent = (event: MouseEvent) => {
    if (
      event.target instanceof Element &&
      profileDropdownref &&
      !profileDropdownref.current?.contains(event.target)
    ) {
      setIsDropdownOpen(false);
    }
  };

  

  const handleOnChange=(event:any)=>{
    const cat=categories?.filter((c)=>c.name===event.target.value)
    if(cat&&cat.length>0)
      setSelectedCategory(cat[0])
  }

  return (
        <Select
        displayEmpty
        value={selectedCategory?.name}
        input={<OutlinedInput />}
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={DropdonwArrowIcon}
        onChange={handleOnChange}
        >
        <MenuItem disabled value={undefined} className="heading-list">
            <em>Category</em>
        </MenuItem>
        {categories?.map((cat,i) => (
            <MenuItem
            key={cat.name}
            value={cat.name}
            >
            {cat.name}
            </MenuItem>
        ))}
        </Select>
  );
}
export default ReportTypeDropdown;
