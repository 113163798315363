import { combineReducers } from 'redux'
import NotificationReducer from './notification.reducer'
import ReportReducer from './report.reducer';
import AdminReducer from './admin.reducer';
import UserReducer from './user.reducer';
import DashboardReducer from './dashboard.reducer';
import SubscriptionReducer from './subscription.reducer';
import DownloadReducer from './download.reducer';
import { reducer as formReducer } from 'redux-form'
import FiltersReducer from './filters.reducer';
import ContactReducer from './contact.reducer';

const rootReducer = combineReducers({
    reportReducer: ReportReducer,
    adminReducer: AdminReducer,
    userReducer: UserReducer,
    dashboardReducer: DashboardReducer,
    notificationReducer: NotificationReducer,
    subscriptionReducer: SubscriptionReducer,
    downloadReducer: DownloadReducer,
    filterReducer: FiltersReducer,
    contactReducer: ContactReducer,
    form: formReducer
})
export type AppState = ReturnType<typeof rootReducer>
export default rootReducer;