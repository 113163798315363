import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import { selectSelectedDashboard } from "../../redux/selectors/dashboard.selector";

export interface RenameDashboardDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function RenameDashboardDialog({ open, setOpen }: RenameDashboardDialogProps) {
  const dispatch = useDispatch();

  const selectedDashboard = useSelector(selectSelectedDashboard);
  const [newDashboardName, setNewDashboardName] = useState(
    selectedDashboard.name ?? ""
  );

  const handleClose = () => {
    setStyleOpen(false)
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(
      dashboardActions.cloneDashboard(
        selectedDashboard.dashboardId ?? 0,
        newDashboardName
      )
    );
    setStyleOpen(false)
    setOpen(false);
  };

  const [isStyleOpen, setStyleOpen]=useState<boolean>(open);
  useEffect(()=>{
    setTimeout(()=>{
      setStyleOpen(open);
    },500)
  },[open])
  return (
    <div id="delete-report-dialog">
      <Dialog open={open} onClose={handleClose} id="clone-form" className={isStyleOpen?"dialog-right in":"dialog-right"}>
        <div className="close" onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g id="ic">
                  <path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </g>
                </svg>
        </div>
        <div className="dialog-title">Clone Dashboard</div>
        <div className="dialog-content">
          <div className="inner-container">
            <div className="select-lable">Dashboard Name<span className="mandatory">*</span></div>
            <input
              id="dashboard-input"
              value={newDashboardName}
              placeholder="Enter name"
              onChange={(e) => setNewDashboardName(e.target.value)}
              maxLength={50}
            />
            <div data-testid="clone-small"
              className={
                50 - newDashboardName.length === 0 ? "small-error" : "clone-small"
              }
            >Limit: 50 characters ({50 - newDashboardName.length} characters remaining)</div>
          </div>
        <div className="dialog-footer">
          <Button onClick={handleClose} className="btn-cancel medium-text" data-testid="clone-cancel">Cancel</Button>
          <Button onClick={handleSubmit} className="btn-save medium-text" 
          disabled={newDashboardName.length === 0}  data-testid="clone-submit"> Clone</Button>
        </div>
        </div>
      </Dialog>
    </div>
  );
}

export default RenameDashboardDialog;
