import { Dispatch } from "react"
import { Subscription } from "../models/subscription"
import { subscriptionService } from "../services/subscription.service"
import { SubscriptionFormData } from "../models/subscriptionFormData"
import { ReportCategory } from "../models/reportCategory"
import { Timezone } from "../models/timezone"
import { IBaseAction } from "./base.action"
import { PaginatedSubscription } from "../models/adminSubscription"
import { SubscriptionExtension } from "../models/subscriptionExtension"
import { JobSchedule } from "../models/jobSchedule"
import { JobScheduleDaysOfWeek } from "../models/jobScheduleDaysOfWeek"
import { SubscriptionFormValidation } from "../models/subscriptionFormValidation"
import { Filter } from "../models/filter";
import { SubscriptionTenantGroup } from "../models/subscriptionTenantGroup";
import { FilteredAdminSubscription, FilteredSubscription } from "../selectors/subscription.selector"
import { NotificationActions } from "./notification.actions"
import { INotificationType } from "../models/notificationType"

interface IGetSubscriptionsAction extends IBaseAction {
    readonly type: 'GET_SUBSCRIPTIONS_REQUEST' | 'GET_SUBSCRIPTIONS_SUCCESS' | 'GET_SUBSCRIPTIONS_FAILURE';
    payload?: Subscription[];
}

interface IGetAdminSubscriptionsAction extends IBaseAction {
    readonly type: 'GET_ADMIN_SUBSCRIPTIONS_REQUEST' | 'GET_ADMIN_SUBSCRIPTIONS_SUCCESS' | 'GET_ADMIN_SUBSCRIPTIONS_FAILURE';
    payload?: PaginatedSubscription;
}

interface IGetSubscriptionAction extends IBaseAction {
    readonly type: 'GET_SUBSCRIPTION_REQUEST' | 'GET_SUBSCRIPTION_SUCCESS' | 'GET_SUBSCRIPTION_FAILURE';
    payload?: Subscription;
}

interface IGetSubscriptionFormAction extends IBaseAction {
    readonly type: 'GET_SUBSCRIPTION_FORM_REQUEST' | 'GET_SUBSCRIPTION_FORM_SUCCESS' | 'GET_SUBSCRIPTION_FORM_FAILURE';
    payload?: SubscriptionFormData;
}

interface IUpdateSubscriptionActive extends IBaseAction {
    readonly type: 'UPDATE_SUBSCRIPTION_ACTIVE_REQUEST' | 'UPDATE_SUBSCRIPTION_ACTIVE_SUCCESS' | 'UPDATE_SUBSCRIPTION_ACTIVE_FAILURE';
    subscriptionId: number
    isActive: boolean
    payload?: Date
}

interface IClearAllAction {
    readonly type: 'CLEAR-ALL'
}

interface ISortSubscriptionsAction {
    readonly type: 'SORT_SUBSCRIPTIONS'
    field: string
}

interface ISortAdminSubscriptionsAction {
    readonly type: 'SORT_ADMIN_SUBSCRIPTIONS'
    field: string[]
}

interface ISetSelectedCategoryAction {
    readonly type: 'SET_SUBSCRIPTION_CATEGORY'
    category: ReportCategory
}

interface ISetAdminSelectedCategoryAction {
    readonly type: 'SET_ADMIN_CATEGORY'
    category: ReportCategory
}

interface ISetSubscriptionAction {
    readonly type: 'SET_SUBSCRIPTION'
    subscription: Subscription
}

interface IValidateSubscriptionAction {
    readonly type: 'VALIDATE_SUBSCRIPTION'
    fieldsValidation: SubscriptionFormValidation
    isSubmit: boolean
}

interface IValidateAdminSubscriptionAction {
    readonly type: 'VALIDATE_ADMIN_SUBSCRIPTION'
    fieldsValidation: SubscriptionFormValidation
    isSubmit: boolean
}


interface IAddSubscriptionAction extends IBaseAction {
    readonly type: 'ADD_SUBSCRIPTION_REQUEST' | 'ADD_SUBSCRIPTION_SUCCESS' | 'ADD_SUBSCRIPTION_FAILURE';
    subscription: Subscription;
    newSubscription?: Subscription;
}

interface ISaveSubscriptionAction extends IBaseAction {
    readonly type: 'SAVE_SUBSCRIPTION_REQUEST' | 'SAVE_SUBSCRIPTION_SUCCESS' | 'SAVE_SUBSCRIPTION_FAILURE';
    subscription: Subscription;
}

interface IDeleteSubscriptionAction extends IBaseAction {
    readonly type: 'DELETE_SUBSCRIPTION_REQUEST' | 'DELETE_SUBSCRIPTION_SUCCESS' | 'DELETE_SUBSCRIPTION_FAILURE';
    subscriptionId: number;
}

interface ISetParametersAction {
    readonly type: 'SET_PARAMETERS'
    parameters: string
}

interface IGetTimezonesAction extends IBaseAction {
    readonly type: 'GET_TIMEZONES_REQUEST' | 'GET_TIMEZONES_SUCCESS' | 'GET_TIMEZONES_FAILURE';
    payload?: Timezone[];
}

interface ISetReportIdAction {
    readonly type: 'SET_REPORT_ID'
    reportId?: number
}

interface ISetAdminRowCountAction {
    readonly type: 'SET_ROW_COUNT'
    count: number
}

interface ISetAdminCurrentPageAction {
    readonly type: 'SET_CURRENT_PAGE'
    page: number
}

interface ISearchSubscriptionsAction {
    readonly type: 'SEARCH_SUBSCRIPTIONS'
    search: string
}

interface ISetSubTitleAction {
    readonly type: 'SET_SUBTITLE'
    subtitle: string
}

interface ISetNotesAction {
    readonly type: 'SET_NOTES'
    notes: string
}

interface ISetExtensionsAction {
    readonly type: 'SET_EXTENSIONS'
    extensions: SubscriptionExtension[]
}

interface ISetJobScheduleAction {
    readonly type: 'SET_JOB_SCHEDULE'
    jobSchedule: JobSchedule
}

interface ISetRunTimeAction {
    readonly type: 'SET_RUN_TIME'
    runTime: string
}

interface ISetTimezoneAction {
    readonly type: 'SET_TIMEZONE'
    timezoneId: number
}

interface ISetDaysAction {
    readonly type: 'SET_DAYS'
    days: JobScheduleDaysOfWeek[]
    excludeWeekends: boolean
    valid: boolean
}

interface ISetRecurrenceFactorAction {
    readonly type: 'SET_RECURRENCE_FACTOR'
    recurrenceFactor: number
}

interface ISetMonthlyRunDayAction {
    readonly type: 'SET_MONTHLY_RUN_DAY'
    monthlyRunDayId: number
}

interface ISetMonthlyDayOfWeekAction {
    readonly type: 'SET_MONTHLY_DAY_OF_WEEK'
    dayOfWeekId: number
}

interface ISetMonthlyFirstOrLastDayAction {
    readonly type: 'SET_MONTHLY_FIRST_LAST_DAY'
    dayId: number
}

interface ISetSubscriptionFiltersAction {
    readonly type: 'SET_SUBSCRIPTION_FILTERS'
    filters: string
}

interface ISetFilterPanOpenAction {
    readonly type: 'SET_SUBSCRIPTION_FILTER_PANE_OPEN'
    open: boolean
}

interface ISetPageFiltersAction {
    readonly type: 'SET_SUBSCRIPTION_PAGE_FILTERS'
    filters: Filter<FilteredSubscription>[]
}

interface IApplyFiltersAction {
    readonly type: 'APPLY_SUBSCRIPTION_FILTERS'
    pageFilters: Filter<FilteredSubscription>[]
}

interface IClearAllFiltersAction {
    readonly type: 'CLEAR_SUBSCRIPTION_ALL_FILTERS'
}

interface IClearFilterAction {
    readonly type: 'CLEAR_SUBSCRIPTION_FILTER'
    filterName: string
}

interface ISetAdminFilterPaneOpenAction {
    readonly type: 'SET_ADMIN_FILTER_PANE_OPEN'
    open: boolean
}

interface ISetAdminFiltersAction {
    readonly type: 'SET_ADMIN_FILTERS'
    filters: Filter<FilteredSubscription>[]
}

interface IApplyAdminFiltersAction {
    readonly type: 'APPLY_ADMIN_FILTERS'
    filters: Filter<FilteredSubscription>[]
}

interface IClearAdminAllFiltersAction {
    readonly type: 'CLEAR_ADMIN_ALL_FILTERS'
}

interface IClearAdminFilterAction {
    readonly type: 'CLEAR_ADMIN_FILTER'
    filterName: string
}

interface ISetFacilitiesAction {
    readonly type: 'SET_FACILITIES'
    tenantGroup: SubscriptionTenantGroup
    valid: boolean
}

interface ISetToEmailAction {
    readonly type: 'SET_TO_EMAIL'
    value: string
}

interface ISetCCEmailAction {
    readonly type: 'SET_CC_EMAIL'
    value: string
}

interface ISetBCCEmailAction {
    readonly type: 'SET_BCC_EMAIL'
    value: string
}

interface ISetIsForAllUsers {
    readonly type: 'SET_FOR_ALL_USERS'
    value: boolean;
    validateFacilities: boolean
}

export type SubscriptionActions =
    | IGetSubscriptionsAction | IClearAllAction | IValidateSubscriptionAction | IAddSubscriptionAction | IGetSubscriptionAction | IUpdateSubscriptionActive | ISetSubscriptionAction | IGetSubscriptionFormAction | ISetSelectedCategoryAction | ISortSubscriptionsAction | ISaveSubscriptionAction | IDeleteSubscriptionAction | ISetParametersAction | IGetTimezonesAction | IGetAdminSubscriptionsAction | ISortAdminSubscriptionsAction | ISetReportIdAction | ISetAdminSelectedCategoryAction | ISetAdminRowCountAction | ISetAdminCurrentPageAction | ISearchSubscriptionsAction | ISetSubTitleAction | ISetNotesAction | ISetExtensionsAction | ISetJobScheduleAction | ISetRunTimeAction | ISetTimezoneAction | ISetDaysAction | ISetRecurrenceFactorAction | ISetMonthlyRunDayAction | ISetMonthlyDayOfWeekAction | ISetMonthlyFirstOrLastDayAction | ISetSubscriptionFiltersAction | ISetFilterPanOpenAction | ISetPageFiltersAction | IApplyFiltersAction | IClearAllFiltersAction | IClearFilterAction | ISetAdminFilterPaneOpenAction | ISetAdminFiltersAction | IApplyAdminFiltersAction | IClearAdminAllFiltersAction | IClearAdminFilterAction | ISetFacilitiesAction | IValidateAdminSubscriptionAction | ISetToEmailAction | ISetCCEmailAction | ISetBCCEmailAction | ISetIsForAllUsers


const getSubscriptions = () => {
    return function (dispatch: Dispatch<IGetSubscriptionsAction>) {
        dispatch({ type: 'GET_SUBSCRIPTIONS_REQUEST' });
        subscriptionService.getSubscriptions().then((response) => {
            return dispatch({ type: 'GET_SUBSCRIPTIONS_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_SUBSCRIPTIONS_FAILURE', error })
        })
    }
}

const getAdminSubscriptions = (pageSize: number, currentPage: number, filters?: Filter<FilteredAdminSubscription>[], categoryId?: number, sortField?: string[], sortDirection?: string) => {
    return function (dispatch: Dispatch<IGetAdminSubscriptionsAction>) {
        dispatch({ type: 'GET_ADMIN_SUBSCRIPTIONS_REQUEST' });
        subscriptionService.getAdminSubscriptions(pageSize, currentPage, filters, categoryId, sortField, sortDirection).then((response) => {
            return dispatch({ type: 'GET_ADMIN_SUBSCRIPTIONS_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_ADMIN_SUBSCRIPTIONS_FAILURE', error })
        })
    }
}

const getSubscriptionById = (subscriptionId: number) => {
    return function (dispatch: Dispatch<IGetSubscriptionAction>) {
        dispatch({ type: 'GET_SUBSCRIPTION_REQUEST' });
        subscriptionService.getSubscriptionById(subscriptionId).then((response) => {
            return dispatch({ type: 'GET_SUBSCRIPTION_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_SUBSCRIPTION_FAILURE', error })
        })
    }
}


const getSubscriptionFormData = () => {
    return function (dispatch: Dispatch<IGetSubscriptionFormAction>) {
        dispatch({ type: 'GET_SUBSCRIPTION_FORM_REQUEST' });
        subscriptionService.getSubscriptionFormData().then((response) => {
            return dispatch({ type: 'GET_SUBSCRIPTION_FORM_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_SUBSCRIPTION_FORM_FAILURE', error })
        })
    }
}

const setSubscription = (subscription: Subscription) => {
    return function (dispatch: Dispatch<ISetSubscriptionAction>) {
        dispatch({ type: 'SET_SUBSCRIPTION', subscription });
    }
}

const validateSubscription = (fieldsValidation: any, isSubmit: boolean) => {
    return function (dispatch: Dispatch<IValidateSubscriptionAction>) {
        dispatch({ type: 'VALIDATE_SUBSCRIPTION', fieldsValidation, isSubmit });
    }
}

const addSubscription = (subscription: Subscription) => {
    return function (dispatch: Dispatch<IAddSubscriptionAction | NotificationActions>) {
        dispatch({ type: 'ADD_SUBSCRIPTION_REQUEST', subscription });
        subscriptionService.addSubscription(subscription).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `Your report has been subscribed successfully.`, stayVisible: false })
            return dispatch({ type: 'ADD_SUBSCRIPTION_SUCCESS', subscription, newSubscription: response })
        }, (error) => {
            return dispatch({ type: 'ADD_SUBSCRIPTION_FAILURE', subscription, error })
        })
    }
}

const setSelectedCategory = (category: ReportCategory) => {
    return function (dispatch: Dispatch<ISetSelectedCategoryAction>) {
        dispatch({ type: 'SET_SUBSCRIPTION_CATEGORY', category });
    }
}

const setAdminSelectedCategory = (category: ReportCategory) => {
    return function (dispatch: Dispatch<ISetAdminSelectedCategoryAction>) {
        dispatch({ type: 'SET_ADMIN_CATEGORY', category });
    }
}

const sortSubscriptions = (field: keyof FilteredSubscription) => {
    return function (dispatch: Dispatch<ISortSubscriptionsAction>) {
        dispatch({ type: 'SORT_SUBSCRIPTIONS', field });
    }
}

const sortAdminSubscriptions = (field: string[]) => {
    return function (dispatch: Dispatch<ISortAdminSubscriptionsAction>) {
        dispatch({ type: 'SORT_ADMIN_SUBSCRIPTIONS', field });
    }
}

const saveSubscription = (subscription: Subscription) => {
    return function (dispatch: Dispatch<ISaveSubscriptionAction | NotificationActions>) {
        dispatch({ type: 'SAVE_SUBSCRIPTION_REQUEST', subscription });
        subscriptionService.saveSubscription(subscription).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `Your subscripton report has been saved successfully.`, stayVisible: false })
            return dispatch({ type: 'SAVE_SUBSCRIPTION_SUCCESS', subscription })
        }, (error) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Error, message: `Unable to subscribe report. Please try again.`, stayVisible: false })
            return dispatch({ type: 'SAVE_SUBSCRIPTION_FAILURE', subscription, error })
        })
    }
}

const deleteSubscription = (subscriptionId: number, history: any) => {
    return function (dispatch: Dispatch<IDeleteSubscriptionAction>) {
        dispatch({ type: 'DELETE_SUBSCRIPTION_REQUEST', subscriptionId });
        subscriptionService.deleteSubscription(subscriptionId).then((response) => {
            dispatch({ type: 'DELETE_SUBSCRIPTION_SUCCESS', subscriptionId });
            history.push('/reports/subscriptions')
        }, (error) => {
            return dispatch({ type: 'DELETE_SUBSCRIPTION_FAILURE', subscriptionId, error })
        })
    }
}

const setParameters = (filters: string) => {
    return function (dispatch: Dispatch<ISetParametersAction>) {
        dispatch({ type: 'SET_PARAMETERS', parameters: filters });
    }
}


const updateSubscriptionActive = (subscriptionId: number, isActive: boolean) => {
    return function (dispatch: Dispatch<IUpdateSubscriptionActive>) {
        dispatch({ type: 'UPDATE_SUBSCRIPTION_ACTIVE_REQUEST', subscriptionId, isActive });
        subscriptionService.updateSubscriptionActive(subscriptionId, isActive).then((response) => {
            return dispatch({ type: 'UPDATE_SUBSCRIPTION_ACTIVE_SUCCESS', subscriptionId, isActive, payload: response })
        }, (error) => {
            return dispatch({ type: 'UPDATE_SUBSCRIPTION_ACTIVE_FAILURE', subscriptionId, isActive, error })
        })
    }
}

const clearAll = () => {
    return function (dispatch: Dispatch<IClearAllAction>) {
        dispatch({ type: 'CLEAR-ALL' });
    }
}

const setSelectedReportId = (reportId?: number) => {
    return function (dispatch: Dispatch<ISetReportIdAction>) {
        dispatch({ type: 'SET_REPORT_ID', reportId });
    }
}

const setAdminRowCount = (count: number) => {
    return function (dispatch: Dispatch<ISetAdminRowCountAction>) {
        dispatch({ type: 'SET_ROW_COUNT', count });
    }
}

const setAdminCurrentPage = (page: number) => {
    return function (dispatch: Dispatch<ISetAdminCurrentPageAction>) {
        dispatch({ type: 'SET_CURRENT_PAGE', page });
    }
}

const searchSubscriptions = (search: string) => {
    return function (dispatch: Dispatch<ISearchSubscriptionsAction>) {
        dispatch({ type: 'SEARCH_SUBSCRIPTIONS', search });
    }
}

const setSubscriptionSubtitle = (subtitle: string) => {
    return function (dispatch: Dispatch<ISetSubTitleAction>) {
        dispatch({ type: 'SET_SUBTITLE', subtitle });
    }
}

const setSubscriptionNotes = (notes: string) => {
    return function (dispatch: Dispatch<ISetNotesAction>) {
        dispatch({ type: 'SET_NOTES', notes });
    }
}

const setSubscriptionExtensions = (extensions: SubscriptionExtension[]) => {
    return function (dispatch: Dispatch<ISetExtensionsAction>) {
        dispatch({ type: 'SET_EXTENSIONS', extensions });
    }
}

const setSubscriptionJobSchedule = (jobSchedule: JobSchedule) => {
    return function (dispatch: Dispatch<ISetJobScheduleAction>) {
        dispatch({ type: 'SET_JOB_SCHEDULE', jobSchedule });
    }
}

const setSubscriptionRunTime = (runTime: string) => {
    return function (dispatch: Dispatch<ISetRunTimeAction>) {
        dispatch({ type: 'SET_RUN_TIME', runTime });
    }
}

const setSubscriptionTimezone = (timezoneId: number) => {
    return function (dispatch: Dispatch<ISetTimezoneAction>) {
        dispatch({ type: 'SET_TIMEZONE', timezoneId });
    }
}

const setSubscriptionDays = (days: JobScheduleDaysOfWeek[], excludeWeekends: boolean, valid: boolean) => {
    return function (dispatch: Dispatch<ISetDaysAction>) {
        dispatch({ type: 'SET_DAYS', days, excludeWeekends, valid });
    }
}

const setSubscriptionRecurrenceFactor = (recurrenceFactor: number) => {
    return function (dispatch: Dispatch<ISetRecurrenceFactorAction>) {
        dispatch({ type: 'SET_RECURRENCE_FACTOR', recurrenceFactor });
    }
}

const setSubscriptionMonthlyRunDay = (monthlyRunDayId: number) => {
    return function (dispatch: Dispatch<ISetMonthlyRunDayAction>) {
        dispatch({ type: 'SET_MONTHLY_RUN_DAY', monthlyRunDayId });
    }
}

const setSubscriptionMonthlyDayOfWeek = (dayOfWeekId: number) => {
    return function (dispatch: Dispatch<ISetMonthlyDayOfWeekAction>) {
        dispatch({ type: 'SET_MONTHLY_DAY_OF_WEEK', dayOfWeekId });
    }
}

const setSubscriptionMonthlyFirstOrLastDay = (dayId: number) => {
    return function (dispatch: Dispatch<ISetMonthlyFirstOrLastDayAction>) {
        dispatch({ type: 'SET_MONTHLY_FIRST_LAST_DAY', dayId });
    }
}

const seSubscriptiontFilters = (filters: string) => {
    return function (dispatch: Dispatch<ISetSubscriptionFiltersAction>) {
        dispatch({ type: 'SET_SUBSCRIPTION_FILTERS', filters });
    }
}

const setFilterPanOpen = (open: boolean) => {
    return function (dispatch: Dispatch<ISetFilterPanOpenAction>) {
        dispatch({ type: 'SET_SUBSCRIPTION_FILTER_PANE_OPEN', open });
    }
}

const setPageFilters = (filters: Filter<FilteredSubscription>[]) => {
    return function (dispatch: Dispatch<ISetPageFiltersAction>) {
        dispatch({ type: 'SET_SUBSCRIPTION_PAGE_FILTERS', filters });
    }
}

const applyFilters = (pageFilters: Filter<FilteredSubscription>[]) => {
    return function (dispatch: Dispatch<IApplyFiltersAction>) {
        dispatch({ type: 'APPLY_SUBSCRIPTION_FILTERS', pageFilters });
    }
}

const clearAllFilters = () => {
    return function (dispatch: Dispatch<IClearAllFiltersAction>) {
        dispatch({ type: 'CLEAR_SUBSCRIPTION_ALL_FILTERS' });
    }
}

const clearFilter = (filterName: string) => {
    return function (dispatch: Dispatch<IClearFilterAction>) {
        dispatch({ type: 'CLEAR_SUBSCRIPTION_FILTER', filterName });
    }
}

const setAdminFilterPanOpen = (open: boolean) => {
    return function (dispatch: Dispatch<ISetAdminFilterPaneOpenAction>) {
        dispatch({ type: 'SET_ADMIN_FILTER_PANE_OPEN', open });
    }
}

const setAdminPageFilters = (filters: Filter<FilteredSubscription>[]) => {
    return function (dispatch: Dispatch<ISetAdminFiltersAction>) {
        dispatch({ type: 'SET_ADMIN_FILTERS', filters });
    }
}

const applyAdminFilters = (filters: Filter<FilteredSubscription>[]) => {
    return function (dispatch: Dispatch<IApplyAdminFiltersAction>) {
        dispatch({ type: 'APPLY_ADMIN_FILTERS', filters });
    }
}

const clearAdminAllFilters = () => {
    return function (dispatch: Dispatch<IClearAdminAllFiltersAction>) {
        dispatch({ type: 'CLEAR_ADMIN_ALL_FILTERS' });
    }
}

const clearAdminFilter = (filterName: string) => {
    return function (dispatch: Dispatch<IClearAdminFilterAction>) {
        dispatch({ type: 'CLEAR_ADMIN_FILTER', filterName });
    }
}

const setSubscriptionTenantGroup = (tenantGroup: SubscriptionTenantGroup, valid: boolean) => {
    return function (dispatch: Dispatch<ISetFacilitiesAction>) {
        dispatch({ type: 'SET_FACILITIES', tenantGroup: tenantGroup, valid });
    }
}

const validateAdminSubscription = (fieldsValidation: any, isSubmit: boolean) => {
    return function (dispatch: Dispatch<IValidateAdminSubscriptionAction>) {
        dispatch({ type: 'VALIDATE_ADMIN_SUBSCRIPTION', fieldsValidation, isSubmit });
    }
}

const setSubscriptionToEmail = (value: string) => {
    return function (dispatch: Dispatch<ISetToEmailAction>) {
        dispatch({ type: 'SET_TO_EMAIL', value });
    }
}

const setSubscriptionCCEmail = (value: string) => {
    return function (dispatch: Dispatch<ISetCCEmailAction>) {
        dispatch({ type: 'SET_CC_EMAIL', value });
    }
}

const setSubscriptionBCCEmail = (value: string) => {
    return function (dispatch: Dispatch<ISetBCCEmailAction>) {
        dispatch({ type: 'SET_BCC_EMAIL', value });
    }
}

const setIsForAllUsers = (value: boolean, validateFacilities: boolean) => {
    return function (dispatch: Dispatch<ISetIsForAllUsers>) {
        dispatch({ type: 'SET_FOR_ALL_USERS', value, validateFacilities });
    }
}

export const subscriptionActions = {
    getSubscriptions,
    setSubscription,
    sortSubscriptions,
    validateSubscription,
    addSubscription,
    saveSubscription,
    deleteSubscription,
    getSubscriptionById,
    getSubscriptionFormData,
    setSelectedCategory,
    setParameters,
    updateSubscriptionActive,
    getAdminSubscriptions,
    sortAdminSubscriptions,
    setAdminSelectedCategory,
    setSelectedReportId,
    setAdminRowCount,
    setAdminCurrentPage,
    searchSubscriptions,
    setSubscriptionSubtitle,
    setSubscriptionNotes,
    setSubscriptionExtensions,
    setSubscriptionJobSchedule,
    setSubscriptionRunTime,
    setSubscriptionTimezone,
    setSubscriptionDays,
    setSubscriptionRecurrenceFactor,
    setSubscriptionMonthlyRunDay,
    setSubscriptionMonthlyDayOfWeek,
    setSubscriptionMonthlyFirstOrLastDay,
    seSubscriptiontFilters,
    setFilterPanOpen,
    setPageFilters,
    applyFilters,
    clearAllFilters,
    clearFilter,
    setAdminFilterPanOpen,
    setAdminPageFilters,
    applyAdminFilters,
    clearAdminAllFilters,
    clearAdminFilter,
    setSubscriptionTenantGroup,
    validateAdminSubscription,
    setSubscriptionToEmail,
    setSubscriptionCCEmail,
    setSubscriptionBCCEmail,
    setIsForAllUsers,
    clearAll
}