import Dialog from '@mui/material/Dialog';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import "./Loading.scss";

function Loading() {
  
  return (
      <Dialog open={true} hideBackdrop={false}>
        <div id="loading-container" className="xsmall-text">
          <Dots speed={1} size={20} color="#00aaa9" />
          Loading
        </div>
      </Dialog>
  );
}

export default Loading;
