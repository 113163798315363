import { UserReportHistory } from "../models/userReportHistory";
import { AppState } from "../../redux/reducers";
import {memoize} from "proxy-memoize";
import _orderBy from "lodash/orderBy";
import { compliesWithFilter, validateReportHistory } from "../models/filter";


export interface FilteredFileStorage extends UserReportHistory {
    name: string;
    nameLowered: string;
    startDateUTC?: Date;
    isPartial: boolean;
    isComplete?:boolean;
    isRunning?:boolean;
    isQueued?:boolean;
}

const getAllUserReportHistory = memoize((userReportHistory: UserReportHistory[]): FilteredFileStorage[] => {
    return userReportHistory.map(reportHistory => {
        return {
            ...reportHistory,
            name: `${reportHistory.userReport?.report?.name ?? reportHistory.subscription?.report?.name}${reportHistory.subscription?.name ? ` - ${reportHistory.subscription.name}` : ''}`,
            nameLowered: `${reportHistory.userReport?.report?.name.toLowerCase() ?? reportHistory.subscription?.report?.name.toLowerCase()}${reportHistory.subscription?.name ? ` - ${reportHistory.subscription?.name.toLowerCase()}` : ''}`,
            startDateUTC: reportHistory.startDate && reportHistory.startDate && new Date(`${reportHistory.startDate}Z`),
            isPartial: reportHistory.status === "ERROR",
            isRunning: reportHistory.status === "RUNNING",
            isComplete: reportHistory.status === "COMPLETE",
            isQueued: reportHistory.status === "QUEUED",
        }
    });
});

export const isUserReportHistoryLoading = (state: AppState) => state.reportReducer.userReportHistoryLoading;

export const selectFilteredReportHistory = (state: AppState) => {
    const { userReportHistory, selectedUserReportHistoryCategory, reportHistorySortField, reportHistorySortDirection, reportHistorySearchKeyword, fileStorageFilters } = state.reportReducer;

    const filtered = getAllUserReportHistory(userReportHistory).filter(reportHistory => 
        validateReportHistory(reportHistory,selectedUserReportHistoryCategory) && (!reportHistorySearchKeyword || reportHistorySearchKeyword.length === 0 || reportHistory.nameLowered.includes(reportHistorySearchKeyword.toLowerCase())) && compliesWithFilter(reportHistory, fileStorageFilters));
    return _orderBy(filtered, [reportHistorySortField], [reportHistorySortDirection]);
}

export const selectFilteredReportHistoryCount = (state: AppState) => {
    const { count} = state.reportReducer;
    return count;
}

export const getReportHistoryExportShare = (state: AppState) => state.reportReducer.userReportHistoryExportShare;
export const getReportSharedUsers = (state: AppState) => state.reportReducer.reportSharedUsers;


export const selectSelectedUserReportHistoryCategory = (state: AppState) => state.reportReducer.selectedUserReportHistoryCategory;

export const selectBlob = (state: AppState) => state.reportReducer.blob;
export const selectFileName = (state: AppState) => state.reportReducer.fileName;

export const selectReportHistorySearchKeyword = (state: AppState) => state.reportReducer.reportHistorySearchKeyword;

export const selectReportHistorySortField = (state: AppState) => state.reportReducer.reportHistorySortField;
export const selectReportHistorySortDirection = (state: AppState) => state.reportReducer.reportHistorySortDirection;

export const selectFilterPanOpen = (state: AppState) => state.reportReducer.fileStorageFilterPaneOpen;
export const selectFileStorageFilters = (state: AppState) => state.reportReducer.fileStorageFilters;
export const selectAppliedFiltersCount = (state: AppState) => { return state.reportReducer.fileStorageFilters.filter((f) => f.applied === true).length };