import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Notification.scss";
import { INotificationType } from "../../redux/models/notificationType";
import {
  selectNotificationColor,
  selectNotificationBorderColor,
  selectNotificationIsVisible,
  selectNotificationMessage,
  selectNotificationStayVisible,
  selectNotificationType,
} from "../../redux/selectors/notification.selector";
import { CircularProgress, Typography } from "@mui/material";

function Notification() {
  const notificationType = useSelector(selectNotificationType);
  const notificationColor = useSelector(selectNotificationColor);
  const notificationBorderColor = useSelector(selectNotificationBorderColor);
  const notificationMessage = useSelector(selectNotificationMessage);
  const stayVisible = useSelector(selectNotificationStayVisible);
  const visibilityRefresh = useSelector(selectNotificationIsVisible);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleClose = () => {
    console.log("closing notification");
    setIsVisible(false);
  };

  const getIcon = (): JSX.Element => {
    if (
      notificationType === INotificationType.Warning ||
      notificationType === INotificationType.Info
    ) {
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className={getNotificationClass(notificationType)}>
      <g id="Search results for Info - Flaticon-12 1" clipPath="url(#clip0_3795_89873)">
      <g id="Group">
      <path id="Vector-Circle" d="M12 0.5C5.36761 0.5 0 5.867 0 12.5C0 19.1323 5.367 24.5 12 24.5C18.6324 24.5 24 19.133 24 12.5C24 5.8677 18.633 0.5 12 0.5ZM13.2323 17.2635C13.2323 17.6426 12.6795 18.0216 12.0002 18.0216C11.2894 18.0216 10.784 17.6426 10.784 17.2635V11.2453C10.784 10.8031 11.2895 10.5029 12.0002 10.5029C12.6795 10.5029 13.2323 10.8031 13.2323 11.2453V17.2635ZM12.0003 9.04981C11.2737 9.04981 10.7051 8.51277 10.7051 7.91248C10.7051 7.31225 11.2737 6.791 12.0003 6.791C12.7111 6.791 13.2798 7.31225 13.2798 7.91248C13.2798 8.51277 12.711 9.04981 12.0003 9.04981Z" fill="#FFF"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3795_89873">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
      </svg>
    } else if (
      notificationType === INotificationType.Unauthorized ||
      notificationType === INotificationType.Error
    ) {
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className={getNotificationClass(notificationType)}>
      <g id="Search results for Cancel - Flaticon-12 1" clipPath="url(#clip0_3795_89859)">
      <g id="Group">
      <path id="Vector" d="M12 24.4921C18.6274 24.4921 24 19.123 24 12.4999C24 5.87687 18.6274 0.507812 12 0.507812C5.37258 0.507812 0 5.87687 0 12.4999C0 19.123 5.37258 24.4921 12 24.4921Z" fill="#E21B1B"/>
      <path id="Vector_2" d="M6.33838 8.69238L8.1942 6.83649L17.663 16.305L15.8072 18.1609L6.33838 8.69238Z" fill="white"/>
      <path id="Vector_3" d="M6.33765 16.3047L15.8062 6.83584L17.6621 8.69167L8.19354 18.1605L6.33765 16.3047Z" fill="white"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_3795_89859">
      <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
      </svg>;
    } else if (notificationType === INotificationType.Loading) {
      return (
        <div>
          <CircularProgress size={20} className={"n-progress "+ getNotificationClass(notificationType)} />
        </div>
      );
    } else {
      return <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none" className={getNotificationClass(notificationType)}>
      <g id="CheckCircle">
      <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M14 25.6998C16.9704 25.6998 19.8192 24.5198 21.9196 22.4194C24.02 20.319 25.2 17.4702 25.2 14.4998C25.2 11.5294 24.02 8.68062 21.9196 6.58021C19.8192 4.4798 16.9704 3.2998 14 3.2998C11.0296 3.2998 8.1808 4.4798 6.08039 6.58021C3.97998 8.68062 2.79999 11.5294 2.79999 14.4998C2.79999 17.4702 3.97998 20.319 6.08039 22.4194C8.1808 24.5198 11.0296 25.6998 14 25.6998ZM19.1898 12.6896C19.4448 12.4256 19.5859 12.0719 19.5827 11.7048C19.5795 11.3378 19.4323 10.9866 19.1727 10.7271C18.9132 10.4675 18.562 10.3203 18.195 10.3171C17.8279 10.3139 17.4742 10.455 17.2102 10.71L12.6 15.3202L10.7898 13.51C10.5257 13.255 10.1721 13.1139 9.80503 13.1171C9.43795 13.1203 9.08681 13.2675 8.82724 13.5271C8.56767 13.7866 8.42043 14.1378 8.41724 14.5048C8.41405 14.8719 8.55517 15.2256 8.81019 15.4896L11.6102 18.2896C11.8727 18.5521 12.2288 18.6995 12.6 18.6995C12.9712 18.6995 13.3272 18.5521 13.5898 18.2896L19.1898 12.6896Z" fill="#149E58"/>
      </g>
      </svg>
    }
  };

  const getNotificationClass=(notificationType: INotificationType | undefined,)=>{
    switch (notificationType) {
      case 0:
        return 'error'
      case 1:
        return 'warning'
      case 2:
        return 'unauthorized'
      case 3:
        return 'forbidden'
      case 4:
        return 'success'
      case 5:
        return 'info'
      case 6:
        return 'loading'
      default:
        break;
    }
  }

  useEffect(() => {
    if (isVisible && !stayVisible) {
      let timer1 = setTimeout(handleClose, 6 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, stayVisible]);

  useEffect(() => {
    if (visibilityRefresh > 0) setIsVisible(true);
  }, [visibilityRefresh]);

  return (
    <div
      id="notification-container"
      className={isVisible ? "notification-container" : "notification-container hidden"}
      style={{ backgroundColor: notificationColor,borderColor:notificationBorderColor }}
    >
      {getIcon()}
      <span data-testid="not-text" className={"message "+getNotificationClass(notificationType)}>{notificationMessage}</span>
      <Typography className={getNotificationClass(notificationType)} onClick={() => handleClose()} id="close-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
          <g id="cancel-01 3">
          <path id="Vector" d="M15.8333 4.6665L4.16666 16.3332" stroke="#09090A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path id="Vector_2" d="M4.16666 4.6665L15.8333 16.3332" stroke="#09090A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </g>
        </svg>
       </Typography>
    </div>
  );
}

export default Notification;
