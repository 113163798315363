import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  selectSelectedSubscription,
  selectFormData,
  selectFieldsValid,
} from "../../redux/selectors/subscription.selector";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import { SubscriptionTenantGroup } from "../../redux/models/subscriptionTenantGroup";
import { SubscriptionTenant } from "../../redux/models/subscriptionTenant";
import { Grid } from "@mui/material";

const InputLarge = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    padding: "6px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

export interface SubsetSubscribersProps {
  action: "add" | "edit";
  subscriberType: number;
}

function SubsetSubscribers({ action, subscriberType }: SubsetSubscribersProps) {
  const dispatch = useDispatch();

  const [selectedPortfolio, setSelectedPortfolio] = React.useState(1);
  const [selectedRegion, setSelectedRegion] = React.useState(0);
  const [selectedFacilityIds, setSelectedFacilityIds] = React.useState<
    number[]
  >([]);

  const selectedSubscription = useSelector(selectSelectedSubscription);
  const formData = useSelector(selectFormData);
  const fieldsValid = useSelector(selectFieldsValid);

  useEffect(() => {
    if (action === "edit" && subscriberType === 1) {
      const selectedFacilities =
        selectedSubscription.subscriptionTenantGroup?.subscriptionTenants?.map(
          (f) => f.tenantId
        );
      setSelectedFacilityIds(selectedFacilities ?? []);

      const facilities = formData.portfolios
        ?.map((p) =>
          p.regions.map((r) =>
            r.facilities
              .filter((f) => f.tenantId === selectedFacilities?.[0])
              .map((f) => {
                return f;
              })
          )
        )
        .flat()
        .flat();

      const regionId = facilities?.[0]?.parentId;
      setSelectedRegion(regionId ?? 0);

      const regions = formData.portfolios
        ?.map((p) =>
          p.regions
            .filter((r) => r.tenantId === regionId)
            .map((r) => {
              return r;
            })
        )
        .flat();

      setSelectedPortfolio(regions?.[0]?.parentId ?? 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFacilityChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const ids = typeof value === "string" ? value.split(",") : value;
    setSelectedFacilityIds(ids);

    const valid =
      subscriberType !== 1 || (subscriberType === 1 && ids.length > 0);

    var tenants = formData.portfolios
      ?.filter((x) => x.tenantId === selectedPortfolio)[0]
      .regions.filter(
        (x) =>
          x.tenantId ===
          (selectedRegion === 0
            ? formData.portfolios?.filter(
                (x) => x.tenantId === selectedPortfolio
              )[0].regions[0].tenantId
            : selectedRegion)
      )[0]
      .facilities.filter((x) => ids.includes(x.tenantId))
      .map((x) => {
        return {
          tenantId: x.tenantId,
        } as SubscriptionTenant;
      });

    if (tenants) {
      var tenantGroup = {
        subscriptionTenants: tenants,
      } as SubscriptionTenantGroup;
      dispatch(
        subscriptionActions.setSubscriptionTenantGroup(tenantGroup, valid)
      );
    }
  };

  return (
    <div>
      <Grid container spacing={1}>
              <Grid item xs={3.5} sm={3.2} md={3.6} lg={3.6} className="select-lable">
              Portfolio
              </Grid>
              <Grid item xs={6}  sm={6} md={6} lg={6}>
              <Select
            className="select-xlarge"
            value={selectedPortfolio}
            onChange={(event) =>
              setSelectedPortfolio(event.target.value as number)
            }
            input={<InputLarge />}
            IconComponent={KeyboardArrowDownIcon}
          >
            {formData.portfolios?.map((x) => {
              return (
                <MenuItem
                  key={x.tenantId}
                  value={x.tenantId}
                  className="medium-text blue-text"
                >
                  {x.displayName}
                </MenuItem>
              );
            })}
          </Select>
              </Grid>
              </Grid>
              <Grid container spacing={1}>
              <Grid item xs={3.5} sm={3.2} md={3.6} lg={3.6} className="select-lable">
              Region
              </Grid>
              <Grid item xs={6}  sm={6} md={6} lg={6}>
              <Select
            className="select-xlarge"
            value={
              selectedRegion === 0
                ? formData.portfolios?.find(
                    (x) => x.tenantId === selectedPortfolio
                  )?.regions[0].tenantId
                : selectedRegion
            }
            onChange={(event) =>
              setSelectedRegion(event.target.value as number)
            }
            input={<InputLarge />}
            IconComponent={KeyboardArrowDownIcon}
          >
            {formData.portfolios
              ?.find((x) => x.tenantId === selectedPortfolio)
              ?.regions.map((x) => {
                return (
                  <MenuItem
                    key={x.tenantId}
                    value={x.tenantId}
                    className="medium-text blue-text"
                  >
                    {x.displayName}
                  </MenuItem>
                );
              })}
          </Select>
              </Grid>
              </Grid>
              <Grid container spacing={1}>
              <Grid item xs={3.5} sm={3.2} md={3.6} lg={3.6} className="select-lable">
              Facilities
              </Grid>
              <Grid item  xs={6}  sm={6} md={6} lg={6}>
              <Select
            className="select-xlarge"
            value={selectedFacilityIds}
            onChange={handleFacilityChange}
            input={<InputLarge />}
            IconComponent={KeyboardArrowDownIcon}
            renderValue={(selected) =>
              formData.portfolios
                ?.find((x) => x.tenantId === selectedPortfolio)
                ?.regions.find(
                  (x) =>
                    x.tenantId ===
                    (selectedRegion === 0
                      ? formData.portfolios?.find(
                          (x) => x.tenantId === selectedPortfolio
                        )?.regions[0].tenantId
                      : selectedRegion)
                )
                ?.facilities?.filter((x) =>
                  selectedFacilityIds.includes(x.tenantId)
                )
                .map((x) => x.displayName)
                .join(", ")
            }
            multiple
          >
            {formData.portfolios
              ?.find((x) => x.tenantId === selectedPortfolio)
              ?.regions.find(
                (x) =>
                  x.tenantId ===
                  (selectedRegion === 0
                    ? formData.portfolios?.filter(
                        (x) => x.tenantId === selectedPortfolio
                      )[0].regions[0].tenantId
                    : selectedRegion)
              )
              ?.facilities?.map((x) => {
                return (
                  <MenuItem
                    key={x.tenantId}
                    value={x.tenantId}
                    className="medium-text blue-text"
                  >
                    <Checkbox
                      checked={selectedFacilityIds.indexOf(x.tenantId) > -1}
                    />
                    {x.displayName}
                  </MenuItem>
                );
              })}
          </Select>
              </Grid>
              </Grid>
      <div className="facility-error">
        {fieldsValid.showFacilitiesError && (
          <div className="small-error new-line">Facility is required</div>
        )}
      </div>
    </div>
  );
}

export default SubsetSubscribers;
