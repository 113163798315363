export interface UpdateUserReportAccessModel {
    action: UpdateUserReportAccessActionEnum;
    userIds: number[];
    reportIds: number[];
}

export enum UpdateUserReportAccessActionEnum {
    Update = 0,
    Add = 1,
    Remove = 2
}