import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportActions } from "../../redux/actions/report.actions";
import { useLocation } from "react-router-dom";
import "./ReportCatalog.scss";
import "../Common/Common.scss";
import {
  isReportsLoading,
  selectReportSearchKeyword,
  FilteredReport,
} from "../../redux/selectors/report.selector";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {  Chip, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { SearchTextField } from "../Common/Utils";
import ViewReportPage from "../ViewReport/ViewReportPage";
import { Report } from "../../redux/models/report";
import NoReportsFound from "./NoReportsFound";
import DeleteReportDialog from "../DeleteReportDialog/DeleteReportDialog";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddEditReportDialog from "./AddReport/AddReport";

interface IReportTableComponentProps {
  unfavoriteDialogOpen: boolean;
  setUnfavoriteDialogOpen: (isOpen: boolean) => void;
  setInfoDialogOpen: (open: boolean) => void;
  reportList: FilteredReport[];
}

const ReportTableComponent = ({
  setInfoDialogOpen,
  reportList,
}: IReportTableComponentProps) => {
  const dispatch = useDispatch();
  const ref = useRef<any>(null);
  const reportsLoading = useSelector(isReportsLoading);
  const isAdmin = useSelector(isUserAdministrator);
  const searchKeyword = useSelector(selectReportSearchKeyword);
  const location = useLocation();
  let favoriteParam = new URLSearchParams(location.search).get("favorites");
  const [paginatedReports,setPaginatedReports]=useState<FilteredReport[]>(reportList.filter(r=>r.reportTypeId==2));
  const [viewType,setViewType]=useState<string>("table");
  const [isReportView,setIsReportView]=useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addReportDialogOpen,setAddReportDialogOpen]=useState<boolean>(false);

  const searchReports = (keyword: string) =>
    dispatch(reportActions.searchReports(keyword));
  const clearSearch = () => dispatch(reportActions.searchReports(""));


    useEffect(() => {
        setPaginatedReports(reportList.filter(r=>r.reportTypeId==2));

    }, [reportList]);
    const handleViewType = ( event: React.MouseEvent<HTMLElement>,
      viewType: string,) =>{
    setViewType(viewType);
    // dispatch(reportActions.setTileView(viewType=='tile'));
  }

  const handleRun = (event:any,report:Report)=>{
    setIsReportView(true);
    setPaginatedReports([report as FilteredReport]);
    clearSearch();
    dispatch(reportActions.setSelectedReport(report));
  }

  const handleDelete = (event:any,report:Report)=>{
    setPaginatedReports([report as FilteredReport]);
    if(report?.reportId){
      dispatch(reportActions.setSelectedReport(report));
      setDeleteDialogOpen(true);
    }
  }

  const handleEdit = (event:any,report:Report)=>{
    if(report?.reportId){
    setPaginatedReports([report as FilteredReport]);
    setAddReportDialogOpen(true);
        handleClose();
        dispatch(reportActions.setSelectedReport(report));
    }
  }

  const handleClose = () => {
    console.log(anchorEl);
    setAnchorEl(null);
};

  return (
    <>
    {addReportDialogOpen&&(
            <AddEditReportDialog
            open={true}
            setOpen={setAddReportDialogOpen}
            action='edit'
            type="report"
            data-testid="addeditelement"
            selectedCategoryId={paginatedReports[0]?.categoryId}
          />)
    }
    {deleteDialogOpen && (
            <DeleteReportDialog
                open={deleteDialogOpen}
                handleMenuClose={handleClose}
                isAdmin={isAdmin||false}
                setOpen={setDeleteDialogOpen}
            />
        )}
    {isReportView?<div className="view-report">
    <Chip icon={<KeyboardBackspaceIcon />} label="Back To List" onClick={()=>setIsReportView(false)}
            className="back-btn" data-testid="handle-back"/>
      <ViewReportPage
           selectedReport={paginatedReports[0]}
           ref={ref}
            reportId={paginatedReports[0].reportId.toString()}/>
        
    </div>
    :<div id="report-list-container" className="report-list-container">
        <div id="type-outer-container">
            <div className="filter-section">
            <div className="search-container">
            <FormControl sx={{ m: 1 }}>
              <SearchTextField
                id="search-bar"
                value={searchKeyword}
                color="primary"
                size="small"
                onChange={(event) => searchReports(event.target.value)}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={clearSearch}
                        edge="end"
                      >
                        {searchKeyword.length > 0 ? (
                          <ClearIcon />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={searchKeyword.length === 0 ? "Search" : ""}
              />
            </FormControl>
            </div>
            
            <div className="view-container">
            
            <ToggleButtonGroup
      exclusive
      onChange={handleViewType}
      value={viewType}
      aria-label="text alignment"
    >
      <ToggleButton value="table" aria-label="left aligned">
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M21 12L9 12M21 6L9 6M21 18L9 18M5 12C5 12.5523 4.55228 13 4 13C3.44772 13 3 12.5523 3 12C3 11.4477 3.44772 11 4 11C4.55228 11 5 11.4477 5 12ZM5 6C5 6.55228 4.55228 7 4 7C3.44772 7 3 6.55228 3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6ZM5 18C5 18.5523 4.55228 19 4 19C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17C4.55228 17 5 17.4477 5 18Z" stroke="#2D4F6C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </ToggleButton>
      <ToggleButton value="tile" aria-label="centered">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M8.4 3H4.6C4.03995 3 3.75992 3 3.54601 3.10899C3.35785 3.20487 3.20487 3.35785 3.10899 3.54601C3 3.75992 3 4.03995 3 4.6V8.4C3 8.96005 3 9.24008 3.10899 9.45399C3.20487 9.64215 3.35785 9.79513 3.54601 9.89101C3.75992 10 4.03995 10 4.6 10H8.4C8.96005 10 9.24008 10 9.45399 9.89101C9.64215 9.79513 9.79513 9.64215 9.89101 9.45399C10 9.24008 10 8.96005 10 8.4V4.6C10 4.03995 10 3.75992 9.89101 3.54601C9.79513 3.35785 9.64215 3.20487 9.45399 3.10899C9.24008 3 8.96005 3 8.4 3Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.4 3H15.6C15.0399 3 14.7599 3 14.546 3.10899C14.3578 3.20487 14.2049 3.35785 14.109 3.54601C14 3.75992 14 4.03995 14 4.6V8.4C14 8.96005 14 9.24008 14.109 9.45399C14.2049 9.64215 14.3578 9.79513 14.546 9.89101C14.7599 10 15.0399 10 15.6 10H19.4C19.9601 10 20.2401 10 20.454 9.89101C20.6422 9.79513 20.7951 9.64215 20.891 9.45399C21 9.24008 21 8.96005 21 8.4V4.6C21 4.03995 21 3.75992 20.891 3.54601C20.7951 3.35785 20.6422 3.20487 20.454 3.10899C20.2401 3 19.9601 3 19.4 3Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M19.4 14H15.6C15.0399 14 14.7599 14 14.546 14.109C14.3578 14.2049 14.2049 14.3578 14.109 14.546C14 14.7599 14 15.0399 14 15.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V15.6C21 15.0399 21 14.7599 20.891 14.546C20.7951 14.3578 20.6422 14.2049 20.454 14.109C20.2401 14 19.9601 14 19.4 14Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.4 14H4.6C4.03995 14 3.75992 14 3.54601 14.109C3.35785 14.2049 3.20487 14.3578 3.10899 14.546C3 14.7599 3 15.0399 3 15.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V15.6C10 15.0399 10 14.7599 9.89101 14.546C9.79513 14.3578 9.64215 14.2049 9.45399 14.109C9.24008 14 8.96005 14 8.4 14Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </ToggleButton>
      
    </ToggleButtonGroup>
    
            </div>
                
            </div>
        </div>
        
        <div className="table-container">
          {
              paginatedReports.map((report) => {
                return report.reportTypeId==2?(
                  <div className="tbl-row" key={report.reportId}>
            <Grid container spacing={2}>
                <Grid item lg={6} md={6} xl={6} xs={6} sm={6}>
                    <h6>{report.name}</h6>
                    <small>{report.description}</small>
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={6} sm={6} className="action-block">
                    <button className="action-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                     <path d="M11.2827 3.45332C11.5131 2.98638 11.6284 2.75291 11.7848 2.67831C11.9209 2.61341 12.0791 2.61341 12.2152 2.67831C12.3717 2.75291 12.4869 2.98638 12.7174 3.45332L14.9041 7.88328C14.9721 8.02113 15.0061 8.09006 15.0558 8.14358C15.0999 8.19096 15.1527 8.22935 15.2113 8.25662C15.2776 8.28742 15.3536 8.29854 15.5057 8.32077L20.397 9.03571C20.9121 9.11099 21.1696 9.14863 21.2888 9.27444C21.3925 9.38389 21.4412 9.5343 21.4215 9.68377C21.3988 9.85558 21.2124 10.0372 20.8395 10.4004L17.3014 13.8464C17.1912 13.9538 17.136 14.0076 17.1004 14.0715C17.0689 14.128 17.0487 14.1902 17.0409 14.2545C17.0321 14.3271 17.0451 14.403 17.0711 14.5547L17.906 19.4221C17.994 19.9355 18.038 20.1922 17.9553 20.3445C17.8833 20.477 17.7554 20.57 17.6071 20.5975C17.4366 20.6291 17.2061 20.5078 16.7451 20.2654L12.3724 17.9658C12.2361 17.8942 12.168 17.8584 12.0962 17.8443C12.0327 17.8318 11.9673 17.8318 11.9038 17.8443C11.832 17.8584 11.7639 17.8942 11.6277 17.9658L7.25492 20.2654C6.79392 20.5078 6.56341 20.6291 6.39297 20.5975C6.24468 20.57 6.11672 20.477 6.04474 20.3445C5.962 20.1922 6.00603 19.9355 6.09407 19.4221L6.92889 14.5547C6.95491 14.403 6.96793 14.3271 6.95912 14.2545C6.95132 14.1902 6.93111 14.128 6.89961 14.0715C6.86402 14.0076 6.80888 13.9538 6.69859 13.8464L3.16056 10.4004C2.78766 10.0372 2.60121 9.85558 2.57853 9.68377C2.55879 9.5343 2.60755 9.38389 2.71125 9.27444C2.83044 9.14863 3.08797 9.11099 3.60304 9.03571L8.49431 8.32077C8.64642 8.29854 8.72248 8.28742 8.78872 8.25662C8.84736 8.22935 8.90016 8.19096 8.94419 8.14358C8.99391 8.09006 9.02793 8.02113 9.09597 7.88328L11.2827 3.45332Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <Typography>Mark as Favorite</Typography>
                    </button>
                    <div className="vr"></div>
                    <button className="action-container">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M19 11H5M19 11C19.5304 11 20.0391 11.2107 20.4142 11.5858C20.7893 11.9609 21 12.4696 21 13V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V13C3 12.4696 3.21071 11.9609 3.58579 11.5858C3.96086 11.2107 4.46957 11 5 11M19 11V9C19 8.46957 18.7893 7.96086 18.4142 7.58579C18.0391 7.21071 17.5304 7 17 7M5 11V9C5 8.46957 5.21071 7.96086 5.58579 7.58579C5.96086 7.21071 6.46957 7 7 7M17 7V5C17 4.46957 16.7893 3.96086 16.4142 3.58579C16.0391 3.21071 15.5304 3 15 3H9C8.46957 3 7.96086 3.21071 7.58579 3.58579C7.21071 3.96086 7 4.46957 7 5V7M17 7H7" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    <Typography>View Subscription</Typography>
                    </button>
                    <div className="vr"></div>
                    <button className="action-container run-report" data-testid="handle-run" onClick={(e)=>handleRun(e,report)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9.5 8.96533C9.5 8.48805 9.5 8.24941 9.59974 8.11618C9.68666 8.00007 9.81971 7.92744 9.96438 7.9171C10.1304 7.90525 10.3311 8.03429 10.7326 8.29239L15.4532 11.3271C15.8016 11.551 15.9758 11.663 16.0359 11.8054C16.0885 11.9298 16.0885 12.0702 16.0359 12.1946C15.9758 12.337 15.8016 12.449 15.4532 12.6729L10.7326 15.7076C10.3311 15.9657 10.1304 16.0948 9.96438 16.0829C9.81971 16.0726 9.68666 15.9999 9.59974 15.8838C9.5 15.7506 9.5 15.512 9.5 15.0347V8.96533Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    <Typography>Run</Typography>
                    </button>
                    {isAdmin&&(
                      <>
                        <div className="vr"></div>
                        <button className="action-container run-report" data-testid="handle-edit" onClick={(e)=>handleEdit(e,report)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M2.5 22.0001L8.04927 19.8657C8.40421 19.7292 8.58168 19.661 8.74772 19.5718C8.8952 19.4927 9.0358 19.4013 9.16804 19.2987C9.31692 19.1831 9.45137 19.0487 9.72028 18.7798L21 7.50006C22.1046 6.39549 22.1046 4.60463 21 3.50006C19.8955 2.39549 18.1046 2.39549 17 3.50006L5.72028 14.7798C5.45138 15.0487 5.31692 15.1831 5.20139 15.332C5.09877 15.4643 5.0074 15.6049 4.92823 15.7523C4.83911 15.9184 4.77085 16.0959 4.63433 16.4508L2.5 22.0001ZM2.5 22.0001L4.55812 16.649C4.7054 16.2661 4.77903 16.0746 4.90534 15.9869C5.01572 15.9103 5.1523 15.8813 5.2843 15.9065C5.43533 15.9354 5.58038 16.0804 5.87048 16.3705L8.12957 18.6296C8.41967 18.9197 8.56472 19.0648 8.59356 19.2158C8.61877 19.3478 8.58979 19.4844 8.51314 19.5947C8.42545 19.7211 8.23399 19.7947 7.85107 19.942L2.5 22.0001Z" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        <Typography>Edit</Typography>
                        </button>
                        <div className="vr"></div>
                        <button className="action-container run-report" data-testid="handle-delete" onClick={(e)=>handleDelete(e,report)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M16.5 6V5.2C16.5 4.0799 16.5 3.51984 16.282 3.09202C16.0903 2.71569 15.7843 2.40973 15.408 2.21799C14.9802 2 14.4201 2 13.3 2H11.7C10.5799 2 10.0198 2 9.59202 2.21799C9.21569 2.40973 8.90973 2.71569 8.71799 3.09202C8.5 3.51984 8.5 4.0799 8.5 5.2V6M10.5 11.5V16.5M14.5 11.5V16.5M3.5 6H21.5M19.5 6V17.2C19.5 18.8802 19.5 19.7202 19.173 20.362C18.8854 20.9265 18.4265 21.3854 17.862 21.673C17.2202 22 16.3802 22 14.7 22H10.3C8.61984 22 7.77976 22 7.13803 21.673C6.57354 21.3854 6.1146 20.9265 5.82698 20.362C5.5 19.7202 5.5 18.8802 5.5 17.2V6" stroke="#637583" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        <Typography>Delete</Typography>
                        </button>
                      </>
                    )}
                    
                </Grid>
            </Grid>
        </div>
                ):<></>;
              })}
    </div>
      {/* // )} */}
      {!reportsLoading && paginatedReports.length === 0 && (
        <div className="no-reports">
          <NoReportsFound isAdmin={false} favoriteParam={favoriteParam} handleAddReport={()=>{}} />
        </div>
      )}
    </div>}
    </>
  );
};

export default ReportTableComponent;
