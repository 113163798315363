import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//actions
import { reportActions } from "../../redux/actions/report.actions";

//selectors
import {
  FilteredReport,
  selectFilteredReportCategories,
  selectAllReports, isReportsLoading, selectFilteredFavoritedReports
} from "../../redux/selectors/report.selector";

//css
import "./HomePage.scss";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useHistory, useLocation} from "react-router-dom";
import { ReportCategory } from "../../redux/models/reportCategory";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { SideNavBarIcons } from "../SideNavBar/SideNavItem/SideNavBarIcons";
import { useAccount, useMsal } from "@azure/msal-react";
import { AuthClaim } from "../../redux/models/authClaim";
import AddEditReportDialog from "../ReportCatalog/AddReport/AddReport";

const HomePage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isLoading = useSelector(isReportsLoading);
  const favoriteParam = new URLSearchParams(location.search).get("favorites");
  const isFavorite=favoriteParam=='true';
  let categoriesData = useSelector(selectFilteredReportCategories);
  categoriesData = categoriesData?.filter(cat => cat.categoryId != 0);
  const isAdmin = useSelector(isUserAdministrator);
  const filteredReports = useSelector(selectAllReports);
  const favoriteReports = useSelector(selectFilteredFavoritedReports);
  const dReports = isFavorite?favoriteReports:filteredReports;
  const [dashboarReports, setDashboardReports] = useState<FilteredReport[]>(dReports);

  const [addReportDialogOpen,setAddReportDialog]=useState<boolean>(false);

  const { accounts } = useMsal();
  const account = useAccount(accounts?.[0] || {});
  let userClaims: AuthClaim = account?.idTokenClaims as AuthClaim;


  useEffect(() => {
    dispatch(reportActions.getReportCategories());
    dispatch(reportActions.setSaved(false));
    dispatch(reportActions.getReports(true));
  }, []);
  const tiles: any = {

    "Payroll": "Payroll",
    "Staffing": "Staffing",
    "Census": "Census",
    "Finance": "Finance",
    "RevenueCycleManagement": "RevenueCycleManagement",
    "Executive": "Executive"
  }
  useEffect(() => {
    console.log(isLoading);
    if(!isLoading){
      const reports = isFavorite&&filteredReports.length?filteredReports.filter((r)=>r.isFavorite):filteredReports;
      setDashboardReports(reports)
    }
  }, [isLoading]);

  

  const setSelectedCategory = (category: ReportCategory) =>
    dispatch(reportActions.setSelectedCategory(category));

  function getReports(cat: ReportCategory) {
    setSelectedCategory(cat);
    localStorage.setItem("selectedCategory",cat.name);
    if (favoriteParam === "true") {
      history.push(`/reports/catalog/${cat.categoryId}?favorites=true`);
    }
    else {
      history.push(`/reports/catalog/${cat.categoryId}`);
    }
  }

  const handleAddReport = ()=>{
    console.log('add')
    setAddReportDialog(true);
  }

  return (
    <Box display="flex" ml={"5%"} mr="5%" pt="20px" className="home-page">
       {addReportDialogOpen && (
        <AddEditReportDialog
          open={addReportDialogOpen}
          setOpen={setAddReportDialog}
          action='add'
          type=""
          selectedCategoryId={0}
        />
      )}
      <div className="home-page-container" data-testid="home-page">
        <Grid item md={12} pb={4}>
          <Grid container spacing={1} width="100%" >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            {isFavorite?<Typography className="home-page-user-name">Favorites</Typography>
                :<Typography className="home-page-user-name">
                    Welcome {userClaims?.name}, <img alt="Hello" height="24px" width="24px" src="./hello.png" />
                  </Typography>
            }
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ justifyContent: 'right', display: 'flex' }}>
              <Box className="home-page-header-button">
                {isAdmin && favoriteParam !== "true" && (
                  <div data-testid="add-report" onClick={handleAddReport} onKeyDown={handleAddReport}>
                    <div className="add-btn">
                      <AddOutlinedIcon />
                      <span className="no-select">Add Report</span>
                    </div>
                  </div>
                )}
              </Box>
            </Grid>
          </Grid>

        </Grid>
        <Grid item md={12}>
          <Grid container spacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
            className="home-page-tiles-container">
            {categoriesData.length ? categoriesData?.map((cat, i) => {
              const name = cat.name;
              const trimed = name.split(" ").join("");
              const iconName = tiles[trimed] ?? "defaultTile";
              const reportsCount = dashboarReports?.filter((c => c.categoryId == cat.categoryId))?.length;
              return <Grid item className="home-page-tile-list" key={'home-page-tile-list' + cat.categoryId}>
                <div data-testid="tile-list" className={`home-page-tiles default-tile tile-${iconName}`} onClick={() => getReports(cat)} key={cat.categoryId}>
                  <div className="home-page-tile">
                    {<SideNavBarIcons iconName={iconName} />}
                    <div>
                      <div className="tile-title">{name}</div>
                      <span className="tile-subtitle">{isLoading ? "..." : reportsCount} Reports</span>
                    </div>
                  </div>
                </div>
              </Grid>
            }) :
              <div className="spinner">
                <CircularProgress size={30} />
              </div>
            }

          </Grid>
        </Grid>
      </div>
    </Box>
  );

}
export default HomePage;