import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import { Filter } from "../../redux/models/filter";
import { ReportCategory } from "../../redux/models/reportCategory";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRaduis: 50,

    "&:focus": {
      borderRadius: 4,
      borderColor: "#5588a3",
    },
  },
}));

export interface RowCountDropdownProps {
  pageCount: number;
  appliedFilters: Filter<any>[];
  selectedCategory?: ReportCategory;
  sortField: string[];
  sortDirection: string;
  refreshSubscriptions: (
    pageSize: number,
    currentPage: number,
    filters?: Filter<any>[],
    categoryId?: number,
    sortField?: string[],
    sortDirection?: string
  ) => void;
}

function RowCountDropdown({
  pageCount,
  appliedFilters,
  selectedCategory,
  sortField,
  sortDirection,
  refreshSubscriptions,
}: RowCountDropdownProps) {
  const dispatch = useDispatch();

  const values = [
    { value: "10", name: "10" },
    { value: "15", name: "15" },
    { value: "25", name: "25" },
    { value: "50", name: "50" },
  ];

  function handlePageCountValueChange(event: any) {
    dispatch(subscriptionActions.setAdminRowCount(event.target.value));
    refreshSubscriptions(
      event.target.value,
      1,
      appliedFilters,
      selectedCategory?.categoryId,
      sortField,
      sortDirection
    );
  }

  return (
    <div className="page-count-wrapper">
      <div className="count-label small-text">Rows per page:</div>
      <Select
        id="count-value-select"
        value={pageCount}
        onChange={handlePageCountValueChange}
        input={<Input />}
      >
        {values.map((val) => (
          <MenuItem
            key={val.value}
            value={val.value}
            className="medium-text blue-text"
          >
            {val.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
export default RowCountDropdown;
