import { useDispatch, useSelector } from "react-redux";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import { SubscriptionExtension } from "../../redux/models/subscriptionExtension";
import {
  selectFormData,
  selectSelectedSubscription,
} from "../../redux/selectors/subscription.selector";

import ReportFilters from "./ReportFilters";
import "./SubscriptionWizard.scss";
import { ReportTypeEnum } from "../../redux/models/reportType";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { ToggleButton } from "@mui/material";
import { useEffect, useState } from "react";

export interface SubscriptionFormProps {
  action: "add" | "edit";
  admin: boolean;
  disabled: boolean;
  reportTypeId?: ReportTypeEnum;
}

function SubscriptionForm({ action, admin, disabled, reportTypeId }: Readonly<SubscriptionFormProps>) {
  const dispatch = useDispatch();

  const formData = useSelector(selectFormData);
  const selectedSubscription = useSelector(selectSelectedSubscription);
  const [selectedFileType,setSelectedFileType]=useState<number>(formData.fileExtensions[0]?.exportFileExtensionId);

  const handleTitleChange = (value: string) => {
    dispatch(subscriptionActions.setSubscriptionSubtitle(value));
  };

  const handleFileTypeCheck = (
    event: any,
    clicked_exportFileExtensionId: number
  ) => {
    if (formData?.fileExtensions.length === 1||clicked_exportFileExtensionId==null) return;
    setSelectedFileType(clicked_exportFileExtensionId);
    let extensions = [...selectedSubscription.subscriptionExtensions];
    extensions = extensions.filter(
      (e) => e.exportFileExtensionId !== clicked_exportFileExtensionId
    );
    // if (!extensions.find(e=>e.exportFileExtensionId==clicked_exportFileExtensionId)) {
      let ext = {
        subscriptionExtensionId: 0,
        subscriptionId: 0,
        exportFileExtensionId: clicked_exportFileExtensionId,
      } as SubscriptionExtension;
      extensions.push(ext);
    // } else {
    //   extensions = extensions.filter(
    //     (e) => e.exportFileExtensionId !== clicked_exportFileExtensionId
    //   );
    // }

    dispatch(subscriptionActions.setSubscriptionExtensions(extensions));
  };

  useEffect(()=>{
    setSelectedFileType(formData.fileExtensions[0]?.exportFileExtensionId);
  },[formData])

  return (
    <div className="subscription-form">
      <div className="select-lable">  Report Subtitle</div>
      <div className="control-cell">
            <input
              value={selectedSubscription.name}
              onChange={(event) => {
                handleTitleChange(event.target.value);
              }}
              placeholder="Enter subtitle"
              maxLength={150}
              disabled={disabled}
            />
          </div>
          <div
            className={
              150 - selectedSubscription.name.length === 0
                ? "small-error"
                : "clone-small"
            }
          >
            Limit: 150 characters ({150 - selectedSubscription.name.length}{" "}
            characters remaining)
          </div>
          <div className="select-lable">
            File Type
          </div>
          <ToggleButtonGroup
              color="primary"
              value={selectedFileType}
              exclusive
              onChange={handleFileTypeCheck}
              size="large"
              aria-label="Platform"
              className="export-type"
            >
              {formData.fileExtensions.map((x) => {
            return (
              x.fileExtension === "PDF"?<ToggleButton key={x.exportFileExtensionId} className={selectedFileType===x.exportFileExtensionId?'exp-selected':''} value={x.exportFileExtensionId}><svg id={x.fileExtension} width="24" height="24" viewBox="0 0 64 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M63.4792 18.0127L45.7013 0.512695C45.368 0.18457 44.9167 0 44.4443 0H7.111C3.18917 0 0 3.13934 0 7.00005V77.0001C0 80.8607 3.18917 84 7.11117 84H56.889C60.8108 84 64 80.8607 64 76.9999V19.2499C64 18.7852 63.8125 18.3409 63.4792 18.0127ZM46.2222 5.97466L57.9305 17.5001H49.7777C47.8177 17.5001 46.2222 15.9295 46.2222 14.0001V5.97466ZM60.4445 76.9999C60.4445 78.9293 58.849 80.4999 56.889 80.4999H7.11117C5.15117 80.4999 3.55567 78.9293 3.55567 76.9999V7.00005C3.55567 5.07068 5.15117 3.50011 7.11117 3.50011H42.6667V14.0001C42.6667 17.8607 45.8558 21 49.7778 21H60.4445V76.9999Z" fill="#8EA0B6"/>
                <path d="M41.0643 51.3738C39.4184 50.0989 37.8542 48.7882 36.8126 47.7628C35.4584 46.4298 34.2517 45.1378 33.2032 43.9073C34.8387 38.9325 35.5558 36.3674 35.5558 35.0001C35.5558 29.1913 33.4237 28 30.2224 28C27.7901 28 24.8891 29.2441 24.8891 35.1676C24.8891 37.7789 26.3422 40.9491 29.2224 44.6336C28.5176 46.751 27.6894 49.1933 26.7589 51.9464C26.3109 53.2674 25.8249 54.491 25.3109 55.6224C24.901 55.8007 24.4952 55.9881 24.0939 56.1846C22.6807 56.8803 21.3387 57.5057 20.0939 58.0868C14.4167 60.7323 10.6667 62.4823 10.6667 65.9378C10.6667 68.4467 13.4359 70 16.0001 70C19.3056 70 24.2969 65.654 27.9427 58.3327C31.7274 56.863 36.4324 55.7743 40.1459 55.0925C43.1216 57.3449 46.4081 59.5 48.0001 59.5C52.4081 59.5 53.3334 56.9912 53.3334 54.8874C53.3334 50.7499 48.5313 50.7499 46.2223 50.7499C45.5053 50.7501 43.5818 50.9586 41.0643 51.3738ZM16.0001 66.5001C14.9844 66.5001 14.2969 66.0284 14.2222 65.9378C14.2222 64.697 17.9809 62.9437 21.6164 61.2484C21.8472 61.1408 22.0817 61.033 22.3196 60.9219C19.6494 64.7331 17.0087 66.5001 16.0001 66.5001ZM28.4446 35.1676C28.4446 31.5001 29.6009 31.5001 30.2224 31.5001C31.4794 31.5001 32.0002 31.5001 32.0002 35.0001C32.0002 35.7383 31.5002 37.584 30.5852 40.4655C29.1892 38.3497 28.4446 36.5365 28.4446 35.1676ZM29.8074 53.9767C29.9193 53.6705 30.027 53.3629 30.1304 53.0538C30.7901 51.1056 31.3839 49.3555 31.9134 47.7799C32.6512 48.5797 33.4464 49.3983 34.2989 50.2374C34.6322 50.5655 35.4586 51.3038 36.5594 52.2283C34.3681 52.6983 32.0366 53.281 29.8074 53.9767ZM49.7779 54.8875C49.7779 55.6737 49.7779 56.0001 48.1286 56.0104C47.6442 55.9079 46.5244 55.2585 45.1424 54.3322C45.6441 54.2776 46.0139 54.2502 46.2223 54.2502C48.8491 54.2502 49.5939 54.503 49.7779 54.8875Z" fill="#8EA0B6"/>
              </svg> PDF 
              </ToggleButton>
              :x.fileExtension === "XLSX" && reportTypeId == 2&& <ToggleButton className={selectedFileType===x.exportFileExtensionId?'exp-selected':''} key={x.exportFileExtensionId} value={x.exportFileExtensionId}>
              <svg id={x.fileExtension} width="24" height="24" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M63.6349 18.38L46.3318 0.400347C46.2099 0.27377 46.0637 0.173078 45.902 0.10429C45.7403 0.0355022 45.5664 3.1873e-05 45.3907 0L19.6016 0C16.7155 0 14.3674 2.34716 14.3674 5.2321V23.5102H3.91605C1.75672 23.5102 0 25.2664 0 27.4252V47.0239C0 49.1825 1.75672 50.9387 3.91605 50.9387H14.3674V62.6954C14.3674 65.5753 16.7155 67.9183 19.6016 67.9183H58.7658C61.6519 67.9183 64 65.5766 64 62.6983V19.2857C64 18.948 63.8691 18.6234 63.6349 18.38ZM45.7143 3.52605L59.9187 18.2857H45.7143V3.52605ZM3.91605 48.3265C3.19707 48.3265 2.61233 47.7422 2.61233 47.0239V27.4252C2.61233 26.7069 3.1972 26.1224 3.91605 26.1224H39.1859C39.9048 26.1224 40.4898 26.7069 40.4898 27.4252V47.0239C40.4898 47.7422 39.9048 48.3265 39.1859 48.3265H3.91605ZM61.3877 62.6983C61.3877 64.1362 60.2114 65.3061 58.7656 65.3061H19.6016C18.1558 65.3061 16.9796 64.135 16.9796 62.6955V50.9387H39.1858C41.3453 50.9387 43.102 49.1825 43.102 47.0239V27.4252C43.102 25.2664 41.3453 23.5102 39.1858 23.5102H16.9796V5.2321C16.9796 3.78737 18.1558 2.6122 19.6016 2.6122H43.102V19.5918C43.102 20.3131 43.6869 20.8979 44.4081 20.8979H61.3877V62.6983Z" fill="#8EA0B6"/>
              <path d="M35.7992 38.0473C35.5008 37.565 35.0874 37.185 34.5595 36.9068C34.0314 36.6289 33.2165 36.3599 32.1144 36.0996C31.0123 35.8393 30.3185 35.5893 30.0328 35.3496C29.8081 35.1608 29.6959 34.9334 29.6959 34.6684C29.6959 34.3775 29.8157 34.1452 30.0558 33.9718C30.4283 33.7015 30.9436 33.5663 31.6016 33.5663C32.2392 33.5663 32.7177 33.6927 33.0366 33.945C33.3554 34.1975 33.5632 34.6122 33.6602 35.1888L35.9254 35.0892C35.8898 34.0586 35.5163 33.2346 34.8045 32.6175C34.0925 32 33.0328 31.6914 31.6248 31.6914C30.7624 31.6914 30.0264 31.8213 29.4168 32.0817C28.8071 32.3419 28.3402 32.7207 28.0162 33.218C27.6923 33.7156 27.5301 34.2502 27.5301 34.8216C27.5301 35.7091 27.8746 36.4617 28.5635 37.0792C29.0533 37.5178 29.9052 37.8878 31.1196 38.1887C32.0633 38.4235 32.6681 38.5868 32.9331 38.6785C33.3211 38.8163 33.5927 38.9783 33.7482 39.1644C33.9039 39.3509 33.9816 39.5764 33.9816 39.842C33.9816 40.2551 33.7967 40.6162 33.4269 40.9247C33.0569 41.2333 32.5073 41.3877 31.7776 41.3877C31.0889 41.3877 30.5417 41.2143 30.1361 40.8671C29.7306 40.5204 29.4616 39.977 29.3287 39.2371L27.1245 39.4513C27.2724 40.7065 27.7266 41.662 28.4869 42.3176C29.2471 42.9729 30.3363 43.3008 31.7548 43.3008C32.7291 43.3008 33.5432 43.1643 34.1963 42.8913C34.8493 42.6187 35.3541 42.2014 35.7113 41.64C36.0685 41.079 36.2472 40.4769 36.2472 39.8339C36.2469 39.1249 36.0977 38.5293 35.7992 38.0473ZM20.451 41.2116V31.9745H18.1854V43.1019H26.0837V41.2116H20.451ZM13.1269 37.3315L16.6168 31.8827H13.9919L11.7879 35.4873L9.53767 31.8827H6.88992L10.3641 37.2473L6.53003 43.1019H9.24676L11.7418 39.2217L14.2289 43.1019H16.9611L13.1269 37.3315Z" fill="#8EA0B6"/>
              </svg>XLSX
              </ToggleButton>
            )
               })
            }
              </ToggleButtonGroup>
      <ReportFilters action={action} admin={admin} disabled={disabled} />
    </div>
  );
}

export default SubscriptionForm;
