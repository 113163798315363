export interface SubscriptionEmail {
  subscriptionEmailId: number;
  to: string;
  cc: string;
  bcc: string;
}

export function CreateDefaultSubscriptionEmail(): SubscriptionEmail {
  let ret: SubscriptionEmail = {
    subscriptionEmailId: 0,
    to: '',
    cc: '',
    bcc: '',
  }
  return ret;
}