import styled from "@emotion/styled";
import { TooltipProps, Tooltip, tooltipClasses } from "@mui/material";

export const TooltipStyle = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#2D3740",
      "&::before": {
        color: "#2D3740",
      }
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#FFFFFF",
      backgroundColor: "#2D3740",
      fontFamily: 'Open Sans',
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "12px",
      letterSpacing: "-0.02em",
    },
  }));