import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "..";
import { loginRequest } from "./authConfig";

export const acquireAccessToken = async () => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    return Promise.reject(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    );
  }
  const request = {
    scopes: loginRequest.scopes,
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  }
  catch (error) {
    msalInstance.acquireTokenRedirect(loginRequest).catch((e) => {
      console.error(e);
      throw error;
    })
  }
};
