import { useState } from "react";
import { useDispatch } from "react-redux";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import {
  FilteredSubscription,
} from "../../redux/selectors/subscription.selector";
import "./Subscriptions.scss";
import { CircularProgress, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import SubscriptionComponent from "../Subscriptions/SubscriptionComponent";
import usePagination from "./Pagination";

export interface MySubscriptionTableProps {
    subscriptionsLoading: boolean;
    filteredSubscriptions:FilteredSubscription[];
  }

function MySubscriptionsTable({subscriptionsLoading,filteredSubscriptions}:MySubscriptionTableProps){
    const dispatch = useDispatch();
    const pageCount = 8;
    const count = Math.ceil(filteredSubscriptions.length / pageCount);
    const _DATA = usePagination(filteredSubscriptions, pageCount);
    const [page, setPage] = useState(1);
    const handleChange = (e:any, p:number) => {
        setPage(p);
        _DATA.jump(p);
        };

    const sortByName = () =>
    dispatch(subscriptionActions.sortSubscriptions("nameLowered"));
  const sortByLastRunDate = () =>
    dispatch(subscriptionActions.sortSubscriptions("lastRunDateUTC"));
  const sortByNextRunDate = () =>
    dispatch(subscriptionActions.sortSubscriptions("nextRunDateUTC"));

    return (
                <TableContainer
                        style={{
                            overflow:
                            subscriptionsLoading || filteredSubscriptions.length === 0
                                ? "hidden"
                                : "auto",
                            margin:"12px 12px",
                            width:"98%",
                            background:"#FFF"
                        }}
                        >
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                <TableCell align="left">
                                
                                </TableCell>
                                <TableCell
                                    align="left"
                                >
                                    <div
                                    className="sortable"
                                    onClick={() => sortByName()}
                                    title="Sort"
                                    >
                                    <span className="head-title">Report Title</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <g id="ArrowDown">
                                        <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M16.707 11.2344C16.8944 11.4219 16.9997 11.6762 16.9997 11.9414C16.9997 12.2066 16.8944 12.4609 16.707 12.6484L10.707 18.6484C10.5194 18.8359 10.2651 18.9412 9.99996 18.9412C9.73479 18.9412 9.48049 18.8359 9.29296 18.6484L3.29296 12.6484C3.19745 12.5562 3.12127 12.4458 3.06886 12.3238C3.01645 12.2018 2.98886 12.0706 2.98771 11.9378C2.98655 11.805 3.01186 11.6733 3.06214 11.5505C3.11242 11.4276 3.18667 11.3159 3.28056 11.222C3.37446 11.1281 3.48611 11.0539 3.60901 11.0036C3.7319 10.9533 3.86358 10.928 3.99636 10.9292C4.12914 10.9303 4.26036 10.9579 4.38236 11.0103C4.50437 11.0627 4.61471 11.1389 4.70696 11.2344L8.99996 15.5274V3.94141C8.99996 3.67619 9.10532 3.42184 9.29285 3.2343C9.48039 3.04676 9.73474 2.94141 9.99996 2.94141C10.2652 2.94141 10.5195 3.04676 10.7071 3.2343C10.8946 3.42184 11 3.67619 11 3.94141V15.5274L15.293 11.2344C15.4805 11.0469 15.7348 10.9416 16 10.9416C16.2651 10.9416 16.5194 11.0469 16.707 11.2344Z" fill="#8EA0B6"/>
                                        </g>
                                    </svg>
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <div
                                    className="sortable"
                                    onClick={() => sortByLastRunDate()}
                                    title="Sort"
                                    >
                                    <span className="head-title">Last Report</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <g id="ArrowDown">
                                        <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M16.707 11.2344C16.8944 11.4219 16.9997 11.6762 16.9997 11.9414C16.9997 12.2066 16.8944 12.4609 16.707 12.6484L10.707 18.6484C10.5194 18.8359 10.2651 18.9412 9.99996 18.9412C9.73479 18.9412 9.48049 18.8359 9.29296 18.6484L3.29296 12.6484C3.19745 12.5562 3.12127 12.4458 3.06886 12.3238C3.01645 12.2018 2.98886 12.0706 2.98771 11.9378C2.98655 11.805 3.01186 11.6733 3.06214 11.5505C3.11242 11.4276 3.18667 11.3159 3.28056 11.222C3.37446 11.1281 3.48611 11.0539 3.60901 11.0036C3.7319 10.9533 3.86358 10.928 3.99636 10.9292C4.12914 10.9303 4.26036 10.9579 4.38236 11.0103C4.50437 11.0627 4.61471 11.1389 4.70696 11.2344L8.99996 15.5274V3.94141C8.99996 3.67619 9.10532 3.42184 9.29285 3.2343C9.48039 3.04676 9.73474 2.94141 9.99996 2.94141C10.2652 2.94141 10.5195 3.04676 10.7071 3.2343C10.8946 3.42184 11 3.67619 11 3.94141V15.5274L15.293 11.2344C15.4805 11.0469 15.7348 10.9416 16 10.9416C16.2651 10.9416 16.5194 11.0469 16.707 11.2344Z" fill="#8EA0B6"/>
                                        </g>
                                    </svg>
                                    </div>
                                </TableCell>
                                <TableCell
                                    align="center"
                                >
                                <span>Access Reports</span>
                                </TableCell>

                                <TableCell align="left">
                                    <div
                                    className="sortable"
                                    onClick={() => sortByNextRunDate()}
                                    title="Sort"
                                    >
                                    <span className="head-title">Next Report</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                        <g id="ArrowDown">
                                        <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M16.707 11.2344C16.8944 11.4219 16.9997 11.6762 16.9997 11.9414C16.9997 12.2066 16.8944 12.4609 16.707 12.6484L10.707 18.6484C10.5194 18.8359 10.2651 18.9412 9.99996 18.9412C9.73479 18.9412 9.48049 18.8359 9.29296 18.6484L3.29296 12.6484C3.19745 12.5562 3.12127 12.4458 3.06886 12.3238C3.01645 12.2018 2.98886 12.0706 2.98771 11.9378C2.98655 11.805 3.01186 11.6733 3.06214 11.5505C3.11242 11.4276 3.18667 11.3159 3.28056 11.222C3.37446 11.1281 3.48611 11.0539 3.60901 11.0036C3.7319 10.9533 3.86358 10.928 3.99636 10.9292C4.12914 10.9303 4.26036 10.9579 4.38236 11.0103C4.50437 11.0627 4.61471 11.1389 4.70696 11.2344L8.99996 15.5274V3.94141C8.99996 3.67619 9.10532 3.42184 9.29285 3.2343C9.48039 3.04676 9.73474 2.94141 9.99996 2.94141C10.2652 2.94141 10.5195 3.04676 10.7071 3.2343C10.8946 3.42184 11 3.67619 11 3.94141V15.5274L15.293 11.2344C15.4805 11.0469 15.7348 10.9416 16 10.9416C16.2651 10.9416 16.5194 11.0469 16.707 11.2344Z" fill="#8EA0B6"/>
                                        </g>
                                    </svg>
                                    </div>
                                </TableCell>
                                <TableCell
                                    align="center"
                                
                                >
                                    <span>Actions</span>
                                </TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            
                            {!subscriptionsLoading &&_DATA.currentData().map((subscription, i: number) => {
                            return (
                            <SubscriptionComponent
                                subscription={subscription}
                                indexItem={i}
                                key={subscription.subscriptionId}
                            />
                            );
                            })}
                        </TableBody>
                    </Table>
                    {subscriptionsLoading && (
                            <div className="spinner" data-testid="loader">
                            <CircularProgress size={30} />
                            </div>
                        )}
                
                        {!subscriptionsLoading && filteredSubscriptions.length === 0 && (
                            <div className="no-subscriptions" data-testid="no-subscriptions">
                            No subscriptions found. Return to the Report Catalog to subscribe
                            your first report.
                            </div>
                        )}

                    <div className="footer" data-testid="footer">
                    {filteredSubscriptions.length<pageCount?<Typography className="page-status">Showing {filteredSubscriptions.length} Results</Typography>
                        :<Typography className="page-status">Showing {pageCount} Results of {filteredSubscriptions.length} </Typography>
                    }
                        <Pagination className="pagination"
                        count={count}
                        size="large"
                        page={page}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        data-testid="page-change"
                        style={{float:'right'}}
                    />
                    </div>
                </TableContainer>
    );
}

export default MySubscriptionsTable