import { IBaseAction } from "../actions/base.action";
import { NotificationActions } from "../actions/notification.actions";
import { INotificationType } from "../models/notificationType";
import { NetworkError, ServerError, ServerErrorType } from "../models/serverError";

type NotificationState = {
    message?: string;
    notificationType?: INotificationType;
    serverErrorType?: ServerErrorType;
    stayVisible: boolean;
    visibilityRefresh: number;
}

const initialState: NotificationState = {
    message: undefined,
    notificationType: undefined,
    stayVisible: false,
    visibilityRefresh: 0
}


type BaseOrNotification = IBaseAction | NotificationActions

const NotificationReducer = (state: NotificationState = initialState, action: BaseOrNotification): NotificationState => {
    if ((action as IBaseAction).error) {
        // if a server error occured
        action = action as IBaseAction;
        ;
        var nType: INotificationType = INotificationType.Error;
        var sType: ServerErrorType = ServerErrorType.Error;
        var defaultMessage = "An unexpected error has occured"
        if ((action.error as NetworkError).status) {
            switch ((action.error as NetworkError).status) {
                case 400:
                    nType = INotificationType.Warning;
                    sType = ServerErrorType.Warning;
                    break;
                case 401:
                    nType = INotificationType.Unauthorized;
                    sType = ServerErrorType.Unauthorized;
                    defaultMessage = "You are unauthorized to perform this action"
                    break;
                default:
                    nType = INotificationType.Error;
                    sType = ServerErrorType.Error;
                    break;
            }
        }
        else if ((action.error as ServerError).errorType) {
            sType = (action.error as ServerError).errorType;
            defaultMessage = (action.error as ServerError).displayMessage;
            switch ((action.error as ServerError).errorType) {
                case ServerErrorType.Info:
                case ServerErrorType.Warning:
                    nType = INotificationType.Warning;
                    break;
                case ServerErrorType.Forbidden:
                case ServerErrorType.Disabled:
                case ServerErrorType.NotApproved:
                    nType = INotificationType.Forbidden;
                    break;
                case ServerErrorType.Unauthorized:
                    nType = INotificationType.Unauthorized;
                    defaultMessage = "You are unauthorized to perform this action"
                    break;
                case ServerErrorType.Error:
                    nType = INotificationType.Error;
                    break;
                default:
                    nType = INotificationType.Error;
            }
        }
        return {
            notificationType: nType,
            serverErrorType: sType,
            message: defaultMessage ?? "An unexpected error has occured",
            visibilityRefresh: (nType !== INotificationType.Forbidden) ? state.visibilityRefresh + 1 : state.visibilityRefresh,
            stayVisible: false
        }
    }
    if ((action as IBaseAction).successMessage) {
        // if a server error occured
        action = action as IBaseAction;
        return {
            notificationType: INotificationType.Success,
            message: action.successMessage,
            visibilityRefresh: state.visibilityRefresh + 1,
            stayVisible: false
        }
    }
    else if ((action as NotificationActions).type) {
        let notificationAction: NotificationActions = action as NotificationActions
        switch (notificationAction.type) {
            case 'SET_NOTIFICATION':
                return {
                    notificationType: notificationAction.notificationType,
                    message: notificationAction.message,
                    visibilityRefresh: state.visibilityRefresh + 1,
                    stayVisible: notificationAction.stayVisible
                }
            // case 'HIDE_NOTIFICATION':
            //     return {
            //         visibilityRefresh: state.visibilityRefresh + 1,
            //         stayVisible: false
            //     }
        }
    }

    return state;
}

export default NotificationReducer;