export interface DashboardPermission {
    dashboardPermissionId: number;
    permission: string;
    active: boolean;
}

export enum DashboardPermissionValues {
    SHARE = "SHARE",
    WIDGETCONTROL = "WIDGETCONTROL",
    DASHBOARDRENAME = "DASHBOARDRENAME",
}
