import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import { selectSelectedDashboard } from "../../redux/selectors/dashboard.selector";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import { DashboardPermissionValues } from "../../redux/models/dashboardPermission";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import { ReportActionIcons } from "../ReportCatalog/ReportActions/ReportActionIcons";
import { IconButton } from "@mui/material";

export interface WidgetActionsProps {
  widgetId: number;
  openFullScreen: (id: number) => void;
  // closeFullScreen: (id: number) => void;
}

export default function WidgetActions({ widgetId, openFullScreen }: WidgetActionsProps) {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const selectedDashboard = useSelector(selectSelectedDashboard);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canManageWidgets = selectedDashboard.dashboardPermissions?.some(
    (dp) => dp.permission === DashboardPermissionValues.WIDGETCONTROL
  );

  return (
    <div className="widget-actions-container">
      {canManageWidgets && (
        <React.Fragment>
          
          <Button
            id="widget-actions-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            onTouchEnd={handleClick}
            style={{ width: 10 }}
          >
            <MoreVertIcon
              titleAccess="Manage widget"
              style={{ color: "#00aaa9" }}
            />
          </Button>
          <IconButton className="no-bradius" onClick={() => openFullScreen(widgetId)}
                  onTouchEnd={() => openFullScreen(widgetId)}
            sx={{
                "&:hover": {
                    bgcolor: "transparent"
                },
               
            }}
            disableRipple
            disabled={false}
        >
            <ReportActionIcons iconName="dasbhoardFullScreen" />
        </IconButton>
          <Menu
            id="dashboard-actions-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "dsh-actions-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                dispatch(
                  dashboardActions.deleteWidget(
                    selectedDashboard.dashboardId ?? 0,
                    widgetId
                  )
                );
              }}
              onTouchEnd={() => {
                handleClose();
                dispatch(
                  dashboardActions.deleteWidget(
                    selectedDashboard.dashboardId ?? 0,
                    widgetId
                  )
                );
              }}
            >
              <CloseIcon fontSize="small" />
              <span className="medium-text">Remove</span>
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </div>
  );
}
