import { useState } from "react";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ReplyAllOutlinedIcon from "@mui/icons-material/ReplyAllOutlined";
import { selectSelectedDashboard } from "../../redux/selectors/dashboard.selector";
import CloneDashboardDialog from "./CloneDashboardDialog";
import DeleteDashboardDialog from "./DeleteDashboardDialog";
import ShareDashboardDialog from "../ShareDashboard/ShareDashboardDialog";
import { DashboardPermissionValues } from "../../redux/models/dashboardPermission";
import { IconButton, Menu, Typography } from "@mui/material";
import { ReportActionIcons } from "../ReportCatalog/ReportActions/ReportActionIcons";

export default function DashboardActions() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [isCloneOpen, setCloneOpen] = useState<boolean>(false);
  const [isDeleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [isShareOpen, setShareOpen] = useState<boolean>(false);

  const selectedDashboard = useSelector(selectSelectedDashboard);
  const [isThreedotActive, setThreedotActive] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setThreedotActive(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const canShare = selectedDashboard.dashboardPermissions?.some(
    (dp) => dp.permission === DashboardPermissionValues.SHARE
  );

  return (
    <div className="dashboard-actions-container">
      {isCloneOpen && (
        <CloneDashboardDialog open={isCloneOpen} setOpen={setCloneOpen} />
      )}
      {isDeleteOpen && (
        <DeleteDashboardDialog
          open={isDeleteOpen}
          setOpen={setDeleteOpen}
          isOwner={selectedDashboard.isOwner ?? false}
        />
      )}
      {isShareOpen && (
        <ShareDashboardDialog open={isShareOpen} setOpen={setShareOpen} />
      )}

      {/* <Button
        id={"dsh-actions-button"}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Actions{" "}
        <ExpandLessOutlinedIcon style={{ display: open ? "" : "none" }} />{" "}
        <ExpandMoreOutlinedIcon style={{ display: open ? "none" : "" }} />
      </Button> */}
      <IconButton onClick={handleClick}
            sx={{
                "&:hover": {
                    bgcolor: "transparent"
                },
            }}
            disableRipple
            disabled={open}
            className={isThreedotActive ? "three-dots dasboard-threedot-icon": "three-dots"}
            
        >
            <ReportActionIcons iconName="threedot" /> 
            <Typography className="more-options">More Options</Typography>
        </IconButton>

      <Menu
        id="dashboard-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "dsh-actions-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            setCloneOpen(true);
          }}
        >
          <FileCopyOutlinedIcon fontSize="small" />
          <ListItemText>Clone</ListItemText>
        </MenuItem>
        {canShare && (
          <span>
            <MenuItem
              onClick={() => {
                handleClose();
                setShareOpen(true);
              }}
            >
              <ReplyAllOutlinedIcon fontSize="small" />
              <ListItemText>Share</ListItemText>
            </MenuItem>
          </span>
        )}
        <span>
          <Divider />
          <MenuItem
            onClick={() => {
              handleClose();
              setDeleteOpen(true);
            }}
          >
            <DeleteForeverOutlinedIcon fontSize="medium" />
            <ListItemText>
              {selectedDashboard.isOwner === true ? "Delete" : "Remove"}
            </ListItemText>
          </MenuItem>
        </span>
      </Menu>
    </div>
  );
}
