import { Redirect, Route, RouteProps } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { AppState } from "../redux/reducers";
import { ReactNode } from "react";
import LoginPage from "../components/LoginPage/LoginPage";
import { INotificationType } from "../redux/models/notificationType";

interface PrivateRouteProps extends RouteProps {
    requireAdmin?: boolean;
  }
  export const PrivateRoute = ({
    component,
    requireAdmin,
    ...options
  }: PrivateRouteProps) => {
    let isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();
    const userReducer = useSelector((state: AppState) => state.userReducer);
    const { user, loadingUser } = userReducer;
    const notificationReducer = useSelector(
      (state: AppState) => state.notificationReducer
    );
    const { notificationType } = notificationReducer;
  
    let finalComponent: ReactNode;
    if (isAuthenticated) {
      if (loadingUser) {
        finalComponent = <div></div>;
      } else if (
        notificationType === INotificationType.Forbidden &&
        options.path === "/forbidden"
      )
        finalComponent = options.children;
      else if (
        requireAdmin === true &&
        (!user || user?.isAdmin !== true) &&
        !loadingUser
      ) {
        finalComponent = <Redirect to="/forbidden" />;
      } else finalComponent = options.children;
    } else if (inProgress !== "none") {
      finalComponent = () => <div></div>;
    } else {
      finalComponent = <LoginPage />;
    }
    return <Route {...options}>{finalComponent}</Route>;
  };