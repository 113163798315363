import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material";
import InputBase from "@mui/material/InputBase";

export interface SelectFilterProps {
  index: number;
  displayName: string;
  setFilter: (index: number, values: any[]) => void;
  values: string[];
  options: string[];
}

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    borderRaduis: 50,
    fontWeight: "bold",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#5588a3",
    },
  },
}));

function SelectFilter({
  index,
  displayName,
  setFilter,
  values,
  options,
}: SelectFilterProps) {
  function setValues(newValue: string) {
    let val = [newValue];
    setFilter(index, val);
  }

  return (
    <div className="filter-wrapper small-text">
      {displayName}
      <div className="text-filter-container">
        <FormControl sx={{ minWidth: 160 }}>
          <Select
            labelId="status-select-label"
            id="status-select"
            value={values[0] ?? "All"}
            onChange={(e) => setValues(e.target.value)}
            input={<Input />}
          >
            {options.map((o, i) => {
              return (
                <MenuItem
                  key={i}
                  value={o}
                  className="myMenuItem small-text blue-text"
                >
                  <span>{o}</span>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
export default SelectFilter;
