import "./DashboardPage.scss";
import DashboardContent from "./DashboardContent";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../redux/reducers";
import { useEffect } from "react";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import { selectIsDashboardInFullscreen } from "../../redux/selectors/dashboard.selector";
import { useHistory, useLocation } from "react-router-dom";

function DashboardPage() {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector(
    (state: AppState) => state.dashboardReducer
  );
  const userReducer = useSelector((state: AppState) => state.userReducer);
  const { selectedDashboard } = dashboardReducer;
  const { user } = userReducer;
  const isDashboardInFullscreen = useSelector(selectIsDashboardInFullscreen);

  const location = useLocation();

  let dashboardId = new URLSearchParams(location.search).get("id");

  useEffect(() => {
    if (user) {
      if (dashboardId) {
        dispatch(dashboardActions.getUserDashboards(parseInt(dashboardId)));
        // remove the url parameter
        // history.replace(
        //   `${history.location.pathname.replace(location.search, "")}`
        // );
      } else {
        dispatch(dashboardActions.getUserDashboards(null));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <div
      id="dashboard-page"
      className={isDashboardInFullscreen ? "fullscreen" : ""}
    >
      {selectedDashboard.dashboardId ? (
        <DashboardContent />
      ) : (
        <div className="no-dashboard">
          Create a dashboard after running a report from the Report Catalog or
          Dashboard Catalog
        </div>
      )}
    </div>
  );
}
export default DashboardPage;
