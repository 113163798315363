import { useHistory, useParams } from "react-router-dom";
import "../StatusPages.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../redux/reducers";
import { INotificationType } from "../../../redux/models/notificationType";
import { ServerErrorType } from "../../../redux/models/serverError";
import { isUserValid } from "../../../redux/selectors/user.selector";

function ForbiddenPage() {
  let history = useHistory();
  const notificationReducer = useSelector(
    (state: AppState) => state.notificationReducer
  );
  const { message, serverErrorType } = notificationReducer;
  const userisValid = useSelector(isUserValid);

  function getTitle(): string {
    if (serverErrorType === ServerErrorType.Disabled) return "Disabled Account";
    if (serverErrorType === ServerErrorType.NotApproved)
      return "Waiting for Approval";
    return "Unauthorized";
  }

  const displayMessage: string =
    // if (inactive)
    //   return "Your account has been disabled. If you think this is a mistake, please contact our Customer Support.";
    // if (approvalRequired)
    //   return "Thank you for registering your account. Your account needs to be approved by an admin before accessing the site. Thank you for your patience.";
    message ??
    "We are sorry, but you do not have authorization to access this page or resource. If you feel this is a mistake, please contact customer support.";

  function getButton(): JSX.Element {
    if (serverErrorType !== ServerErrorType.Forbidden)
      return (
        <a className="action-button" href="/logout">
          Sign out
        </a>
      );
    return (
      <a className="action-button" href="/">
        Return Home
      </a>
    );
  }

  const showContactButton: boolean =
    displayMessage.toLowerCase().includes("contact customer support") &&
    (userisValid ?? false) &&
    serverErrorType !== ServerErrorType.Disabled &&
    serverErrorType !== ServerErrorType.NotApproved;

  return (
    <div id="status-page">
      <div id="status-container">
        <div className="status-message-container">
          <img
            className="apex-logo"
            src="/apex-logo-h.png"
            alt="Apex Global - Insights Portal"
          ></img>
          <h2>{getTitle()}</h2>
          <p>{displayMessage}</p>
          <div id="action-button-container">
            {getButton()}
            {showContactButton && (
              <a className="action-button" href="/support">
                Contact Support
              </a>
            )}
          </div>
        </div>
        {serverErrorType === ServerErrorType.Forbidden && (
          <div className="status-code-container">
            <h1>403</h1>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForbiddenPage;
