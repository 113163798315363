import { Report } from "../models/report"
import { AppState } from "../../redux/reducers";
import {memoize} from 'proxy-memoize';
import _orderBy from "lodash/orderBy";
import { compliesWithFilter } from "../models/filter";


export interface FilteredReport extends Report {
    isLoading: boolean
    nameLowered: string
    lastRunDateUTC?: Date
    nextRunDateUTC?: Date
}


const getAllReports = memoize((reports: Report[]): FilteredReport[] => {
    return reports.flat().filter(r => r.active).map(report => {
        return {
            ...report,
            nameLowered: report?.name.toLowerCase(),
            lastRunDateUTC: report.lastRunDate && new Date(`${report.lastRunDate}Z`),
            nextRunDateUTC: report.nextRunDate && new Date(`${report.nextRunDate}Z`),
            isLoading: false
        }
    });
});

const getReportsByFilter=(isFavorite:boolean,state: AppState)=>{
    const { reports, selectedCategory, reportSearchKeyword,  pageFilters } = state.reportReducer;
    if(isFavorite){
       return getAllReports(reports).filter(report => (report.isFavorite) && (!reportSearchKeyword || reportSearchKeyword.length === 0 || report.nameLowered.includes(reportSearchKeyword.toLowerCase())) && compliesWithFilter(report, pageFilters));
    }else{
       return getAllReports(reports).filter(report => (!selectedCategory || selectedCategory.categoryId==null || report.categoryId === selectedCategory.categoryId) && (!reportSearchKeyword || reportSearchKeyword.length === 0 || report.nameLowered.includes(reportSearchKeyword.toLowerCase())) && compliesWithFilter(report, pageFilters));
    } 
}

const getFilteredReports = memoize((state: AppState): FilteredReport[] => {
    const { sortField, sortDirection, pinFavorites, getReportLoading } = state.reportReducer;

    const filtered =getReportsByFilter(false,state);  

    const filteredLoading = filtered.map(r => {
        return {
            ...r,
            isLoading: getReportLoading[r.reportId.toString()]
        }
    })

    if (pinFavorites) {
        const sorted = _orderBy(filteredLoading, [sortField], [sortDirection]);
        return _orderBy(sorted, ["isFavorite"], ["desc"]);
    }
    else {

        if (sortField !== "nameLowered") {
            return _orderBy(filteredLoading, [sortField, "nameLowered"], [sortDirection, "asc"]);
        }
        return _orderBy(filteredLoading, [sortField], [sortDirection]);
    }
}, {
    size: 50
});

const getFilteredFavoritedReports = memoize((state: AppState): FilteredReport[] => {
    const {  sortField, sortDirection, pinFavorites, getReportLoading } = state.reportReducer;

    const filtered =getReportsByFilter(true,state);

    const filteredLoading = filtered.map(r => {
        return {
            ...r,
            isLoading: getReportLoading[r.reportId.toString()]
        }
    })

    if (pinFavorites) {
        const sorted = _orderBy(filteredLoading, [sortField], [sortDirection]);
        return _orderBy(sorted, ["isFavorite"], ["desc"]);
    }
    else {

        if (sortField !== "nameLowered") {
            return _orderBy(filteredLoading, [sortField, "nameLowered"], [sortDirection, "asc"]);
        }
        return _orderBy(filteredLoading, [sortField], [sortDirection]);
    }
}, {
    size: 50
});


export const isReportsLoading = (state: AppState) => state.reportReducer.reportsLoading;

export const selectAllReports = (state: AppState) => getAllReports(state.reportReducer.reports);
export const selectFilteredReports = (state: AppState) => getFilteredReports(state);
export const selectFilteredFavoritedReports = (state: AppState) => getFilteredFavoritedReports(state);
export const selectFilteredReportTiles = (state: AppState) => _orderBy(getFilteredReports(state), ["categoryId", "nameLowered"]);
export const selectFilteredFavoritedReportTiles = (state: AppState) => _orderBy(getFilteredReports(state)?.filter(r => r.isFavorite === true), ["categoryId", "nameLowered"]);


export const selectFilteredReportCategories = memoize((state: AppState) => {
    const { reportCategories, reports } = state.reportReducer;
    var repCats = reports.flat().map(r => r.categoryId);

    const categories = [...reportCategories.filter(rc => {
        // only show the categories that have reports
        return repCats.some((c) => c === rc.categoryId)
    }),
    {
        categoryId: 0,
        name: "All",
        description: "All Categories",
        active: true,
    }];
    return _orderBy(categories, ["categoryId"], ["asc"]);
}, {
    size: 50
});

// export const selectAllReportCategories = memoize((state: AppState) => {
//     const { reportCategories } = state.reportReducer;

//     const categories = [...reportCategories,
//     // only show the categories that have reports
//     {
//         categoryId: 0,
//         name: "All",
//         description: "All Categories",
//         active: true,
//     }];
//     return _orderBy(categories, ["categoryId"], ["asc"]);
// }, {
//     size: 50
// });
export const selectAllReportCategories = memoize((state: AppState) => {
    const { reportCategories } = state.reportReducer;
    // return reportCategories;
    const categories = [...reportCategories,
            // only show the categories that have reports
            {
                categoryId: 0,
                name: "All",
                description: "All Categories",
                active: true,
            }];
            return _orderBy(categories, ["categoryId"], ["asc"]);
}, {
    size: 50
});

export const selectSelectedCategory = (state: AppState) => state.reportReducer.selectedCategory;

export const selectReportSearchKeyword = (state: AppState) => state.reportReducer.reportSearchKeyword;

export const selectSortField = (state: AppState) => state.reportReducer.sortField;
export const selectSortDirection = (state: AppState) => state.reportReducer.sortDirection;

export const pinFavoriteReports = (state: AppState) => state.reportReducer.pinFavorites;

export const selectSelectedReport = (state: AppState) => state.reportReducer.selectedReport;

export const selectFormReportTypes = (state: AppState) => state.reportReducer.reportTypes;
export const selectFormReportCategories = (state: AppState) => state.reportReducer.reportCategories;

export const selectSaved = (state: AppState) => state.reportReducer.saved;
export const selectDeleted = (state: AppState) => state.reportReducer.deleted;

export const selectIsRedirect = (state: AppState) => state.reportReducer.isRedirect;

export const selectReportFilterPanOpen = (state: AppState) => state.reportReducer.filterPaneOpen;
export const selectReportFilters = (state: AppState) => state.reportReducer.pageFilters;
export const selectAppliedFiltersCount = (state: AppState) => { return state.reportReducer.pageFilters.filter((f) => f.applied === true).length };

export const reportFilters = (state: AppState) => state.reportReducer.reportFilters;
export const reportSlicers = (state: AppState) => state.reportReducer.slicerStates;

export const selectIsCurrentlyExporting = (state: AppState) => state.reportReducer.isCurrentlyExporting;
export const selectStartExportLoading = (state: AppState) => state.reportReducer.startExportLoading;

export const selectReportIsTileView = (state: AppState) => state.reportReducer.isTileView;
