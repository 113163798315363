import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import "./UserManagement.scss";
import {
  FilteredUser,
  selectAreAllUsersChecked,
  selectFilteredUsers,
  selectLoadingUsers,
  selectNumUsersChecked,
  selectUserSortDirection,
  selectUserSortField,
} from "../../redux/selectors/user.selector";

import Button from "@mui/material/Button";
import SortIcon from "@mui/icons-material/Sort";
import Filters from "../Filters/Filters";
import { CreateUserFilters } from "../../redux/models/filter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import {
  filterActions,
  FilteredList,
  FilteredObjectKeys,
} from "../../redux/actions/filters.actions";
import { userActions } from "../../redux/actions/user.actions";
import { AppState } from "../../redux/reducers";
import UserManagementComponent from "./UserManagementComponent";
import { adminActions } from "../../redux/actions/admin.actions";
import RoleDialog from "./RoleDialog";
import DashboardDialog from "./DashboardDialog";
import { reportActions } from "../../redux/actions/report.actions";
import DisableUserDialog from "./DisableUserDialog";
import ReportAccessDialog from "./ReportAccessDialog";
import SearchComponent from "../Common/SearchComponent";

function UserManagementPage() {
  const dispatch = useDispatch();
  const filteredUsers = useSelector(selectFilteredUsers);
  const usersLoading = useSelector(selectLoadingUsers);
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const sortField = useSelector(selectUserSortField);
  const sortDirection = useSelector(selectUserSortDirection);
  const filters = useSelector((state: AppState) => state.filterReducer.filters);
  const areAllUsersChecked = useSelector(selectAreAllUsersChecked);
  const numUsersChecked = useSelector(selectNumUsersChecked);
  const [roleDialogOpen, setRoleDialogOpen] = useState<boolean>(false);
  const [dashboardDialogOpen, setDashboardDialogOpen] =
    useState<boolean>(false);
  const [usersToEdit, setUsersToEdit] = useState<FilteredUser[]>([]);
  const [disableDialogOpen, setDisableDialogOpen] = useState<boolean>(false);
  const [reportDialogOpen, setReportDialogOpen] = useState<boolean>(false);
  const [userToDisable, setUserToDisable] = useState<FilteredUser>();

  const applyFilters = () => {
    dispatch(filterActions.applyFilters());
  };

  const clearAllFilters = () => {
    let defaultFilters = CreateUserFilters();
    dispatch(filterActions.setFilters(defaultFilters));
  };

  const clearFilter = (filterName: string) => {
    dispatch(filterActions.clearFilter(filterName));
  };

  const setFilters = (filters: FilteredList) => {
    dispatch(filterActions.setFilters(filters));
  };

  const getSortIconClassName = (sortName: FilteredObjectKeys) => {
    if (sortField === sortName) {
      if (sortDirection === "asc") return "selected reverse";
      return "selected";
    }
    return "";
  };

  const openRoleDialog = (users: FilteredUser[]) => {
    setUsersToEdit([...users]);
    setRoleDialogOpen(true);
    setDashboardDialogOpen(false);
    setReportDialogOpen(false);
  };

  const openDashboardDialog = (users: FilteredUser[]) => {
    setUsersToEdit([...users]);
    setRoleDialogOpen(false);
    setDashboardDialogOpen(true);
    setReportDialogOpen(false);
  };

  const openReportDialog = (users: FilteredUser[]) => {
    setUsersToEdit([...users]);
    setRoleDialogOpen(false);
    setDashboardDialogOpen(false);
    setReportDialogOpen(true);
  };

  const handleActiveToggleChanged = (user: FilteredUser, checked: boolean) => {
    console.log(
      `Changing ${user.emailAddress} from ${user.active} to ${checked}`
    );
    if (!user.isLoading) {
      if (checked) dispatch(userActions.updateUserActive(user.userId, checked));
      else {
        // show confirmation dialog if disabling
        setUserToDisable(user);
        setDisableDialogOpen(true);
      }
    }
  };

  /**
   * Called when initially rendered
   */
  useEffect(() => {
    dispatch(filterActions.setFilters(CreateUserFilters()));
    dispatch(userActions.getUsers());
    dispatch(adminActions.getSecurityRoles());
    dispatch(reportActions.getReports(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("check changed");
    setUsersToEdit(filteredUsers.filter((f) => f.checked));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numUsersChecked]);

  return (
    <div id="user-management-page">
      {/* {deleteDialogOpen && (
            <DeleteReportDialog open={true} setOpen={setDeleteDialogOpen} />
          )}
          {unfavoriteDialogOpen && getUnfavoriteDialog()} */}
      {disableDialogOpen && userToDisable && (
        <DisableUserDialog
          userToDisable={userToDisable}
          open={disableDialogOpen}
          setOpen={setDisableDialogOpen}
        />
      )}
      {roleDialogOpen && usersToEdit && (
        <RoleDialog
          users={usersToEdit}
          isOpen={roleDialogOpen}
          onClose={() => setRoleDialogOpen(false)}
        />
      )}
      {dashboardDialogOpen && usersToEdit && (
        <DashboardDialog
          users={usersToEdit}
          isOpen={dashboardDialogOpen}
          onClose={() => setDashboardDialogOpen(false)}
        />
      )}
      {reportDialogOpen && usersToEdit && (
        <ReportAccessDialog
          users={usersToEdit}
          isOpen={reportDialogOpen}
          onClose={() => setReportDialogOpen(false)}
        />
      )}
      <div className="page-header">User Management</div>
      <div className="page-header-wrapper">
        <div className="page-filters">
          <Filters
            isOpen={filtersOpen}
            setIsOpen={setFiltersOpen}
            filters={filters}
            setFilters={setFilters}
            applyFilters={applyFilters}
            clearFilter={clearFilter}
            clearAllFilters={clearAllFilters}
            appliedFiltersCount={filters.filter((f) => f.applied).length}
          />
        </div>
      </div>

      <div id="um-list-container">
        <div id="um-list-title">
          <div id="um-batch-actions-container">
            <div style={{ width: "110px" }}>
              <div>Batch Actions:</div>
              <div className="subtitle">
                {numUsersChecked > 0 ? `# selected: ${numUsersChecked}` : ""}
              </div>
            </div>
            <Button
              disabled={numUsersChecked === 0}
              onClick={() =>
                openDashboardDialog(filteredUsers.filter((f) => f.checked))
              }
            >
              Change Default Dashboard
            </Button>
            <Button
              disabled={numUsersChecked === 0}
              onClick={() =>
                openRoleDialog(filteredUsers.filter((f) => f.checked))
              }
            >
              Change Role
            </Button>
            <Button
              disabled={numUsersChecked === 0}
              onClick={() =>
                openReportDialog(filteredUsers.filter((f) => f.checked))
              }
            >
              Change Report Access
            </Button>
          </div>
          <div className="vertical-divider"></div>
          <SearchComponent/>
        </div>

        <TableContainer
          style={{
            overflow:
              usersLoading || filteredUsers.length === 0 ? "hidden" : "auto",
          }}
        >
          <Table stickyHeader size="small" aria-label="User Management">
            <TableHead>
              <TableRow className="table-header">
                <TableCell align="center" style={{ width: "8%", minWidth: 20 }}>
                  <Checkbox
                    size="small"
                    checked={areAllUsersChecked ?? false}
                    onChange={(e) =>
                      dispatch(userActions.checkAllUsers(e.target.checked))
                    }
                  />
                </TableCell>

                <TableCell
                  align="left"
                  style={{ minWidth: 200, maxWidth: 300 }}
                >
                  <div
                    className="sortable"
                    onClick={() =>
                      dispatch(
                        filterActions.setSortField<keyof FilteredUser>(
                          "emailAddress"
                        )
                      )
                    }
                    title="Sort"
                  >
                    Email Address
                    <SortIcon
                      className={getSortIconClassName("emailAddress")}
                    />
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  style={{ maxWidth: 110, minWidth: 110 }}
                >
                  <div
                    className="sortable"
                    onClick={() =>
                      dispatch(
                        filterActions.setSortField<keyof FilteredUser>(
                          "ampUserId"
                        )
                      )
                    }
                    title="Sort"
                  >
                    AMPUserId
                    <SortIcon className={getSortIconClassName("ampUserId")} />
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  style={{ maxWidth: 165, minWidth: 185 }}
                >
                  <div
                    className="sortable"
                    onClick={() =>
                      dispatch(
                        filterActions.setSortField<keyof FilteredUser>(
                          "lastSignInDateTime"
                        )
                      )
                    }
                    title="Sort"
                  >
                    Last Login
                    <SortIcon
                      className={getSortIconClassName("lastSignInDateTime")}
                    />
                  </div>
                </TableCell>

                <TableCell
                  align="center"
                  style={{ minWidth: 165, maxWidth: 185 }}
                >
                  <div
                    className="sortable"
                    onClick={() =>
                      dispatch(
                        filterActions.setSortField<keyof FilteredUser>(
                          "b2CObjectId"
                        )
                      )
                    }
                    title="Sort"
                  >
                    B2CObjectId
                    <SortIcon className={getSortIconClassName("b2CObjectId")} />
                  </div>
                </TableCell>

                <TableCell
                  align="right"
                  style={{ minWidth: 175, maxWidth: 195 }}
                >
                  Default Dashboard
                </TableCell>
                <TableCell
                  align="right"
                  style={{ minWidth: 125, maxWidth: 145 }}
                >
                  Report Access
                </TableCell>
                <TableCell
                  align="right"
                  style={{ minWidth: 110, maxWidth: 130 }}
                >
                  <div
                    className="sortable"
                    onClick={() =>
                      dispatch(
                        filterActions.setSortField<keyof FilteredUser>("role")
                      )
                    }
                    title="Sort"
                  >
                    Role
                    <SortIcon className={getSortIconClassName("role")} />
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: 110, minWidth: 110, maxWidth: 110 }}
                >
                  <div
                    className="sortable"
                    onClick={() =>
                      dispatch(
                        filterActions.setSortField<keyof FilteredUser>("active")
                      )
                    }
                    title="Sort"
                  >
                    Status
                    <SortIcon className={getSortIconClassName("active")} />
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>

            {/* Body */}
            <TableBody>
              {!usersLoading &&
                filteredUsers.map((user, i) => {
                  return (
                    <UserManagementComponent
                      key={i}
                      user={user}
                      openRoleDialog={openRoleDialog}
                      openDashboardDialog={openDashboardDialog}
                      openDisableDialog={handleActiveToggleChanged}
                      openReportAccessDialog={openReportDialog}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {usersLoading && (
          <div className="spinner">
            <CircularProgress size={30} />
          </div>
        )}

        {!usersLoading && filteredUsers.length === 0 && (
          <div className="no-users">No users Found!</div>
        )}
      </div>
    </div>
  );
}

export default UserManagementPage;
