import {
  Button,
  CircularProgress,
  Dialog,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationActions } from "../../redux/actions/notification.actions";
import { reportActions } from "../../redux/actions/report.actions";
import { INotificationType } from "../../redux/models/notificationType";
import {
  CreateDefaultReportCategory,
  ReportCategory,
} from "../../redux/models/reportCategory";
import { AppState } from "../../redux/reducers";
import {
  selectAllReports,
  selectFormReportCategories,
} from "../../redux/selectors/report.selector";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    padding: "6px 5px 5px 12px",
    color: "#00334e",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

interface IReportCategoryFormProps {
  open: boolean;
  handleClose: () => void;
  categoryToEdit?: ReportCategory;
}
const ReportCategoryForm = ({
  open,
  handleClose,
  categoryToEdit,
}: IReportCategoryFormProps) => {
  const dispatch = useDispatch();
  const isAdding = categoryToEdit === undefined;

  const { reportReducer } = useSelector((state: AppState) => state);
  const { loadingAdd, loadingUpdate, loadingDelete } = reportReducer;
  const reportCategories = useSelector(selectFormReportCategories);
  const reports = useSelector(selectAllReports);
  const [localCategory, setLocalCategory] = useState<ReportCategory>(
    categoryToEdit ? { ...categoryToEdit } : CreateDefaultReportCategory()
  );
  const [action, setAction] = useState<"add" | "update" | "delete" | null>(
    null
  );
  const [deletePage2Open, setDeletePage2Open] = useState<boolean>(false);
  const [selectedNextCategoryId, setSelectedNextCategoryId] = useState<
    number | null
  >(null);
  const canSubmit =
    localCategory?.name?.length > 0 &&
    !loadingAdd &&
    !loadingUpdate &&
    !loadingDelete;

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (localCategory) {
      setLocalCategory({ ...localCategory, name: e.target.value });
    }
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (localCategory) {
      setLocalCategory({ ...localCategory, description: e.target.value });
    }
  };

  const handleAdd = () => {
    dispatch(reportActions.AddReportCategory(localCategory));
  };

  const handleUpdate = () => {
    dispatch(reportActions.updateReportCategory(localCategory));
  };

  const handleDelete = () => {
    if (deletePage2Open && selectedNextCategoryId !== null) {
      dispatch(
        reportActions.deleteReportCategory(
          localCategory.categoryId,
          selectedNextCategoryId
        )
      );
    }
    if (reports.some((r) => r.categoryId === localCategory.categoryId)) {
      setDeletePage2Open(true);
    } else
      dispatch(
        reportActions.deleteReportCategory(localCategory.categoryId, null)
      );
  };

  useEffect(() => {
    if (open) {
      if (categoryToEdit) setLocalCategory({ ...categoryToEdit });
      else setLocalCategory(CreateDefaultReportCategory());
    }
  }, [open, categoryToEdit]);

  useEffect(() => {
    if (!reports || reports.length === 0) {
      dispatch(reportActions.getReports(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  useEffect(() => {
    if (loadingAdd) setAction("add");
    else if (loadingUpdate) setAction("update");
    else if (loadingDelete) setAction("delete");
  }, [loadingAdd, loadingUpdate, loadingDelete]);

  /**
   * Reset choice every time this opens
   */
  useEffect(() => {
    if (deletePage2Open) {
      setSelectedNextCategoryId(null);
    }
  }, [deletePage2Open]);

  /**
   * Show notifications after actions
   */
  useEffect(() => {
    if (action === "add" || action === "update") {
      if (
        reportCategories.find(
          (rc) =>
            rc.name === localCategory.name &&
            rc.description === localCategory.description &&
            rc.active === localCategory.active
        ) !== undefined
      ) {
        dispatch(
          notificationActions.setNotification(
            INotificationType.Success,
            `Report Category "${localCategory.name}" was successfully ${
              action === "add" ? "added" : "updated"
            }`,
            false
          )
        );
        console.log("closing report category form");
        handleClose();
      }
    } else if (action === "delete") {
      if (
        reportCategories.find(
          (rc) => rc.categoryId === localCategory.categoryId
        ) === undefined
      ) {
        dispatch(
          notificationActions.setNotification(
            INotificationType.Success,
            `Report Category "${localCategory.name}" was successfully deleted`,
            false
          )
        );
        console.log("closing report category form");

        handleClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportCategories]);

  const handleNewCategoryChange = (
    event: SelectChangeEvent<number | string>
  ) => {
    setSelectedNextCategoryId(+event.target.value);
  };

  const getDeletePage2 = (): JSX.Element[] => {
    return [
      <div key="1" className="dialog-title">
        {`Deleting "${localCategory.name}"`}
      </div>,
      <div key="2" className="dialog-content">
        <p>
          <span className="warning">Warning:</span> There are reports with this
          category. Please select a category that those reports will now use, or
          close this prompt and change them individually before proceeding.
        </p>
        <Select
          id="existing-dashboard-select"
          className="select-lable select-xlarge"
          value={selectedNextCategoryId ?? ""}
          onChange={handleNewCategoryChange}
          input={<Input />}
          IconComponent={KeyboardArrowDownIcon}
          style={{ position: "relative", width: "100%" }}
          displayEmpty
          renderValue={(selected) => {
            if (selected === 0 || selected === "") {
              return <em>Choose...</em>;
            }
            let r = reportCategories.find((rc) => rc.categoryId === selected)!;
            return r.name;
          }}
        >
          {reportCategories
            .filter((rc) => rc.categoryId !== localCategory.categoryId)
            .map((d, i) => {
              return (
                <MenuItem
                  key={i}
                  value={d.categoryId}
                  className="pd-menuitem medium-text blue-text"
                >
                  <div className="pd-menuitem-inner">
                    <div className="db-name">{d.name}</div>
                  </div>
                </MenuItem>
              );
            })}
        </Select>
        <div className="dialog-title">{`Reports with category "${localCategory.name}"`}</div>
        <ul className="rcf-reports-same-list">
          {reports
            .filter((r) => r.categoryId === localCategory.categoryId)
            ?.map((r) => {
              return <li key={r.reportId}>{r.name}</li>;
            })}
        </ul>
      </div>,
      <div key="3" className="dialog-footer">
        <hr className="warning" />
        <Button
          onClick={() => setDeletePage2Open(false)}
          className="btn-cancel medium-text"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            setDeletePage2Open(false);
            handleClose();
          }}
          className="btn-cancel medium-text"
        >
          Close
        </Button>
        <Button
          onClick={handleDelete}
          className="btn-delete medium-text"
          disabled={selectedNextCategoryId === null}
        >
          {loadingDelete ? <CircularProgress size={20} /> : "Proceed"}
        </Button>
      </div>,
    ];
  };

  return (
    <Dialog open={open} onClose={handleClose} className="report-category">
      {deletePage2Open
        ? getDeletePage2()
        : [
            <div key="1" className="dialog-title">
              {isAdding
                ? "Add Report Category"
                : `Editing Category - "${categoryToEdit?.name}"`}
                <span onClick={handleClose} className="close-icon" onKeyDown={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19 5L5 19" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M5 5L19 19" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </span>
            </div>,
            <div key="2" className="dialog-content">
                <div className="select-lable">{isAdding ? "Name" : "Update Name"}</div>
                <input
                  placeholder="Enter name here"
                  value={localCategory?.name}
                  onChange={handleNameChange}
                />
                <div className="label" style={{ marginTop: "20px" }}>
                  {isAdding
                    ? "Description (optional)"
                    : "Update Description (optional)"}
                </div>
                <input
                  placeholder={
                    isAdding
                      ? "Enter description here"
                      : localCategory?.description ?? "Enter description here"
                  }
                  value={localCategory?.description}
                  onChange={handleDescriptionChange}
                />
            </div>,
            <div key="3" className="dialog-footer">
              <Button
                onClick={handleClose}
                className="btn-cancel"
              >
                Cancel
              </Button>
              {isAdding && (
                <Button
                  onClick={handleAdd}
                  className="btn-save"
                  disabled={!canSubmit}
                >
                  {loadingAdd ? <CircularProgress size={20} /> : "Add"}
                </Button>
              )}
              {!isAdding && (
                <Button
                  onClick={handleDelete}
                  className="btn-delete delete medium-text"
                  disabled={
                    reports.some(
                      (r) => r.categoryId === localCategory.categoryId
                    ) && reportCategories.length === 1
                  }
                >
                  {loadingDelete ? <CircularProgress size={20} /> : "Delete"}
                </Button>
              )}
              {!isAdding && (
                <Button
                  onClick={handleUpdate}
                  className="btn-save medium-text"
                  disabled={!canSubmit}
                >
                  {loadingUpdate ? <CircularProgress size={20} /> : "Update"}
                </Button>
              )}
            </div>,
          ]}
    </Dialog>
  );
};

export default ReportCategoryForm;
