import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import "../../Common/DialogRight.scss";
import "./AddReport.scss";
import { Field, Form } from "react-final-form";
import { GenericSelectItem, getPowerBIReports, getPowerBiWorkSpaces, getReportTypeCategories, getTypes, renderSelectedCateogry, selectCategoryRequired } from "../../ReportForm/ReportFormUtils";
import { CircularProgress, Grid, MenuItem, Tooltip, Typography } from "@mui/material";
import { FormApi } from "final-form";
import { useHistory, useParams } from "react-router-dom";
import { adminActions } from "../../../redux/actions/admin.actions";
import { reportActions } from "../../../redux/actions/report.actions";
import { Report } from "../../../redux/models/report";
import { ReportCategory } from "../../../redux/models/reportCategory";
import { selectpowerBIReports, selectpowerBIWorkspaces, selectloadingPowerBIReports, selectloadingPowerBIWorkspaces } from "../../../redux/selectors/admin.selector";
import { selectSelectedReport, selectFormReportTypes, selectFormReportCategories, selectSaved } from "../../../redux/selectors/report.selector";
import ReportCategoryForm from "../../ReportForm/ReportCategoryForm";
import { guidRequired } from "../../Common/Utils";


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '45% ',
    },
  },
};

export interface AddEditReportDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  addingReport?: boolean;
  action:string;
  type:string;
  selectedCategoryId:number;
}



function AddEditReportDialog({
  open,
  setOpen,
  addingReport,
  action,
  type,
  selectedCategoryId
}: AddEditReportDialogProps) {

  
  interface SelectProps {
    input: any;
    label: string;
    children: GenericSelectItem[];
    disabled: boolean;
    control: any;
    meta: any;
  }
  

    const dispatch = useDispatch();
  const history = useHistory();

  const reportCategories = useSelector(selectFormReportCategories);
  const { id } = useParams<{ id: string }>();
  const [isCategoryDialogOpen, setIsCategoryDialogOpen] =
    useState<boolean>(false);
  const [categoryToEdit, setCatagoryToEdit] = useState<
    ReportCategory | undefined
  >();
  const [localWorkspaceId, setLocalWorkspaceId] = useState<string | undefined>(
    undefined
  );
  const [localReportId, setLocalReportId] = useState<string | undefined>(
    undefined
  );
  const [selectedReport,setSelectedReport] = useState<Report | null>(useSelector(selectSelectedReport));
  const reportTypes = useSelector(selectFormReportTypes);
  const saved = useSelector(selectSaved);
  const _powerBIReports = useSelector(selectpowerBIReports);
  const _powerBIWorkspaces = useSelector(selectpowerBIWorkspaces);
  const loadingPowerBIReports = useSelector(selectloadingPowerBIReports);
  const loadingPowerBIWorkspaces = useSelector(selectloadingPowerBIWorkspaces);
  const [isSaved,setIsSaved]=useState(false);
  

  useEffect(() => {
    dispatch(reportActions.getReportCategories());
    dispatch(reportActions.getReportTypes());
    dispatch(adminActions.getPowerBIWorkspaces());
    if (action === "add") {
      const reportTypeId=type&&type==='dashboard'?1:0
      let defaultReport = {
        reportId:0,
        name: "",
        description: "",
        longRunningReport: true,
        embedURL: "",
        active: true,
        reportTypeId: reportTypeId===0&&type&&type=='report'?2:reportTypeId,
        categoryId: selectedCategoryId|0,
        defaultReport: false,
        powerBIReportId: "",
        powerBIWorkspaceId: ""
    }
    setSelectedReport(defaultReport);
    console.log(selectedCategoryId);
    } else if(id){
      dispatch(reportActions.getReportById(parseInt(id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action === "add" && saved) {
      dispatch(reportActions.setSaved(false));
      if(type&&type.toLowerCase()=='dashboard'){
        if(isSaved)
        history.goBack()
    }
    else{
      history.push('/');
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saved]);

  useEffect(() => {
    if (
      action === "edit" &&
      _powerBIWorkspaces.length > 0 &&
      selectedReport?.powerBIWorkspaceId
    ) {
      dispatch(
        adminActions.getPowerBIReports(selectedReport?.powerBIWorkspaceId)
      );
    }
  }, [_powerBIWorkspaces]);

  const getCategories = (
    form: FormApi<any, Partial<any>>
  ): GenericSelectItem[] => {
    return categories.map((c) => {
      return {
        ...c,
        form: form,
      };
    });
  };

  

   


  const categories: GenericSelectItem[] =getReportTypeCategories(reportCategories,setCatagoryToEdit,setIsCategoryDialogOpen);


  const powerBIWorkspaces: GenericSelectItem[] =getPowerBiWorkSpaces(_powerBIWorkspaces);
  
  const powerBIReports: GenericSelectItem[] = getPowerBIReports(_powerBIReports);
  
  const types:GenericSelectItem[]=getTypes(reportTypes);
  

  const ReportTypeInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: action === "add" ? "rgba(85, 136, 163, 0.2)" : "#e8e8e8",
      borderBottom:
        action === "add" ? "2px solid #5588a3" : "2px solid #959494",
      fontSize: "1em",
      padding: "10px 16px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        border: "2px solid #5588a3",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e8e8e8",
      },
    },
  }));

  const CategoryInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
      backgroundColor: "rgba(85, 136, 163, 0.2)",
      borderBottom: "2px solid #5588a3",
      fontSize: "1em",
      padding: "10px 16px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        border: "2px solid #5588a3",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e8e8e8",
      },
    },
  }));

  const required = (value: any) =>
    value && value.length > 0 ? undefined : "is required";
  const selectRequired = (value: any) =>
    value && value > 0 ? undefined : "is required";
  
  
  const onChange = () => {
    dispatch(reportActions.setSaved(false));
  };

  const getMenuItem = (val: GenericSelectItem) => {
    return (
      <MenuItem
        key={val.value}
        value={val.value}
        className="medium-text list-item"
      >
        <div className="rf-menu-item-inner">
          <div className={val.icon?'inline-container':''}>{val.name}</div>
          {val.icon && (
            <Tooltip title={val.iconTooltip ?? ""} placement="right">
              {val.icon}
            </Tooltip>
          )}
          {val.subtitle && <span className="subtitle">{val.subtitle}</span>}
        </div>
      </MenuItem>
    );
  };

  const renderReportTypeSelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (!disabled) {
            input.onChange(e);
            onChange();
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => renderReportTypeValue(selected)}
      >
        {getReportTypeMenuItems(children)}
      </Select>
      {touched && error && (
        <span className="error small-text">
          {label} {error}
        </span>
      )}
    </div>
  );

  const getReportTypeMenuItems=(children:GenericSelectItem[])=>{
    return children.map((val) =>{
      if(type&&type=='dashboard'){
      if(val.name.toLowerCase()=='interactive'){
        return getMenuItem(val)
      }
    }
    else{
     return getMenuItem(val)
    }
  } )
  }

  

  const renderReportTypeValue=(selected: string | number) =>{
  
    if (reportTypes.length == 0 || selected == 0 ||selected == "") {
      return <em>Choose...</em>;
    }
  if(reportTypes[Symbol.iterator]&& typeof reportTypes[Symbol.iterator]==='function'){
      let r = reportTypes?.find((rt) => rt.reportTypeId == selected)!;
    return r.name;
  }
  
  }

  const renderCategorySelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (e.target.value === -1) {
            console.log("adding");
            setCatagoryToEdit(undefined);
            setIsCategoryDialogOpen(true);
          } else if (!disabled) {
            input.onChange(e);
            onChange();
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => renderSelectedCateogry(selected,reportCategories)}
      >
        {children.map((val) => {
           if(selectedCategoryId&&selectedCategoryId!=null&&action!=='edit'){
            if(val.value==selectedCategoryId){
              return getMenuItem(val)
            }
          }
          else{
           return getMenuItem(val)
          }
          })}
      </Select>
      {touched && error && (
        <span className="error-txt">
           {`${label} ${error}`}
        </span>
      )}
    </div>
  );

  const renderWorkspaceSelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select workspace-dropdown"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (!disabled) {
            input.onChange(e);
            onChange();
            let w = powerBIWorkspaces.find(
              (pw) => pw.value === e.target.value
            )!;
            dispatch(adminActions.getPowerBIReports(w.value.toString()));
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (loadingPowerBIWorkspaces) {
            return "Loading...";
          }
          if (
            powerBIWorkspaces.length === 0 ||
            selected === 0 ||
            selected === ""
          ) {
            return <em>Choose...</em>;
          }
          let w = powerBIWorkspaces.find((pw) => pw.value === selected);
          if (w) return w.name;
          else return "Error: Not found";
        }}
        MenuProps={MenuProps}
      >
        {children.map((val) => getMenuItem(val))}
      </Select>
      {touched && error && (
        <span className="error-txt">
           {`${label} ${error}`}
        </span>
      )}
      {!error && localWorkspaceId && !loadingPowerBIWorkspaces && (
        <span className="info small-text">{localWorkspaceId}</span>
      )}
    </div>
  );

  const renderReportSelectField = ({
    input,
    label,
    children,
    disabled,
    control,
    meta: { touched, error },
  }: SelectProps) => (
    <div>
      <Select
        className="select"
        value={input?.value === 0 ? "" : input.value}
        onChange={(e: SelectChangeEvent<number | string>) => {
          if (!disabled) {
            input.onChange(e);
            onChange();
          }
        }}
        input={control}
        disabled={disabled}
        displayEmpty
        renderValue={(selected) => {
          if (loadingPowerBIWorkspaces || loadingPowerBIReports) {
            return "Loading...";
          }
          if (
            powerBIReports.length === 0 ||
            selected === 0 ||
            selected === ""
          ) {
            return <em>Choose...</em>;
          }

          let w = powerBIReports.find((pw) => pw.value === selected);
          if (w) return w.name;
          else return <em>Error: Not found in workspace</em>;
        }}
        MenuProps={MenuProps}
      >
        {children.map((val) => getMenuItem(val))}
      </Select>
      {touched && error && (
        <span className="error-txt">
           {`${label} ${error}`}
        </span>
      )}
      {!error &&
        localReportId &&
        !loadingPowerBIWorkspaces &&
        !loadingPowerBIReports && (
          <span className="info small-text">{localReportId}</span>
        )}
      {localWorkspaceId &&
        powerBIReports.length === 0 &&
        !loadingPowerBIReports &&
        !loadingPowerBIWorkspaces && (
          <span className="error-txt">
            There are no reports in the chosen Workspace
          </span>
        )}
    </div>
  );

  const formDebug = (state: any, fieldStates: any) => {
    const w_id = state.values?.selectedReport?.powerBIWorkspaceId;
    if (w_id) setLocalWorkspaceId(w_id);
    const r_id = state.values?.selectedReport?.powerBIReportId;
    if (r_id) setLocalReportId(r_id);
  };
  const [isStyleOpen, setStyleOpen]=useState<boolean>(open);
  useEffect(()=>{
    setTimeout(()=>{
      setStyleOpen(open);
    },500)
  },[open])

  const handleClose = () => {
    setStyleOpen(false)
    setTimeout(()=>{
      setOpen(false);
    },200)
  };

  const handleAddCategory = ()=>{
    setCatagoryToEdit(undefined);
    setIsCategoryDialogOpen(true);
  }


  return (
    <div id="add-report-container">
      <ReportCategoryForm
          open={isCategoryDialogOpen}
          handleClose={() => setIsCategoryDialogOpen(false)}
          categoryToEdit={categoryToEdit}
        />
      <Dialog open={open} onClose={handleClose} className={isStyleOpen?"dialog-right in":"dialog-right"}>
        <div className="close" onClick={handleClose}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g id="ic">
                <path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
              </svg>
            </div>
        <div className="dialog-title">{action === "add" ?"Add" : "Edit"} Report</div>
        <div className="dialog-content" data-testid="dialog-content">
             
              <Form
          initialValues={{
            selectedReport: selectedReport,
          }}
          mutators={{
            resetCategory: (args, state, utils) => {
              utils.changeValue(state, "selectedReport.categoryId", () => 0);
            },
          }}
          debug={formDebug}
          onSubmit={(values: any) => {
            if (action === "add") {
              dispatch(reportActions.addReport([values.selectedReport]));
            } else {
              dispatch(reportActions.saveReport(values.selectedReport));
            }
            setOpen(false);
            setIsSaved(true);
          }}
          render={({ handleSubmit, form, submitting }) => (
            <form id="report-form" onSubmit={handleSubmit} onChange={onChange}>
                <div className="form-container">
                    <div className="select-lable"> Report Title 
                    <span className="mandatory"> *</span>
                    </div>
                    <Field 
                     name="selectedReport.name"
                     label="Report Title"
                     placeholder="Enter title"
                     validate={required}>
                      {props => (
                        <div>
                          <input
                            type="text"
                            placeholder={props.placeholder}
                            {...props.input}
                            disabled={props.disabled}
                          />
                          {props.meta.touched && props.meta.error && (
                          <span className="error-txt">
                            {`${props.label} ${props.meta.error}`}
                          </span>
                        )}
                        </div>
                      )}
                    </Field>
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                    
                        <div className="select-lable">Report Type <span className="mandatory">*</span></div>
                        <div className="control-cell">
                            <Field
                                name="selectedReport.reportTypeId"
                                component={renderReportTypeSelectField}
                                label="Report Type"
                                children={types}
                                disabled={ action !== "add" ||
                                (action === "add" && reportTypes.length === 0)}
                                control={<ReportTypeInput />}
                                validate={selectRequired}
                            />
                        </div>
                        </Grid>
                        <Grid item md={6} xs={12} sm={6}>
                        <div className="select-lable">Report Category <span className="mandatory">*</span>
                        <div className="add-new" onClick={handleAddCategory}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clipPath="url(#clip0_2705_65018)">
                              <path d="M7.99967 5.3335V10.6668M5.33301 8.00016H10.6663M14.6663 8.00016C14.6663 11.6821 11.6816 14.6668 7.99967 14.6668C4.31778 14.6668 1.33301 11.6821 1.33301 8.00016C1.33301 4.31826 4.31778 1.3335 7.99967 1.3335C11.6816 1.3335 14.6663 4.31826 14.6663 8.00016Z" stroke="#165CE8" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_2705_65018">
                                <rect width="16" height="16" fill="white"></rect>
                              </clipPath>
                            </defs>
                          </svg>
                          <Typography>Add New</Typography></div>
                        </div>
                        <div className="control-cell">
                            <div className="select-div">
                            <Field
                                name="selectedReport.categoryId"
                                component={renderCategorySelectField}
                                label="Category"
                                children={getCategories(form)}
                                disabled={categories.length === 1}
                                control={<CategoryInput />}
                                validate={(value)=>selectCategoryRequired(value,reportCategories)}
                            />
                            </div>
                        </div>
                        </Grid>
                    </Grid>
                    <div className="field-container">
                      <div className="select-lable">Workspace <span className="mandatory">*</span></div>
                          <div className="control-cell">
                              <Field
                              name="selectedReport.powerBIWorkspaceId"
                              component={renderWorkspaceSelectField}
                              label="Workspace"
                              children={powerBIWorkspaces}
                              disabled={
                                  action === "edit" ||
                                  loadingPowerBIWorkspaces ||
                                  powerBIWorkspaces.length === 0
                              }
                              control={<ReportTypeInput />}
                              validate={guidRequired}
                              />
                          </div>
                    </div>
                        
                    <div className="field-container">
                    <div className="select-lable">Report <span className="mandatory">*</span></div>
                    <div className="control-cell">
                        <Field
                        name="selectedReport.powerBIReportId"
                        component={renderReportSelectField}
                        label="Report"
                        children={powerBIReports}
                        disabled={
                            action === "edit" ||
                            loadingPowerBIReports ||
                            powerBIReports.length === 0
                        }
                        control={<ReportTypeInput />}
                        validate={guidRequired}
                        />
                        {(loadingPowerBIReports ||
                        loadingPowerBIWorkspaces) && (
                        <div className="loading">
                            <CircularProgress size={15} />
                        </div>
                        )}
                    </div>
                    </div>
                        
                    <div className="field-container">
                      <div className="select-lable">Report Info </div>
                      <div className="control-cell">
                          <Field
                          name="selectedReport.description"
                          component={"textarea"}
                          label="Report Info"
                          placeholder="Enter info about this report"
                          />
                      </div>
                    </div>
                        
                </div>
                <div className="dialog-footer">
                    <Button onClick={handleClose} className="btn-cancel medium-text">Cancel</Button>
                    <Button data-testid="save" type="submit" onClick={handleSubmit} className="btn-save medium-text" 
                    disabled={submitting}> Save</Button>
                </div>
            </form>
          )}
        />
            
        </div>
        
      </Dialog>
    </div>
  );
  
}

export default AddEditReportDialog;


