import { Dispatch } from "react"
import { SubscriptionEmailDownload } from "../models/subscriptionEmailDownload"
import { downloadService } from "../services/download.service"
import { IBaseAction } from "./base.action"

export interface IDownloadFileAction extends IBaseAction {
  readonly type: 'DOWNLOAD_FILE_REQUEST' | 'DOWNLOAD_FILE_SUCCESS' | 'DOWNLOAD_FILE_FAILURE';
  userReportHistoryExportDetailsId: number,
  blob?: Blob,
}

export interface IGetReportsFromEmailAction extends IBaseAction {
  readonly type: 'GET_REPORTS_BY_EMAIL_REQUEST' | 'GET_REPORTS_BY_EMAIL_SUCCESS' | 'GET_REPORTS_BY_EMAIL_FAILURE';
  userReportHistoryId: number,
  payload?: SubscriptionEmailDownload,
}


export type DownloadActions = IDownloadFileAction | IGetReportsFromEmailAction


const downloadFile = (userReportHistoryExportDetailsId: number) => {
  return function (dispatch: Dispatch<DownloadActions>) {
    dispatch({ type: 'DOWNLOAD_FILE_REQUEST', userReportHistoryExportDetailsId });
    downloadService.downloadFile(userReportHistoryExportDetailsId).then((response) => {
      return dispatch({ type: 'DOWNLOAD_FILE_SUCCESS', userReportHistoryExportDetailsId, blob: response })
    }, (error) => {
      return dispatch({ type: 'DOWNLOAD_FILE_FAILURE', userReportHistoryExportDetailsId, error })
    })
  }
}

const getReportsFromEmail = (emailId: number) => {
  return function (dispatch: Dispatch<IGetReportsFromEmailAction>) {
    dispatch({ type: 'GET_REPORTS_BY_EMAIL_REQUEST', userReportHistoryId: emailId });
    downloadService.getReportsFromEmail(emailId).then((response) => {
      return dispatch({ type: 'GET_REPORTS_BY_EMAIL_SUCCESS', userReportHistoryId: emailId, payload: response })
    }, (error) => {
      return dispatch({ type: 'GET_REPORTS_BY_EMAIL_FAILURE', userReportHistoryId: emailId, error })
    })
  }
}

export const downloadActions = {
  downloadFile,
  getReportsFromEmail
}