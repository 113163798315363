import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { SignInButton } from "../Auth/SignInbutton";
import "./Login.scss";

var sessionExpired: boolean | null = null;

function LoginPage() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    let timeout = new URLSearchParams(location.search).get("timeout");
    sessionExpired = timeout === "true";
    if (sessionExpired) {
      // Remove the timeout param in the url without re-rendering
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginMessage = sessionExpired
    ? "Your session has expired due to inactivity."
    : "Welcome to the Client Portal!";

  return (
    <div id="login-page">
      <img
        className="apex-logo"
        src="/apex-logo-h.png"
        alt="Apex Global - Insights Portal"
      ></img>

      <div id="login-container">
        <div>{loginMessage}</div>
        <SignInButton />
      </div>
    </div>
  );
}
export default LoginPage;
