import { useEffect, useState, useRef  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportActions } from "../../redux/actions/report.actions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "../ReportCatalog/ReportCatalog.scss";
import {
  selectFilteredReports,
  selectFilteredFavoritedReports,
} from "../../redux/selectors/report.selector";

import ViewReportPage from "../ViewReport/ViewReportPage";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import { Grid, Typography } from "@mui/material";
import { AppState } from "../../redux/reducers";
import SubscriptionWizard from "../SubscriptionWizard/SubscriptionWizard";
import { ReportActions } from "../ReportCatalog/ReportActions/ReportActions";

  

const RunReportPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const ref = useRef<any>(null);
  const reportReducer = useSelector((state: AppState) => state.reportReducer);

  let favoriteParam = new URLSearchParams(location.search).get("favorites");
  let favoriteValue = favoriteParam === "true";
  const fsr = useSelector(selectFilteredFavoritedReports);
  const fr = useSelector(selectFilteredReports);
  const filteredReports = favoriteValue ? fsr : fr;
  const {categoryId} = useParams<any>();
  const [dashboarReports, setDashboardReports] = useState<any>([]);
  const isAdmin = useSelector(isUserAdministrator)??false;
  const [isReportLoaded,setReportLoading]=useState<boolean>(false);
  const [count,setCount]=useState(0);
  const localReport = reportReducer.selectedReport;
  const [subscribeUsersDialogOpen,setSubscribeUsersDialogOpen]=useState<boolean>(false);
  const [subscribeDialogOpen,setSubscribeDialogOpen]=useState<boolean>(false);

  useEffect(() => {
    if(reportReducer.loading){
      setCount(count+1);
    }
    if((!reportReducer.loading&&count>0)||reportReducer.deleted){
      setReportLoading(true);
      console.log(isReportLoaded);
    }
    setDashboardReports(filteredReports.filter(rep=>rep.reportTypeId==1 && rep.categoryId == categoryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(dashboarReports);
  }, [filteredReports,reportReducer.loading]);

  useEffect(() => {
    dispatch(reportActions.clearAllFilters());
    if (!favoriteValue && location.search !== "") {
      history.replace(`/reports/catalog`);
    } else {
      dispatch(reportActions.getReports(true));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const handleSubscribe = () => {
    console.log(localReport);
    if(localReport){
      dispatch(reportActions.setSelectedReport(localReport));
      setSubscribeDialogOpen(true);
      ref.current?.handleSubscribeBtnClick();
    }	
  }
  const handleSubscribeUsers = () => {
    if(localReport){
      dispatch(reportActions.setSelectedReport(localReport));
      setSubscribeUsersDialogOpen(true);
      ref.current?.handleSubscribeUsersBtnClick();
    }	
  }

  return (
    <div id="report-catalog-main">
        {subscribeDialogOpen && (
        <SubscriptionWizard action="add" admin={false} open={subscribeDialogOpen}
        setOpen={setSubscribeDialogOpen} reportId={localReport?.reportId}/>	
      )}
      {subscribeUsersDialogOpen && (
        <SubscriptionWizard action="add" admin={true} open={subscribeUsersDialogOpen}
        setOpen={setSubscribeUsersDialogOpen} reportId={localReport?.reportId}/>	
      )}
        <Grid container spacing={2} className="breadcrumb-list">
          <Grid item xs={6} paddingLeft={10}>
            <Typography className="grid-item home-grid" onClick={()=>history.push("/")}>Home</Typography>
            <Typography className="grid-item"> / {localReport?.name}</Typography>
          </Grid>
          <Grid item xs={6} className="action-items">
            <ReportActions
              isAdmin={isAdmin}
              exportReport={ref.current?.callExportReport}
              setUnfavoriteDialogOpen={undefined}
              reportType={localReport?.reportTypeId}
              printReport={ref.current?.callPrintReport}
              handleSubscribe={handleSubscribe}
              handleSubscribeUsers={handleSubscribeUsers}
              selectedReport={localReport}
              refreshReport={ref.current?.refreshReport}
              handleFullScreen={ref.current?.handleFullScreen}
              isReportLoaded={ref.current?.isReportLoaded}
              selectedTab={0}
            />
         </Grid>
        </Grid>
        <ViewReportPage ref={ref}/>
    </div>
  );
};

export default RunReportPage;
