import { FormApi } from "final-form";
import EditIcon from "@mui/icons-material/EditOutlined";
import { ReportCategory } from "../../redux/models/reportCategory";

export interface GenericSelectItem {
    value: number | string;
    name: string;
    icon?: JSX.Element;
    iconTooltip?: string;
    subtitle?: string;
    form?: FormApi<any, Partial<any>>;
    className?:string;
  }
export const getPowerBiWorkSpaces=(_powerBIWorkspaces:any)=>{
    let powerBIWorkspaces: GenericSelectItem[] =[];
    if(_powerBIWorkspaces[Symbol.iterator]&& typeof _powerBIWorkspaces[Symbol.iterator]==='function'){
      _powerBIWorkspaces?.map((w:any)=>{
      const obj={
        value: w.workspaceId,
        name: w.name,
      } as GenericSelectItem;
      powerBIWorkspaces.push(obj);
    })
  }
    return powerBIWorkspaces;
  }
export const getPowerBIReports=(_powerBIReports:any)=>{
    let powerBIReports: GenericSelectItem[] =[];
    if(_powerBIReports[Symbol.iterator]&& typeof _powerBIReports[Symbol.iterator]==='function'){
      for(const element of _powerBIReports){
      const r=element;
      const obj={
        value: r.id,
        name: r.name,
        subtitle: r.id,
      } as GenericSelectItem;
      powerBIReports.push(obj);
    }
  }
    return powerBIReports;
  }
export const getTypes=(reportTypes:any)=>{
    let powerBIReports: GenericSelectItem[] =[];
    if(reportTypes[Symbol.iterator]&& typeof reportTypes[Symbol.iterator]==='function'){
      for(const element of reportTypes){
          const t=element;
          const obj={
            value: t.reportTypeId,
            name: t.name,
            itemType: "reportType",
          } as GenericSelectItem;
          powerBIReports.push(obj);
        }
      }
    return powerBIReports;
  }
export  const getReportTypeCategories=(reportCategories:ReportCategory[],setCatagoryToEdit:any,setIsCategoryDialogOpen:any)=>{
    let categoriesData:GenericSelectItem[]=[];
    if(reportCategories[Symbol.iterator]&& typeof reportCategories[Symbol.iterator]==='function'){
      for(const element of reportCategories) {
        const t=element;
        let robj = {
          value: t.categoryId,
          name: t.name,
          icon: (
            <EditIcon
              fontSize="small"
              onClick={(e) => {
                e.stopPropagation();
                console.log("editing");
                console.log(t);
                setCatagoryToEdit(t);
                setIsCategoryDialogOpen(true);
              }}
            />
          ),
          iconTooltip: "Edit",
        } as GenericSelectItem;
        categoriesData.push(robj);
      }
    }
      return categoriesData;
      
  }
export const selectCategoryRequired = (value: any,reportCategories:any) => {
  let found = false;
        if(reportCategories[Symbol.iterator]&& typeof reportCategories[Symbol.iterator]==='function'){
          for(const element of reportCategories){
            const rc=element;
            if(rc.categoryId === value)
            found=true;
          }
        console.log(`Found: ${found}`);
        return found && value && value > 0 ? undefined : "is required";
        }
};
export const renderSelectedCateogry=(selected:any,reportCategories:any)=>{
  let c;
        if(reportCategories[Symbol.iterator]&& typeof reportCategories[Symbol.iterator]==='function'){
          for(const element of reportCategories){
            const rc=element;
            if(rc.categoryId === selected)
              c=rc;
          }
          if (
            !c ||
            reportCategories.length === 0 ||
            selected === 0 ||
            selected === ""
          ) {
            return <em>Choose...</em>;
          }
          return c.name;
        }
}