import { useEffect, useState, useRef  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reportActions } from "../../redux/actions/report.actions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import "./ReportCatalog.scss";
import {
  selectFilteredReports,
  selectFilteredFavoritedReports,
  selectSelectedReport,
} from "../../redux/selectors/report.selector";

import ReportTableComponent from "./ReportTableComponent";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ViewReportPage from "../ViewReport/ViewReportPage";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import { Grid, Typography } from "@mui/material";
import { AppState } from "../../redux/reducers";
import { ReportActions } from "./ReportActions/ReportActions";
import AddEditReportDialog from "./AddReport/AddReport";
import NoReportsFound from "./NoReportsFound";
import SubscriptionWizard from "../SubscriptionWizard/SubscriptionWizard";

function TabPanel(props:any) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        className="tab-panel"
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index:number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

const ReportCatalogPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const ref = useRef<any>(null);
  const reportReducer = useSelector((state: AppState) => state.reportReducer);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [unfavoriteDialogOpen, setUnfavoriteDialogOpen] = useState(false);
  const currReport = useSelector(selectSelectedReport);

  let favoriteParam = new URLSearchParams(location.search).get("favorites");
  let favoriteValue = favoriteParam === "true";
  const fsr = useSelector(selectFilteredFavoritedReports);
  const fr = useSelector(selectFilteredReports);
  const filteredReports = favoriteValue ? fsr : fr;
  const [value, setValue] = useState<number>(0);
  const {categoryId} = useParams<any>();
  const [dashboardReports, setDashboardReports] = useState<any>([]);
  const isAdmin = useSelector(isUserAdministrator)??false;
  const [isReportLoaded,setIsReportLoaded]=useState<boolean>(false);
  const [count,setCount]=useState(0);
  const localReport = reportReducer.selectedReport;
  const [addReportDialogOpen,setAddReportDialogOpen]=useState<boolean>(false);
  const [subscribeUsersDialogOpen,setSubscribeUsersDialogOpen]=useState<boolean>(false);
  const [subscribeDialogOpen,setSubscribeDialogOpen]=useState<boolean>(false);

  const handleChange = (event:any, newValue:any) => {
    dispatch(reportActions.setSelectedReport(null));
    dispatch(reportActions.searchReports(""));
    setValue(newValue);
  };

  useEffect(() => {
    if(reportReducer.loading){
      setCount(count+1);
    }
    if((!reportReducer.loading&&count>0)||reportReducer.deleted){
      setIsReportLoaded(true);
    }
    setDashboardReports(filteredReports.filter(rep=>rep.reportTypeId==1 && rep.categoryId == categoryId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredReports,reportReducer.loading]);

  useEffect(() => {
    dispatch(reportActions.clearAllFilters());
    if (!favoriteValue && location.search !== "") {
      history.replace(`/reports/catalog`);
    } else {
      dispatch(reportActions.getReports(true));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(infoDialogOpen)
  }, [location]);

  useEffect(() => {
    if (!currReport) {
      setUnfavoriteDialogOpen(false);
      setInfoDialogOpen(false);
    }
  }, [currReport]);
	
  const handleSubscribe = () => {
    console.log(localReport);
    if(localReport){
      dispatch(reportActions.setSelectedReport(localReport));
      setSubscribeDialogOpen(true);
      ref.current?.handleSubscribeBtnClick();
    }
  }
  const handleSubscribeUsers = () => {
      dispatch(reportActions.setSelectedReport(localReport));
      setSubscribeUsersDialogOpen(true);
      ref.current?.handleSubscribeUsersBtnClick();
  }
  const handleAddReport = ()=>{
    setAddReportDialogOpen(true);
  }

  return (
    <div id="report-catalog-main">
      {addReportDialogOpen && (
        <AddEditReportDialog
          open={addReportDialogOpen}
          setOpen={setAddReportDialogOpen}
          action='add'
          type="dashboard"
          selectedCategoryId={categoryId}
        />
      )}
      {subscribeDialogOpen && (
        <SubscriptionWizard action="add" admin={false} open={subscribeDialogOpen}
        setOpen={setSubscribeDialogOpen} reportId={localReport?.reportId}/>	
      )}
      {subscribeUsersDialogOpen && (
        <SubscriptionWizard action="add" admin={true} open={subscribeUsersDialogOpen}
        setOpen={setSubscribeUsersDialogOpen} reportId={localReport?.reportId}/>	
      )}
        <Grid container spacing={2} className="breadcrumb-list">
          <Grid item xs={6} paddingLeft={10}>
            <Typography className="grid-item home-grid" onClick={()=>history.push("/")}>Home</Typography>
            <Typography className="grid-item"> / {localStorage.getItem("selectedCategory")}</Typography>
          </Grid>
          <Grid item xs={6} className="action-items">
            <ReportActions
              isAdmin={isAdmin}
              exportReport={ref.current?.callExportReport}
              setUnfavoriteDialogOpen={undefined}
              reportType={localReport?.reportTypeId}
              printReport={ref.current?.callPrintReport}
              handleSubscribe={handleSubscribe}
              handleSubscribeUsers={handleSubscribeUsers}
              selectedReport={localReport}
              refreshReport={ref.current?.refreshReport}
              handleFullScreen={ref.current?.handleFullScreen}
              isReportLoaded={ref.current?.isReportLoaded}
            />
         </Grid>
        </Grid>
      <Box sx={{ bgcolor: 'background.paper'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="full width tabs example"
          className="tabs-list"
        >
          <Tab label="Dashboard" {...a11yProps(0)} />
          <Tab label="Reports" {...a11yProps(1)} />
        </Tabs>
       
        <TabPanel value={value} index={0}>
          {dashboardReports?.length? 
          <ViewReportPage
           selectedReport={dashboardReports[0]}
           ref={ref}
            reportId={dashboardReports[0].reportId}/>
          :
          isReportLoaded&&<NoReportsFound isAdmin={isAdmin} favoriteParam={favoriteParam} handleAddReport={handleAddReport}/>
        }
        </TabPanel>
        <TabPanel value={value} index={1}>
        <ReportTableComponent
          unfavoriteDialogOpen={unfavoriteDialogOpen}
          setUnfavoriteDialogOpen={setUnfavoriteDialogOpen}
          setInfoDialogOpen={setInfoDialogOpen}
          reportList={filteredReports.filter(p=> p.categoryId == categoryId)}
        />
        </TabPanel>
    </Box>
    </div>
  );
};

export default ReportCatalogPage;
