import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedSubscription,
  selectSelectedFrequencyType,
} from "../../redux/selectors/subscription.selector";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import { ResetJobSchedule } from "../../redux/models/jobSchedule";
import DailyRecurrence from "./DailyRecurrence";
import WeeklyRecurrence from "./WeeklyRecurrence";
import MonthlyRecurrence from "./MonthlyRecurrence";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";

export interface RecurrenceFormProps {
  formData:any;
}

function RecurrenceForm({formData}:Readonly<RecurrenceFormProps>) {
  const dispatch = useDispatch();

  const selectedSubscription = useSelector(selectSelectedSubscription);
  const selectedFrequencyType = useSelector(selectSelectedFrequencyType);

  const handleFrequencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const jobSchedule = ResetJobSchedule(
      selectedSubscription.jobSchedule,
      formData.jobScheduleFreqType?.filter(
        (t:any) => t.jobScheduleFreqTypeId.toString() === event.target.value
      )[0]?.jobScheduleFreqTypeId
    );

    if (
      event.target.value ===
      formData.jobScheduleFreqType
        ?.filter((t:any) => t.description === "Daily")[0]
        .jobScheduleFreqTypeId.toString()
    ) {
      if (formData.excludeWeekends) {
        jobSchedule.jobScheduleDaysOfWeek = formData.daysOfWeek
          .filter((d:any) => !["Sa", "Su"].includes(d.shortName))
          .map((d:any) => {
            return {
              jobScheduleDayOfWeekId: 0,
              jobScheduleId: selectedSubscription.jobScheduleId,
              dayOfWeekId: d.dayOfWeekId,
            };
          });
      } else {
        jobSchedule.jobScheduleDaysOfWeek = formData.daysOfWeek.map((d:any) => {
          return {
            jobScheduleDayOfWeekId: 0,
            jobScheduleId: selectedSubscription.jobScheduleId,
            dayOfWeekId: d.dayOfWeekId,
          };
        });
      }
    }
    if (
      event.target.value ===
      formData.jobScheduleFreqType
        .filter((t:any) => t.description === "Monthly")[0]
        .jobScheduleFreqTypeId.toString()
    ) {
      jobSchedule.jobScheduleMonthlyRunDay = 1;
    }

    dispatch(subscriptionActions.setSubscriptionJobSchedule(jobSchedule));
  };

  const handleNotesChange = (value: string) => {
    dispatch(subscriptionActions.setSubscriptionNotes(value));
  };

  return (
    <div className="recurrence-form">
      <ToggleButtonGroup
              color="primary"
              value={selectedSubscription.jobSchedule.jobScheduleFreqTypeId > 0
                              ? selectedSubscription.jobSchedule.jobScheduleFreqTypeId
                              : selectedFrequencyType.jobScheduleFreqTypeId
                          }
              exclusive
              onChange={(event:any)=>{handleFrequencyChange(event)}}
              size="large"
              aria-label="Platform"
              data-testid="test-freqType"
            >
              {formData.jobScheduleFreqType.map((x:any) => {
              return<ToggleButton key={x.jobScheduleFreqTypeId} value={x.jobScheduleFreqTypeId}>
              {x.description}
                </ToggleButton>
              
               })
            }
              </ToggleButtonGroup>
              <div className="frequency-right">
              {selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
                formData.jobScheduleFreqType?.filter(
                  (t:any) => t.description === "Weekly"
                )[0]?.jobScheduleFreqTypeId && <WeeklyRecurrence formData={formData}/>}
              {selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
                formData.jobScheduleFreqType?.filter(
                  (t:any) => t.description === "Monthly"
                )[0]?.jobScheduleFreqTypeId && <MonthlyRecurrence formData={formData}/>}
              <DailyRecurrence formData={formData}/>
                
          </div>
          <Grid container spacing={1}>
        <Grid item xs={12} sm={3.6} md={selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
                  formData.jobScheduleFreqType?.filter(
                    (t:any) => t.description === "Weekly"
                  )[0]?.jobScheduleFreqTypeId?3:4} lg={selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
                    formData.jobScheduleFreqType?.filter(
                      (t:any) => t.description === "Weekly"
                    )[0]?.jobScheduleFreqTypeId?3:4} className="select-lable">
          Notes
        </Grid>
        <Grid item xs={12} sm={8} md={selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
                    formData.jobScheduleFreqType?.filter(
                      (t:any) => t.description === "Weekly"
                    )[0]?.jobScheduleFreqTypeId?9:8} lg={selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
                    formData.jobScheduleFreqType?.filter(
                      (t:any) => t.description === "Weekly"
                    )[0]?.jobScheduleFreqTypeId?9:8}>
        <textarea value={selectedSubscription.jobSchedule.notes} data-testid="test-notes"
              onChange={(event) => {
                handleNotesChange(event.target.value);
              }}
              placeholder="Add notes"
              maxLength={250}
              />
              <div
            className={
              250 - selectedSubscription.jobSchedule.notes.length === 0
                ? "small-error"
                : "info small"
            }
          >
            Limit: 250 characters (
            {250 - selectedSubscription.jobSchedule.notes.length} characters
            remaining)
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default RecurrenceForm;
