
export interface SubscriptionFormValidation {
  fileType: boolean;
    showFileTypeError: boolean;
    days: boolean;
    showDaysError: boolean;
    runTime: boolean;
    showRunTimeError: boolean;
    facilities: boolean;
    showFacilitiesError: boolean;
}


export function CreateDefaultSubscriptionFormValidation(): SubscriptionFormValidation {
  let ret: SubscriptionFormValidation = {
    fileType: false,
    showFileTypeError: false,
    days: false,
    showDaysError: false,
    runTime: true,
    showRunTimeError: false,
    facilities: true,
    showFacilitiesError: false,
  }
  return ret;
}

export function CreateEditSubscriptionFormValidation(): SubscriptionFormValidation {
  let ret: SubscriptionFormValidation = {
    fileType: true,
    showFileTypeError: false,
    days: true,
    showDaysError: false,
    runTime: true,
    showRunTimeError: false,
    facilities: true,
    showFacilitiesError: false,
  }
  return ret;
}