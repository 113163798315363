import CloseIcon from "@mui/icons-material/Close";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Embed, service, Visual } from "powerbi-client";
import { Widget } from "../../redux/models/widget";
import WidgetActions from "./WidgetActions";
import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";

export interface WidgetProps {
  widget: Widget;
  fullScreenOpen: boolean;
  openFullScreen: (id: number) => void;
  closeFullScreen: (id: number) => void;
}

function WidgetVisualComponent({
  widget,
  fullScreenOpen,
  openFullScreen,
  closeFullScreen,
}: WidgetProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerStyle, setContainerStyle] = useState<
    React.CSSProperties | undefined
  >(undefined);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [visual, setVisual] = useState<Visual>();
  const [embedObject, _setEmbedObject] = useState<Embed | null>(null);
  const embedObjectRef = React.useRef(embedObject);

  const setEmbedObject = (data: Embed | null) => {
    embedObjectRef.current = data;
    _setEmbedObject(data);
  };

  const embedSettings: models.ISettings = {
    layoutType: models.LayoutType.Custom,
    customLayout: {
      displayOption: models.DisplayOption.FitToWidth,
    },
  };

  var visualSlicers = widget.slicers ? JSON.parse(widget.slicers) : undefined;

  const visualConfig: models.IVisualEmbedConfiguration = {
    type: "visual",
    embedUrl: widget.widgetLayout?.embedUrl,
    pageName: widget.reportVisual!.pageName,
    visualName: widget.reportVisual!.visualName!,
    tokenType: models.TokenType.Embed,
    accessToken: widget.widgetLayout?.embedToken,
    // settings: embedSettings,
    slicers: visualSlicers,
  };

  const eventHandlersMap = new Map([
    [
      "loaded",
      function () {
        console.log("Report has loaded");
        console.log(loaded);
        setLoaded(true);
      },
    ],
    [
      "rendered",
      function () {
        console.log("Report has rendered");
      },
    ],
    [
      "error",
      function (event?: service.ICustomEvent<any>) {
        if (event) {
          console.error(event.detail);
        }
      },
    ],
  ]);

  const updateFullscreenPositionAndSize = () => {
    if (fullScreenOpen) {
      if (containerRef.current) {
        let cx = containerRef.current.getBoundingClientRect().x;
        let cy = containerRef.current.getBoundingClientRect().y;
        console.log("updating style");
        setContainerStyle({
          position: "absolute",
          top: -window.scrollY + -cy + 10,
          left: -cx + 10,
          width: "calc(100vw - 20px)",
          height: "calc(100vh - 20px)",
        });
      }
    }
  };

  const resizeEmbed = () => {
    if (embedObjectRef?.current) {
      var visual = embedObjectRef?.current as Visual;
      var iframe = visual.iframe;
      let tmpw = iframe.clientWidth;
      let tmph = iframe.clientHeight;
      // let c = iframe.contentWindow?.document.getElementsByClassName(
      //   "themableBorderColorSolid"
      // );
      // if (c) {
      //   for (var i = 0; i < c.length; i++) {
      //     let g = c[i] as HTMLElement;
      //     g.style.border = "none";
      //   }
      // }
      if (widget.widgetLayout) {
        console.log("resizing visual");
        visual.resizeActivePage(models.PageSizeType.Custom, tmpw, tmph);
        visual?.resizeVisual(
          widget!.reportVisual!.pageName!,
          widget!.reportVisual!.visualName!,
          tmpw,
          tmph
        );
      }
    }
  };

  useEffect(() => {
    if (widget.widgetLayout?.isFullScreen) {
      console.log(`Widget ${widget.widgetId} is fullscreen`);
      updateFullscreenPositionAndSize();
      let parentReactGridItem = containerRef.current?.parentElement;
      if (parentReactGridItem) {
        parentReactGridItem.style.zIndex = "2";
      }
    } else {
      setContainerStyle(undefined);
      let parentReactGridItem = containerRef.current?.parentElement;
      if (parentReactGridItem) {
        parentReactGridItem.style.zIndex = "unset";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.widgetLayout?.isFullScreen]);

  useEffect(() => {
    resizeEmbed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget.x, widget.y, widget.width, widget.height]);

  useEffect(() => {
    setTimeout(() => {
      // wait for the fullscreen animation to occur before resizing the embed
      resizeEmbed();
    }, 300);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerStyle]);

  useEffect(() => {
    if (visual) {
      var filters: models.IFilter[] = widget.filters
        ? JSON.parse(widget.filters)
        : undefined;
      if (filters) {
        visual.setFilters(filters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visual]);

  const escFunction = (e: any)=>{
    if (e.key === "Escape") {
      closeFullScreen(widget.widgetId!);
    }
  }

  useEffect(()=>{
    document.addEventListener("keyup", escFunction, false);
    return ()=>{
      document.addEventListener("keyup", escFunction, false);
    }
  },[])

  const showEmbed =
    // widget?.report?.reportTypeId === ReportTypeEnum.PowerBI_Report
    //   ? true
    //   :
    !!widget?.widgetLayout?.embedToken;

  console.log(`visual error: ${widget.widgetLayout?.errorMessage}`);

  if (widget.widgetLayout?.visible) {
    return (
      <div
        className="widget-container"
        ref={containerRef}
        style={containerStyle}
      >
        <div className="widget-ellipsis large-text">
          {fullScreenOpen ? (
            <span onClick={() => closeFullScreen(widget.widgetId!)} onTouchEnd={() => closeFullScreen(widget.widgetId!)}>
              <CloseIcon />
            </span>
          ) : (
            <WidgetActions widgetId={widget.widgetId ?? 0} openFullScreen={openFullScreen}/>
          )}
        </div>
        <div className="widget-inner-container">
          <div className="widget-body">
            {(widget.widgetLayout?.errorMessage?.length ?? 0) > 0 && (
              <div id="error-container">
                <CancelOutlinedIcon />
                <span>{widget.widgetLayout?.errorMessage}</span>
              </div>
            )}
            {showEmbed ? (
              <PowerBIEmbed
                eventHandlers={eventHandlersMap}
                embedConfig={visualConfig}
                cssClassName="report-style"
                getEmbeddedComponent={(embedObject: Embed) => {
                  setEmbedObject(embedObject);
                  console.log(
                    `Embedded object of type "${embedObject.embedtype}" received`
                  );
                  setVisual(embedObject as Visual);
                }}
              />
            ) : (
              <div id="progress-container">
                <CircularProgress size={40} />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
export default WidgetVisualComponent;
