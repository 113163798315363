import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import {
  selectSelectedDashboard,
  selectSharing,
} from "../../redux/selectors/dashboard.selector";
import {
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import RemoveUserDialog from "../Dashboard/RemoveUserDialog";

const ShareTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#959494",
    fontSize: "1em",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "none",
    },
  },
});

export interface ShareDashboardDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function ShareDashboardDialog({ open, setOpen }: ShareDashboardDialogProps) {
  const dispatch = useDispatch();

  const selectedDashboard = useSelector(selectSelectedDashboard);
  const sharing = useSelector(selectSharing);

  const [userEmail, setUserEmail] = useState("");
  const [userListExpanded, setUserListExpanded] = useState(false);
  const [sendEmailChecked, setSendEmailChecked] = useState(true);
  const [emailmessage, setEmailMessage] = useState("");
  const [userEmailValid, setUserEmailValid] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);

  const handleClose = () => {
      setStyleOpen(false);
      setOpen(false);
  };
  const [isStyleOpen, setStyleOpen]=useState<boolean>(open);
  useEffect(()=>{
    setTimeout(()=>{
      setStyleOpen(open);
    },500)
  },[open])

  const handleEmailAddressChange = (newVal: string) => {
    if (newVal.length > 100) return;
    if (validateEmail(newVal)) {
      setUserEmailValid(true);
    } else {
      setUserEmailValid(false);
    }

    setUserEmail(newVal);
  };

  const handleMessageChange = (newVal: string) => {
    if (newVal.length > 500) return;
    setEmailMessage(newVal);
  };

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleShare = () => {
    if (!sharing) {
      dispatch(
        dashboardActions.shareDashboard({
          dashboardId: selectedDashboard.dashboardId ?? 0,
          emailAddress: userEmail,
          allowReshare: false,
          sendEmail: sendEmailChecked,
          customMessage: emailmessage,
        })
      );
      setUserEmail("");
      setUserEmailValid(false);
      setEmailMessage("");
      setUserListExpanded(true);
    }
  };

  const openRemoveDialog = (dashboardUserId: number) => {
    dispatch(dashboardActions.setDashboardUserToRemove(dashboardUserId));
    setRemoveOpen(true);
  };

  return (
    <div id="share-dashboard-dialog">
      <RemoveUserDialog open={removeOpen} setOpen={setRemoveOpen} />
      <Dialog open={open} onClose={handleClose} className={isStyleOpen?"dialog-right in":"dialog-right"}>
        <div className="close" onClick={handleClose}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g id="ic">
                    <path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    </g>
                  </svg>
          </div>
        <div className="dialog-title">
          Share Dashboard
          
        </div>
        <div className="dialog-content share-form">
          <Typography className="share-subtitle">
                Entering the users email address will provide them limited access to
                this dashboard.
          </Typography>
          <div className="label-block">
            <div className="select-lable">Enter Email Address</div>
            <div className="email-notification-label">
              <Checkbox data-testid="send-emailcheck"
                  onChange={(e) => setSendEmailChecked(!sendEmailChecked)}
                  checked={sendEmailChecked}
                />
                Send Email Notification
            </div>
          </div>
          <div className="email-container">
            <input
              value={userEmail}
              placeholder="Enter email address"
              onChange={(e) => handleEmailAddressChange(e.target.value)}
              data-testid="email-input"
              id="email-input"
             />
             {userEmail.length > 0 && !userEmailValid && (
              <span className="error small-text" data-testid="share-small">Invalid email address</span>
            )}
            
          </div>
          {sendEmailChecked&&<div className="">
            <div className="select-lable">Message for email notification</div>
              <ShareTextField
                id="email-message-input"
                data-testid="email-message-input"
                value={emailmessage}
                disabled={!sendEmailChecked}
                fullWidth
                color="primary"
                size="small"
                onChange={(e) => handleMessageChange(e.target.value)}
                rows={4}
                multiline
                placeholder="Message...(optional if sending email)"
              />
            </div>}
            {selectedDashboard?.dashboardUsers?.length ?? 0?
              <Typography data-testid="see-userlist" className="userlist-label" onClick={(e) => setUserListExpanded(!userListExpanded)}>
                {userListExpanded ? "Hide User List" : "See User List"}
              </Typography>:<></>
            }
            {userListExpanded&&<div className="shared-with">
              
                  {selectedDashboard?.dashboardUsers?.length ?? 0 > 0 ? (
                    selectedDashboard?.dashboardUsers?.map((du) => {
                      return (
                        <div key={du.dashboardUserId} className="userlist">
                          <div  data-testid="userlist-email">{du.user.emailAddress}</div>
                          <svg data-testid="remove-test" onClick={() => {openRemoveDialog(du.dashboardUserId);}} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10 14L12 12M12 12L14 10M12 12L10 10M12 12L14 14M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z" stroke="#8EA0B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>
                        </div>
                      );
                    })
                  ) : (
                    <div className="help-text">
                      There are currently no shared users. Try adding an email
                      above!
                    </div>
                  )}
                </div>
}
        <div className="dialog-footer">
          <Button onClick={handleClose} className="btn-cancel medium-text" data-testid="share-cancel">Cancel</Button>
          <Button data-testid="save" onClick={handleShare} disabled={!sharing && !userEmailValid}  className="btn-save medium-text" 
          > {sharing ? (
            <CircularProgress className="progress" size={20} />
          ) : (
            "Share"
          )}</Button>
        </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ShareDashboardDialog;
