// SET THE APPCONFIG
const appConfig = {
    apiBaseUrl: `https://${process.env.REACT_APP_API_BASE_URL}`,
    b2c: {
        tenant: process.env.REACT_APP_B2C_TENANT!,
        signInPolicy: process.env.REACT_APP_SIGNINPOLICY!,
        applicationID: process.env.REACT_APP_B2C_APPID!,
        reactRedirectUri: `${window.location.protocol}//${window.location.host}${window.location.pathname}`,
        logoutRedirectUri: `${window.location.protocol}//${window.location.host}`
    },
    dataRangePicker:process.env.REACT_APP_DATE_RANGE_PICKER||''

}

// EXPORT
export default appConfig;