import { AdminActions } from "../actions/admin.actions";
import { BlobStorageStats } from "../models/blobStorageStats";
import { PowerBIReport } from "../models/powerBIReport";
import { PowerBIWorkspace } from "../models/powerBIWorkspace";
import { NetworkError, ServerError } from "../models/serverError";
import { Setting } from "../models/setting";
import { SecurityRole } from "../models/securityRole";

type AdminState = {
    settings: Setting[];
    loading: boolean;
    loadingPowerBIReports: boolean;
    loadingPowerBIWorkspaces: boolean;
    error?: ServerError | NetworkError;
    saved: boolean;
    timoutActive?: boolean;
    timoutValue?: number;
    timoutWarning?: number;
    blobStorageStats?: BlobStorageStats
    powerBIWorkspaces: PowerBIWorkspace[]
    powerBIReports: PowerBIReport[],
    securityRoles: SecurityRole[]
}
const initialState: AdminState = {
    settings: [],
    loading: false,
    loadingPowerBIReports: false,
    loadingPowerBIWorkspaces: false,
    error: undefined,
    saved: false,
    powerBIWorkspaces: [],
    powerBIReports: [],
    securityRoles: []
}
const AdminReducer = (state: AdminState = initialState, action: AdminActions): AdminState => {
    switch (action.type) {
        case 'GET_SETTINGS_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case 'GET_SETTINGS_SUCCESS':
            return {
                ...state,
                settings: action.payload ?? state.settings,
                loading: false,
                error: undefined
            }
        case 'GET_SETTINGS_FAILURE':
            return {
                ...state,
                settings: [],
                loading: false,
                error: action.error
            }
        case 'SAVE_SETTINGS_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined,
                saved: false
            }
        case 'SAVE_SETTINGS_SUCCESS':
            return {
                ...state,
                loading: false,
                error: undefined,
                settings: action.updatedSettings ?? state.settings,
                saved: true
            }
        case 'SAVE_SETTINGS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error,
                saved: false
            }
        case 'SET_SAVED':
            return {
                ...state,
                saved: action.saved
            }

        // Get Storage Stats
        case 'ADMIN_GET_STORAGE_STATS_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'ADMIN_GET_STORAGE_STATS_SUCCESS':
            return {
                ...state,
                loading: false,
                blobStorageStats: action.storageStats
            }
        case 'ADMIN_GET_STORAGE_STATS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        // GET POWERBI WORKSPACES
        case 'ADMIN_GET_POWERBI_WORKSPACES_REQUEST':
            return {
                ...state,
                loadingPowerBIWorkspaces: true,
                powerBIReports: []
            }
        case 'ADMIN_GET_POWERBI_WORKSPACES_SUCCESS':
            return {
                ...state,
                loadingPowerBIWorkspaces: false,
                powerBIWorkspaces: action.powerBiWorkspaces ?? []
            }
        case 'ADMIN_GET_POWERBI_WORKSPACES_FAILURE':
            return {
                ...state,
                loadingPowerBIWorkspaces: false,
                error: action.error
            }

        // GET POWERBI REPORTS
        case 'ADMIN_GET_POWERBI_REPORTS_REQUEST':
            return {
                ...state,
                loadingPowerBIReports: true
            }
        case 'ADMIN_GET_POWERBI_REPORTS_SUCCESS':
            return {
                ...state,
                loadingPowerBIReports: false,
                powerBIReports: action.powerBiReports ?? []
            }
        case 'ADMIN_GET_POWERBI_REPORTS_FAILURE':
            return {
                ...state,
                loadingPowerBIReports: false,
                error: action.error
            }

        // GET SECURITY ROLES
        case 'ADMIN_GET_SECURITY_ROLES_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'ADMIN_GET_SECURITY_ROLES_SUCCESS':
            return {
                ...state,
                securityRoles: action.securityRoles ?? [],
                loading: false
            }
        case 'ADMIN_GET_SECURITY_ROLES_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        case 'ADMIN_CLEAR_ALL':
            return initialState
        default:
            return state;
    }
}
export default AdminReducer;