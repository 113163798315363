import { useState, useEffect } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from '../azure/authConfig';

interface useMyAuthContext {
    token: string | null
}

const useMyAuth = (): useMyAuthContext => {
    const [token, setToken] = useState<string | null>(null);
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts?.[0] || {});

    useEffect(() => {
        if (account) {
            instance.acquireTokenSilent({
                ...loginRequest,
                account: account,
            }).then((t) => {
                //console.log("token");
                //console.log(t);
                setToken(t.accessToken);
            }, (error) => {
                console.log(error);
                setToken(null);
                instance.acquireTokenRedirect({ ...loginRequest, account: account })
            });
        }
    });

    return { token };
}

export default useMyAuth;