import { Dispatch } from "react"
import { Report } from "../models/report"
import { ReportType } from "../models/reportType"
import { ReportCategory } from "../models/reportCategory"
import { reportService } from "../services/report.service"
import { UserReportHistory } from "../models/userReportHistory"
import { ExportFileExtension } from "../models/exportFileExtension"
import { IBaseAction } from "./base.action"
import { NotificationActions } from "./notification.actions"
import { INotificationType } from "../models/notificationType"
import { adminService } from "../services/admin.service"
import { downloadService } from "../services/download.service"
import { models } from "powerbi-client";
import { Filter } from "../models/filter"
import { FilteredReport } from "../selectors/report.selector"
import { FilteredFileStorage } from "../selectors/history.selector"
import { ShareReportUser } from "../models/shareReport"

interface IGetUserReportAction extends IBaseAction {
    readonly type: 'GET_USER_REPORT_REQUEST' | 'GET_USER_REPORT_SUCCESS' | 'GET_USER_REPORT_FAILURE';
    payload?: Report[];
}

interface IGetReportAction extends IBaseAction {
    readonly type: 'GET_REPORT_REQUEST' | 'GET_REPORT_SUCCESS' | 'GET_REPORT_FAILURE';
    payload?: Report;
}

interface IRunReportAction extends IBaseAction {
    readonly type: 'RUN_REPORT_REQUEST' | 'RUN_REPORT_SUCCESS' | 'RUN_REPORT_FAILURE';
    uniqueEmbedId?: string;
}

interface IExportReportAction extends IBaseAction {
    readonly type: 'EXPORT_REPORT_REQUEST' | 'EXPORT_REPORT_SUCCESS' | 'EXPORT_REPORT_FAILURE';
    userReportHistoryExportDetailsId?: number;
}

interface ISetIsCurrentlyExportingAction {
    readonly type: 'SET_IS_CURRENTLY_EXPORTING';
    value: boolean;
}

interface IGETExportReportAction extends IBaseAction {
    readonly type: 'GET_EXPORTED_REPORT_REQUEST' | 'GET_EXPORTED_REPORT_SUCCESS' | 'GET_EXPORTED_REPORT_FAILURE';
    userReportHistoryExportDetailsId: number,
    blob?: Blob,
    fileName?: string,
}

interface IUpdateReportAction extends IBaseAction {
    readonly type: 'UPDATE_REPORT_REQUEST';
    userReportHistoryId?: number;
    embedToken?: string;
    embedURL?: string;
    status?: string;
    progressComplete?: number;
}

interface IGetReportTypesAction extends IBaseAction {
    readonly type: 'GET_REPORT_TYPE_REQUEST' | 'GET_REPORT_TYPE_SUCCESS' | 'GET_REPORT_TYPE_FAILURE';
    payload?: ReportType[];
}

interface IGetReportCategoriesAction extends IBaseAction {
    readonly type: 'GET_REPORT_CATEGORY_REQUEST' | 'GET_REPORT_CATEGORY_SUCCESS' | 'GET_REPORT_CATEGORY_FAILURE';
    payload?: ReportCategory[];
}

interface IAddReportCategoryAction extends IBaseAction {
    readonly type: 'ADD_REPORT_CATEGORY_REQUEST' | 'ADD_REPORT_CATEGORY_SUCCESS' | 'ADD_REPORT_CATEGORY_FAILURE';
    payload?: ReportCategory;
}
interface IDeleteUserReportHistoryAction extends IBaseAction {
    readonly type: 'DELETE_REQUEST' | 'DELETE_SUCCESS' | 'DELETE_FAILURE';
    payload?: boolean;
}

interface IUpdateReportCategoryAction extends IBaseAction {
    readonly type: 'UPDATE_REPORT_CATEGORY_REQUEST' | 'UPDATE_REPORT_CATEGORY_SUCCESS' | 'UPDATE_REPORT_CATEGORY_FAILURE';
    payload?: ReportCategory;
}

interface IDeleteReportCategoryAction extends IBaseAction {
    readonly type: 'DELETE_REPORT_CATEGORY_REQUEST' | 'DELETE_REPORT_CATEGORY_SUCCESS' | 'DELETE_REPORT_CATEGORY_FAILURE';
    payload?: boolean;
    reportCategoryId: number;
}

interface IGetExportFileExtensionsAction extends IBaseAction {
    readonly type: 'GET_EXPORT_EXTENSIONS_REQUEST' | 'GET_EXPORT_EXTENSIONS_SUCCESS' | 'GET_EXPORT_EXTENSIONS_FAILURE';
    payload?: ExportFileExtension[];
}

interface IClearAllAction {
    readonly type: 'CLEAR-ALL'
}

interface ISetSelectedCategoryAction {
    readonly type: 'SET_CATEGORY'
    category: ReportCategory
}

interface ISetSelectedReportAction {
    readonly type: 'SET_REPORT'
    report: Report|null
}

interface IAddReportAction extends IBaseAction {
    readonly type: 'ADD_REPORT_REQUEST' | 'ADD_REPORT_SUCCESS' | 'ADD_REPORT_FAILURE';
    reports: Report[];
    newReport?: Report[];
}

interface ISaveReportAction extends IBaseAction {
    readonly type: 'SAVE_REPORT_REQUEST' | 'SAVE_REPORT_SUCCESS' | 'SAVE_REPORT_FAILURE';
    report: Report;
}

interface IDeleteReportAction extends IBaseAction {
    readonly type: 'DELETE_REPORT_REQUEST' | 'DELETE_REPORT_SUCCESS' | 'DELETE_REPORT_FAILURE';
    reportIds: number[];
}

interface IGetUserReportHistoryAction extends IBaseAction {
    readonly type: 'GET_USER_REPORT_HISTORY_REQUEST' | 'GET_USER_REPORT_HISTORY_SUCCESS' | 'GET_USER_REPORT_HISTORY_FAILURE';
    userReportHistory?: UserReportHistory[];
    count?:number;
}

interface IGetUserReportHistoryBySubscriptionIdAction extends IBaseAction {
    readonly type: 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_REQUEST' | 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_SUCCESS' | 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_FAILURE';
    userReportHistory?: UserReportHistory[];
}

interface ISetSelectedUserReportHistoryAction extends IBaseAction {
    readonly type: 'SET_USER_REPORT_HISTORY';
    userReportHistory: UserReportHistory;
}

interface ISortReportsAction {
    readonly type: 'SORT_REPORTS'
    field: string
}


interface ISortReportHistoryAction {
    readonly type: 'SORT_REPORT_HISTORY'
    field: string
}

interface ISetUserReportHistoryProgressAction {
    readonly type: 'SET_USER_REPORT_HISTORY_PROGRESS'
    userReportHistoryId: number
    userReportHistoryExportDetailsId: number
    progressComplete: number,
    userReportHistoryStatus: string,
    userReportHistoryExportDetailsStatus: string
}

interface ISetSelectedUserReportHistoryCategoryAction {
    readonly type: 'SET_REPORT_HISTORY_CATEGORY'
    category: ReportCategory
}

interface ISearchReportsAction {
    readonly type: 'SEARCH_REPORTS'
    search: string
}

interface ISearchReportHistoryAction {
    readonly type: 'SEARCH_REPORT_HISTORY'
    search: string
}

interface ISetFavoriteReportAction extends IBaseAction {
    readonly type: 'SET_FAVORITE_REPORT_REQUEST' | 'SET_FAVORITE_REPORT_SUCCESS' | 'SET_FAVORITE_REPORT_FAILURE';
    report: Report;
}

interface ISetPinFavoriteReportsAction {
    readonly type: 'SET_PIN_FAVORITE_REPORT'
    value: boolean
}

interface ISetSavedAction extends IBaseAction {
    readonly type: 'SET_REPORT_SAVED'
    saved: boolean
}

interface ISetDeletedAction extends IBaseAction {
    readonly type: 'SET_REPORT_DELETED'
    deleted: boolean
}

interface IResetReportAction extends IBaseAction {
    readonly type: 'RESET_REPORT'
}

interface ISaveSlicerState {
    readonly type: 'REPORT_SAVE_SLICER_STATE'
    slicerStates: models.ISlicer[]
}

interface ISaveReportFilters {
    readonly type: 'REPORT_SAVE_REPORT_FILTERS'
    filters: models.IFilter[]
}

interface ISetFilterPanOpenAction {
    readonly type: 'SET_FILTER_PANE_OPEN'
    open: boolean
}

interface ISetFiltersAction {
    readonly type: 'SET_REPORT_PAGE_FILTERS'
    filters: Filter<FilteredReport>[]
}

interface IApplyFiltersAction {
    readonly type: 'APPLY_FILTERS'
    filters: Filter<FilteredReport>[]
}

interface IClearAllFiltersAction {
    readonly type: 'CLEAR_ALL_FILTERS'
}

interface IClearFilterAction {
    readonly type: 'CLEAR_FILTER'
    filterName: string
}

interface ISetFileStorageFilterPanOpenAction {
    readonly type: 'SET_FILE_STORAGE_FILTER_PANE_OPEN'
    open: boolean
}

interface ISetFileStorageFiltersAction {
    readonly type: 'SET_FILE_STORAGE_FILTERS'
    filters: Filter<FilteredFileStorage>[]
}

interface IApplyFileStorageFiltersAction {
    readonly type: 'APPLY_FILE_STORAGE_FILTERS'
    filters: Filter<FilteredFileStorage>[]
}

interface IClearFileStorageAllFiltersAction {
    readonly type: 'CLEAR_FILE_STORAGE_ALL_FILTERS'
}

interface IClearFileStorageFilterAction {
    readonly type: 'CLEAR_FILE_STORAGE_FILTER'
    filterName: string
}

interface ISetTileViewAction {
    readonly type: 'REPORT_SET_TILE_VIEW'
    isTileView: boolean;
}

interface IAddUserReportShareAction extends IBaseAction {
    readonly type: 'ADD_USER_REPORT_SHARE_REQUEST' | 'ADD_USER_REPORT_SHARE_SUCCESS' | 'ADD_USER_REPORT_SHARE_FAILURE';
    payload?: any;
    userReportHistoryExportShare?:any;
}

interface IReportSharedUsersAction extends IBaseAction {
    readonly type: 'REPORT_SHARED_USERS_REQUEST' | 'REPORT_SHARED_USERS_SUCCESS' | 'REPORT_SHARED_USERS_FAILURE';
    payload?: any;
    reportSharedUsers?:any;
}

interface IEMAILReportShareAction extends IBaseAction {
    readonly type: 'EMAIL_REPORT_SHARE_REQUEST' | 'EMAIL_REPORT_SHARE_SUCCESS' | 'EMAIL_REPORT_SHARE_FAILURE';
    payload?: any;
}

interface IRemoveReportShareUser extends IBaseAction {
    readonly type: 'REPORTSHARE_REMOVE_USER_REQUEST' | 'REPORTSHARE_REMOVE_USER_SUCCESS' | 'REPORTSHARE_REMOVE_USER_FAILURE';
    userId: number;
    userReportHistoryExportShareId: number;
    response?: boolean;
}
interface ISetReportLoading extends IBaseAction {
    readonly type: 'SET_REPORT_LOADING'
    loadingReport: boolean
}

export type ReportActions =
    | IGetUserReportAction | IClearAllAction | IRunReportAction | IUpdateReportAction | IExportReportAction | ISetIsCurrentlyExportingAction
    | IGetReportTypesAction | IGETExportReportAction | IGetReportCategoriesAction | IAddReportCategoryAction | IUpdateReportCategoryAction | IDeleteReportCategoryAction | ISetSelectedCategoryAction | ISetSelectedReportAction | IAddReportAction | ISaveReportAction | IDeleteReportAction | IGetReportAction | ISortReportsAction | IGetUserReportHistoryAction | IGetExportFileExtensionsAction | IGetUserReportHistoryBySubscriptionIdAction | ISortReportHistoryAction | ISetSelectedUserReportHistoryAction | ISetUserReportHistoryProgressAction | ISetSelectedUserReportHistoryCategoryAction | ISearchReportsAction | ISearchReportHistoryAction | ISetFavoriteReportAction | ISetPinFavoriteReportsAction | ISetSavedAction | IResetReportAction | ISetFilterPanOpenAction | ISetFiltersAction | IApplyFiltersAction | IClearAllFiltersAction | IClearFilterAction | ISetFileStorageFilterPanOpenAction | ISetFileStorageFiltersAction | IApplyFileStorageFiltersAction | IClearFileStorageAllFiltersAction | IClearFileStorageFilterAction | ISaveSlicerState | ISaveReportFilters | ISetTileViewAction
    | IDeleteUserReportHistoryAction| ISetDeletedAction | IAddUserReportShareAction | IEMAILReportShareAction | IReportSharedUsersAction | IRemoveReportShareUser
    | ISetReportLoading

const getReports = (getRunDates: boolean) => {
    return function (dispatch: Dispatch<IGetUserReportAction>) {
        dispatch({ type: 'GET_USER_REPORT_REQUEST' });
        reportService.getReports(getRunDates).then((response) => {
            return dispatch({ type: 'GET_USER_REPORT_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_USER_REPORT_FAILURE', error })
        })
    }
}

const getReportById = (reportId: number) => {
    return function (dispatch: Dispatch<IGetReportAction>) {
        dispatch({ type: 'GET_REPORT_REQUEST' });
        reportService.getReportById(reportId).then((response) => {
            return dispatch({ type: 'GET_REPORT_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_REPORT_FAILURE', error })
        })
    }
}

const runReportByReportId = (reportId: number) => {
    return function (dispatch: Dispatch<IRunReportAction>) {
        dispatch({ type: 'RUN_REPORT_REQUEST' });
        reportService.runReportByReportId(reportId).then((response) => {
            return dispatch({ type: 'RUN_REPORT_SUCCESS', uniqueEmbedId: response })
        }, (error) => {
            return dispatch({ type: 'RUN_REPORT_FAILURE', error })
        })
    }
}

const updateReport = (embedToken?: string, embedURL?: string, status?: string, progressComplete?: number) => {
    return function (dispatch: Dispatch<IUpdateReportAction>) {
        return dispatch({ type: 'UPDATE_REPORT_REQUEST', embedToken, embedURL, status, progressComplete });
    }
}

const exportReport = (reportId: number, embedUrl: string, extentions: string[]) => {
    return function (dispatch: Dispatch<IExportReportAction>) {
        dispatch({ type: 'EXPORT_REPORT_REQUEST' });
        reportService.exportReport(reportId, embedUrl, extentions).then((response) => {
            return dispatch({ type: 'EXPORT_REPORT_SUCCESS', userReportHistoryExportDetailsId: response })
        }, (error) => {
            return dispatch({ type: 'EXPORT_REPORT_FAILURE', error })
        })
    }
}

const setIsCurrentlyExporting = (value: boolean) => {
    return function (dispatch: Dispatch<ISetIsCurrentlyExportingAction>) {
        return dispatch({ type: 'SET_IS_CURRENTLY_EXPORTING', value });
    }
}

const getExportedFile = (userReportHistoryExportDetailsId: number, fileName: string) => {
    return function (dispatch: Dispatch<IGETExportReportAction | NotificationActions>) {
        dispatch({ type: 'GET_EXPORTED_REPORT_REQUEST', userReportHistoryExportDetailsId });
        downloadService.downloadFile(userReportHistoryExportDetailsId).then((response) => {
            dispatch({ type: 'GET_EXPORTED_REPORT_SUCCESS', userReportHistoryExportDetailsId, blob: response, fileName: fileName })
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `Your report has been downloaded`, stayVisible: false })
        }, (error) => {
            return dispatch({ type: 'GET_EXPORTED_REPORT_FAILURE', userReportHistoryExportDetailsId, error })
        })
    }
}

const getReportTypes = () => {
    return function (dispatch: Dispatch<IGetReportTypesAction>) {
        dispatch({ type: 'GET_REPORT_TYPE_REQUEST' });
        reportService.getReportTypes().then((response) => {
            return dispatch({ type: 'GET_REPORT_TYPE_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_REPORT_TYPE_FAILURE', error })
        })
    }
}

const getReportCategories = () => {
    return function (dispatch: Dispatch<IGetReportCategoriesAction>) {
        dispatch({ type: 'GET_REPORT_CATEGORY_REQUEST' });
        reportService.getReportCategories().then((response) => {
            return dispatch({ type: 'GET_REPORT_CATEGORY_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_REPORT_CATEGORY_FAILURE', error })
        })
    }
}

const AddReportCategory = (category: ReportCategory) => {
    return function (dispatch: Dispatch<IAddReportCategoryAction>) {
        dispatch({ type: 'ADD_REPORT_CATEGORY_REQUEST' });
        adminService.addReportCategory(category).then((response) => {
            return dispatch({ type: 'ADD_REPORT_CATEGORY_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'ADD_REPORT_CATEGORY_FAILURE', error })
        })
    }
}

const DeleteUserReportHistory = (userReportHistoryId: number) => {
    return function (dispatch: Dispatch<IDeleteUserReportHistoryAction>) {
        dispatch({ type: 'DELETE_REQUEST' });
        adminService.deleteUserReportHistory(userReportHistoryId).then((response) => {
            return dispatch({ type: 'DELETE_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'DELETE_FAILURE', error })
        })
    }
}

const updateReportCategory = (category: ReportCategory) => {
    return function (dispatch: Dispatch<IUpdateReportCategoryAction>) {
        dispatch({ type: 'UPDATE_REPORT_CATEGORY_REQUEST' });
        adminService.updateReportCategory(category).then((response) => {
            return dispatch({ type: 'UPDATE_REPORT_CATEGORY_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'UPDATE_REPORT_CATEGORY_FAILURE', error })
        })
    }
}

const deleteReportCategory = (categoryId: number, nextCategoryId: number | null) => {
    return function (dispatch: Dispatch<IDeleteReportCategoryAction>) {
        dispatch({ type: 'DELETE_REPORT_CATEGORY_REQUEST', reportCategoryId: categoryId });
        adminService.deleteReportCategory(categoryId, nextCategoryId).then((response) => {
            return dispatch({ type: 'DELETE_REPORT_CATEGORY_SUCCESS', payload: response, reportCategoryId: categoryId })
        }, (error) => {
            return dispatch({ type: 'DELETE_REPORT_CATEGORY_FAILURE', error, reportCategoryId: categoryId })
        })
    }
}

const setSelectedCategory = (category: ReportCategory) => {
    return function (dispatch: Dispatch<ISetSelectedCategoryAction>) {
        dispatch({ type: 'SET_CATEGORY', category });
    }
}

const searchReports = (search: string) => {
    return function (dispatch: Dispatch<ISearchReportsAction>) {
        dispatch({ type: 'SEARCH_REPORTS', search });
    }
}

const searchReportHistory = (search: string) => {
    return function (dispatch: Dispatch<ISearchReportHistoryAction>) {
        dispatch({ type: 'SEARCH_REPORT_HISTORY', search });
    }
}

const setSelectedReport = (report: Report|null) => {
    return function (dispatch: Dispatch<ISetSelectedReportAction>) {
        dispatch({ type: 'SET_REPORT', report });
    }
}

const addReport = (reports: Report[]) => {
    return function (dispatch: Dispatch<IAddReportAction | NotificationActions>) {
        dispatch({ type: 'ADD_REPORT_REQUEST', reports });
        adminService.addReport(reports).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `Report added successfully.`, stayVisible: false })
            return dispatch({ type: 'ADD_REPORT_SUCCESS', reports, newReport: response })
        }, (error) => {
            return dispatch({ type: 'ADD_REPORT_FAILURE', reports, error })
        })
    }
}

const saveReport = (report: Report) => {
    return function (dispatch: Dispatch<ISaveReportAction>) {
        dispatch({ type: 'SAVE_REPORT_REQUEST', report });
        adminService.saveReport(report).then((response) => {
            return dispatch({ type: 'SAVE_REPORT_SUCCESS', report })
        }, (error) => {
            return dispatch({ type: 'SAVE_REPORT_FAILURE', report, error })
        })
    }
}

const deleteReport = (reportIds: number[]) => {
    return function (dispatch: Dispatch<IDeleteReportAction | NotificationActions>) {
        dispatch({ type: 'DELETE_REPORT_REQUEST', reportIds });
        adminService.deleteReport(reportIds).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `Your report has been deleted successfully.`, stayVisible: false })
            return dispatch({ type: 'DELETE_REPORT_SUCCESS', reportIds })
        }, (error) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Error, message: `Unable to delete report, please try again.`, stayVisible: false })
            return dispatch({ type: 'DELETE_REPORT_FAILURE', reportIds, error })
        })
    }
}

const getUserReportHistory = (page:number,userReportHistoryList:UserReportHistory[]) => {
    return function (dispatch: Dispatch<IGetUserReportHistoryAction>) {
        dispatch({ type: 'GET_USER_REPORT_HISTORY_REQUEST' });
        reportService.getUserReportHistory(page).then((response:any) => {
            return dispatch({ type: 'GET_USER_REPORT_HISTORY_SUCCESS', userReportHistory: [...userReportHistoryList,...response.userReportHistoryList], count:response.count })
        }, (error) => {
            return dispatch({ type: 'GET_USER_REPORT_HISTORY_FAILURE', error })
        })
    }
}

const getUserReportHistoryByToken = (urltoken:string) => {
    return function (dispatch: Dispatch<IGetUserReportHistoryAction>) {
        dispatch({ type: 'GET_USER_REPORT_HISTORY_REQUEST' });
        reportService.getUserReportHistoryByToken(urltoken).then((response) => {
            return dispatch({ type: 'GET_USER_REPORT_HISTORY_SUCCESS', userReportHistory: response })
        }, (error) => {
            return dispatch({ type: 'GET_USER_REPORT_HISTORY_FAILURE', error })
        })
    }
}

const getUserReportHistoryBySubscriptionId = (subscriptionId?: number) => {
    return function (dispatch: Dispatch<IGetUserReportHistoryBySubscriptionIdAction>) {
        dispatch({ type: 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_REQUEST' });
        reportService.getUserReportHistoryBySubscriptionId(subscriptionId).then((response) => {
            return dispatch({ type: 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_SUCCESS', userReportHistory: response })
        }, (error) => {
            return dispatch({ type: 'GET_USER_REPORT_HISTORY_BY_SUBSCRIPTION_FAILURE', error })
        })
    }
}

const setUserReportHistoryProgress = (userReportHistoryId: number, userReportHistoryExportDetailsId: number, progressComplete: number, userReportHistoryStatus: string, userReportHistoryExportDetailsStatus: string) => {
    return function (dispatch: Dispatch<ISetUserReportHistoryProgressAction>) {
        dispatch({ type: 'SET_USER_REPORT_HISTORY_PROGRESS', userReportHistoryId, userReportHistoryExportDetailsId, progressComplete, userReportHistoryStatus, userReportHistoryExportDetailsStatus });
    }
}

const sortReports = (field: string) => {
    return function (dispatch: Dispatch<ISortReportsAction>) {
        dispatch({ type: 'SORT_REPORTS', field });
    }
}

const getExportFileExtensions = () => {
    return function (dispatch: Dispatch<IGetExportFileExtensionsAction>) {
        dispatch({ type: 'GET_EXPORT_EXTENSIONS_REQUEST' });
        reportService.getExportFileExtensions().then((response) => {
            return dispatch({ type: 'GET_EXPORT_EXTENSIONS_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_EXPORT_EXTENSIONS_FAILURE', error })
        })
    }
}

const sortReportHistory = (field: string) => {
    return function (dispatch: Dispatch<ISortReportHistoryAction>) {
        dispatch({ type: 'SORT_REPORT_HISTORY', field });
    }
}

const setSelectedReportHistoryCategory = (category: ReportCategory) => {
    return function (dispatch: Dispatch<ISetSelectedUserReportHistoryCategoryAction>) {
        dispatch({ type: 'SET_REPORT_HISTORY_CATEGORY', category });
    }
}

const setFavoriteReport = (report: Report) => {
    return function (dispatch: Dispatch<ISetFavoriteReportAction>) {
        dispatch({ type: 'SET_FAVORITE_REPORT_REQUEST', report });
        reportService.setFavoriteReport(report.reportId).then((response) => {
            return dispatch({ type: 'SET_FAVORITE_REPORT_SUCCESS', report })
        }, (error) => {
            console.log(error);
            return dispatch({ type: 'SET_FAVORITE_REPORT_FAILURE', report, error })
        })
    }
}

const setPinFavoriteReports = (value: boolean) => {
    return function (dispatch: Dispatch<ISetPinFavoriteReportsAction>) {
        dispatch({ type: 'SET_PIN_FAVORITE_REPORT', value });
    }
}

const setSaved = (saved: boolean) => {
    return function (dispatch: Dispatch<ISetSavedAction>) {
        dispatch({ type: 'SET_REPORT_SAVED', saved });
    }
}

const setDeleted = (deleted: boolean) => {
    return function (dispatch: Dispatch<ISetDeletedAction>) {
        dispatch({ type: 'SET_REPORT_DELETED', deleted });
    }
}
const resetReport = () => {
    return function (dispatch: Dispatch<IResetReportAction>) {
        dispatch({ type: 'RESET_REPORT' });
    }
}

const saveSlicerState = (slicerStates: models.ISlicer[]) => {
    return function (dispatch: Dispatch<ISaveSlicerState>) {
        dispatch({ type: 'REPORT_SAVE_SLICER_STATE', slicerStates });
    }
}

const saveReportFilters = (filters: models.IFilter[]) => {
    return function (dispatch: Dispatch<ISaveReportFilters>) {
        dispatch({ type: 'REPORT_SAVE_REPORT_FILTERS', filters });
    }
}

const setFilterPanOpen = (open: boolean) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'SET_FILTER_PANE_OPEN', open });
    }
}

const setFilters = (filters: Filter<FilteredReport>[]) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'SET_REPORT_PAGE_FILTERS', filters });
    }
}

const applyFilters = (filters: Filter<FilteredReport>[]) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'APPLY_FILTERS', filters });
    }
}

const clearAllFilters = () => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'CLEAR_ALL_FILTERS' });
    }
}

const clearFilter = (filterName: string) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'CLEAR_FILTER', filterName });
    }
}

const setFileStorageFilterPanOpen = (open: boolean) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'SET_FILE_STORAGE_FILTER_PANE_OPEN', open });
    }
}

const setFileStorageFilters = (filters: Filter<FilteredFileStorage>[]) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'SET_FILE_STORAGE_FILTERS', filters });
    }
}

const applyFileStorageFilters = (filters: Filter<FilteredFileStorage>[]) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'APPLY_FILE_STORAGE_FILTERS', filters });
    }
}

const clearFileStorageAllFilters = () => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'CLEAR_FILE_STORAGE_ALL_FILTERS' });
    }
}

const clearFileStorageFilter = (filterName: string) => {
    return function (dispatch: Dispatch<ReportActions>) {
        dispatch({ type: 'CLEAR_FILE_STORAGE_FILTER', filterName });
    }
}

const setTileView = (isTileView: boolean) => {
    return function (dispatch: Dispatch<ISetTileViewAction>) {
        dispatch({ type: 'REPORT_SET_TILE_VIEW', isTileView });
    }
}

const clearAll = () => {
    return function (dispatch: Dispatch<IClearAllAction>) {
        dispatch({ type: 'CLEAR-ALL' });
    }
}

const AddUserReportShare = (report: ShareReportUser) => {
    return function (dispatch: Dispatch<IAddUserReportShareAction>) {
        dispatch({ type: 'ADD_USER_REPORT_SHARE_REQUEST' });
        reportService.addUserReportShare(report).then((response) => {
            return dispatch({ type: 'ADD_USER_REPORT_SHARE_SUCCESS', userReportHistoryExportShare: response })
        }, (error) => {
            return dispatch({ type: 'ADD_USER_REPORT_SHARE_FAILURE', error })
        })
    }
}

const EmailReportShare = (report: ShareReportUser) => {
    return function (dispatch: Dispatch<IEMAILReportShareAction | NotificationActions>) {
        dispatch({ type: 'EMAIL_REPORT_SHARE_REQUEST' });
        reportService.emailReportShare(report).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `Email has been sent to ${report.emailAddress}`, stayVisible: false })
            return dispatch({ type: 'EMAIL_REPORT_SHARE_SUCCESS', payload: response })
        },(error)=>{
            return dispatch({ type: 'EMAIL_REPORT_SHARE_FAILURE', error })
        })
    }
}

const getReportSharedUsers = (token: string) => {
    return function (dispatch: Dispatch<IReportSharedUsersAction>) {
        dispatch({ type: 'REPORT_SHARED_USERS_REQUEST' });
        reportService.getReportSharedUsers(token).then((response) => {
            return dispatch({ type: 'REPORT_SHARED_USERS_SUCCESS', reportSharedUsers: response })
        },(error)=>{
            return dispatch({ type: 'REPORT_SHARED_USERS_FAILURE', error })
        })
    }
}

const removeReportShareUser = (userId: number, userReportHistoryExportShareId: number) => {
    return function (dispatch: Dispatch<IRemoveReportShareUser | NotificationActions>) {
        dispatch({ type: 'REPORTSHARE_REMOVE_USER_REQUEST', userId, userReportHistoryExportShareId });
        reportService.removeReportShareUser(userId, userReportHistoryExportShareId).then((response) => {
            dispatch({ type: 'SET_NOTIFICATION', notificationType: INotificationType.Success, message: `User has been removed successfully.`, stayVisible: false })
            return dispatch({ type: 'REPORTSHARE_REMOVE_USER_SUCCESS', userId, userReportHistoryExportShareId, response })
        }, (error) => {
            return dispatch({ type: 'REPORTSHARE_REMOVE_USER_FAILURE', userId, userReportHistoryExportShareId, error })
        })
    }
}

const setReportLoading = (loadingReport: boolean) => {
    return function (dispatch: Dispatch<ISetReportLoading>) {
        dispatch({ type: 'SET_REPORT_LOADING', loadingReport });
    }
}



export const reportActions = {
    getReports,
    runReportByReportId,
    updateReport,
    exportReport,
    setIsCurrentlyExporting,
    getExportedFile,
    getReportTypes,
    getReportCategories,
    AddReportCategory,
    updateReportCategory,
    deleteReportCategory,
    setSelectedCategory,
    setSelectedReport,
    addReport,
    saveReport,
    deleteReport,
    getReportById,
    getUserReportHistory,
    getUserReportHistoryByToken,
    getUserReportHistoryBySubscriptionId,
    sortReports,
    getExportFileExtensions,
    sortReportHistory,
    setUserReportHistoryProgress,
    setSelectedReportHistoryCategory,
    searchReports,
    searchReportHistory,
    setFavoriteReport,
    setPinFavoriteReports,
    setSaved,
    setDeleted,
    resetReport,
    saveSlicerState,
    saveReportFilters,
    setFilterPanOpen,
    setFilters,
    applyFilters,
    clearAllFilters,
    clearFilter,
    setFileStorageFilterPanOpen,
    setFileStorageFilters,
    applyFileStorageFilters,
    clearFileStorageAllFilters,
    clearFileStorageFilter,
    setTileView,
    clearAll,
    DeleteUserReportHistory,
    AddUserReportShare,
    EmailReportShare,
    getReportSharedUsers,
    removeReportShareUser,
    setReportLoading
    
}