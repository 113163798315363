import { InputBase, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../redux/actions/dashboard.actions";
import {
  selectNextDefaultDashboards,
  selectSelectedDashboard,
} from "../../redux/selectors/dashboard.selector";
import { styled } from "@mui/material/styles";
import CommonStepper from "../Common/CommonStepper";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    padding: "6px 5px 5px 12px",
    color: "#00334e",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

export interface DeleteDashboardDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  isOwner: boolean;
}

function DeleteDashboardDialog({
  open,
  setOpen,
  isOwner,
}: DeleteDashboardDialogProps) {
  const dispatch = useDispatch();
  const selectedDashboard = useSelector(selectSelectedDashboard);
  const nextDefaultDashboards = useSelector(selectNextDefaultDashboards);
  const [numPages] = useState<number>(
    selectedDashboard.isDefault && nextDefaultDashboards.length > 0 ? 2 : 1
  );
  const [currPage, setCurrPage] = useState<number>(1);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [selectedNewDefaultId, setSelectedNewDefaultId] = useState<
    number | null
  >(
    nextDefaultDashboards.length > 0
      ? nextDefaultDashboards[0].dashboardId!
      : null
  );
  const steps = ['Delete Dashboard', 'Choose another'];

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    if (isOwner) {
      dispatch(
        dashboardActions.deleteDashboard(
          selectedDashboard.dashboardId!,
          selectedNewDefaultId,
          selectedDashboard.isDefault
        )
      );
    } else {
      dispatch(
        dashboardActions.removeDashboardUser(
          selectedDashboard.dashboardId!,
          selectedDashboard.dashboardUserId!,
          selectedNewDefaultId
        )
      );
    }
    setOpen(false);
  };

  const handleNewDefaultChange = (
    event: SelectChangeEvent<number | string>
  ) => {
    setSelectedNewDefaultId(+event.target.value);
  };

  const getPage = (pageNumber: number) => {
    if (pageNumber === 1) return getPage1();
    else return getPage2();
  };

  const getPage1 = () => {
    let prompt = isOwner
      ? "Are you sure you want to delete this dashboard? This will also remove access to those it was shared with."
      : "Are you sure you want to remove yourself as a recipient of this dashboard? The owner's dashboard will still remain.";
    return <>
    <p className="del-heading">Warning! Delete Dashboard?</p>
    <p className="warning-msg">{prompt}</p>
    </>;
  };

  const getPage2 = () => {
    return (
      <div>
        <p className="dflt-dshbd-selection">Choose New Default Dashboard <span color="var(--Error-color-code, #E2494F)">*</span></p>
        <Select
          id="existing-dashboard-select"
          data-testid="existing-dashboard-select"
          title="Dashboard"
          className="select-xlarge"
          value={selectedNewDefaultId ?? ""}
          onChange={handleNewDefaultChange}
          input={<Input />}
          IconComponent={KeyboardArrowDownIcon}
          style={{ position: "relative", width: "100%" }}
        >
          {nextDefaultDashboards.map((d, i) => {
            return (
              <MenuItem
                key={i}
                value={d.dashboardId}
                className="pd-menuitem medium-text blue-text"
              >
                <div className="pd-menuitem-inner">
                  <div className="db-name">{d.name}</div>
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const getSubmitText = () => {
    console.log(numPages);
    if (currPage < numPages) return "Yes, Continue";
    return "Confirm";
  };

  return (
    <div id="delete-report-dialog">
      <Dialog open={open} onClose={handleClose} className={activeStep===1?"del-dialog del-dash-dialog step2":"del-dialog del-dash-dialog"}>
      {numPages>1&&<CommonStepper steps={steps} activeStep={activeStep}/>
      }
        <div className="del-icon-block">
          <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
            <circle cx="27" cy="27" r="27" fill="#E2494F"></circle>
            <path d="M34.3501 23.5499L33.4397 36.299C33.402 36.8288 33.165 37.3246 32.7763 37.6866C32.3876 38.0486 31.8762 38.2499 31.345 38.2499H22.6552C22.124 38.2499 21.6126 38.0486 21.2239 37.6866C20.8352 37.3246 20.5982 36.8288 20.5604 36.299L19.6501 23.5499M24.9001 27.7499V34.0499M29.1001 27.7499V34.0499M30.1501 23.5499V20.3999C30.1501 20.1214 30.0395 19.8543 29.8426 19.6574C29.6456 19.4605 29.3786 19.3499 29.1001 19.3499H24.9001C24.6216 19.3499 24.3545 19.4605 24.1576 19.6574C23.9607 19.8543 23.8501 20.1214 23.8501 20.3999V23.5499M18.6001 23.5499H35.4001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
          </svg>
        </div>
        {/* <div className="dialog-content">
          <p className="del-heading">Warning! Delete Dashboard?</p>
          <p className="warning-msg"> {`Are you sure you want to delete this dashboard? This will also remove access to those it was shared with.`}</p>
        </div>
        <div className="dialog-footer">
          <Button onClick={handleClose} className="btn-cancel">
            Cancel
          </Button>
          <Button onClick={()=>setCurrPage(currPage + 1)} className="btn-delete">
            Delete Report
          </Button>
        </div> */}
        <div className="dialog-content">{getPage(currPage)}</div>
         <div className="dialog-footer">
           
          <Button onClick={handleClose} className="btn-cancel">
            Cancel
          </Button>
          <Button data-testid="btn-save"
            onClick={() =>
              {if(currPage === numPages) handleDelete(); else {setCurrPage(currPage + 1);setActiveStep(currPage)}}
            }
            className="btn-save"
          >
            {getSubmitText()}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

export default DeleteDashboardDialog;
