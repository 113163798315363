import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import {
  selectSelectedSubscription,
} from "../../redux/selectors/subscription.selector";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import SubsetSubscribers from "./SubsetSubscribers";
import { ToggleButtonGroup, ToggleButton} from "@mui/material";

export interface SubscribersFormProps {
  action: "add" | "edit";
  admin: boolean;
}

function SubscribersForm({ action, admin }: SubscribersFormProps) {
  const dispatch = useDispatch();

  const selectedSubscription = useSelector(selectSelectedSubscription);

  const [subscriberType, setSubscriberType] = React.useState(2);

  useEffect(() => {
    if (admin) {
      // const allFacilities = formData.portfolios?.map((p) =>
      //   p.regions.map((r) =>
      //     r.facilities.map((f) => {
      //       return {
      //         tenantEntityId: f.tenantId,
      //         subscriptionId: selectedSubscription.subscriptionId,
      //       };
      //     })
      //   )
      // );
      // if (action === "add") {
      //   dispatch(
      //     subscriptionActions.setSubscriptionTenantGroup(
      //       {} as SubscriptionTenantGroup,
      //       true
      //     )
      //   );
      // } else {
      if (selectedSubscription.isForAllUsers) {
        setSubscriberType(0);
      } else if (
        (selectedSubscription.subscriptionTenantGroup?.subscriptionTenants
          ?.length ?? 0) === 0
      ) {
        setSubscriberType(2);
      } else {
        setSubscriberType(1);
      }
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubscriberTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSubscriberType(parseInt(event.target.value));

    if (parseInt(event.target.value) === 0) {
      dispatch(subscriptionActions.setIsForAllUsers(true, false));
    } else if (parseInt(event.target.value) === 2) {
      dispatch(subscriptionActions.setIsForAllUsers(false, false));
    } else {
      dispatch(subscriptionActions.setIsForAllUsers(false, true));
    }

    // dispatch(
    //   subscriptionActions.setSubscriptionTenantGroup(
    //     {} as SubscriptionTenantGroup,
    //     true
    //   )
    // );
  };

  const handleToChange = (value: string) => {
    dispatch(subscriptionActions.setSubscriptionToEmail(value));
  };


  return (
    <div className="subscribers-form">
      <ToggleButtonGroup
              color="primary"
              value={subscriberType}
              exclusive
              onChange={(event:any)=>{handleSubscriberTypeChange(event)}}
              size="large"
              aria-label="Platform"
              data-testid="test-freqType"
            >
                <ToggleButton key={0} value={0}> All Users </ToggleButton>
              <ToggleButton key={1} value={1}> Subsets </ToggleButton>
                <ToggleButton key={2} value={2}> Individuals </ToggleButton>
            
              </ToggleButtonGroup>
              <div className="frequency-right">
            {subscriberType === 0 && (
              <div className="label subscribers-info">
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                    <g clipPath="url(#clip0_3000_86901)">
                      <path d="M20.5 0C9.17033 0 0 9.16881 0 20.5C0 31.8297 9.16881 41 20.5 41C31.8297 41 41 31.8314 41 20.5C41 9.17033 31.8314 0 20.5 0ZM20.5 38.5882C10.5261 38.5882 2.41176 30.4739 2.41176 20.5C2.41176 10.5261 10.5261 2.41176 20.5 2.41176C30.4739 2.41176 38.5882 10.5261 38.5882 20.5C38.5882 30.4739 30.4739 38.5882 20.5 38.5882Z" fill="#AF1E37"/>
                      <path d="M21.7058 14.4707H19.294V26.5295H25.3234V24.1178H21.7058V14.4707ZM30.147 24.1178V14.4707H27.7352V26.5295H33.7646V24.1178H30.147ZM13.2646 14.4707C11.2698 14.4707 9.64697 16.0935 9.64697 18.0884V26.5295H12.0587V22.9119H14.4705V26.5295H16.8823V18.0884C16.8823 16.0935 15.2595 14.4707 13.2646 14.4707ZM12.0587 20.5001V18.0884C12.0587 17.4234 12.5997 16.8825 13.2646 16.8825C13.9295 16.8825 14.4705 17.4234 14.4705 18.0884V20.5001H12.0587Z" fill="#AF1E37"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_3000_86901">
                        <rect width="41" height="41" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>
             <Typography> This will create subscriptions for users under all portfolios /regions/ facilities.</Typography>
            </div>
              
            )}
            {subscriberType === 1 && (
              <SubsetSubscribers
                action={action}
                subscriberType={subscriberType}
              />
            )}
            {subscriberType === 2 && (
              <div className="label subscribers-info">
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path d="M19.375 29.6876H4.175C3.31667 29.6866 2.49377 29.3452 1.88684 28.7383C1.2799 28.1314 0.938492 27.3085 0.9375 26.4501V6.05014C0.9375 5.17264 1.295 4.33139 1.91875 3.74264C2.21339 3.44443 2.56492 3.20845 2.9525 3.04869C3.34008 2.88893 3.75581 2.80866 4.175 2.81264H34.575C35.41 2.81264 36.2012 3.13264 36.8013 3.71264C37.4338 4.27389 37.8125 5.13514 37.8125 6.05014V19.4001C37.8125 19.6488 37.7137 19.8872 37.5379 20.0631C37.3621 20.2389 37.1236 20.3376 36.875 20.3376C36.6264 20.3376 36.3879 20.2389 36.2121 20.0631C36.0363 19.8872 35.9375 19.6488 35.9375 19.4001V6.05014C35.9375 5.67014 35.7875 5.32014 35.5275 5.08764C35.4032 4.96109 35.255 4.86054 35.0915 4.79187C34.9279 4.72319 34.7524 4.68776 34.575 4.68764H4.175C3.80875 4.68764 3.4825 4.82264 3.235 5.07889C3.10133 5.20283 2.99478 5.35312 2.92206 5.52028C2.84935 5.68744 2.81204 5.86785 2.8125 6.05014V26.4501C2.8125 27.2001 3.425 27.8126 4.175 27.8126H19.375C19.6236 27.8126 19.8621 27.9114 20.0379 28.0872C20.2137 28.263 20.3125 28.5015 20.3125 28.7501C20.3125 28.9988 20.2137 29.2372 20.0379 29.4131C19.8621 29.5889 19.6236 29.6876 19.375 29.6876Z" fill="#AF1E37"/>
                <path d="M19.3413 21.2251C18.789 21.2275 18.2578 21.0135 17.8613 20.6289L1.90884 5.09138C1.816 5.0066 1.74116 4.90401 1.68877 4.78972C1.63639 4.67543 1.60753 4.55177 1.6039 4.4261C1.60028 4.30042 1.62198 4.17531 1.66769 4.05819C1.71341 3.94107 1.78222 3.83434 1.87002 3.74436C1.95783 3.65438 2.06283 3.58297 2.17879 3.5344C2.29476 3.48582 2.4193 3.46106 2.54503 3.4616C2.67075 3.46214 2.79508 3.48796 2.91062 3.53753C3.02616 3.58709 3.13056 3.65939 3.21759 3.75013L19.1701 19.2876C19.2601 19.3751 19.4201 19.3751 19.5101 19.2876L35.4976 3.71638C35.5854 3.62874 35.6897 3.5594 35.8045 3.51237C35.9193 3.46534 36.0422 3.44156 36.1663 3.4424C36.2903 3.44325 36.413 3.4687 36.5271 3.51729C36.6413 3.56588 36.7446 3.63664 36.8312 3.72546C36.9178 3.81429 36.9859 3.91941 37.0316 4.03475C37.0772 4.15008 37.0996 4.27334 37.0972 4.39736C37.0949 4.52139 37.068 4.64372 37.0181 4.75727C36.9682 4.87083 36.8962 4.97334 36.8063 5.05888L20.8188 20.6301C20.423 21.0141 19.8928 21.2281 19.3413 21.2264V21.2251Z" fill="#AF1E37"/>
                <path d="M6.54106 29.6872C6.35413 29.6883 6.17117 29.6333 6.01579 29.5294C5.86041 29.4255 5.73975 29.2774 5.66938 29.1042C5.599 28.931 5.58215 28.7408 5.621 28.5579C5.65985 28.3751 5.75261 28.2081 5.88731 28.0784L16.3873 17.8509C16.566 17.6808 16.8045 17.588 17.0512 17.5926C17.2979 17.5972 17.5328 17.6989 17.705 17.8755C17.8773 18.0522 17.9729 18.2896 17.9712 18.5363C17.9695 18.783 17.8707 19.0191 17.6961 19.1934L7.19606 29.4209C7.02122 29.5924 6.7859 29.688 6.54106 29.6872ZM23.3873 21.0872C23.1435 21.0876 22.9093 20.9924 22.7348 20.8222L21.0598 19.1972C20.9701 19.1117 20.8983 19.0094 20.8484 18.896C20.7986 18.7826 20.7717 18.6605 20.7693 18.5367C20.7669 18.4128 20.7891 18.2898 20.8345 18.1745C20.88 18.0593 20.9478 17.9543 21.0341 17.8654C21.1204 17.7766 21.2234 17.7057 21.3372 17.6569C21.4511 17.6081 21.5735 17.5823 21.6973 17.5811C21.8212 17.5799 21.944 17.6032 22.0588 17.6497C22.1736 17.6963 22.278 17.7651 22.3661 17.8522L24.0411 19.4772C24.1749 19.6072 24.267 19.7743 24.3053 19.9569C24.3437 20.1395 24.3267 20.3295 24.2565 20.5024C24.1863 20.6753 24.0661 20.8234 23.9112 20.9275C23.7564 21.0317 23.5739 21.0873 23.3873 21.0872ZM30.6248 37.1872C25.9723 37.1872 22.1873 33.4022 22.1873 28.7497C22.1873 24.0972 25.9723 20.3122 30.6248 20.3122C35.2773 20.3122 39.0623 24.0972 39.0623 28.7497C39.0623 33.4022 35.2773 37.1872 30.6248 37.1872ZM30.6248 22.1872C27.0073 22.1872 24.0623 25.1322 24.0623 28.7497C24.0623 32.3672 27.0073 35.3122 30.6248 35.3122C34.2423 35.3122 37.1873 32.3672 37.1873 28.7497C37.1873 25.1322 34.2423 22.1872 30.6248 22.1872Z" fill="#AF1E37"/>
                <path d="M30.625 32.1875C30.3764 32.1875 30.1379 32.0887 29.9621 31.9129C29.7863 31.7371 29.6875 31.4986 29.6875 31.25V26.25C29.6875 26.0014 29.7863 25.7629 29.9621 25.5871C30.1379 25.4113 30.3764 25.3125 30.625 25.3125C30.8736 25.3125 31.1121 25.4113 31.2879 25.5871C31.4637 25.7629 31.5625 26.0014 31.5625 26.25V31.25C31.5625 31.4986 31.4637 31.7371 31.2879 31.9129C31.1121 32.0887 30.8736 32.1875 30.625 32.1875Z" fill="#AF1E37"/>
                <path d="M33.125 29.6875H28.125C27.8764 29.6875 27.6379 29.5887 27.4621 29.4129C27.2863 29.2371 27.1875 28.9986 27.1875 28.75C27.1875 28.5014 27.2863 28.2629 27.4621 28.0871C27.6379 27.9113 27.8764 27.8125 28.125 27.8125H33.125C33.3736 27.8125 33.6121 27.9113 33.7879 28.0871C33.9637 28.2629 34.0625 28.5014 34.0625 28.75C34.0625 28.9986 33.9637 29.2371 33.7879 29.4129C33.6121 29.5887 33.3736 29.6875 33.125 29.6875Z" fill="#AF1E37"/>
              </svg>
             <Typography> This will create subscriptions for users entered in the additional recipients section below.</Typography>
            </div>
            )}
            <div className="select-lable">Additional Recipients {subscriberType === 2 ? <span className="error">*</span>:null}</div>
            <input
              value={selectedSubscription.subscriptionEmail?.to}
              onChange={(event) => {
                handleToChange(event.target.value);
              }}
              placeholder="ex:Semi-colon delimited i.e. sam@aol.com;dave@msn.com;..."
              maxLength={250}
            />
          </div>
    </div>
  );
}

export default SubscribersForm;
