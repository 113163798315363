import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import AdminSubscriptionComponent from "./AdminSubscriptionComponent";
import ReportTypeDropdown from "../ReportTypeDropdown/ReportTypeDropdown";
import CircularProgress from "@mui/material/CircularProgress";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import {
  selectAdminSortedSubscriptions,
  isSubscriptionsLoading,
  selectAdminSubscriptionSelectedCategory,
  selectAdminSubscriptionsPagination,
  selectAdminSubscriptionsPageCount,
  selectAdminSubscriptionsCurrentPage,
  selectAdminSortField,
  selectAdminSortDirection,
  selectAdminFilterPanOpen,
  selectAdminPageFilters,
  selectAppliedAdminPageFilters,
  selectAppliedAdminPageFiltersCount,
  FilteredSubscription,
  FilteredAdminSubscription,
} from "../../redux/selectors/subscription.selector";
import { ReportCategory } from "../../redux/models/reportCategory";
import RowCountDropdown from "./RowCountDropdown";
import Pagination from "./Pagination";
import SortIcon from "@mui/icons-material/Sort";
import Filters from "../Filters/Filters";
import { Filter } from "../../redux/models/filter";
import "./AdminSubscriptions.scss";
import { Subscription } from "../../redux/models/subscription";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function AdminSubscriptions() {
  const dispatch = useDispatch();

  const isAdmin = useSelector(isUserAdministrator);

  const subscriptionsLoading = useSelector(isSubscriptionsLoading);
  const adminSubscriptions = useSelector(selectAdminSortedSubscriptions);
  const selectedCategory = useSelector(selectAdminSubscriptionSelectedCategory);
  const pagination = useSelector(selectAdminSubscriptionsPagination);
  const pageCount = useSelector(selectAdminSubscriptionsPageCount);
  const currentPage = useSelector(selectAdminSubscriptionsCurrentPage);
  const sortField = useSelector(selectAdminSortField);
  const sortDirection = useSelector(selectAdminSortDirection);
  const filtersOpen = useSelector(selectAdminFilterPanOpen);
  const filters = useSelector(selectAdminPageFilters);
  const appliedFilters = useSelector(selectAppliedAdminPageFilters);
  const appliedFiltersCount = useSelector(selectAppliedAdminPageFiltersCount);

  useEffect(() => {
    dispatch(
      subscriptionActions.getAdminSubscriptions(
        pageCount,
        currentPage,
        appliedFilters,
        selectedCategory?.categoryId,
        sortField,
        sortDirection
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortSubscriptions = (sortFields: string[]) => {
    const sortDir =
      sortField.length === 1 && sortField[0] === sortFields[0]
        ? sortDirection === "asc"
          ? "desc"
          : "asc"
        : "asc";
    dispatch(subscriptionActions.sortAdminSubscriptions(sortFields));
    refreshSubscriptions(
      pageCount,
      1,
      appliedFilters,
      selectedCategory?.categoryId,
      sortFields,
      sortDir
    );
  };

  const setSelectedCategory = (category: ReportCategory) => {
    dispatch(subscriptionActions.setAdminSelectedCategory(category));
    refreshSubscriptions(
      pageCount,
      1,
      appliedFilters,
      category.categoryId,
      sortField,
      sortDirection
    );
  };

  const refreshSubscriptions = (
    pageSize: number,
    currentPage: number,
    filters?: Filter<FilteredAdminSubscription>[],
    categoryId?: number,
    sortField?: string[],
    sortDirection?: string
  ) =>
    dispatch(
      subscriptionActions.getAdminSubscriptions(
        pageSize,
        currentPage,
        filters,
        categoryId,
        sortField,
        sortDirection
      )
    );

  const setFilterPanOpen = (open: boolean) =>
    dispatch(subscriptionActions.setAdminFilterPanOpen(open));

  const setFilters = (filters: Filter<FilteredSubscription>[]) =>
    dispatch(subscriptionActions.setAdminPageFilters(filters));

  const applyFilters = (filters: Filter<FilteredSubscription>[]) => {
    dispatch(subscriptionActions.applyAdminFilters(filters));
    refreshSubscriptions(
      pageCount,
      1,
      filters.filter(
        (f) => f.values.length > 0 && (f.values[0] || f.values[1])
      ),
      selectedCategory?.categoryId,
      sortField,
      sortDirection
    );
  };

  const clearAllFilters = () => {
    dispatch(subscriptionActions.clearAdminAllFilters());
    refreshSubscriptions(
      pageCount,
      1,
      [],
      selectedCategory?.categoryId,
      sortField,
      sortDirection
    );
  };

  const clearFilter = (filterName: string) => {
    dispatch(subscriptionActions.clearAdminFilter(filterName));
    refreshSubscriptions(
      pageCount,
      1,
      appliedFilters.filter((f) => f.name !== filterName),
      selectedCategory?.categoryId,
      sortField,
      sortDirection
    );
  };

  if (isAdmin) {
    return (
      <div id="subscription-admin-main">
        <div className="page-header">Subscriptions</div>
        <div className="page-header-wrapper">
          <div className="control-wrapper">
            <div id="type-outer-container">
              <div className="small-text">Select category:</div>
              <ReportTypeDropdown
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </div>
            <div className="row-count-container">
              <RowCountDropdown
                pageCount={pageCount}
                appliedFilters={appliedFilters}
                selectedCategory={selectedCategory}
                sortField={sortField}
                sortDirection={sortDirection}
                refreshSubscriptions={refreshSubscriptions}
              />
            </div>
          </div>
          <div className="page-filters">
            <Filters
              isOpen={filtersOpen}
              setIsOpen={setFilterPanOpen}
              filters={filters}
              setFilters={setFilters}
              applyFilters={applyFilters}
              clearFilter={clearFilter}
              clearAllFilters={clearAllFilters}
              appliedFiltersCount={appliedFiltersCount}
            />
          </div>
        </div>
        <div id="subscription-list-container">
          <div id="subscription-list-title" className="large-text"></div>
          <TableContainer
            style={{
              overflow:
                subscriptionsLoading ||
                !adminSubscriptions ||
                adminSubscriptions.length === 0
                  ? "hidden"
                  : "auto",
            }}
          >
            <Table
              stickyHeader
              size="small"
              aria-label="My Subscriptions"
              className="collapse-table"
            >
              <TableHead>
                <TableRow className="table-header">
                  <TableCell
                    align="center"
                    style={{ width: "7%", minWidth: 90 }}
                  >
                    View Parameters
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ width: "20%", minWidth: 200, maxWidth: "20%" }}
                  >
                    <div
                      className="sortable"
                      onClick={() => sortSubscriptions(["emailLowered"])}
                      title="Sort"
                    >
                      User
                      <SortIcon
                        className={
                          sortField.includes("emailLowered")
                            ? sortDirection === "asc"
                              ? "selected reverse"
                              : "selected"
                            : ""
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ width: "30%", minWidth: 265, maxWidth: "30%" }}
                  >
                    <div
                      className="sortable"
                      onClick={() => sortSubscriptions(["nameLowered"])}
                      title="Sort"
                    >
                      Report Title
                      <SortIcon
                        className={
                          sortField.includes("nameLowered")
                            ? sortDirection === "asc"
                              ? "selected reverse"
                              : "selected"
                            : ""
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ width: "15%", minWidth: 165 }}
                  >
                    <div
                      className="sortable"
                      onClick={() => sortSubscriptions(["lastRunDateUTC"])}
                      title="Sort"
                    >
                      Last Report
                      <SortIcon
                        className={
                          sortField.includes("lastRunDateUTC")
                            ? sortDirection === "asc"
                              ? "selected reverse"
                              : "selected"
                            : ""
                        }
                      />
                    </div>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{ width: "15%", minWidth: 165 }}
                  >
                    <div
                      className="sortable"
                      onClick={() => sortSubscriptions(["nextRunDateUTC"])}
                      title="Sort"
                    >
                      Next Report
                      <SortIcon
                        className={
                          sortField.includes("nextRunDateUTC")
                            ? sortDirection === "asc"
                              ? "selected reverse"
                              : "selected"
                            : ""
                        }
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      width: "13%",
                      minWidth: 80,
                      position: "sticky",
                      right: 0,
                    }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!subscriptionsLoading &&
                  adminSubscriptions &&
                  adminSubscriptions.map(
                    (subscription: Subscription, i: number) => {
                      return (
                        <AdminSubscriptionComponent
                          subscription={subscription}
                          key={i}
                        />
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </TableContainer>
          {subscriptionsLoading && (
            <div className="spinner">
              <CircularProgress size={30} />
            </div>
          )}
          {!subscriptionsLoading &&
            (!adminSubscriptions || adminSubscriptions.length === 0) && (
              <div className="no-subscriptions">No subscriptions found.</div>
            )}
          {pagination && pagination.totalCount > pagination.pageSize && (
            <Pagination
              pageCount={pageCount}
              currentPage={currentPage}
              appliedFilters={appliedFilters}
              selectedCategory={selectedCategory}
              sortField={sortField}
              sortDirection={sortDirection}
              refreshSubscriptions={refreshSubscriptions}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default AdminSubscriptions;
