import { Dispatch } from "react"
import { Filter } from "../models/filter";
import { FilteredFileStorage } from "../selectors/history.selector";
import { FilteredReport } from "../selectors/report.selector";
import { FilteredAdminSubscription, FilteredSubscription } from "../selectors/subscription.selector";
import { FilteredUser } from "../selectors/user.selector";


export type FilteredObject = FilteredSubscription | FilteredAdminSubscription | FilteredFileStorage | FilteredReport | FilteredUser;
export type FilteredObjectKeys = keyof FilteredSubscription | keyof FilteredAdminSubscription | keyof FilteredFileStorage | keyof FilteredReport | keyof FilteredUser;
// export type FilteredLists = [] | Filter<FilteredSubscription>[] | Filter<FilteredAdminSubscription>[] | Filter<FilteredFileStorage>[] | Filter<FilteredReport>[] | Filter<FilteredUser>[];
export type FilteredList = (Filter<FilteredSubscription> | Filter<FilteredAdminSubscription> | Filter<FilteredFileStorage> | Filter<FilteredReport> | Filter<FilteredUser>)[];


// DEFINE THE INTERFACES

interface ISetFiltersAction {
    readonly type: 'FILTERS_SET_FILTERS';
    filters: FilteredList
}

interface ISetFilterAction {
    readonly type: 'FILTERS_SET_FILTER';
    filter: Filter<FilteredObject>
}

interface ISetSortFieldAction {
    readonly type: 'FILTERS_SET_SORT_FIELD';
    sortField: FilteredObjectKeys
}

interface ISetSearchKeywordAction {
    readonly type: 'FILTERS_SET_SEARCH_KEYWORD';
    searchKeyword: string
}

interface IApplyFiltersAction {
    readonly type: 'FILTERS_APPLY_FILTERS';
}

interface IClearFilterAction {
    readonly type: 'FILTERS_CLEAR_FILTER';
    filterName: string
}


// EXPORT THE INTERFACES
export type FilterActions = ISetFiltersAction | ISetFilterAction | ISetSortFieldAction | ISetSearchKeywordAction | IApplyFiltersAction | IClearFilterAction


// DEFINE THE ACTIONS

/**
 * Set the filters
 * @param filters the filters to set
 * @returns 
 */
const setFilters = (filters: FilteredList) => {
    return function (dispatch: Dispatch<ISetFiltersAction>) {
        dispatch({ type: 'FILTERS_SET_FILTERS', filters: filters });
    }
}

/**
 * Set the filter
 * @param filter the filter to set
 * @returns 
 */
const setFilter = (filter: Filter<FilteredSubscription | FilteredAdminSubscription | FilteredFileStorage | FilteredReport | FilteredUser>) => {
    return function (dispatch: Dispatch<ISetFilterAction>) {
        dispatch({ type: 'FILTERS_SET_FILTER', filter: filter });
    }
}

const setSortField = <T extends FilteredObjectKeys>(sortField: T) => {
    return function (dispatch: Dispatch<ISetSortFieldAction>) {
        dispatch({ type: 'FILTERS_SET_SORT_FIELD', sortField: sortField });
    }
}

const setSearchKeyword = (searchKeyword: string) => {
    return function (dispatch: Dispatch<ISetSearchKeywordAction>) {
        dispatch({ type: 'FILTERS_SET_SEARCH_KEYWORD', searchKeyword: searchKeyword });
    }
}

const applyFilters = () => {
    return function (dispatch: Dispatch<IApplyFiltersAction>) {
        dispatch({ type: 'FILTERS_APPLY_FILTERS' });
    }
}

const clearFilter = (filterName: string) => {
    return function (dispatch: Dispatch<IClearFilterAction>) {
        dispatch({ type: 'FILTERS_CLEAR_FILTER', filterName });
    }
}


// EXPORT THE ACTIONS
export const filterActions = {
    setFilters,
    setFilter,
    setSortField,
    setSearchKeyword,
    applyFilters,
    clearFilter
}