import appConfig from "../../utils/appConfig";
import {
  FetchUtils,
} from "../../utils/fetch.utils";
import { SubscriptionEmailDownload } from "../models/subscriptionEmailDownload";

const apiBaseUrl = appConfig.apiBaseUrl;
const reportApi = 'report';

const downloadFile = (userReportHistoryExportDetailsId: number): Promise<Blob> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetExportPowerBIReport?userReportHistoryExportDetailsId=${userReportHistoryExportDetailsId}`;
  let response = FetchUtils.GETFile(URL);
  return response;
};

const getReportsFromEmail = (emailAccessId: number): Promise<SubscriptionEmailDownload> => {
  const URL = `${apiBaseUrl}/${reportApi}/GetReportsFromEmail?userReportHistoryId=${emailAccessId}`;
  let response = FetchUtils.GET<SubscriptionEmailDownload>(URL);
  return response;
};


export const downloadService = {
  downloadFile,
  getReportsFromEmail
};
