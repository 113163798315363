import "./ContactSupport.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import ContactFormComponent from "./ContactFormComponent";
import { contactActions } from "../../redux/actions/contact.actions";
import { Grid, Typography } from "@mui/material";

function ContactPage() {
  const dispatch = useDispatch();

  // Called on initial render
  useEffect(() => {
    dispatch(contactActions.getContactForm());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="contactus-container" data-testid="contactus-container">
      <Grid container spacing={0} className="sub-header">
          <Typography>Contact Us</Typography>
      </Grid>
      <ContactFormComponent />
    </div>
  );
}
export default ContactPage;
