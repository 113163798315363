import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useRef, useState } from "react";
import "./EnvironmentIndicator.scss";

function EnvironmentIndicator() {
  const [z, setZ] = useState<string | number>("unset");
  const containerRef = useRef<HTMLDivElement>(null);
  const rect = containerRef.current?.getBoundingClientRect();

  const mouseMove = (e: MouseEvent) => {
    if (rect) {
      let containerX = rect.x;
      let containerX2 = rect.x + rect.width;
      let containerY = rect.y;
      let containerY2 = rect.y + rect.height;
      // console.log(e.x);
      // console.log(e.y);
      if (
        e.x >= containerX &&
        e.x <= containerX2 &&
        e.y >= containerY &&
        e.y <= containerY2
      ) {
        setZ(-1);
      } else {
        setZ("unset");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", mouseMove);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  });

  return (
    <div
      id="environment-banner"
      className="small-text"
      ref={containerRef}
      style={{ zIndex: z }}
    >
      <InfoOutlinedIcon />
      {`${process.env.REACT_APP_STAGE?.toUpperCase() ?? "Local"} Environment`}
    </div>
  );
}

export default EnvironmentIndicator;
