import { UserActions } from "../actions/user.actions"
import { UserWithDetails } from "../models/userWithDetails";
import { NetworkError, ServerError } from "../models/serverError"
import { User } from "../models/user"
import { userService } from "../services/user.service";

type UserState = {
    user?: User;
    users: UserWithDetails[];
    loading: boolean;
    loadingUser: boolean;
    loadingUsers: boolean;
    getUserLoading: Record<string, boolean>;
    getUserChecked: Record<string, boolean>;
    error?: ServerError | NetworkError;
}
const initialState: UserState = {
    user: undefined,
    users: [],
    loading: false,
    loadingUser: true,
    loadingUsers: false,
    getUserLoading: {},
    getUserChecked: {},
    error: undefined,
}
const UserReducer = (state: UserState = initialState, action: UserActions): UserState => {
    var _users: UserWithDetails[];

    switch (action.type) {
        // GET OR CREATE USER
        case 'GET_CREATE_USER_REQUEST':
            return {
                ...state,
                loadingUser: true,
                error: undefined
            }
        case 'GET_CREATE_USER_SUCCESS':
            return {
                ...state,
                user: action.payload ?? state.user,
                loadingUser: false,
                error: undefined,
            }
        case 'GET_CREATE_USER_FAILURE':
            return {
                ...state,
                loadingUser: false,
                error: action.error
            }

        // GET USERS
        case 'GET_USERS_REQUEST':
            return {
                ...state,
                loadingUsers: true
            }
        case 'GET_USERS_SUCCESS':
            return {
                ...state,
                loadingUsers: false,
                users: action.users ?? [],
                getUserLoading: {},
                getUserChecked: {}
            }
        case 'GET_USERS_FAILURE':
            return {
                ...state,
                loadingUsers: false,
                error: action.error
            }

        // UPDATE USER ACTIVE
        case 'UPDATE_USER_ACTIVE_REQUEST':
            return {
                ...state,
                getUserLoading: { ...state.getUserLoading, [action.userId.toString()]: true },
                loading: true
            }
        case 'UPDATE_USER_ACTIVE_SUCCESS':
            return {
                ...state,
                users: [...state.users.map((u) => { return u.userId === action.userId ? { ...u, active: action.isActive } : u })],
                getUserLoading: { ...state.getUserLoading, [action.userId.toString()]: false },
                loading: false
            }
        case 'UPDATE_USER_ACTIVE_FAILURE':
            return {
                ...state,
                getUserLoading: { ...state.getUserLoading, [action.userId.toString()]: false },
                error: action.error,
                loading: false
            }

        // UPDATE USER SECURITY ROLES
        case 'USER_UPDATE_USER_SECURITY_ROLES_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'USER_UPDATE_USER_SECURITY_ROLES_SUCCESS':
            _users = [...state.users.map((u) => {
                // eslint-disable-next-line no-loop-func
                let actionUser = action.users?.find(au => au.userId === u.userId);
                if (actionUser) {
                    return {
                        ...u,
                        roles: actionUser.roles
                    }
                }
                return u;
            })];
            return {
                ...state,
                users: _users,
                loading: false
            }
        case 'USER_UPDATE_USER_SECURITY_ROLES_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        // UPDATE USER REPORT ACCESS
        case 'USER_UPDATE_USER_REPORT_ACCESS_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'USER_UPDATE_USER_REPORT_ACCESS_SUCCESS':
            _users = [...state.users.map((u) => {
                // eslint-disable-next-line no-loop-func
                let actionUser = action.users?.find(au => au.userId === u.userId);
                if (actionUser) {
                    return {
                        ...u,
                        reports: actionUser.reports
                    }
                }
                return u;
            })];
            return {
                ...state,
                users: _users,
                loading: false
            }
        case 'USER_UPDATE_USER_REPORT_ACCESS_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        // UPDATE USER DEFAULT DASHBOARD
        case 'USER_UPDATE_USER_DEFAULT_DASHBOARD_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'USER_UPDATE_USER_DEFAULT_DASHBOARD_SUCCESS':
            _users = [...state.users.map((u) => {
                // eslint-disable-next-line no-loop-func
                let actionUser = action.users?.find(au => au.userId === u.userId);
                if (actionUser) {
                    return {
                        ...u,
                        defaultDashboardName: actionUser.defaultDashboardName,
                        reports: actionUser.reports
                    }
                }
                return u;
            })];
            return {
                ...state,
                users: _users,
                loading: false
            }
        case 'USER_UPDATE_USER_DEFAULT_DASHBOARD_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        // USER SIGN OUT
        case 'USER_SIGN_OUT_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case 'USER_SIGN_OUT_SUCCESS':
            return {
                ...state,
                loading: false,
                error: undefined,
                user: undefined
            }
        case 'USER_SIGN_OUT_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        // CHECK USER
        case 'USER_CHECK_USER':
            return {
                ...state,
                getUserChecked: { ...state.getUserChecked, [action.userId.toString()]: action.isChecked },
            }

        // CHECK ALL USERS
        case 'USER_CHECK_ALL_USERS':
            let _getUserChecked = { ...state.getUserChecked }
            for (var u of state.users) {
                _getUserChecked[u.userId.toString()] = action.isChecked
            }
            return {
                ...state,
                getUserChecked: _getUserChecked
            }

        // CLEAR ALL
        case 'USERS_CLEAR_ALL':
            return { ...initialState }
        default:
            return state;
    }
}
export default UserReducer;