import { JobScheduleDaysOfWeek } from "./jobScheduleDaysOfWeek";
import { JobScheduleFreqType } from "./jobScheduleFreqType";
import { JobScheduleMonthlyDayOfWeek } from "./jobScheduleMonthlyDayOfWeek";
import { JobScheduleMonthlyFirstOrLastDay } from "./jobScheduleMonthlyFirstOrLastDay";
import { Timezone } from "./timezone";

export interface JobSchedule {
  jobScheduleId?: Number;
  description: string;
  jobScheduleFreqTypeId: number;
  jobScheduleFreqType?: JobScheduleFreqType;
  jobScheduleMonthlyRunDay?: number;
  jobScheduleMonthlyDayOfWeekId?: number,
  jobScheduleMonthlyFirstOrLastDayId?: number,
  jobScheduleMonthlyDayOfWeek?: JobScheduleMonthlyDayOfWeek;
  jobScheduleMonthlyFirstOrLastDay?: JobScheduleMonthlyFirstOrLastDay;
  runTime: string;
  jobScheduleRecurrenceFactor: number;
  notes: string;
  jobScheduleDaysOfWeek: JobScheduleDaysOfWeek[];
  activeStartDateTime?: Date;
  activeEndDateTime?: Date;
  timezoneId?: number;
  timezone?: Timezone;
}

export function CreateDefaultJobSchedule(defaultTimezone?: Timezone): JobSchedule {
  let ret: JobSchedule = {
    jobScheduleId: undefined,
    description: '',
    jobScheduleFreqTypeId: 0,
    //jobScheduleFreqType: CreateDefaultJobScheduleFreqType(),
    ////jobScheduleFreqRelativeInterval: CreateDefaultJobScheduleFreqRelativeInterval(),
    //jobScheduleMonthlyDayOfWeek: CreateDefaultJobScheduleMonthlyDayOfWeek(),
    //jobScheduleMonthlyFirstOrLastDay: CreateDefaultJobScheduleMonthlyFirstOrLastDay(),
    runTime: '12:00 AM',
    jobScheduleRecurrenceFactor: 1,
    notes: '',
    jobScheduleDaysOfWeek: [],
    timezoneId: defaultTimezone?.timezoneId
  }
  return ret;
}

export function ResetJobSchedule(jobSchedule: JobSchedule, freqTypeId: number): JobSchedule {
  jobSchedule.jobScheduleFreqTypeId = freqTypeId;
  jobSchedule.runTime = "12:00 AM";
  jobSchedule.jobScheduleRecurrenceFactor = 1;
  jobSchedule.jobScheduleMonthlyRunDay = undefined;
  jobSchedule.jobScheduleMonthlyDayOfWeekId = undefined;
  jobSchedule.jobScheduleMonthlyFirstOrLastDayId = undefined;
  jobSchedule.jobScheduleDaysOfWeek = [];
  return jobSchedule;
}