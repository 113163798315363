import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { DateUtils } from "../../utils/date.utils";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { FilteredSubscription } from "../../redux/selectors/subscription.selector";

export interface SubscriptionComponentProps {
  subscription: FilteredSubscription;
}

function SubscriptionComponent({ subscription }: SubscriptionComponentProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow>
        <TableCell colSpan={6}>
          <Table size="small">
            <TableBody>
              <TableRow className={subscription?.active ? "" : "inactive"}>
                <TableCell
                  align="center"
                  style={{
                    width: "7%",
                    minWidth: 90,
                    borderBottomLeftRadius: isOpen ? 0 : 10,
                  }}
                >
                  <div className="show" onClick={() => setIsOpen(!isOpen)}>
                    {!isOpen && (
                      <div>
                        <span>Show</span> <ArrowDropDownIcon />
                      </div>
                    )}
                    {isOpen && (
                      <div>
                        <span>Hide</span> <ArrowDropUpIcon />
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  style={{ width: "20%", minWidth: 200, maxWidth: "20%" }}
                >
                  {subscription.ownedByUser?.emailAddress}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ width: "30%", minWidth: 200, maxWidth: "30%" }}
                >
                  {subscription.report?.name}
                  {subscription.name?.length ? ` - ${subscription.name}` : ""}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "15%", minWidth: 165, maxWidth: "15%" }}
                >
                  {DateUtils.formatDateTime(subscription.lastRunDateUTC)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ width: "15%", minWidth: 165, maxWidth: "15%" }}
                >
                  {DateUtils.formatDateTime(subscription.nextRunDateUTC)}
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    width: "13%",
                    position: "sticky",
                    right: 0,
                    maxWidth: "13%",
                  }}
                >
                  <div
                    className="actions"
                    style={{
                      borderBottomRightRadius: isOpen ? 0 : 10,
                      paddingTop: 5,
                    }}
                  >
                    <span className="sub-status">
                      {subscription?.active ? "Active" : " Disabled"}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Collapse
            in={isOpen}
            timeout="auto"
            unmountOnExit
            className={
              subscription?.active ? "collapse-row" : "collapse-row-inactive"
            }
          >
            <div className="medium-text">
              <span className="bold-text">File types: </span>
              <span>
                {subscription.subscriptionExtensions &&
                  subscription.subscriptionExtensions
                    .map((s) => s.exportFileExtension?.fileExtension)
                    .join(", ")}
              </span>
            </div>
            <div className="subscription-component-params medium-text">
              <div className="filters-container">
                <span className="bold-text">Filters: </span>
                <span>
                  {subscription.parameters &&
                    subscription.parameters
                      .split(" And ")
                      .map((p, i) => (
                        <div key={i}>
                          {p.includes(".") ? p.split(".")[1] : p.split("/")[1]}
                        </div>
                      ))}
                </span>
              </div>
              <div>
                <span className="bold-text">Notes: </span>
                {subscription.jobSchedule?.notes ?? "N/A"}
              </div>
            </div>
          </Collapse>
          <div className="row-sep"></div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default SubscriptionComponent;
