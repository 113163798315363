export interface ServerError {
    displayMessage: string;
    errorType: ServerErrorType;
}

export enum ServerErrorType {
    Info = 0,
    Warning = 1,
    Error = 2,
    Unauthorized = 3,
    Forbidden = 4,
    Disabled = 5,
    NotApproved = 6
}

export interface NetworkError {
    status: number;
    title: string;
}