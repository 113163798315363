import { DashboardActions } from "../actions/dashboard.actions";
import { Dashboard } from "../models/dashboard";
import { NetworkError, ServerError } from "../models/serverError";
import { Widget, WidgetLayout } from "../models/widget";
import _ from "lodash";

type DashboardState = {
    dashboards: Dashboard[];
    selectedDashboard: Dashboard;
    loading: boolean;
    loadingEmbedRequest: boolean;
    error?: ServerError | NetworkError;
    newWidget?: Widget;
    fullScreen: boolean;
    sharing: boolean;
    dashboardUserIdToRemove: number;
}

const initialState: DashboardState = {
    dashboards: [],
    selectedDashboard: {} as Dashboard,
    loading: false,
    loadingEmbedRequest: false,
    error: undefined,
    newWidget: undefined,// CreateDefaultWidget(),
    fullScreen: false,
    sharing: false,
    dashboardUserIdToRemove: 0,
}
const createWidgetLayout = (item: Widget, index: number): WidgetLayout => {
    return {
        i: index.toString(),
        x: item.x ?? 0,
        y: item.y ?? 0,
        w: item.width ?? 0,
        h: item.height ?? 0,
        minW: 4,
        minH: 4,
        // embedUrl: undefined,
        // embedToken: undefined,
        // // uniqueEmbedId: undefined,
        visible: true,
        isFullScreen: false
    };
}
const DashboardReducer = (state: DashboardState = initialState, action: DashboardActions): DashboardState => {
    let _dashboards: Dashboard[];
    let _selectedDashboard: Dashboard | undefined;
    switch (action.type) {
        case 'SET_WIDGETS':
            return {
                ...state,
                selectedDashboard: { ...state.selectedDashboard, widgets: action.widgets } ?? state.selectedDashboard
            }

        case 'SET_WIDGET_FULLSCREEN':
            return {
                ...state,
                selectedDashboard: {
                    ...state.selectedDashboard,
                    widgets: [...state.selectedDashboard.widgets!.map(w => {
                        return (w?.widgetId === action.widgetId ?
                            {
                                ...w, widgetLayout:
                                    { ...w.widgetLayout!, isFullScreen: action.isFullscreen, static: action.isFullscreen }
                            }
                            : w)
                    })]
                }
            }

        // GET_USER_DASHBOARDS
        case 'DASHBOARD_GET_USER_DASHBOARDS_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'DASHBOARD_GET_USER_DASHBOARDS_SUCCESS':
            _dashboards = action.response!.map((d: Dashboard): Dashboard => {
                return {
                    ...d, widgets: d.widgets?.map((w, i) => { return { ...w, widgetLayout: createWidgetLayout(w, i), visible: true } })
                }
            });
            if (action.selectDashboardId !== null) {
                _selectedDashboard = _dashboards.find(d => d.dashboardId === action.selectDashboardId)!;
            }
            else
                _selectedDashboard = (state.selectedDashboard.dashboardId ? _dashboards?.find(d => d.dashboardId === state.selectedDashboard.dashboardId) : _dashboards?.find(d => d.isDefault)) ?? {} as Dashboard;
            return {
                ...state,
                dashboards: [..._dashboards],
                selectedDashboard: _selectedDashboard,
                // pinToDashboardId: _dashboards?.find(d => d.isDefault)?.dashboardId ?? 0,
                newWidget: { ...state.newWidget, dashboardId: _dashboards?.find(d => d.isDefault)?.dashboardId ?? 0 } ?? state.newWidget,
                loading: false
            }
        case 'DASHBOARD_GET_USER_DASHBOARDS_FAILURE':
            return {
                ...state,
                loading: false
            }

        // ADD WIDGET
        case 'DASHBOARD_ADD_WIDGET_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'DASHBOARD_ADD_WIDGET_SUCCESS':
            let updated = state.dashboards;
            if (action.response?.dashboard) {
                updated = updated.filter(d => d.dashboardId !== action.response?.dashboard?.dashboardId);
                updated.push({ ...action.response.dashboard });
            }
            return {
                ...state,
                loading: false,
                dashboards: updated ?? state.dashboards
            }
        case 'DASHBOARD_ADD_WIDGET_FAILURE':
            return {
                ...state,
                loading: false
            }

        // UPDATE_DASHBOARD
        case 'DASHBOARD_UPDATE_DASHBOARD_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'DASHBOARD_UPDATE_DASHBOARD_SUCCESS':
            _dashboards = [...state.dashboards.map(d => { return action.dashboard.dashboardId === d.dashboardId ? { ...action.dashboard } : { ...d, isDefault: false } })];
            return {
                ...state,
                loading: false,
                dashboards: _dashboards,
                selectedDashboard: _dashboards.find(d => d.dashboardId === state.selectedDashboard.dashboardId) ?? {}
            }
        case 'DASHBOARD_UPDATE_DASHBOARD_FAILURE':
            return {
                ...state,
                loading: false
            }

        // RESIZE_WIDGETS
        case 'DASHBOARD_RESIZE_WIDGET_REQUEST':
            return {
                ...state
            }
        case 'DASHBOARD_RESIZE_WIDGET_SUCCESS':
            return {
                ...state
            }
        case 'DASHBOARD_RESIZE_WIDGET_FAILURE':
            return {
                ...state
            }

        // CLONE_DASHBOARD
        case 'DASHBOARD_CLONE_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'DASHBOARD_CLONE_SUCCESS':
            return {
                ...state,
                loading: false,
                dashboards: [...state.dashboards, { ...action.response, widgets: action.response?.widgets?.map((w, i) => { return { ...w, widgetLayout: createWidgetLayout(w, i) } }) }],
                selectedDashboard: { ...action.response, widgets: action.response?.widgets?.map((w, i) => { return { ...w, widgetLayout: createWidgetLayout(w, i) } }) } ?? state.selectedDashboard
            }
        case 'DASHBOARD_CLONE_FAILURE':
            return {
                ...state,
                loading: false
            }

        // DELETE_DASHBOARD
        case 'DASHBOARD_DELETE_DASHBOARD_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'DASHBOARD_DELETE_DASHBOARD_SUCCESS':
            _dashboards = [...state.dashboards.filter(d => d.dashboardId !== action.dashboardId)]
            if (action.nextDefaultDashboardId !== null) {
                if(action.nextDefaultDashboardId!=0){
                    _dashboards = _dashboards.map(d => { return d.dashboardId === action.nextDefaultDashboardId ? { ...d, isDefault: true } : { ...d, isDefault: false } })
                }
                _selectedDashboard = _dashboards.find(d => d.isDefault) ?? {}
            }
            else {
                _selectedDashboard = {};
            }
            return {
                ...state,
                loading: false,
                dashboards: _dashboards,
                selectedDashboard: _selectedDashboard
            }
        case 'DASHBOARD_DELETE_DASHBOARD_FAILURE':
            return {
                ...state,
                loading: false
            }


        // SET_CURRENT_DASHBOARD:
        case 'DASHBOARD_SET_CURRENT_DASHBOARD':
            _dashboards = state.dashboards!.map((d: Dashboard): Dashboard => {
                return {
                    ...d, widgets: d.widgets?.map((w, i) => { return { ...w, widgetLayout: createWidgetLayout(w, i), visible: true } })
                }
            });
            return {
                ...state,
                dashboards: [..._dashboards],
                selectedDashboard: _dashboards.find(d => d.dashboardId === action.dashboardId) ?? {} as Dashboard,
            }

        // case 'SET_EXIST_DASHBOARD':
        //     return {
        //         ...state,
        //         newWidget: { ...state.newWidget, dashboardId: state.pinToDashboardId, dashboard: { ...state.newWidget?.dashboard, name: '' } ?? state.newWidget?.dashboard } ?? state.newWidget,
        //         newDashboardName: '',
        //         selectedOption: 1
        //     }
        // case 'SET_NEW_DASHBOARD':
        //     return {
        //         ...state,
        //         newWidget: { ...state.newWidget, dashboardId: 0, dashboard: { ...state.newWidget?.dashboard, dashboardId: 0 } } ?? state.newWidget,
        //         newDashboardName: '',
        //         selectedOption: 2
        //     }
        // case 'SET_NEW_DASHBOARD_NAME':
        //     return {
        //         ...state,
        //         newDashboardName: action.name,
        //         newWidget: { ...state.newWidget, dashboard: { ...state.newWidget?.dashboard, name: action.name } ?? state.newWidget?.dashboard } ?? state.newWidget,
        //     }
        // case 'SET_PIN_TO_DASHBOARD':
        //     return {
        //         ...state,
        //         newWidget: { ...state.newWidget, dashboardId: action.dashboardId, dashboard: { ...state.newWidget?.dashboard, dashboardId: action.dashboardId } } ?? state.newWidget,
        //     }
        case 'SET_NEW_WIDGET':
            return {
                ...state,
                newWidget: {
                    ...state.newWidget,
                    reportId: action.visualName ? undefined : action.reportId,
                    filters: action.filters ? JSON.stringify(action.filters) : undefined,
                    slicers: action.slicers ? JSON.stringify(action.slicers) : undefined,
                    reportVisual: action.visualName ? { ...state.newWidget?.reportVisual, reportId: action.reportId, visualName: action.visualName, pageName: action.pageName } : undefined
                } ?? state.newWidget,
            }

        // DELETE_WIDGET
        case 'DASHBOARD_DELETE_WIDGET_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'DASHBOARD_DELETE_WIDGET_SUCCESS':
            _dashboards = [...state.dashboards];
            _selectedDashboard = _dashboards.find(d => d.dashboardId === action.dashboardId);

            if (_selectedDashboard) {
                _selectedDashboard.widgets = _.reject(_selectedDashboard?.widgets, { widgetId: action.widgetId });
            }
            return {
                ...state,
                loading: false,
                selectedDashboard: { ..._selectedDashboard },
                dashboards: [..._dashboards],
            }
        case 'DASHBOARD_DELETE_WIDGET_FAILURE':
            return {
                ...state,
                loading: false
            }

        // EMDED WIDGET
        case 'DASHBOARD_EMBED_WIDGETS_REQUEST':
            return {
                ...state,
                loadingEmbedRequest: true
            }

        case 'DASHBOARD_EMBED_WIDGETS_SUCCESS':
            _dashboards = state.dashboards;
            let _d = _dashboards.find(d => d.dashboardId === action.dashboardId);

            if (_d && _d.widgets) {
                for (const w of _d.widgets) {
                    let widgetResponse = action.response?.find(ww => ww.widgetId === w.widgetId);
                    if (widgetResponse) {
                        w.widgetLayout!.uniqueEmbedId = widgetResponse.report?.uniqueEmbedId ?? widgetResponse.reportVisual?.report?.uniqueEmbedId
                    }
                    else {
                        console.log(`Can't find widget with id ${w.widgetId}`)
                    }
                }
            }

            return {
                ...state,
                dashboards: [..._dashboards],
                selectedDashboard: _d ?? state.selectedDashboard,
                loadingEmbedRequest: state.selectedDashboard.widgets?.find(w => w.widgetLayout?.embedToken === undefined) !== undefined
            }
        case 'DASHBOARD_EMBED_WIDGETS_FAILURE':
            return {
                ...state,
                loadingEmbedRequest: state.selectedDashboard.widgets?.find(w => w.widgetLayout?.embedToken === undefined) !== undefined
            }

        // UPDATE_WIDGET_EMBED
        case 'UPDATE_WIDGET_EMBED':
            _dashboards = [...state.dashboards.map((d: Dashboard): Dashboard => {
                return { ...d, widgets: d.widgets?.map(w => w.widgetLayout?.uniqueEmbedId === action.uniqueEmbedId ? { ...w, widgetLayout: { ...w.widgetLayout, embedUrl: action.embedUrl, embedToken: action.embedToken } } : w) }
            })];
            _selectedDashboard = _dashboards?.find(d => d.dashboardId === state.selectedDashboard.dashboardId) ?? {} as Dashboard
            return {
                ...state,
                dashboards: _dashboards,
                selectedDashboard: _selectedDashboard,
                loadingEmbedRequest: _selectedDashboard.widgets?.find(w => w.widgetLayout?.embedToken === undefined) !== undefined
            }

        // UPDATE_WIDGET_ERROR
        case 'UPDATE_WIDGET_ERROR':
            _dashboards = [...state.dashboards.map((d: Dashboard): Dashboard => {
                return { ...d, widgets: d.widgets?.map(w => w.widgetLayout?.uniqueEmbedId === action.uniqueEmbedId ? { ...w, widgetLayout: { ...w.widgetLayout, errorMessage: action.errorMessage } } : w) }
            })];
            _selectedDashboard = _dashboards?.find(d => d.dashboardId === state.selectedDashboard.dashboardId) ?? {} as Dashboard
            return {
                ...state,
                dashboards: _dashboards,
                selectedDashboard: _selectedDashboard,
                loadingEmbedRequest: _selectedDashboard.widgets?.find(w => w.widgetLayout?.embedToken === undefined) !== undefined
            }

        // SHARE DASHBOARD
        case 'DASHBOARD_SHARE_REQUEST':
            return {
                ...state,
                sharing: true,
            }
        case 'DASHBOARD_SHARE_SUCCESS':
            return {
                ...state,
                sharing: false,
                selectedDashboard: action.response ? { ...state.selectedDashboard, dashboardUsers: [...state.selectedDashboard.dashboardUsers ?? [], action.response] } : state.selectedDashboard
            }
        case 'DASHBOARD_SHARE_FAILURE':
            return {
                ...state,
                sharing: false,
            }
        // REMOVE_USER
        case 'DASHBOARD_REMOVE_USER_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'DASHBOARD_REMOVE_USER_SUCCESS':
            _dashboards = [...state.dashboards];
            _selectedDashboard = { ...state.selectedDashboard };
            if (action.dashboardUserId === state.selectedDashboard.dashboardUserId) {
                // user is removing themself as a recipient
                _dashboards = _.reject(_dashboards, { dashboardId: action.dashboardId });
                if (action.nextDefaultDashboardId !== null) {
                    _dashboards = _dashboards.map(d => { return d.dashboardId === action.nextDefaultDashboardId ? { ...d, isDefault: true } : { ...d, isDefault: false } })
                    _selectedDashboard = _dashboards.find(d => d.dashboardId === action.nextDefaultDashboardId) ?? {}
                }
                else
                    _selectedDashboard = _dashboards.length > 0 ? (_dashboards.find(d => d.isDefault) ?? {}) : {};
            }
            else {
                _selectedDashboard = { ...state.selectedDashboard, dashboardUsers: state.selectedDashboard.dashboardUsers ? [...state.selectedDashboard.dashboardUsers?.filter(du => du.dashboardUserId !== action.dashboardUserId)] : [] } ?? state.selectedDashboard;
            }
            return {
                ...state,
                loading: false,
                dashboards: _dashboards,
                selectedDashboard: _selectedDashboard
            }
        case 'DASHBOARD_REMOVE_USER_FAILURE':
            return {
                ...state,
                loading: false
            }
        // SET USER TO REMOVE:
        case 'DASHBOARD_SET_REMOVE_USER':
            return {
                ...state,
                dashboardUserIdToRemove: action.dashboardUserId
            }
        case 'DASHBOARD-CLEAR-ALL':
            return {
                ...initialState,
                dashboards: [...state.dashboards.map(d => {
                    return { ...d, widgets: [] }
                })],
                selectedDashboard: {
                    ...state.selectedDashboard,
                    widgets: []
                }
            }
        default:
            return state;
    }
}
export default DashboardReducer;