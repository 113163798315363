import { Dispatch } from "react"
import { BlobStorageStats } from "../models/blobStorageStats"
import { PowerBIReport } from "../models/powerBIReport"
import { PowerBIWorkspace } from "../models/powerBIWorkspace"
import { SecurityRole } from "../models/securityRole"
import { Setting } from "../models/setting"
import { adminService } from "../services/admin.service"
import { userService } from "../services/user.service"
import { IBaseAction } from "./base.action"

interface IGetSettingsAction extends IBaseAction {
    readonly type: 'GET_SETTINGS_REQUEST' | 'GET_SETTINGS_SUCCESS' | 'GET_SETTINGS_FAILURE';
    payload?: Setting[];
}

interface ISetSavedAction extends IBaseAction {
    readonly type: 'SET_SAVED'
    saved: boolean
}

interface ISaveSettingsAction extends IBaseAction {
    readonly type: 'SAVE_SETTINGS_REQUEST' | 'SAVE_SETTINGS_SUCCESS' | 'SAVE_SETTINGS_FAILURE';
    updatedSettings?: Setting[]
}

interface IClearAllAction {
    readonly type: 'ADMIN_CLEAR_ALL'
}

interface IGetStorageStatsAction extends IBaseAction {
    readonly type: 'ADMIN_GET_STORAGE_STATS_REQUEST' | 'ADMIN_GET_STORAGE_STATS_SUCCESS' | 'ADMIN_GET_STORAGE_STATS_FAILURE';
    storageStats?: BlobStorageStats
}

interface IGetPowerBIReports extends IBaseAction {
    readonly type: 'ADMIN_GET_POWERBI_REPORTS_REQUEST' | 'ADMIN_GET_POWERBI_REPORTS_SUCCESS' | 'ADMIN_GET_POWERBI_REPORTS_FAILURE';
    powerBiReports?: PowerBIReport[]
}

interface IGetPowerBIWorkspaces extends IBaseAction {
    readonly type: 'ADMIN_GET_POWERBI_WORKSPACES_REQUEST' | 'ADMIN_GET_POWERBI_WORKSPACES_SUCCESS' | 'ADMIN_GET_POWERBI_WORKSPACES_FAILURE';
    powerBiWorkspaces?: PowerBIWorkspace[]
}

interface IGetSecurityRolesAction extends IBaseAction {
    readonly type: 'ADMIN_GET_SECURITY_ROLES_REQUEST' | 'ADMIN_GET_SECURITY_ROLES_SUCCESS' | 'ADMIN_GET_SECURITY_ROLES_FAILURE';
    securityRoles?: SecurityRole[]
}


export type AdminActions =
    | IGetSettingsAction | ISetSavedAction | ISaveSettingsAction | IClearAllAction | IGetStorageStatsAction | IGetPowerBIReports | IGetPowerBIWorkspaces | IGetSecurityRolesAction


const getSettings = () => {
    return function (dispatch: Dispatch<IGetSettingsAction>) {
        dispatch({ type: 'GET_SETTINGS_REQUEST' });
        userService.getSettings().then((response) => {
            return dispatch({ type: 'GET_SETTINGS_SUCCESS', payload: response })
        }, (error) => {
            return dispatch({ type: 'GET_SETTINGS_FAILURE', error })
        })
    }
}

const saveSettings = (settings: Setting[]) => {
    return function (dispatch: Dispatch<ISaveSettingsAction>) {
        dispatch({ type: 'SAVE_SETTINGS_REQUEST' });
        adminService.setSettings(settings).then((response) => {
            return dispatch({ type: 'SAVE_SETTINGS_SUCCESS', updatedSettings: settings })
        }, (error) => {
            return dispatch({ type: 'SAVE_SETTINGS_FAILURE', error })
        })
    }
}

const setSaved = (saved: boolean) => {
    return function (dispatch: Dispatch<ISetSavedAction>) {
        dispatch({ type: 'SET_SAVED', saved });
    }
}

const clearAll = () => {
    return function (dispatch: Dispatch<IClearAllAction>) {
        return dispatch({ type: 'ADMIN_CLEAR_ALL' })
    }
}

const getBlobStorageStats = () => {
    return function (dispatch: Dispatch<IGetStorageStatsAction>) {
        dispatch({ type: 'ADMIN_GET_STORAGE_STATS_REQUEST' });
        adminService.getStorageStats().then((response) => {
            return dispatch({ type: 'ADMIN_GET_STORAGE_STATS_SUCCESS', storageStats: response })
        }, (error) => {
            return dispatch({ type: 'ADMIN_GET_STORAGE_STATS_FAILURE', error })
        })
    }
}

const getPowerBIWorkspaces = () => {
    return function (dispatch: Dispatch<IGetPowerBIWorkspaces>) {
        dispatch({ type: 'ADMIN_GET_POWERBI_WORKSPACES_REQUEST' });
        adminService.getPowerBIWorkspaces().then((response) => {
            return dispatch({ type: 'ADMIN_GET_POWERBI_WORKSPACES_SUCCESS', powerBiWorkspaces: response })
        }, (error) => {
            return dispatch({ type: 'ADMIN_GET_POWERBI_WORKSPACES_FAILURE', error })
        })
    }
}

const getPowerBIReports = (workspaceId: string) => {
    return function (dispatch: Dispatch<IGetPowerBIReports>) {
        dispatch({ type: 'ADMIN_GET_POWERBI_REPORTS_REQUEST' });
        adminService.getPowerBIReports(workspaceId).then((response) => {
            return dispatch({ type: 'ADMIN_GET_POWERBI_REPORTS_SUCCESS', powerBiReports: response })
        }, (error) => {
            return dispatch({ type: 'ADMIN_GET_POWERBI_REPORTS_FAILURE', error })
        })
    }
}

const getSecurityRoles = () => {
    return function (dispatch: Dispatch<IGetSecurityRolesAction>) {
        dispatch({ type: 'ADMIN_GET_SECURITY_ROLES_REQUEST' });
        adminService.getSecurityRoles().then((response) => {
            return dispatch({ type: 'ADMIN_GET_SECURITY_ROLES_SUCCESS', securityRoles: response })
        }, (error) => {
            return dispatch({ type: 'ADMIN_GET_SECURITY_ROLES_FAILURE', error })
        })
    }
}




export const adminActions = {
    getSettings,
    saveSettings,
    setSaved,
    clearAll,
    getBlobStorageStats,
    getPowerBIWorkspaces,
    getPowerBIReports,
    getSecurityRoles
}