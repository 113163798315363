import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import {
  FilteredSubscription,
  isSubscriptionsLoading,
  selectAppliedFiltersCount,
  selectFilteredSubscriptions,
  selectPageFilters, selectSubscriptionSearchKeyword, selectSubscriptionSelectedCategory,
} from "../../redux/selectors/subscription.selector";
import "./Subscriptions.scss";
import {FormControl, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import ReportTypeDropdown from './ReportTypeDrowdown';
import { ReportCategory } from "../../redux/models/reportCategory";
import { selectAllReportCategories } from "../../redux/selectors/report.selector";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import { DateRange } from "@mui/lab";
import DateRangeFilter from "./DateRangeFilter";
import { Filter } from "../../redux/models/filter";
import { Dayjs } from "dayjs";
import MySubscriptionsTable from "./MySubscriptionTable";
import { SearchTextField } from "../Common/Utils";



function MySubscriptions() {
  const dispatch = useDispatch();

  const { rid } = useParams<{ rid: string }>();
  const selectedCategory = useSelector(selectSubscriptionSelectedCategory);
  const categories = useSelector(selectAllReportCategories);
  const filters = useSelector(selectPageFilters);
  const searchKeyword = useSelector(selectSubscriptionSearchKeyword);
  const subscriptionsLoading = useSelector(isSubscriptionsLoading);
  const filteredSubscriptions = useSelector(selectFilteredSubscriptions);
  const appliedFiltersCount = useSelector(selectAppliedFiltersCount);

  useEffect(() => {
    if (rid !== undefined) {
      dispatch(subscriptionActions.setSelectedReportId(parseInt(rid)));
    } else {
      dispatch(subscriptionActions.setSelectedReportId());
    }
    dispatch(subscriptionActions.getSubscriptions());
  }, [rid]);

  const searchSubscriptions = (keyword: string) =>
    dispatch(subscriptionActions.searchSubscriptions(keyword));
  const clearSearch = () =>
    dispatch(subscriptionActions.searchSubscriptions(""));

  const setSelectedCategory = (category: ReportCategory) =>
  dispatch(subscriptionActions.setSelectedCategory(category));
  const setFilters = (filters: Filter<FilteredSubscription>[]) =>
    dispatch(subscriptionActions.setPageFilters(filters));

    const applyFilters = (filters: Filter<FilteredSubscription>[]) =>
    dispatch(subscriptionActions.applyFilters(filters));

    const clearAllFilters = () => dispatch(subscriptionActions.clearAllFilters());

  const clearFilter = (filterName: string) =>
    dispatch(subscriptionActions.clearFilter(filterName));

  useEffect(() => {
    if (
      categories.length > 0 &&
      categories.find((c) => c.categoryId === selectedCategory?.categoryId) ===
        undefined
    )
      setSelectedCategory(categories[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories]);

  function setDateFilter(
    index: number,
    values: any[],
    newValue: DateRange<Dayjs>
  ) {
    filters[index].values = values;
    filters[index].rawValues = newValue;
    setFilters(filters);
    applyFilters(filters);
  }

  
    

  return (
    <div id="subscription-container" data-testid="subscription-container">
        <Grid container spacing={0} className="sub-header">
          <Grid item xs={12} lg={1.5} xl={1} md={2} sm={3.5} className="sub-title">
            <Typography>Subscriptions</Typography>
          </Grid>
          <Grid item xs={12} xl={2} lg={3} md={2} sm={3.5} className="cat-list">
            <ReportTypeDropdown
                  visibleCategories={categories}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                />
          </Grid>
        </Grid>
        <Grid container spacing={0} className="filter-section">
          <Grid item lg={3} xl={3} md={3} xs={12} sm={6} className="search-container">
          <FormControl sx={{ m: 1 }}>
              <SearchTextField
                id="search-bar"
                value={searchKeyword}
                color="primary"
                size="small"
                onChange={(event) => searchSubscriptions(event.target.value)}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={clearSearch}
                        edge="end"
                      >
                        {searchKeyword.length > 0 ? (
                          <ClearIcon />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={searchKeyword.length === 0 ? "Search" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item lg={2.5} xl={2.5} md={0} sm={0}></Grid>
            {filters?.map((filter, i) => {
              if (filter.filterType === "date") {
                return(
                  <Grid key={filter.name} data-testid="filestorage-daterange-list" className={filter.name} item xl={3.2} lg={3.2} md={4.5} xs={12} sm={6}>
                    <DateRangeFilter
                      key={filter.name}
                      index={i}
                      displayName={filter.displayName}
                      setFilter={setDateFilter}
                      values={filter?.rawValues ??[null, null]}
                    />
                  </Grid>
                    );
                }
              })}
        </Grid>

        {appliedFiltersCount > 0 && (
        <div className="filters-footer">
          <Typography className="title"> Applied Filters:</Typography>
            
            {filters?.map((filter, i) => {
              if (filter.applied)
                return (
                  <Typography className="filter" key={filter.name}>
                    {filter.displayName}{" "}
                    <span
                      onClick={() => {
                        clearFilter(filter.name?.toString());
                      }}
                    >
                      X
                    </span>
                  </Typography>
                );
              return null;
            })}
          <Typography
            className="clear-all"
            onClick={() => clearAllFilters()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M9.5 2.5L2.5 9.5" stroke="#4664F0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M2.5 2.5L9.5 9.5" stroke="#4664F0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Clear All
          </Typography>
        </div>
      )}

        <MySubscriptionsTable subscriptionsLoading={subscriptionsLoading} filteredSubscriptions={filteredSubscriptions}></MySubscriptionsTable>
      </div>
  );
}

export default MySubscriptions;

