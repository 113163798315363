import { useHistory, useRouteMatch } from "react-router-dom";

export interface ISideNavSubItem{
  label: string, 
  url: string, 
  adminOnly?: boolean, 
  navItemName?: string
  isAdmin?: boolean, 
  arrowUp?: boolean, 
  isAdministrationUp?: boolean, 
  setIsCollapsed: (is: boolean)=>void,
}

const SideNavSubItem = ({
  label, 
  url, 
  adminOnly, 
  navItemName, 
  isAdmin, 
  arrowUp, 
  isAdministrationUp,
  setIsCollapsed
}:ISideNavSubItem) => {
  const history = useHistory();
  let isActive = useRouteMatch(url) !== null;
  if (adminOnly && !isAdmin) {
    return null;
  }
  if (navItemName === 'configurations' && arrowUp) {
    return null;
  }
  
  if (navItemName === 'admin' && isAdministrationUp) {
    return null;
  }
  return (
    <div
      key={url}
      className={"nav-sub-item no-select" + (isActive ? " active" : "")}
      onClick={() => {
        setIsCollapsed(true);
        history.push(url);
      }}
    >
      <span>{label}</span>
    </div>
  );
};

  export default SideNavSubItem;