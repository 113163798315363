import { Button, Grid, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Link } from "react-router-dom";


interface NoReportsFoundProps {
  isAdmin:boolean;
  favoriteParam:string|null;
  handleAddReport: () => void;
}

const NoReportsFound = ({isAdmin,favoriteParam,handleAddReport}:NoReportsFoundProps) => {

return (
<>
         
         {isAdmin && favoriteParam !== "true"&& (
           <Grid container spacing={1} width="100%" >
             <Grid item xs={12} sm={6} md={6} lg={6} xl={6}></Grid>
             <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ justifyContent: 'right', display: 'flex' }}>
               <div className="home-page-header-button">
                 <div data-testid="add-report" className="add-report" onClick={handleAddReport}>
                   <div className="add-btn">
                     <AddOutlinedIcon />
                     <span className="no-select">Add Report</span>
                   </div>
                 </div>
               </div>
               </Grid>
           </Grid>
         )}
         <div className="noreports">
           <svg data-testid="svg-container" xmlns="http://www.w3.org/2000/svg" width="367" height="320" viewBox="0 0 367 320" fill="none">
             <path d="M11.6055 163V166" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M4.86719 164.625L7.31862 167.149" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M2 171.342H5.46629" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M4.58594 178.18L7.03737 175.656" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M11.6055 179V176" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M16.6055 177L14.6055 175" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M20.6055 172H17.6055" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M18.6055 165L16.6055 167" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M172.605 32V34" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M166.605 38H168.605" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M172.605 44V42" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M177.605 38H175.605" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M56.6055 116V119" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M47.6055 125H50.6055" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M56.6055 133V130" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M64.6055 125H61.6055" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path d="M271.859 225.039C274.249 225.039 276.187 226.978 276.187 229.368C276.187 231.759 274.249 233.697 271.859 233.697C269.469 233.697 267.531 231.759 267.531 229.368" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M291.666 185.763C294.236 184.962 298.175 181.819 299.087 178.438C299.914 181.427 303.38 184.962 306.508 185.229C302.987 186.476 299.621 190.179 299.087 193.088C298.723 190.123 294.068 186.196 291.666 185.763Z" fill="#EBECEE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M27.877 223.705C29.1623 223.305 31.131 221.734 31.5869 220.043C32.0003 221.537 33.7331 223.305 35.2968 223.439C33.537 224.061 31.8543 225.913 31.5869 227.368C31.4052 225.885 29.0774 223.921 27.877 223.705Z" fill="#EBECEE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M51.7294 84.599C51.7294 86.4536 50.2268 87.9558 48.3735 87.9558C46.5194 87.9558 45.0176 86.4536 45.0176 84.599C45.0176 82.7452 46.5194 81.2422 48.3735 81.2422C50.2268 81.2422 51.7294 82.7452 51.7294 84.599Z" fill="#D1D8DF"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M275.824 93.6105C275.824 96.7092 273.312 99.2211 270.215 99.2211C267.117 99.2211 264.605 96.7092 264.605 93.6105C264.605 90.5119 267.117 88 270.215 88C273.312 88 275.824 90.5119 275.824 93.6105Z" fill="#EBECEE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M353.519 78.4115C353.519 80.8699 351.527 82.862 349.07 82.862C346.613 82.862 344.621 80.8699 344.621 78.4115C344.621 75.9531 346.613 73.9609 349.07 73.9609C351.527 73.9609 353.519 75.9531 353.519 78.4115Z" fill="#EBECEE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M114.449 286.269C114.449 287.818 113.193 289.074 111.644 289.074C110.095 289.074 108.84 287.818 108.84 286.269C108.84 284.719 110.095 283.463 111.644 283.463C113.193 283.463 114.449 284.719 114.449 286.269Z" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M112.78 63.0326C112.78 64.8872 111.278 66.3894 109.424 66.3894C107.571 66.3894 106.068 64.8872 106.068 63.0326C106.068 61.1788 107.571 59.6758 109.424 59.6758C111.278 59.6758 112.78 61.1788 112.78 63.0326Z" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M198.383 317.337C196.239 318.153 193.839 317.076 193.023 314.931C192.207 312.786 193.283 310.385 195.429 309.57C197.572 308.754 199.972 309.83 200.788 311.975C201.605 314.12 200.527 316.521 198.383 317.337Z" stroke="#D2D8DF" strokeWidth="2.921" strokeLinecap="round"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M197.105 238H115.1C109.328 238 104.604 233.261 104.604 227.47V110.527C104.604 104.736 109.328 100 115.1 100H197.105C202.879 100 207.604 104.736 207.604 110.527V227.47C207.604 233.261 202.879 238 197.105 238Z" fill="#E0E2EE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M190.105 243H108.1C102.328 243 97.6035 238.261 97.6035 232.47V115.527C97.6035 109.736 102.328 105 108.1 105H190.105C195.879 105 200.604 109.736 200.604 115.527V232.47C200.604 238.261 195.879 243 190.105 243Z" fill="#E8EBF2"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M183.296 246.948H101.122C95.3379 246.948 90.6035 242.229 90.6035 236.462V120.003C90.6035 114.236 95.3379 109.52 101.122 109.52H183.296C189.082 109.52 193.816 114.236 193.816 120.003V236.462C193.816 242.229 189.082 246.948 183.296 246.948Z" fill="#D8DBEA"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M183.296 246.948H101.122C95.3379 246.948 90.6035 242.229 90.6035 236.462V120.003C90.6035 114.236 95.3379 109.52 101.122 109.52H183.296C189.082 109.52 193.816 114.236 193.816 120.003V236.462C193.816 242.229 189.082 246.948 183.296 246.948Z" fill="#F1F2F7"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M171.451 145H110.756C109.022 145 107.604 143.65 107.604 142.001C107.604 140.35 109.022 139 110.756 139H171.451C173.185 139 174.604 140.35 174.604 142.001C174.604 143.65 173.185 145 171.451 145Z" fill="#E0E2EE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M171.451 163H110.756C109.022 163 107.604 161.65 107.604 160.001C107.604 158.35 109.022 157 110.756 157H171.451C173.185 157 174.604 158.35 174.604 160.001C174.604 161.65 173.185 163 171.451 163Z" fill="#E0E2EE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M171.451 181H110.756C109.022 181 107.604 179.65 107.604 178.001C107.604 176.352 109.022 175 110.756 175H171.451C173.185 175 174.604 176.352 174.604 178.001C174.604 179.65 173.185 181 171.451 181Z" fill="#E0E2EE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M171.451 199H110.756C109.022 199 107.604 197.65 107.604 196.001C107.604 194.35 109.022 193 110.756 193H171.451C173.185 193 174.604 194.35 174.604 196.001C174.604 197.65 173.185 199 171.451 199Z" fill="#E0E2EE"/>
             <path fillRule="evenodd" clipRule="evenodd" d="M142.438 217H110.769C109.028 217 107.604 215.65 107.604 214.001C107.604 212.35 109.028 211 110.769 211H142.438C144.179 211 145.604 212.35 145.604 214.001C145.604 215.65 144.179 217 142.438 217Z" fill="#E0E2EE"/>
             <path opacity="0.5" d="M204.398 239.186L351.389 314.292L365.112 274.493L220.892 226.519L204.398 239.186Z" fill="url(#paint0_linear_2221_90303)"/>
             <path d="M200.386 115.718L251.071 119.714L239.816 229.964C239.816 229.964 238.83 231.067 236.439 230.639C234.048 230.211 231.539 228.88 231.539 228.88L224 141.234C224 141.234 219.641 172.226 219.945 180.945C220.175 187.514 222.421 195.528 223.623 202.383C224.657 208.282 226.834 222.381 226.834 222.381C226.834 222.381 224.262 223.556 222.894 223.821C221.526 224.086 219.772 222.628 219.772 222.628C219.772 222.628 202.001 188.131 201.773 181.58C201.544 175.028 200.386 115.718 200.386 115.718Z" fill="url(#paint1_linear_2221_90303)"/>
             <path d="M218.984 222.467C218.984 222.467 216.727 226.532 213.922 229.01C211.118 231.488 205.584 234.448 204.322 235.792C203.061 237.137 204.439 239.118 204.439 239.118L218.002 238.645L228.84 229.476L227.325 220.739L218.984 222.467Z" fill="#677285"/>
             <path d="M241.093 227.173L244.26 235.955C244.26 235.955 239.06 239.982 231.875 241.103C224.691 242.223 219.741 240.502 219.741 240.502C219.741 240.502 218.443 237.888 220.888 236.771C222.011 236.258 224.528 234.125 226.737 231.394C227.735 230.161 231.492 227.508 231.492 227.508L241.093 227.173Z" fill="#677285"/>
             <path d="M263.797 58.2378C263.797 58.2378 267.951 64.5575 270.498 62.8665C273.046 61.1755 288.948 44.876 288.948 44.876C288.948 44.876 293.679 41.2341 294.554 42.0999C295.429 42.9656 292.976 44.876 292.976 44.876C292.976 44.876 298.138 45.383 299.751 45.383C301.364 45.383 304.756 47.9582 302.538 48.8247C300.32 49.6913 291.714 49.9054 291.714 49.9054C291.714 49.9054 278.65 74.6118 271.842 76.3636C265.034 78.1155 255.654 68.2077 255.654 68.2077L263.797 58.2378Z" fill="#FDE7E6"/>
             <path d="M197.419 58.6902C197.419 58.6902 194.695 64.2674 192.227 62.9294C189.759 61.5914 175.909 46.9859 175.909 46.9859C175.909 46.9859 171.297 44.161 170.169 44.6693C169.041 45.1776 171.822 46.9859 171.822 46.9859C171.822 46.9859 165.036 47.5364 163.76 48.0495C162.483 48.5625 160.996 50.136 162.926 50.8512C164.857 51.5664 172.245 52.0752 172.245 52.0752C172.245 52.0752 182.515 72.3704 190.296 74.7435C198.078 77.1166 204.707 67.7661 204.707 67.7661L197.419 58.6902Z" fill="#FDE7E6"/>
             <path d="M222.612 41.6099V31.6385C222.612 31.6385 217.442 31.9004 215.34 28.4384C213.238 24.9765 212.671 15.1524 212.671 15.1524L214.254 10.5975L229.826 9.93945L238.007 16.9109L234.198 23.6384L233.472 24.9314L237.103 40.221L222.612 41.6099Z" fill="#FDE7E6"/>
             <mask id="mask0_2221_90303" maskUnits="userSpaceOnUse" x="212" y="9" width="27" height="33">
             <path d="M222.612 41.6099V31.6385C222.612 31.6385 217.442 31.9004 215.34 28.4384C213.238 24.9765 212.671 15.1524 212.671 15.1524L214.254 10.5975L229.826 9.93945L238.007 16.9109L234.198 23.6384L233.472 24.9314L237.103 40.221L222.612 41.6099Z" fill="white"/>
             </mask>
             <g mask="url(#mask0_2221_90303)">
             <path d="M232.742 22.1636C228.14 27.887 220.18 31.8871 219.144 31.8871C218.108 31.8871 224.932 39.0654 224.932 39.0654L230.483 41.6107L238.364 39.9401L241.177 31.8871L236.211 22.7505C236.211 22.7505 237.344 16.4401 232.742 22.1636Z" fill="url(#paint2_linear_2221_90303)"/>
             </g>
             <path d="M194.112 62.6952L201.765 73.0028L206.645 67.849C206.645 67.849 202.933 88.2007 201.765 97.9613C200.596 107.722 200.379 117.444 200.379 117.444C200.379 117.444 212.632 121.993 221.766 122.758C230.9 123.524 252.165 121.553 252.165 121.553L249.42 64.2649L257.384 71.6588L266.735 60.861C266.735 60.861 250.638 36.1227 247.594 34.6815C244.549 33.2404 236.087 34.6815 236.087 34.6815C236.087 34.6815 230.082 41.7879 227.647 41.8871C225.212 41.9862 219.81 34.6815 219.81 34.6815C219.81 34.6815 211.181 33.3032 208.672 36.1227C206.162 38.9422 194.112 62.6952 194.112 62.6952Z" fill="#AF1E37"/>
             <path d="M212.674 15.263C212.674 15.263 214.509 10.6321 216.905 11.439C219.172 12.2025 221.135 14.0194 223.956 14.8723C226.776 15.7253 227.088 11.8938 229.004 12.5407C230.921 13.1877 229.656 18.8241 231.007 19.8737C232.358 20.9234 232.358 12.5407 235.237 14.7975C238.117 17.0542 232.233 23.4061 232.233 23.4061C232.233 23.4061 232.987 27.3102 233.229 27.5757C233.471 27.8413 233.443 24.7354 233.443 24.7354C233.443 24.7354 236.194 23.6797 237.366 18.9179C238.538 14.1562 239.045 12.2603 237.366 8.80049C235.687 5.34067 235.208 2.63104 231.007 0.998878C226.805 -0.633283 223.617 0.00508529 220.681 0.998878C217.744 1.99267 215.616 3.96724 214.258 5.93208C211.091 10.5961 212.674 15.263 212.674 15.263Z" fill="#4D364F"/>
             <defs>
             <linearGradient id="paint0_linear_2221_90303" x1="199.297" y1="259.748" x2="227.557" y2="333.5" gradientUnits="userSpaceOnUse">
             <stop stopColor="#C4CDD1"/>
             <stop offset="1" stopColor="#C6CFD3" stopOpacity="0.01"/>
             </linearGradient>
             <linearGradient id="paint1_linear_2221_90303" x1="254.736" y1="230.165" x2="250.708" y2="114.833" gradientUnits="userSpaceOnUse">
             <stop stopColor="#A1A7B8"/>
             <stop offset="1" stopColor="#7B8195"/>
             </linearGradient>
             <linearGradient id="paint2_linear_2221_90303" x1="234.923" y1="34.9153" x2="234.923" y2="25.2538" gradientUnits="userSpaceOnUse">
             <stop stopColor="#FBD8D6"/>
             <stop offset="1" stopColor="#EAC0BE" stopOpacity="0.7"/>
             </linearGradient>
             </defs>
           </svg>
       <div className="no-rpt-txt">
       {favoriteParam=='true'?<><Typography>
           There are no reports currently marked as favorites. You can visit the dashboard to mark them as favorites.
         </Typography>
         <Link to={'/'} className="primary-btn"><Button className="primary-btn">Go to Home Page</Button></Link>
         </>:<Typography>No reports Found!</Typography>
        } 
       </div>
         </div>
     </>
);

}
export default NoReportsFound