import styled from "@emotion/styled";
import { Check } from "@mui/icons-material";
import { StepConnector, stepConnectorClasses, StepIconProps, Stepper, Step, StepLabel } from "@mui/material";
import './CommonStepper.scss';


export interface CommonStepperProps {
    steps: any;
    activeStep: number;
  }

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#DBE6F0',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#DBE6F0',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#DBE6F0',
      borderTopWidth: 2,
      borderRadius: 1,
    },
  }));
  
  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color:  '#DBE6F0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#AF1E37',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: '#758496',
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    }),
  );
  
  function QontoStepIcon(props: Readonly<StepIconProps>) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }
function CommonStepper({ steps, activeStep}: Readonly<CommonStepperProps>) {



    return (
        <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />} className="stepper-section">
          {steps.map((label:any) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
    )
}

export default CommonStepper;