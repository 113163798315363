import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TimeField from "react-simple-timefield";
import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  selectSelectedSubscription,
  selectFieldsValid,
} from "../../redux/selectors/subscription.selector";
import { subscriptionActions } from "../../redux/actions/subscription.actions";
import Grid from "@mui/material/Grid";
import { Switch } from "@mui/material";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    width: 50,
    padding: "6px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));
const WeekendSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  marginTop:10,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#2C4259',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const InputLarge = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    //width: 350,
    padding: "6px 5px 5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

function months() {
  let months = [];
  for (let i = 1; i < 13; i++) {
    months.push(
      <MenuItem key={i} value={i} className="medium-text blue-text">
        {i}
      </MenuItem>
    );
  }
  return months;
}
export interface DailyRecurrenceProps {
  formData:any;
}

function DailyRecurrence({formData}:Readonly<DailyRecurrenceProps>) {
  const dispatch = useDispatch();

  const [excludeWeekends, setExcludeWeekends] = React.useState(false);

  const selectedSubscription = useSelector(selectSelectedSubscription);
  const [description,setDescription]=useState<string>("");
  const fieldsValid = useSelector(selectFieldsValid);

  useEffect(() => {
    
      if (
      selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
      formData.jobScheduleFreqType.filter((t:any) => t.description === "Daily")[0]
        ?.jobScheduleFreqTypeId
    ) {
      if (selectedSubscription.jobSchedule.jobScheduleDaysOfWeek.length === 7) {
        setExcludeWeekends(false);
      } else {
        setExcludeWeekends(true);
      }
    }
    const recurrenceType=formData.jobScheduleFreqType.filter((r:any)=>r.jobScheduleFreqTypeId===selectedSubscription.jobSchedule.jobScheduleFreqTypeId);
    if(recurrenceType.length){
      setDescription(recurrenceType[0].description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubscription]);

  const handleRunTimeChange = (value: string) => {
    const splitTime = selectedSubscription.jobSchedule.runTime.split(" ");
    dispatch(
      subscriptionActions.setSubscriptionRunTime(`${value} ${splitTime[1]}`)
    );
  };

  const handleTimezoneChange = (event: any) => {
    dispatch(subscriptionActions.setSubscriptionTimezone(event.target.value));
  };

  const handleRunTimePeriodChange = () => {
    const splitTime = selectedSubscription.jobSchedule.runTime.split(" ");
    dispatch(
      subscriptionActions.setSubscriptionRunTime(
        `${splitTime[0]} ${splitTime[1] === "AM" ? "PM" : "AM"}`
      )
    );
  };

  const handleExcludeWeekendsCheck = (event: any) => {
    const days = event.target.checked
      ? formData.daysOfWeek
          .filter((d:any) => !["Sa", "Su"].includes(d.shortName))
          .map((d:any) => {
            return {
              jobScheduleDayOfWeekId: 0,
              jobScheduleId: selectedSubscription.jobScheduleId,
              dayOfWeekId: d.dayOfWeekId,
            };
          })
      : formData.daysOfWeek.map((d:any) => {
          return {
            jobScheduleDayOfWeekId: 0,
            jobScheduleId: selectedSubscription.jobScheduleId,
            dayOfWeekId: d.dayOfWeekId,
          };
        });

    dispatch(
      subscriptionActions.setSubscriptionDays(days, event.target.checked, true)
    );
  };

  function handleMonthlyRecurrenceFactorChange(event: any) {
    dispatch(
      subscriptionActions.setSubscriptionRecurrenceFactor(event.target.value)
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="daily-recurrence" style={{ paddingTop: 0 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3.6} md={description=='Weekly'?3:4} lg={description=='Weekly'?3:4} className="select-lable" data-testid="timeLable">
          Select Time
        </Grid>
        <Grid item xs={12} sm={8} md={description=='Weekly'?9:8} lg={description=='Weekly'?9:8}>
          <div className="time-input-container">
              
              <span className="input-maiden">{selectedSubscription.jobSchedule.runTime?.split(" ")[1]}</span>
                <CodeOutlinedIcon  onClick={handleRunTimePeriodChange} className="indicator-maiden"/>
                <TimeField data-testid="input-time" 
                value={selectedSubscription.jobSchedule.runTime?.split(" ")[0]}
                onChange={(event) => handleRunTimeChange(event.target.value)}
                input={<input type="text" className="time-input" />}
                colon=":"
              />
            </div>
            {fieldsValid.showRunTimeError && (
            <div className="small-error">Invalid Run Time</div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3.6} md={description=='Weekly'?3:4} lg={description=='Weekly'?3:4} className="select-lable">
          Timezone
        </Grid>
        <Grid item xs={12} sm={8} md={description=='Weekly'?9:8} lg={description=='Weekly'?9:8}>
          <Select
                className="select-xlarge"
                value={selectedSubscription.jobSchedule.timezoneId ?? 0}
                onChange={handleTimezoneChange}
                input={<InputLarge />}
                IconComponent={KeyboardArrowDownIcon}
                data-testid="test-timezone"
              >
                {formData.timezones.map((tz:any) => {
                  return (
                    <MenuItem
                      key={tz.timezoneId}
                      value={tz.timezoneId}
                      className="medium-text blue-text"
                    >
                      {tz.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
        </Grid>
      </Grid>
      {(selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
            formData.jobScheduleFreqType?.filter(
              (t:any) => t.description === "Daily"
            )[0]?.jobScheduleFreqTypeId ||
            selectedSubscription.jobSchedule.jobScheduleFreqTypeId === 0) && (
      <Grid container spacing={1}>
        <Grid item xs={5} sm={3.6} md={4} lg={4} className="select-lable">
          Exclude Weekends
        </Grid>
        <Grid item xs={6} sm={8} md={8} lg={8}>
        <WeekendSwitch inputProps={{ 'aria-label': 'ant design' }} data-testid="test-weekends" checked={formData.excludeWeekends ?? excludeWeekends} onChange={(event) => handleExcludeWeekendsCheck(event)}/>
        </Grid>
      </Grid>
            )}
      {selectedSubscription.jobSchedule.jobScheduleFreqTypeId ===
            formData.jobScheduleFreqType?.filter(
              (t:any) => t.description === "Monthly"
            )[0]?.jobScheduleFreqTypeId && (
              <Grid container spacing={1}>
        <Grid item xs={12} sm={3.6} md={4} lg={4} className="select-lable">
          Every
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Select
                  className="select"
                  value={
                    selectedSubscription.jobSchedule.jobScheduleRecurrenceFactor
                  }
                  onChange={handleMonthlyRecurrenceFactorChange}
                  input={<Input />}
                  IconComponent={KeyboardArrowDownIcon}
                >
                  {months()}
                </Select>
               
                </Grid>
        <Grid item xs={12} sm={3} md={3} lg={3} className="select-lable">
            Month(s)
        </Grid>
      </Grid>
            
          )}
    </div>
    </LocalizationProvider>
  );
}

export default DailyRecurrence;
