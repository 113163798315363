import { AppState } from "../../redux/reducers";
import { Dashboard } from "../models/dashboard";
import { DashboardPermissionValues } from "../models/dashboardPermission";
import { ExistingDashboard } from "../models/existingDashboard";
import { Widget } from "../models/widget";
import GridLayout, { Layout } from "react-grid-layout";


const getExistingDashboardsList = //memoize((subscriptions: Subscription[]) => { 
    (dashboards: Dashboard[]): ExistingDashboard[] => {
        return dashboards.map(d => {
            let canManageWidgets = d.dashboardPermissions?.some(
                (dp) => dp.permission === DashboardPermissionValues.WIDGETCONTROL
            );
            let containsWholeReport = d.widgets?.some(w => w.report != null);
            let containsWidgets = d.widgets?.length;
            let dontAllowAddMessage: string | undefined = undefined;
            let dontAllowAddReport: boolean = false;
            let dontAllowAddReportVisual: boolean = false;
            if (!canManageWidgets) {
                dontAllowAddMessage = 'You are not able to add widgets to a dashboard that was shared with you.'
                dontAllowAddReport = true;
                dontAllowAddReportVisual = true;
            }
            else if (containsWholeReport) {
                dontAllowAddMessage = 'You are not able to add to a dashboard that already contains a whole report.'
                dontAllowAddReport = true;
                dontAllowAddReportVisual = true;
            }
            else if (containsWidgets) {
                dontAllowAddMessage = 'You are not able to add a whole report to a dashboard that already contains widgets.';
                dontAllowAddReport = true;
                dontAllowAddReportVisual = false;
            }

            return {
                dashboardId: d.dashboardId,
                name: d.name,
                dontAllowAddReport: dontAllowAddReport,
                dontAllowAddReportVisual: dontAllowAddReportVisual,
                dontAllowAddMessage: dontAllowAddMessage

            } as ExistingDashboard
        });
    }



export const isDashboardLoading = (state: AppState) => state.dashboardReducer.loading;

export const selectDashboards = (state: AppState) => state.dashboardReducer.dashboards;
export const selectNextDefaultDashboards = (state: AppState) => state.dashboardReducer.dashboards?.filter(d => d.dashboardId !== state.dashboardReducer.selectedDashboard.dashboardId);
export const selectExistingDashboards = (state: AppState) => getExistingDashboardsList(state.dashboardReducer.dashboards);
export const selectSelectedDashboard = (state: AppState) => state.dashboardReducer.selectedDashboard;
export const selectDashboardsCount = (state: AppState) => state.dashboardReducer.dashboards.length;
// export const selectSelectedOptionDashboards = (state: AppState) => state.dashboardReducer.selectedOption;
// export const selectPinToDashboardId = (state: AppState) => state.dashboardReducer.pinToDashboardId;
// export const selectNewDashboardName = (state: AppState) => state.dashboardReducer.newDashboardName;
export const selectIsDashboardInFullscreen = (state: AppState) => state.dashboardReducer.selectedDashboard.widgets?.find(w => w.widgetLayout?.isFullScreen) !== undefined;

// export const selectNewWidget = (state: AppState) => state.dashboardReducer.newWidget;
export const selectNewWidget = (state: AppState): Widget => { return { ...state.dashboardReducer.newWidget, reportId: state.reportReducer.selectedReport?.reportId } };


export const selectSharing = (state: AppState) => state.dashboardReducer.sharing;
export const selectDashboardUserIdToRemove = (state: AppState) => state.dashboardReducer.dashboardUserIdToRemove;

export const selectSelectedDashboardWidgets = (state: AppState) => state.dashboardReducer.selectedDashboard.widgets;
export const selectDashboardLayout = (state: AppState) => {
    const fullScreenOpen = state.dashboardReducer.fullScreen;
    const selectedDashboard = state.dashboardReducer.selectedDashboard;
    const localWidgets = state.dashboardReducer.selectedDashboard.widgets;
    const canManageWidgets = selectedDashboard.dashboardPermissions?.some(
        (dp) => dp.permission === DashboardPermissionValues.WIDGETCONTROL
    );
    let newLayout: GridLayout.Layout[] | undefined = undefined;
    if (localWidgets) {
        newLayout = JSON.parse(
            JSON.stringify(
                localWidgets.map((w, i) => {
                    return {
                        ...w.widgetLayout,
                        static: !canManageWidgets || fullScreenOpen,
                        x: w.x,
                        y: w.y,
                        i: w.widgetLayout?.i ?? i.toString(),
                    };
                })
            )
        );
    }

    return newLayout;
}

