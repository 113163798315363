import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filterActions } from "../../redux/actions/filters.actions";
import { userActions } from "../../redux/actions/user.actions";
import { selectFilteredUsers, selectLoadingUsers } from "../../redux/selectors/user.selector";

import { CreateUserFilters } from "../../redux/models/filter";
import { ShareReportUser } from "../../redux/models/shareReport";
import { reportActions } from "../../redux/actions/report.actions";
import { UserReportHistory } from "../../redux/models/userReportHistory";
import './ReportShareDialog.scss';
import { getReportHistoryExportShare, getReportSharedUsers } from "../../redux/selectors/history.selector";
import { UserReportHistoryExportShare } from "../../redux/models/UserReportHistoryExportShare";
import SearchIcon from "@mui/icons-material/Search";
import { ShareTextField } from "./SearchComponent";
import DeleteDialog from "./DeleteDialog";
interface ReportShareDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleReportShare: (userObj?:UserReportHistoryExportShare,selectedEmail?:string) => void;
  warningMsg: string;
  reportHistory:UserReportHistory;
}


function ReportShareDialog({ open, setOpen, warningMsg, handleReportShare, reportHistory }: ReportShareDialogProps) {

  const dispatch = useDispatch();;
  const usersLoading = useSelector(selectLoadingUsers);
  const filteredUsers = useSelector(selectFilteredUsers);
  const getReportHistoryExport = useSelector(getReportHistoryExportShare);
  const [step,setStep]= useState<number>(1);
  const [linkText, setLinkText] = useState<string>("");
  const [userEmail, setUserEmail] = useState("");
  const [userListExpanded, setUserListExpanded] = useState(false);
  const [userEmailValid, setUserEmailValid] = useState(false);
  const reportSharedUsers= useSelector(getReportSharedUsers);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToRemove, setUserToRemove] = useState<UserReportHistoryExportShare>();
  const [isStyleOpen, setStyleOpen]=useState<boolean>(false);

 
  const handleClose = () => {
    setStyleOpen(false)
    setTimeout(()=>{
      setOpen(false);
    setStep(1)
    },200)
  };

  useEffect(()=>{
    filterActions.setSearchKeyword("");
    dispatch(userActions.getUsers());
    let defaultFilters = CreateUserFilters();
    dispatch(filterActions.setFilters(defaultFilters));
    handleAddReportShare();
  },[]);

  const handleAddReportShare = ()=>{
    const selectedUser=filteredUsers.filter((user,i)=>user.checked);
    const selectedUserEmails=selectedUser.map((user,i)=>user.emailAddress);
    const req:ShareReportUser={
      userReportHistoryExportDetailsId:reportHistory.userReportHistoryExportDetails[0].userReportHistoryExportDetailsId,
      emailAddress:selectedUserEmails
    }
    dispatch(reportActions.AddUserReportShare(req));
  }

  const copyToClipBoard=()=>{
    navigator.clipboard
      .writeText(linkText)
      .then(() => {
        setIsLinkCopied(true);
        setTimeout(() => {
         setIsLinkCopied(false);
        }, 1500);
      })
      .catch(() => {
        console.log("error in copying link please try again.");
      });
    
  }
  useEffect(()=>{
    if(getReportHistoryExport){
      setStep(1);
      setLinkText(getReportHistoryExport? window.location.origin + "/reports/storage?sharelink=" +getReportHistoryExport.urlToken.toString():'');
      dispatch(reportActions.getReportSharedUsers(getReportHistoryExport.urlToken));
    }
    
  },[getReportHistoryExport])
  useEffect(()=>{
    setTimeout(()=>{
      setStyleOpen(true);
    },200)
  },[])

  const validateEmail = (email: string) => {
    let result= 
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.exec(String(email).toLowerCase());
      return result;
  };
  const handleEmailAddressChange = (newVal: string) => {
    if (newVal.length > 100) return;
    if (validateEmail(newVal)!=null) {
      setUserEmailValid(true);
    } else {
      setUserEmailValid(false);
    }

    setUserEmail(newVal);
  };

  const handleEmailLink =()=>{
    setStep(2)
  }

  const openRemoveDialog=(user:UserReportHistoryExportShare)=> {
    setUserToRemove(user)
    setDeleteDialogOpen(true);
    
  }

  const handleDelete = ()=>{
    if(userToRemove?.user.userId&& userToRemove?.userReportHistoryExportShareId){
    dispatch(reportActions.removeReportShareUser(userToRemove.user.userId,userToRemove.userReportHistoryExportShareId));
    setDeleteDialogOpen(false);
    setUserToRemove(undefined);
    handleClose();
  }
  }
    return (
      <>
      {deleteDialogOpen && (
        <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          warningMsg={`You are about to remove "${userToRemove?.user.emailAddress}", by which user cannot access this report.`}
          handleDelete={handleDelete}
          data-testid="deletebox"
        />
      )}
      <div id="delete-report-dialog">
        <Dialog open={open} onClose={handleClose} className={isStyleOpen?"report-share in":"report-share"}>
          <div className="close" onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g id="ic">
              <path id="Icon" d="M18 6L6 18M6 6L18 18" stroke="#758496" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
              </g>
            </svg>
          </div>
          <div className="dialog-title primary">Report Share</div>
          <div className="dialog-content">
            {step===2&&<>
            <div className="input-container">
              <SearchIcon/>
            <ShareTextField
                id="email-address-input"
                value={userEmail}
                fullWidth
                color="primary"
                size="small"
                onChange={(e) => handleEmailAddressChange(e.target.value)}
                placeholder="Enter email address"
              />
              </div>
              {userEmail.length > 0 && !userEmailValid && (
              <span className="error small-text">Invalid email address</span>
            )}

             {/* expandable list */}
             {reportSharedUsers&&reportSharedUsers.length>0?<Accordion
              expanded={userListExpanded}
              onChange={(e) => setUserListExpanded(!userListExpanded)}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="accordion-summary"
              >
                <Button id="expand-collapse-button">
                  {userListExpanded ? "HIDE USER LIST" : "SHOW USER LIST"}
                </Button>
              </AccordionSummary>

              <AccordionDetails>
                <div className="shared-with">
                  {reportSharedUsers?.length ?? 0  ? (
                    reportSharedUsers?.map((du) => {
                      return (
                        <div key={du.userReportHistoryExportShareId}>
                          <div>{du.user.emailAddress}</div>
                          <div>
                            <Button
                              onClick={() => {
                                openRemoveDialog(du);
                              }}
                              className="medium-text"
                            >
                              Remove
                            </Button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="help-text">
                      There are currently no shared users. Try adding an email
                      above!
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>:<></>
            }
          
          </>
          }
          {step===1&&<>
          {/* <Grid container spacing={2}> */}
            {/* <Grid item md={3} xs={12}> */}
              <Typography className="share-title">Export Share Link</Typography>
              {/* </Grid> */}
            {/* <Grid item md={9} xs={12}> */}
              <input type="text" disabled value={linkText} />
            {/* </Grid> */}
            {isLinkCopied&& (
              <span className="copy-link-succes small-text">Link is copied!</span>
            )}
          {/* </Grid> */}
          </>
          } 
        {usersLoading && (
          <div className="spinner">
            <CircularProgress size={30} />
          </div>
        )}

        {!usersLoading && filteredUsers.length === 0 && (
          <div className="no-users">No users Found!</div>
        )}
          </div>
          <div className="dialog-footer">
            <Button onClick={handleClose} className="cancel medium-text">
              Cancel
            </Button>
            {step===2&&<Button onClick={()=>handleReportShare(getReportHistoryExport,userEmail)} className="action medium-text">
              Submit
            </Button>}
            {step===1&&<>
              <Button onClick={copyToClipBoard} className="action medium-text copy-link">
              Copy Link
            </Button>
            <Button onClick={handleEmailLink} className="email-action medium-text email-link">
              Email Link
            </Button>
            </>}
          </div>
        </Dialog>
      </div>
      </>
    );
  } 

export default ReportShareDialog;
