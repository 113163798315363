import {
  Button,
  CircularProgress,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import { selectAllReports } from "../../redux/selectors/report.selector";
import {
  FilteredUser,
  selectUserGenericLoading,
} from "../../redux/selectors/user.selector";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { userActions } from "../../redux/actions/user.actions";
import { notificationActions } from "../../redux/actions/notification.actions";
import { INotificationType } from "../../redux/models/notificationType";

const Input = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "rgba(85, 136, 163, 0.2)",
    borderBottom: "2px solid #5588a3",
    fontSize: "1em",
    padding: "6px 5px 5px 12px",
    color: "#00334e",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: "#5588a3",
    },
  },
}));

interface DashboardDialogProps {
  users: FilteredUser[];
  isOpen: boolean;
  onClose: () => void;
}
function DashboardDialog({ users, isOpen, onClose }: DashboardDialogProps) {
  const dispatch = useDispatch();
  const reports = useSelector(selectAllReports);
  const [selectedReportId, setSelectedReportId] = useState<number>();
  const loading = useSelector(selectUserGenericLoading);

  const handleSelectedReportIdChange = (
    event: SelectChangeEvent<number | string>
  ) => {
    console.log(+event.target.value);
    setSelectedReportId(+event.target.value);
  };

  const handleSubmit = () => {
    if (selectedReportId) {
      var userIds = users.map((u) => {
        return u.userId;
      });

      dispatch(
        userActions.updateUserDefaultDashboard(
          userIds,
          selectedReportId,
          () => {
            onClose();
            dispatch(
              notificationActions.setNotification(
                INotificationType.Success,
                `Successfully updated default dashboard for ${
                  users.length === 1
                    ? users[0].emailAddress
                    : `${users.length} users`
                }`,
                false
              )
            );
          }
        )
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      setSelectedReportId(undefined);
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div id="dashboard-dialog">
        <div className="dialog-title warning">Change Default Dashboard</div>
        <div className="subtitle">{`Editing for ${
          users.length === 1 ? users[0].emailAddress : `${users.length} users`
        }`}</div>
        <div className="dialog-content">
          <Select
            id="existing-dashboard-select"
            className="select-xlarge"
            value={selectedReportId ?? ""}
            onChange={handleSelectedReportIdChange}
            input={<Input />}
            IconComponent={KeyboardArrowDownIcon}
            style={{ position: "relative", width: "100%" }}
          >
            {reports.map((r, i) => {
              return (
                <MenuItem
                  key={i}
                  value={r.reportId}
                  className="pd-menuitem medium-text blue-text"
                >
                  <div className="pd-menuitem-inner">
                    <div className="db-name">{r.name}</div>
                  </div>
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <div className="dialog-footer">
          <hr className="warning" />
          <Button onClick={onClose} className="cancel medium-text">
            Cancel
          </Button>
          {loading ? (
            <div
              style={{
                display: "inline-flex",
                justifyContent: "center",
                width: "120px",
                height: "37px",
              }}
            >
              <CircularProgress size={20} />
            </div>
          ) : (
            <Button
              onClick={handleSubmit}
              className="action medium-text"
              disabled={selectedReportId === undefined}
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}
export default DashboardDialog;
