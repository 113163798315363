import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

export interface NewDeleteDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  handleDelete: () => void;
  handleClose: () => void;
  warningMsg?: string;
  heading?:string;
  actionName?:string;
}

function NewDeleteDialog({ open, setOpen,handleDelete,heading,warningMsg,handleClose,actionName }: NewDeleteDialogProps) {
    return (
    <div id="delete-report-dialog">
      <Dialog open={open} onClose={handleClose} className="del-dialog">
          <div className="del-icon-block">
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
              <circle cx="27" cy="27" r="27" fill="#E2494F"></circle>
              <path d="M34.3501 23.5499L33.4397 36.299C33.402 36.8288 33.165 37.3246 32.7763 37.6866C32.3876 38.0486 31.8762 38.2499 31.345 38.2499H22.6552C22.124 38.2499 21.6126 38.0486 21.2239 37.6866C20.8352 37.3246 20.5982 36.8288 20.5604 36.299L19.6501 23.5499M24.9001 27.7499V34.0499M29.1001 27.7499V34.0499M30.1501 23.5499V20.3999C30.1501 20.1214 30.0395 19.8543 29.8426 19.6574C29.6456 19.4605 29.3786 19.3499 29.1001 19.3499H24.9001C24.6216 19.3499 24.3545 19.4605 24.1576 19.6574C23.9607 19.8543 23.8501 20.1214 23.8501 20.3999V23.5499M18.6001 23.5499H35.4001" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
          </div>
          <div className="dialog-content">
            {heading&&<p className="del-heading">{heading}</p>}
          <p className="warning-msg">{warningMsg}</p>
        </div>
        <div className="dialog-footer">
          <Button onClick={handleClose} className="btn-cancel">
            Cancel
          </Button>
          <Button onClick={handleDelete} className="btn-delete">
            {actionName??`Remove`}
          </Button>
        </div>
        </Dialog>
    </div>
  );
}

export default NewDeleteDialog;
