import {
  FilteredUser,
  selectUserGenericLoading,
} from "../../redux/selectors/user.selector";
import Dialog from "@mui/material/Dialog";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectAllReports } from "../../redux/selectors/report.selector";
import { useEffect, useState } from "react";
import { userActions } from "../../redux/actions/user.actions";
import { notificationActions } from "../../redux/actions/notification.actions";
import { INotificationType } from "../../redux/models/notificationType";
import { UpdateUserReportAccessActionEnum } from "../../redux/models/updateUserReportAccessModel";

interface IReportAccessDialogProps {
  users: FilteredUser[];
  isOpen: boolean;
  onClose: () => void;
}

function ReportAccessDialog({
  users,
  isOpen,
  onClose,
}: IReportAccessDialogProps) {
  const dispatch = useDispatch();
  const reports = useSelector(selectAllReports);
  const loading = useSelector(selectUserGenericLoading);
  const [selectedReports, setSelectedReports] = useState<number[]>([]);
  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
  const usersLength = users.length;
  const selectedReportsLength = selectedReports.length;

  function handleReportChange(reportId: number, checked: boolean) {
    if (checked) {
      if (!selectedReports.some((r) => r === reportId)) {
        setSelectedReports([
          ...selectedReports,
          reports.find((r) => r.reportId === reportId)!.reportId,
        ]);
      }
    } else {
      setSelectedReports([...selectedReports.filter((r) => r !== reportId)]);
    }
  }

  const handleSubmit = (action: UpdateUserReportAccessActionEnum) => {
    var userIds = users.map((u) => {
      return u.userId;
    });
    var reportIds = selectedReports.map((sr) => {
      return sr;
    });

    dispatch(
      userActions.updateUserReportAccess(action, userIds, reportIds, () => {
        if (action === UpdateUserReportAccessActionEnum.Update) {
          onClose();
        } else if (action === UpdateUserReportAccessActionEnum.Remove) {
          setConfirmRemoveDialogOpen(false);
        }
        dispatch(
          notificationActions.setNotification(
            INotificationType.Success,
            `Successfully updated report access for ${
              users.length === 1
                ? users[0].emailAddress
                : `${users.length} users`
            }`,
            false
          )
        );
      })
    );
  };

  useEffect(() => {
    if (isOpen) {
      if (users && usersLength === 1) {
        let initialSelectedReports: number[] = [];
        for (var r of reports) {
          let add = true;
          for (var u of users) {
            // eslint-disable-next-line no-loop-func
            if (!u.reports.some((reportId) => reportId === r.reportId)) {
              add = false;
            }
          }
          if (add) initialSelectedReports.push(r.reportId);
        }
        setSelectedReports(initialSelectedReports);
      } else {
        setSelectedReports([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, reports, isOpen]);

  console.log("users");
  console.log(users);

  const ConfirmRemoveDialog = () => {
    return (
      <Dialog
        open={confirmRemoveDialogOpen}
        onClose={() => setConfirmRemoveDialogOpen(false)}
      >
        <div className="dialog-title warning">Warning</div>
        <div className="dialog-content">
          <p>
            {`You are about to remove access to ${selectedReports.length} report(s) for ${usersLength} users. Are you sure you want to proceed?`}
          </p>
        </div>
        <div className="dialog-footer">
          <hr className="warning" />
          <Button
            onClick={() => setConfirmRemoveDialogOpen(false)}
            className="cancel medium-text"
          >
            Cancel
          </Button>
          {loading ? (
            <div
              style={{
                display: "inline-flex",
                justifyContent: "center",
                width: "120px",
                height: "37px",
              }}
            >
              <CircularProgress size={20} />
            </div>
          ) : (
            <Button
              onClick={() =>
                handleSubmit(UpdateUserReportAccessActionEnum.Remove)
              }
              className="action medium-text"
            >
              Remove Access
            </Button>
          )}
        </div>
      </Dialog>
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      {confirmRemoveDialogOpen && ConfirmRemoveDialog()}
      <div id="report-access-dialog">
        <div className="dialog-title warning">Manage Report Access</div>
        <div className="subtitle">{`Editing for ${
          users.length === 1 ? users[0].emailAddress : `${users.length} users`
        }`}</div>
        <div className="dialog-content">
          <p>
            {`
            Select the reports you want to add or remove access for and click ${
              usersLength === 1 ? "Update" : "the appropriate button"
            } below.`}
          </p>
          <FormGroup>
            {reports.map((r, i) => {
              return (
                <div key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          selectedReports.length > 0 &&
                          selectedReports.some((sr) => sr === r.reportId)
                        }
                        onChange={(e, checked) =>
                          handleReportChange(r.reportId, checked)
                        }
                      />
                    }
                    label={r.name}
                  />
                </div>
              );
            })}
          </FormGroup>
        </div>
        <div className="dialog-footer">
          <hr className="warning" />

          {loading && !confirmRemoveDialogOpen ? (
            <div
              style={{
                display: "inline-flex",
                justifyContent: "center",
                width: "120px",
                height: "37px",
              }}
            >
              <CircularProgress size={20} />
            </div>
          ) : users.length === 1 ? (
            <div id="actions-container">
              <Button
                onClick={() => onClose()}
                className="cancel medium-text"
                key="2"
              >
                Close
              </Button>
              {/* <span>Selected: </span> */}
              <Button
                onClick={() =>
                  handleSubmit(UpdateUserReportAccessActionEnum.Update)
                }
                className="action small-text"
                disabled={loading}
              >
                Update Report Access
              </Button>
            </div>
          ) : (
            [
              <div id="actions-container" key="1">
                {/* <span>Selected: </span> */}
                <Button
                  onClick={() => setConfirmRemoveDialogOpen(true)}
                  className="action small-text red"
                  disabled={loading || selectedReportsLength === 0}
                >
                  Remove Report Access
                </Button>
                <Button
                  onClick={() =>
                    handleSubmit(UpdateUserReportAccessActionEnum.Add)
                  }
                  className="action small-text"
                  disabled={loading || selectedReportsLength === 0}
                >
                  Add Report Access
                </Button>
              </div>,
              <Button
                onClick={() => onClose()}
                className="cancel medium-text"
                style={{ marginTop: 15 }}
                key="2"
              >
                Close
              </Button>,
            ]
          )}
        </div>
      </div>
    </Dialog>
  );
}
export default ReportAccessDialog;
