import Dialog from "@mui/material/Dialog";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectSecurityRoles } from "../../redux/selectors/admin.selector";
import { useEffect, useState } from "react";
import { userActions } from "../../redux/actions/user.actions";
import {
  FilteredUser,
  selectUserGenericLoading,
} from "../../redux/selectors/user.selector";
import { SecurityRole } from "../../redux/models/securityRole";
import { notificationActions } from "../../redux/actions/notification.actions";
import { INotificationType } from "../../redux/models/notificationType";

interface RoleDialogProps {
  users: FilteredUser[];
  isOpen: boolean;
  onClose: () => void;
}
function RoleDialog({ users, isOpen, onClose }: RoleDialogProps) {
  const dispatch = useDispatch();
  const roles = useSelector(selectSecurityRoles);
  const [selectedRoles, setSelectedRoles] = useState<SecurityRole[]>([]);
  const loading = useSelector(selectUserGenericLoading);

  const handleRoleChange = (roleId: number, checked: boolean) => {
    if (checked) {
      if (!selectedRoles.some((r) => r.securityRoleId === roleId)) {
        setSelectedRoles([
          ...selectedRoles,
          roles.find((r) => r.securityRoleId === roleId)!,
        ]);
      }
    } else {
      setSelectedRoles([
        ...selectedRoles.filter((r) => r.securityRoleId !== roleId),
      ]);
    }
  };

  const handleSubmit = () => {
    var userIds = users.map((u) => {
      return u.userId;
    });
    var roleIds = selectedRoles.map((sr) => {
      return sr.securityRoleId;
    });
    dispatch(
      userActions.updateUserSecurityRoles(userIds, roleIds, () => {
        onClose();
        dispatch(
          notificationActions.setNotification(
            INotificationType.Success,
            `Successfully updated roles for ${
              users.length === 1
                ? users[0].emailAddress
                : `${users.length} users`
            }`,
            false
          )
        );
      })
    );
  };

  useEffect(() => {
    if (isOpen) {
      let initialSelectedRoles = [];
      for (var r of roles) {
        let add = true;
        for (var u of users) {
          // eslint-disable-next-line no-loop-func
          if (!u.roles.some((ur) => ur.securityRoleId === r.securityRoleId)) {
            add = false;
          }
        }
        if (add) initialSelectedRoles.push(r);
      }
      setSelectedRoles(initialSelectedRoles);
    }
  }, [users, roles, isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div id="role-dialog">
        <div className="dialog-title warning">Manage Role</div>
        <div className="subtitle">{`Editing for ${
          users.length === 1 ? users[0].emailAddress : `${users.length} users`
        }`}</div>
        <div className="dialog-content">
          <p>Select/deselect a role below.</p>
          <FormGroup>
            {roles.map((r, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={
                        selectedRoles.length > 0 &&
                        selectedRoles.some(
                          (sr) => sr.securityRoleId === r.securityRoleId
                        )
                      }
                      onChange={(e, checked) =>
                        handleRoleChange(r.securityRoleId, checked)
                      }
                    />
                  }
                  label={r.name}
                />
              );
            })}
          </FormGroup>
        </div>
        <div className="dialog-footer">
          <hr className="warning" />
          <Button onClick={() => onClose()} className="cancel medium-text">
            Cancel
          </Button>
          {loading ? (
            <div
              style={{
                display: "inline-flex",
                justifyContent: "center",
                width: "120px",
                height: "37px",
              }}
            >
              <CircularProgress size={20} />
            </div>
          ) : (
            <Button onClick={handleSubmit} className="action medium-text">
              Submit
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default RoleDialog;
