import { NetworkError, ServerError } from "../models/serverError";
import { ContactActions } from "../actions/contact.actions";
import { ContactForm } from "../models/contactForm";

type ContactState = {
    loading: boolean;
    loadingSubmit: boolean;
    error?: ServerError | NetworkError;
    contactForm?: ContactForm
}
const initialState: ContactState = {
    loading: false,
    loadingSubmit: false,
    error: undefined,
    contactForm: undefined
}
const ContactReducer = (state: ContactState = initialState, action: ContactActions): ContactState => {
    switch (action.type) {
        // GET CONTACT FORM
        case 'CONTACT_GET_CONTACT_FORM_REQUEST':
            return {
                ...state,
                loading: true,
                error: undefined
            }
        case 'CONTACT_GET_CONTACT_FORM_SUCCESS':
            return {
                ...state,
                loading: false,
                error: undefined,
                contactForm: action.response
            }
        case 'CONTACT_GET_CONTACT_FORM_FAILURE':
            return {
                ...state,
                loading: false,
                error: action.error
            }

        // SUBMIT CONTACT FORM
        case 'CONTACT_SUBMIT_CONTACT_FORM_REQUEST':
            return {
                ...state,
                loadingSubmit: true,
                error: undefined
            }
        case 'CONTACT_SUBMIT_CONTACT_FORM_SUCCESS':
            return {
                ...state,
                loadingSubmit: false,
                error: undefined
            }
        case 'CONTACT_SUBMIT_CONTACT_FORM_FAILURE':
            return {
                ...state,
                loadingSubmit: false,
                error: action.error
            }
        default:
            return state;
    }
}
export default ContactReducer;