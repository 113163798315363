import { EndSessionRequest } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { userActions } from "../../redux/actions/user.actions";
import appConfig from "../../utils/appConfig";

var sessionExpired: boolean | null = null;

function Logout() {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const location = useLocation();
  let history = useHistory();
  let isAuthenticated = useIsAuthenticated();

  let timeout = new URLSearchParams(location.search).get("timeout");
  sessionExpired = timeout === "true";

  const logoutMsal = () => {
    let logout: Promise<void>;
    if (sessionExpired) {
      logout = instance.logoutRedirect({
        postLogoutRedirectUri: `${appConfig.b2c.logoutRedirectUri}?timeout=true`,
      });
    } else {
      logout = logout = instance.logoutRedirect({
        postLogoutRedirectUri: `${appConfig.b2c.logoutRedirectUri}`,
      });
    }

    logout.catch((e) => {
      console.error(e);
    });
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(userActions.userSignOut(logoutMsal));
    } else {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return <div></div>;
}
export default Logout;
