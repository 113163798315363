import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import { isMobile } from "react-device-detect";
import UserCard from "../Nav/UserCard";
import "./SideNavBar.scss";
import SideNavItem from "./SideNavItem";
import { SideNavBarIcons } from "./SideNavItem/SideNavBarIcons";
import { Box } from "@mui/material";
import { selectIsDashboardInFullscreen } from "../../redux/selectors/dashboard.selector";


interface ISideNavBar {
  isCollapsed: boolean,
  setIsCollapsed: (is: boolean) => void;
  setPageName: (p: string) => void;
}

function SideNavBar({ isCollapsed, setIsCollapsed, setPageName }: ISideNavBar) {
  const isDashboardInFullscreen = useSelector(selectIsDashboardInFullscreen);
  let navContainer = useRef<HTMLDivElement>(null);
  const isAdmin = useSelector(isUserAdministrator);
  const [arrowUp, setArrowUp] = useState(true);
  const [isAdministrationUp, setIsAdministrationUp] = useState(true);

  let userCardNavRef = useRef<HTMLDivElement>(null);
  const NavigationMenu = [
    {
      label: "Home",
      url: "/",
      icon: 'home_icon',
      adminOnly: false,
      subItems: [],
      chevron: undefined
    },
    {
      label: "My Dashboard",
      url: "/mydashboard",
      icon: 'dashboard-icon',
      adminOnly: false,
      subItems: [],
      chevron: undefined
    },
    {
      label: "Favorites",
      url: "/favorites?favorites=true",
      icon: 'favorites_icon',
      adminOnly: false,
      subItems: [],
      chevron: undefined
    },
    {
      label: "Alerts",
      url: "/alerts",
      icon: 'alerts_icon',
      adminOnly: false,
      subItems: [],
      chevron: undefined
    },
    {
      label: "Configurations",
      url: "/configurations",
      icon: 'subscription_icon',
      adminOnly: false,
      subItems: [
        { label: "My Subscriptions", url: "/reports/subscriptions", adminOnly: false, navItemName: 'configurations' },
        { label: "File Storage", url: "/reports/storage", adminOnly: false, navItemName: 'configurations' },
      ],
      chevron: arrowUp ? <SideNavBarIcons iconName={"arrow-down"} /> : <SideNavBarIcons iconName={"arrow-up"} />
    },
    {
      label: "Administration",
      url: "/admin/subscriptions",
      icon: 'setting_icon',
      adminOnly: true,
      subItems: [
        { label: "User Management", url: "/admin/userManagement", adminOnly: true, navItemName: 'admin' },
        { label: "All Subscriptions", url: "/admin/subscriptions", adminOnly: true, navItemName: 'admin' },
        { label: "Settings", url: "/admin/settings", adminOnly: true, navItemName: 'admin' },
      ],
      chevron: isAdministrationUp ? <SideNavBarIcons iconName={"arrow-down"} /> : <SideNavBarIcons iconName={"arrow-up"} />
    },
    {
      label: "Contact Support",
      url: "/support",
      icon: 'support_icon',
      adminOnly: false,
      subItems: [],
      chevron: undefined
    },
  ];

  useEffect(() => {
    function handleResize() {
      setIsCollapsed(isMobile || window.innerWidth < 600);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const collapsed = isCollapsed ? "sidebar-collapsed": "sidebar-mobile";
  const responsiveClass =  window.innerWidth < 600 ? collapsed: "sidebar";

  // Render
  return (
    isDashboardInFullscreen?<></>:<div className={responsiveClass}>
      <Box
        id="side-nav-container"
        ref={navContainer}
        className={isCollapsed ? "collapsed" : ""}
      >
        <div id="nav-items-container" data-testid="sidebar-items">
          <div className="nav-item no-select side-nav-logo" data-testid="side-nav-logo">
            <img
              className="side-nav-logo-img"
              src={"/apex-logo.png"}
              alt="Apex Insights Portal"
            />
            <span data-testid="side-nav-logo-menutitle" className="side-nav-logo-text">Apex Insights Portal</span>
          </div>
          {(window.innerWidth > 600) && <div className="nav-item no-select collapse-navigation" data-testid="collapse-navigation" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ?
              <SideNavBarIcons iconName={"collapse_arrow_right"} />
              : <SideNavBarIcons iconName={"collapse_arrow_left"} />}
            {/* <span data-testid="collapse-navigation-menutitle" className="nav-collapse">Collapse Navigation</span> */}
          </div>}
          {NavigationMenu.map((menu, i) => {
            return <SideNavItem
              key={menu.label}
              label={menu.label}
              url={menu.url}
              icon={menu.icon}
              adminOnly={menu.adminOnly}
              subItems={menu.subItems}
              chevrun={menu.chevron}
              isAdmin={isAdmin}
              isCollapsed={isCollapsed}
              setIsCollapsed={setIsCollapsed}
              isAdministrationUp={isAdministrationUp}
              setIsAdministrationUp={setIsAdministrationUp}
              arrowUp={arrowUp}
              setArrowUp={setArrowUp}
              setPageName={setPageName}
            />
          })}

        </div>
        <div
          id="side-nav-bottom-container"
          className={isCollapsed ? "collapsed" : ""}
          ref={userCardNavRef}
        >
          <UserCard isCollapsed={isCollapsed} />
        </div>
      </Box>
    </div>
        
  );
}

export default SideNavBar;