import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Checkbox, FormControl, Grid, IconButton, InputAdornment, Typography } from "@mui/material";
import React,{ useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import { ReportCategory } from "../../redux/models/reportCategory";
import { FilteredFileStorage, isUserReportHistoryLoading, selectBlob, selectFileName, selectFileStorageFilters, selectFilteredReportHistory, selectReportHistorySearchKeyword, selectSelectedUserReportHistoryCategory } from "../../redux/selectors/history.selector";
import { reportActions } from "../../redux/actions/report.actions";
import { SearchTextField } from "../Common/Utils";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import "../MySubscriptions/Subscriptions.scss";
import ReportTypeDropdown from "../MySubscriptions/ReportTypeDrowdown";
import DateRangeFilter from "../MySubscriptions/DateRangeFilter";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { Filter } from "../../redux/models/filter";
import FileStorageTable from "./FileStorageTable";
import { isUserAdministrator } from "../../redux/selectors/user.selector";
import { SignalRTargets } from "../../redux/models/signalRTargets";
import useMyAuth from "../../hooks/useMyAuth";
import { AppState } from "../../redux/reducers";
import appConfig from "../../utils/appConfig";


function FileStoragePageL() {
    const dispatch = useDispatch();
    const selectedCategory = useSelector(selectSelectedUserReportHistoryCategory);
    const searchKeyword = useSelector(selectReportHistorySearchKeyword);
    const filters = useSelector(selectFileStorageFilters);
    const userReportHistoryLoading = useSelector(isUserReportHistoryLoading);
    const filteredUserReportHistory = useSelector(selectFilteredReportHistory);
    const isAdmin = useSelector(isUserAdministrator);
    const [isReportShare, setIsReportShare]=useState<boolean>(false);
    const [isFileDownloaded,setIsFileDownloaded]=useState<boolean>(false);
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const [statusType,setStatusType]=useState<string>('');
  
    const { sid} = useParams<{ sid: string }>();
    const location = useLocation();
    const sharelink = new URLSearchParams(location.search).get("sharelink");
    
    const { token } = useMyAuth();

    const reportReducer = useSelector((state: AppState) => state.reportReducer);
    const connectionRef = React.useRef(connection);
    const reportReducerRef = React.useRef(reportReducer);
    reportReducerRef.current = reportReducer;
    const { deleteLoading } = reportReducer;
    const [page, setPage] = useState(1);

    let blob = useSelector(selectBlob);
    const fileName = useSelector(selectFileName);

    const loadUserReportHistory = (page:number) => {
      if (sid === undefined&&sharelink==undefined) {
        dispatch(reportActions.getUserReportHistory(page||1,filteredUserReportHistory));
      } else if(sharelink==undefined){
        dispatch(
          reportActions.getUserReportHistoryBySubscriptionId(parseInt(sid))
        );
      }
      else{
        setIsReportShare(true);
        dispatch(
          reportActions.getUserReportHistoryByToken(sharelink)
        );
      }
    }
    const handleChange = (e:any, p:number) => {
      console.log(e)
      console.log(JSON.stringify(filteredUserReportHistory));
      setPage(page+1);
      loadUserReportHistory(page+1);
    };
    const setSelectedCategory = (category: ReportCategory) =>
    dispatch(reportActions.setSelectedReportHistoryCategory(category));

    const searchReportHistory = (keyword: string) =>
    dispatch(reportActions.searchReportHistory(keyword));
    const setFilters = (filters: Filter<FilteredFileStorage>[]) => {
        dispatch(reportActions.applyFileStorageFilters(filters));
      }
    const clearSearch = () => dispatch(reportActions.searchReportHistory(""));
    function setDateFilter(
        index: number,
        values: any[],
        newValue: DateRange<Dayjs>
      ) {
        filters[index].values = values;
        filters[index].rawValues = newValue;
        setFilters(filters);
      }

      useEffect(() => {
        loadUserReportHistory(page);
      }, [deleteLoading]);
    
      const cleanupConnection = () => {
        console.log("Cleaning up...");
        if (connectionRef.current) {
          connectionRef.current.stop()
          .then(() => {
            console.log("successfully SIGNALR DISCONNECTED");
          })
          .catch(() => {
            console.log("something went wrong");
          });
          console.log("SIGNALR DISCONNECTED");
        }
      };
      // Called when mounted
      useEffect(() => {
        return () => {
          cleanupConnection();
        };
      }, []);
      useEffect(() => {
        if(filteredUserReportHistory?.length&&isReportShare&&!isFileDownloaded){
          const exportFile=filteredUserReportHistory[0]?.userReportHistoryExportDetails[0];
            const id=exportFile.userReportHistoryExportDetailsId;
            const fileName=filteredUserReportHistory[0].name ?? "";
            const fileExtension=exportFile.storageURL?.split(".")?.pop()?.toLowerCase() ?? ""
          dispatch(reportActions.getExportedFile(id, `${fileName}.${fileExtension}`));
          setIsFileDownloaded(true);
        }
      }, [filteredUserReportHistory]);
    
      const _setConnection = (data: HubConnection | null) => {
        connectionRef.current = data;
        setConnection(data);
      };
    
      const SignalRCallback_status = (
        userReportHistoryId: number,
        userReportHistoryStatus: string,
        userReportHistoryExportDetailsId: number,
        userReportHistoryExportDetailsStatus: string,
        progressComplete: number
      ) => {
        if (
          reportReducerRef.current.userReportHistory.filter(
            (x) => x.userReportHistoryId === userReportHistoryId
          ).length > 0
        ) {
          dispatch(
            reportActions.setUserReportHistoryProgress(
              userReportHistoryId,
              userReportHistoryExportDetailsId,
              progressComplete,
              userReportHistoryStatus,
              userReportHistoryExportDetailsStatus
            )
          );
        }
      };
    
      // Called when user authenticated
      useEffect(() => {
        if (token) {
          const hubConnection = new HubConnectionBuilder()
            .withUrl(`${appConfig.apiBaseUrl}/reportHub`, {
              accessTokenFactory: () => token,
            })
            .withAutomaticReconnect()
            .build();
    
          hubConnection.on(
            SignalRTargets.reportUpdate_status,
            SignalRCallback_status
          );
    
          _setConnection(hubConnection);
    
          hubConnection
            .start()
            .then(() => {
              console.log("SignalR Connected");
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [token]);
    
      useEffect(() => {
        if (blob && fileName) {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          a.click();
          // blob = undefined;
        }
      }, [blob, fileName]);
      
      const sortByStatus = (type:string) =>{
        setStatusType(type);
        dispatch(reportActions.sortReportHistory(type));
      }
      
    return (
    <div id="filestorage-container" data-testid="subscription-container">
        <Grid container spacing={0} className="sub-header">
          <Grid item xs={12} lg={1.5} xl={1} md={2} sm={3.5} className="sub-title">
            <Typography>File Storage</Typography>
          </Grid>
          <Grid item xs={12} xl={2} lg={3} md={2} sm={3.5} className="cat-list">
            <ReportTypeDropdown
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                    />
          </Grid>
        </Grid>
        <Grid container spacing={0} className="filter-section">
          <Grid item lg={2.8} xl={2.8} md={3} xs={12} sm={6} className="search-container">
          <FormControl sx={{ m: 1 }}>
              <SearchTextField
                id="search-bar"
                value={searchKeyword}
                color="primary"
                size="small"
                onChange={(event) => searchReportHistory(event.target.value)}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={clearSearch}
                        edge="end"
                      >
                        {searchKeyword.length > 0 ? (
                          <ClearIcon />
                        ) : (
                          <SearchIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={searchKeyword.length === 0 ? "Search" : ""}
              />
            </FormControl>
          </Grid>
          {filters?.map((filter, i) => {
              if (filter.filterType === "date") {
                return(
                  <Grid key={filter.name} data-testid="filestorage-daterange-list" className={filter.name} item xl={3} lg={3} md={6} xs={12} sm={6}>
                    <DateRangeFilter
                            key={filter.name}
                            index={i}
                            displayName={filter.displayName}
                            values={filter?.rawValues ?? [null, null]} 
                            setFilter={setDateFilter}                    />
                  </Grid>
                    );
                }
              })}
          <Grid item lg={6.2} xl={6.2} md={12} sm={12} xs={12} className="legend">
            <Typography>Sort By:</Typography>
            <div className="status in-progress">
                  {/* <span className="status-name">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <circle cx="6" cy="6" r="6" fill="#33933D"/>
                    </svg>
                    ALL
                    <Checkbox  size="small" defaultChecked />
                  </span> */}
            </div>
            <div className="status queue">
                  <span className="status-name">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <circle cx="6" cy="6" r="6" fill="#33933D"/>
                    </svg>
                    In Queue
                    <Checkbox  size="small" onChange={()=>sortByStatus("isQueued")} checked={statusType=='isQueued'} />
                  </span>
            </div>
            <div className="status in-progress">
                  <span className="status-name">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <circle cx="6" cy="6" r="6" fill="#33933D"/>
                    </svg>
                    In Progress
                    <Checkbox  size="small" onChange={()=>sortByStatus("isRunning")} checked={statusType=='isRunning'} />
                  </span>
            </div>
            <div className="status succcess">
                  <span className="status-name">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <circle cx="6" cy="6" r="6" fill="#33933D"/>
                    </svg>
                    Completed
                    <Checkbox  size="small" onChange={()=>sortByStatus("isComplete")} checked={statusType=='isComplete'} />
                  </span>
            </div>
            <div className="status error-block">
                  <span className="status-name">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <circle cx="6" cy="6" r="6" fill="#33933D"/>
                    </svg>
                    Error
                    <Checkbox  size="small" onChange={()=>sortByStatus("isPartial")} checked={statusType=='isPartial'}/>
                  </span>
            </div>
          </Grid>
        </Grid>

        <FileStorageTable userReportHistoryLoading={userReportHistoryLoading} filteredUserReportHistory={filteredUserReportHistory} isAdmin={isAdmin} isReportShare={isReportShare} setStatusType={setStatusType} handleChange={handleChange} page={page} />

    </div>
    );
}

export default FileStoragePageL