export interface ReportCategory {
    categoryId: number;
    name: string;
    description: string;
    active: boolean;
    color?: string;
    emailSubject?: string;
    emailBody?: string;
    createdDate?: Date;
    modifiedDate?: Date;
}


export function CreateDefaultReportCategory(): ReportCategory {
    let ret: ReportCategory = {
        categoryId: 0,
        name: "",
        description: "",
        active: true,
    }
    return ret;
}