import appConfig from "../../utils/appConfig";
import { FetchUtils } from "../../utils/fetch.utils";
import { ContactForm } from "../models/contactForm";


const apiBaseUrl = appConfig.apiBaseUrl;
const contactApi = 'contact';


const getContactForm = (): Promise<ContactForm> => {
    const URL = `${apiBaseUrl}/${contactApi}/GetContactForm`;
    let response = FetchUtils.GET<ContactForm>(URL);
    return response;
};

const submitContactForm = (form: ContactForm): Promise<boolean> => {
    const URL = `${apiBaseUrl}/${contactApi}/SubmitContactForm`;
    let response = FetchUtils.POST<boolean>(URL, form);
    return response;
};


export const contactService = {
    getContactForm,
    submitContactForm,
};
