import "../StatusPages.scss";

function NotFoundPage() {
  return (
    <div id="status-page">
      <div id="status-container">
        <div className="status-message-container">
          <img
            className="apex-logo"
            src="/apex-logo-h.png"
            alt="Apex Global - Insights Portal"
          ></img>
          <h2>Whoops.</h2>
          <p>
            The page you are seeking may have been removed, renamed or it is
            temporarily unavailable.
          </p>
          <a href="/">Return Home</a>
        </div>
        <div className="status-code-container">
          <h1>404</h1>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
